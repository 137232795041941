/**
 * @flow
 */
import { FormHelperText, Grid, InputLabel } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const Control = ({ name, control }: { name: String; control: React.Node }) => {
  const { errors, submitCount } = useFormikContext();
  return (
    <>
      <Grid item xs={2}>
        <InputLabel htmlFor={name} sx={{ mt: 1.25 }}>
          {I18n.t(`WhistleblowerPortalConfig.atts.${name}`)}
        </InputLabel>
      </Grid>
      <Grid item xs={10}>
        {control}
        <FormHelperText
          sx={{
            display: errors[name] && submitCount > 0 ? 'block' : 'none',
          }}
          error
        >
          {errors[name]}
        </FormHelperText>
      </Grid>
    </>
  );
};

export default Control;
