import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Typography,
} from '@mui/material';
import MessagesIcon from 'assets/icons/messages.svg';
import { DrawerProvider } from 'components/@home/@documents/DrawerProvider';
import DocumentFormatEditDrawer, {
  EditDrawerContext as DocumentFormatEditDrawerContext,
} from 'components/@home/@documents/SentDocuments/EditDrawer';
import DocumentFormatSendDrawer from 'components/@home/@documents/SentDocuments/SendDrawer';
import { SendDrawerContext as DocumentFormatSendDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import SendDrawer, {
  SendDrawerContext,
} from 'components/@home/@documents/SharedDocuments/SendDrawer';
import ShareDrawer, {
  ShareDrawerContext,
} from 'components/@home/@documents/SharedDocuments/ShareDrawer';
import FolderArrowRightIcon from 'mdi-react/FolderArrowRightIcon';
import FolderHomeIcon from 'mdi-react/FolderHomeIcon';
import FolderSharedIcon from 'mdi-react/FolderSharedIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, Outlet, useLocation } from 'react-router-dom';
import headerActions from 'store/app/ui/header/action';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';
import isTeamAdminSelector from 'store/selectors/isTeamAdmin';
import useActions from 'utils/useActions';

const icons = [
  ['home', () => <FolderHomeIcon />],
  ['shared', () => <FolderSharedIcon />],
  [
    'chats',
    () => (
      <SvgIcon>
        <MessagesIcon />
      </SvgIcon>
    ),
  ],
  ['sent', () => <FolderArrowRightIcon />, 'teamAdmin'],
  ['separator-1'],
  // ['recent', () => <ClockOutlineIcon />],
  // ['starred', () => <StarOutlineIcon />],
  // ['separator-2'],
  ['deleted', () => <TrashCanOutlineIcon />],
].map(([name, Icon, role = 'member']) => ({ name, Icon, role }));

const DocumentsScene = () => {
  const location = useLocation();
  const isTeamAdmin = useSelector(isTeamAdminSelector);
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);
  const header = useActions(headerActions);
  const channelsPannelOpen = useSelector(state => state.header.channelsPannelOpen);
  return (
    <Grid container sx={{ flex: 1 }}>
      <Grid
        item
        sx={{
          borderRight: 1,
          flex: 0,
          borderColor: 'divider',
          position: { xs: 'absolute', md: 'static' },
          overflow: 'hidden',
          width: { xs: channelsPannelOpen ? 300 : 0, md: 'auto' },
          backgroundColor: 'background.paper',
          zIndex: { xs: 2, md: 0 },
          minWidth: { md: 300 },
        }}
      >
        <Box sx={{ padding: 2 }}>
          <Typography variant="h5">{I18n.t('DocumentsScene.Title')}</Typography>
        </Box>
        <List>
          {icons
            .filter(
              i =>
                i.role === 'member' ||
                (i.role === 'teamAdmin' && isTeamAdmin) ||
                (i.role === 'companyAdmin' && isCompanyAdmin),
            )
            .map(({ name, Icon }) =>
              name.startsWith('separator-') ? (
                <Box
                  key={name}
                  sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    marginTop: 1,
                    marginBottom: 1,
                  }}
                />
              ) : (
                <ListItemButton
                  key={name}
                  to={`${name}/`}
                  component={Link}
                  selected={!!location.pathname.match(new RegExp(`/documents/${name}(/|$)`))}
                  sx={{ paddingLeft: 4, paddingRight: 4 }}
                  onClick={() => channelsPannelOpen && header.toggleChannelsPanel()}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      whiteSpace: 'nowrap',
                      borderBottom: 0,
                      minHeight: 0,
                      paddingTop: 0,
                      paddingBottom: 0,
                    }}
                    primary={I18n.t(`DocumentsScene.Folders.${name}`)}
                  />
                </ListItemButton>
              ),
            )}
        </List>
      </Grid>
      <DrawerProvider context={DocumentFormatSendDrawerContext}>
        <DrawerProvider context={DocumentFormatEditDrawerContext}>
          <DrawerProvider context={SendDrawerContext}>
            <DrawerProvider context={ShareDrawerContext}>
              <Outlet />
              <ShareDrawer />
              <SendDrawer />
              <DocumentFormatSendDrawer />
              <DocumentFormatEditDrawer />
            </DrawerProvider>
          </DrawerProvider>
        </DrawerProvider>
      </DrawerProvider>
    </Grid>
  );
};

export default DocumentsScene;
