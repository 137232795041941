import isEmpty from 'lodash/isEmpty';

/**
 * Convert an array of employees to a dictionary, group by "firstName's first letter" in UPPER CASE
 * e.g.
 *    ['John Doe', 'julie Padowan'] => {'J': ['John Doe', 'julie Padowan']}
 */
export default employees =>
  employees.reduce((a, v) => {
    const letter = v.firstName?.substring(0, 1).toUpperCase() || '';
    const letterEmployees = isEmpty(a[letter]) ? [v] : [...a[letter], v];
    return { ...a, [letter]: letterEmployees };
  }, {});
