/**
 * @flow
 */
import { Button, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AnswerBar from 'components/@home/drawers/FormAnalyticsDrawer/AnswerBar';
import QuestionTypeIcon from 'components/@home/drawers/FormDrawer/QuestionTypeIcon';
import { Confirm, confirmOptions } from 'components/@home/drawers/FormDrawer/questionTypes';
import isEmpty from 'lodash/isEmpty';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  question: Object,
  summary: Object,
  index: number,
  showAnswers: Function,
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 8,
    padding: '30px 20px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
  },
  question: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
  },
  icon: {
    fontSize: 18,
    marginRight: 20,
  },
  fullWidth: {
    width: '100%',
  },
  title: {
    fontSize: 15,
    lineHeight: '22px',
    fontWeight: 500,
  },
  answers: {
    marginTop: 10,
  },
  answer: {
    display: 'flex',
    flexGrow: 1,
  },
  showAnswers: {
    width: 180,
    display: 'flex',
    alignItems: 'center',
    marginLeft: 20,
    background: 'none',
    margin: 5,
  },
  pct: {
    width: '3em',
    textAlign: 'right',
    marginRight: '0.5em',
    justifyContent: 'flex-end',
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const QuestionSummary = ({ question, index, summary, showAnswers }: Props) => {
  const classes = useStyles();

  const opts =
    question.type === Confirm
      ? confirmOptions.map(([_id, label]) => ({ _id, label }))
      : Object.values(question.options || {});
  const options = opts.map(({ _id, label }: Object) => ({
    _id,
    label,
    users: summary.users || 0,
    count: summary.answers[_id],
    pct: summary.users ? (summary.answers[_id] / summary.users) * 100 : 0,
  }));
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.icon}>
          <QuestionTypeIcon type={question.type} text={`${index + 1}`} />
        </div>
        <div className={classes.question}>
          <Typography className={classes.title}>{question.title}</Typography>
          <div className={classes.answers}>
            {!isEmpty(options) ? (
              options.map(option => (
                <div key={option._id} className={classes.answer}>
                  <AnswerBar option={option} />
                  <Button
                    variant="text"
                    className={classes.showAnswers}
                    onClick={() => showAnswers(question, option._id)}
                  >
                    <Typography className={classes.pct}>{option.pct.toFixed()}%</Typography>
                    <Typography className={classes.chevron}>
                      (
                      {I18n.t('FormAnalyticsDrawer.responses', {
                        count: option.count,
                      })}
                      )
                      <SvgIcon className={classes.arrowIcon}>
                        <ChevronRightIcon />
                      </SvgIcon>
                    </Typography>
                  </Button>
                </div>
              ))
            ) : (
              <Button
                variant="text"
                onClick={() => showAnswers(question)}
                className={classes.showAnswers}
              >
                <Typography className={classes.chevron}>
                  {I18n.t('FormAnalyticsDrawer.responses', {
                    count: summary.users,
                  })}
                  <SvgIcon className={classes.arrowIcon}>
                    <ChevronRightIcon />
                  </SvgIcon>
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionSummary;
