import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import { node } from 'prop-types';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import currentChannel from 'store/selectors/currentChannel';
import EmptyChat from './EmptyChat';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    overflow: 'hidden',
  },
  loadingChannel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const MessagesWrapper = ({ children }) => {
  const channel = useSelector(currentChannel);
  const classes = useStyles();
  if (isEmpty(channel?.messages) && (channel.isDirect || channel?.loaded)) {
    return (
      <div className={classes.root}>
        <EmptyChat />
      </div>
    );
  }

  if (isEmpty(channel.messages)) {
    return <Loading />;
  }

  return <div className={classes.root}>{children}</div>;
};

MessagesWrapper.propTypes = {
  children: node,
};

MessagesWrapper.defaultProps = {
  children: null,
};

export default memo(MessagesWrapper);
