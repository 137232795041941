/* eslint-disable react/no-danger */
import { makeStyles } from '@mui/styles';
import { string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import authCompany from 'store/selectors/authCompany';
import connector from './connector';
import CustomPaper from './CustomPaper';
import IAcceptTermsButton from './IAcceptTermsButton';
import LegalDoc from './LegalDoc';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  termsBlock: {
    width: 'calc(min(1240px, 80vw))',
    margin: '20px 0',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginTop: '2em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const LegalDocScene = ({ doc }) => {
  const company = useSelector(authCompany);
  const classes = useStyles();
  const navigate = useNavigate();
  const acceptTerms = () => {
    navigate('/welcome/legal', { state: { accepted: true } });
  };

  return (
    <section className={classes.root}>
      <div className={classes.termsBlock}>
        <CustomPaper>
          <LegalDoc>{company[doc]}</LegalDoc>
          <div className={classes.actions}>
            <IAcceptTermsButton onClick={acceptTerms} />
          </div>
        </CustomPaper>
      </div>
    </section>
  );
};

LegalDocScene.propTypes = {
  doc: string.isRequired,
};

export default connector(LegalDocScene);
