/* eslint-disable react/no-access-state-in-setstate */
import { Table, TableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import { connect } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, func, object, string } from 'prop-types';
import React from 'react';
import employeeShape from 'shapes/employee';
import arrayToObject from 'utils/arrayToObject';
import searchEmployees from 'utils/searchEmployees';
import EmployeeRow from './EmployeeRow';
import TableHead from './TableHead';
import Toolbar from './Toolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
  },
});

class EmployeesTable extends React.Component {
  state = {
    order: {
      by: 'name',
      isReverse: false,
    },
    filteredIds: [],
    page: 0,
    rowsPerPage: 10,
    searchString: '',
  };

  selectAll = enabled => {
    const {
      name,
      employees,
      onChange,
      value,
      formik: { initialValues },
      defaultRole: role,
    } = this.props;
    let { filteredIds } = this.state;
    if (isEmpty(filteredIds)) {
      filteredIds = employees.map(e => e._id);
    }
    const newValue = filteredIds.map(
      employee => value.find(v => v.employee === employee) || { employee, role },
    );
    if (enabled) {
      onChange(name, newValue);
      onChange(
        `${name}Removed`,
        initialValues[name]
          .map(({ employee }) => employee)
          .filter(employee => !newValue.some(v => v.employee === employee)),
      );
    } else {
      onChange(name, []);
      onChange(
        `${name}Removed`,
        initialValues[name]
          .map(({ employee }) => employee)
          .filter(employee => newValue.some(v => v.employee === employee)),
      );
    }
  };

  toggleSelect = employee => {
    const {
      name,
      value,
      onChange,
      formik: { values },
      defaultRole: role,
    } = this.props;

    if (value.find(member => member.employee === employee)) {
      onChange(
        name,
        value.filter(member => member.employee !== employee),
      );
      onChange(`${name}Removed`, [...values[`${name}Removed`], employee]);
    } else {
      onChange(name, [...value, { employee, role }]);
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(_id => _id !== employee),
      );
    }
  };

  sort = by => {
    const { order } = this.state;

    this.setState({
      order: { by, isReverse: !order.isReverse },
    });
  };

  search = searchString => {
    const { employees } = this.props;
    const filteredIds = searchEmployees(searchString, employees);

    this.setState({ searchString, filteredIds, page: 0 });
  };

  changePage = ({ selected: page }) => {
    this.setState({ page });
  };

  changeRowsPerPage = e => {
    this.setState({ rowsPerPage: e.target.value });
  };

  filterEmployees = employees => {
    const { filteredIds, searchString } = this.state;
    if (isEmpty(filteredIds) && isEmpty(searchString)) return employees;
    if (isEmpty(filteredIds) && !isEmpty(searchString)) return [];

    const employeesMap = arrayToObject(employees);
    return filteredIds.map(_id => employeesMap[_id]);
  };

  sortEmployees = employees => {
    const { order } = this.state;
    const { value } = this.props;
    const getRoleValue = _id => {
      const member = value.find(m => m.employee === _id) || {};
      switch (member.role) {
        case 'admin':
          return '3';
        case 'member':
          return '2';
        case 'guest':
          return '1';
        default:
          return '0';
      }
    };
    const sorted = employees.sort((prev, next) => {
      let prevElement = prev[order.by] || '';
      let nextElement = next[order.by] || '';
      if (typeof prevElement.join === 'function') {
        prevElement = prevElement.join(',');
        nextElement = nextElement.join(',');
      }
      if (order.by === 'role') {
        prevElement = getRoleValue(prev._id) + prev.name;
        nextElement = getRoleValue(next._id) + next.name;
      }
      return prevElement.localeCompare(nextElement) || prev.name.localeCompare(next.name);
    });

    return order.isReverse ? sorted.reverse() : sorted;
  };

  paginateEmployees = employees => {
    const { page, rowsPerPage } = this.state;
    return employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  setRole = (employee, role) => {
    const { name, value, onChange } = this.props;
    const newValue = value.map(m => (m.employee === employee._id ? { ...m, role } : m));
    onChange(name, newValue);
  };

  render() {
    const { classes, error, value, employees, defaultRole } = this.props;
    const { order, rowsPerPage, page } = this.state;
    const selectedCount = value.length;

    const filtered = this.filterEmployees(employees);
    const sorted = this.sortEmployees(filtered);
    const paginated = this.paginateEmployees(sorted);

    const employeesCount = filtered.length;

    return (
      <div className={classes.root}>
        <Toolbar error={error} selected={selectedCount} onSearch={this.search} />
        <Table className={classes.table} aria-labelledby="employeesTable">
          <TableHead
            order={order}
            selected={selectedCount}
            rows={employeesCount}
            onSort={this.sort}
            onSelectAll={this.selectAll}
          />
          <TableBody>
            {paginated.map(e => (
              <EmployeeRow
                key={e._id}
                employee={e}
                defaultRole={defaultRole}
                member={value.find(m => m.employee === e._id)}
                onSelect={this.toggleSelect}
                onSetRole={this.setRole}
              />
            ))}
          </TableBody>
        </Table>
        <Pagination
          total={employeesCount}
          page={page}
          perPage={rowsPerPage}
          onChange={this.changePage}
        />
      </div>
    );
  }
}

EmployeesTable.propTypes = {
  classes: object.isRequired,
  name: string.isRequired,
  value: arrayOf(string).isRequired,
  error: string,
  employees: arrayOf(employeeShape).isRequired,
  defaultRole: string,
  onChange: func.isRequired,
  formik: object.isRequired,
};
EmployeesTable.defaultProps = {
  error: null,
  defaultRole: null,
};

export default withStyles(styles)(connect(EmployeesTable));
