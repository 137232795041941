import { Typography } from '@mui/material';
import List from '@mui/material/List';
import makeStyles from '@mui/styles/makeStyles';
import AppsSettingsIcon from 'assets/icons/appSettings.svg';
import BotSettingsIcon from 'assets/icons/botSettings.svg';
import ChatSettingsIcon from 'assets/icons/chatSettings.svg';
import GeneralSettingsIcon from 'assets/icons/generalSettings.svg';
import TeamSettingsIcon from 'assets/icons/teamsSettings.svg';
import SettingsItem from 'components/@home/@settings/SettingsMenu/SettingsItem';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.secondary.extraLight}`,
    backgroundColor: theme.palette.background.paper,
    width: 250,
  },

  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  listRoot: {
    padding: 10,
  },
  itemRoot: {
    minHeight: 50,
    borderRadius: 5,
    '& + & $itemTextRoot': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
  itemSelected: {
    backgroundColor: theme.palette.secondary.ultraLight,
    '& $itemTextRoot': {
      borderBottom: '0 !important',
      borderTop: '0 !important',
    },
    '& $itemTextPrimary': {
      fontWeight: 600,
    },
    '& + $itemRoot $itemTextRoot': {
      borderTop: '0 !important',
    },
  },
  itemTextRoot: {
    minHeight: 0,
    margin: 0,
    borderBottom: 0,
  },
  itemTextPrimary: {},
}));

const icons = {
  general: <GeneralSettingsIcon />,
  teams: <TeamSettingsIcon />,
  chats: <ChatSettingsIcon />,
  apps: <AppsSettingsIcon />,
  chatFlow: <BotSettingsIcon />,
};

const SettingsPannel = () => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <div className={classes.head}>
        <Typography variant="h5">{I18n.t('SettingsMenu.Title')}</Typography>
      </div>
      <List classes={{ root: classes.listRoot }}>
        {Object.entries(icons).map(([name, icon], index) => (
          <SettingsItem name={name} key={name} icon={icon} index={index} />
        ))}
      </List>
    </section>
  );
};

export default SettingsPannel;
