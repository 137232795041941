/** @flow */
import { ListItemButton, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import channels from 'store/app/entities/channels/action';
import direct from 'store/app/ui/drawers/direct/action';
import authEmployeeSelector from 'store/selectors/authEmployee';
import useActions from 'utils/useActions';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    marginRight: 15,
  },
  location: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const EmployeeListItem = ({ employee }: { employee: Object }) => {
  const classes = useStyles();
  const authEmployee = useSelector(authEmployeeSelector);
  const [close, channelSet] = useActions([direct.close, channels.set]);
  const navigate = useNavigate();
  const name = useMemo(
    () => `${employee.firstName || ''} ${employee.surName || ''}`.trim(),
    [employee.firstName, employee.surName],
  );
  const createChannel = useCallback(async () => {
    const channel = {
      _id: employee.user_id,
      name,
      isDirect: true,
      isActive: true,
      isReadOnly: false,
      avatar: employee.avatar,
      company_id: employee.company,
      complete: false,
      loadingMessages: false,
      messages_ids: [],
      page: 1,
      messagesUnreadCount: 0,
      messagesTotalCount: 0,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      lastMessage_id: null,
      isDeleted: false,
      employees_ids: authEmployee ? [employee._id, authEmployee._id] : [employee._id],
    };
    await channelSet(channel);
    navigate(`/home/messages/${channel._id}`, { state: { channel } });
    close();
  }, [
    authEmployee,
    channelSet,
    close,
    employee._id,
    employee.avatar,
    employee.company,
    employee.user_id,
    name,
    navigate,
  ]);

  const employeeSecondaryParts = useMemo(
    () => [employee.jobTitle, employee.department],
    [employee.jobTitle, employee.department],
  );

  return (
    <ListItemButton disableGutters onClick={createChannel} className={classes.root}>
      <EmployeeAvatar className={classes.avatar} large employee={employee} />
      <ListItemText primary={name} secondary={employeeSecondaryParts.filter(Boolean).join(', ')} />
      <ListItemText className={classes.location} secondary={employee.location} />
    </ListItemButton>
  );
};

export default EmployeeListItem;
