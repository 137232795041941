import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import employeeDrawer from 'store/app/ui/drawers/employee/action';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    drawers: {
      employee: bindActionCreators(employeeDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
