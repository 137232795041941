import { Box, Drawer, Typography } from '@mui/material';
import { CompanyForm } from 'components/@admin/@companies/CompanyForm';
import CloseButton from 'components/@home/drawers/CloseButton';
import { Formik } from 'formik';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Company from 'services/api/Company';
import {
  useCreateCompanyMutation,
  useEditCompanyMutation,
  useListCompaniesQuery,
} from 'store/app/entities/CompaniesSlice';
import privacyEs from './privacy_es.html';
import termsEs from './terms_es.html';

const getBody = async values => {
  const {
    address,
    allowAnonymousEmployees,
    billingContactName,
    billingContactSurname,
    businessName,
    city,
    contactEmail,
    country,
    defaultMobileNotifications,
    demo,
    divisions,
    dpoEmail,
    supportEmail,
    featureFlags,
    fromTemplate,
    language,
    languages,
    name,
    privacy,
    state,
    stock,
    stripeCustomerId,
    subDomain,
    subscription,
    template,
    terms,
    timeZone,
    vatIDNumber,
    webSiteUrl,
    zip,
  } = values;
  const Mustache = (await import('mustache')).default;
  const allTags = new Set();
  Mustache.parse(privacy)
    .filter(([key, value]) => key === 'name' && value.startsWith('company.'))
    .forEach(([, curr]) => allTags.add(curr));
  Mustache.parse(terms)
    .filter(([key, value]) => key === 'name' && value.startsWith('company.'))
    .forEach(([, curr]) => allTags.add(curr));
  const c = Array.from(allTags).reduce((ret, key) => {
    const bodyKey = key.replace('company.', '');
    return { ...ret, [bodyKey]: values[bodyKey] || `{{${key}}}` };
  }, {});
  const renderedPrivacy = Mustache.render(privacy, { company: c });
  const renderedTerms = Mustache.render(terms, { company: c });
  return {
    address,
    allowAnonymousEmployees: allowAnonymousEmployees?.[0] === 'on' ? 1 : 0,
    billingContactName,
    billingContactSurname,
    businessName,
    city,
    contactEmail,
    country,
    defaultMobileNotifications,
    demo: demo?.[0] === 'on' ? 1 : 0,
    divisions,
    dpoEmail,
    supportEmail,
    featureFlags,
    fromTemplate,
    language,
    languages,
    name,
    privacy: renderedPrivacy,
    state,
    stock,
    stripeCustomerId,
    subDomain,
    subscription: subscription?.status
      ? {
          ...subscription,
          status: subscription.status.toLowerCase(),
        }
      : subscription,
    template: template?.[0] === 'on' ? 1 : 0,
    terms: renderedTerms,
    timeZone,
    vatIDNumber,
    webSiteUrl,
    zip,
  };
};

const CompanyDrawer = ({ company, isOpen, onClose }) => {
  const isEdit = company?._id;
  const [createCompany, createCompanyStatus] = useCreateCompanyMutation();
  const [editCompany, editCompanyStatus] = useEditCompanyMutation();
  const { error, isLoading, isError } = {
    error: createCompanyStatus.error || editCompanyStatus.error,
    isLoading: createCompanyStatus.isLoading || editCompanyStatus.isLoading,
    isError: createCompanyStatus.isError || editCompanyStatus.isError,
  };
  const { data: { companies: templates } = {}, isFetching } = useListCompaniesQuery({
    template: true,
    perPage: 100,
    sortBy: 'name',
    sort: 'asc',
  });
  const handleSubmit = async ({ _id, logo, logoDark, onBoardingImage, ...values }) => {
    const body = await getBody(values);
    const ret = _id ? await editCompany({ _id, body }) : await createCompany(body);
    const companyId = ret.data.data.company._id;

    const images = { logo, logoDark, onBoardingImage };
    await Promise.all(
      Object.entries(images).map(async ([key, value]) => {
        if (value?.length === 1 && value[0] instanceof File) {
          images[key] = await Company.upload(companyId, value[0]);
        } else {
          delete images[key];
        }
      }),
    );

    if (Object.keys(images).length) {
      await editCompany({ _id: companyId, body: images });
    }

    if (!ret.error) {
      onClose();
    }
  };
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: {
            xs: '100%',
            md: 1100,
          },
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            paddingTop: 2.5,
            paddingLeft: 2.5,
          }}
        >
          <CloseButton onClick={onClose} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            minWidth: 500,
            flexDirection: 'column',
            padding: 2.5,
          }}
        >
          <Typography variant="h6" component="h1">
            {isEdit
              ? I18n.t(`CompanyDrawer.Edit`, { name: company.name })
              : I18n.t(`CompanyDrawer.Create company`)}
          </Typography>
          <Formik
            initialValues={{
              ...company,
              timeZone: company.timeZone || 'Europe/Madrid',
              language: company.language || 'es',
              languages: company.languages || ['es', 'en'],
              allowAnonymousEmployees: company.allowAnonymousEmployees ? ['on'] : [],
              demo: company.demo ? ['on'] : [],
              template: company.template ? ['on'] : [],
              divisions: company.divisions || [],
              featureFlags: company.featureFlags || [],
              privacy: company.privacy || privacyEs,
              terms: company.terms || termsEs,
              defaultMobileNotifications: company.defaultMobileNotifications || 'Restricted',
            }}
            onSubmit={handleSubmit}
          >
            <CompanyForm
              company={company}
              onClose={onClose}
              error={error}
              isLoading={isLoading || isFetching}
              isError={isError}
              templates={templates || []}
            />
          </Formik>
        </Box>
      </Box>
    </Drawer>
  );
};

CompanyDrawer.propTypes = {
  company: object,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

CompanyDrawer.defaultProps = {
  company: {},
};

export default CompanyDrawer;
