import { Button, SvgIcon } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Next from 'assets/icons/next.svg';
import classNames from 'classnames';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useMe } from 'store/app/auth/action';
import companyActions from 'store/app/entities/companies/action';
import authCompany from 'store/selectors/authCompany';
import authEmployee from 'store/selectors/authEmployee';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import useActions from 'utils/useActions';
import img0 from './images/onboarding-mock-0.png';
import img1 from './images/onboarding-mock-1.png';
import img2 from './images/onboarding-mock-2.png';
import img3 from './images/onboarding-mock-3.png';

const imgs = [img0, img1, img2, img3];

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ONBOARDING_STEPS = 4;

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  paper: {
    width: '580px',
    maxWidth: '100%',
    maxHeight: '720px',
    margin: '1em 0',
    display: 'flex',
    flexDirection: 'column',
    boxShadow:
      '0px 11px 15px -7px rgba(0,0,0,0.05), ' +
      '0px 24px 38px 3px rgba(0,0,0,0.02), ' +
      '0px 9px 46px 8px rgba(0,0,0,0.01)',
    flexGrow: 1,
    overflow: 'hidden',
  },
  imgWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.75em',
  },
  img: {
    flexGrow: 1,
    minHeight: '200px',
    backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 0.4)), url(${img0})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  step0: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  step1: {
    backgroundImage: `url(${img1})`,
  },
  step2: {
    backgroundImage: `url(${img2})`,
  },
  step3: {
    backgroundImage: `url(${img3})`,
  },
  heading: {
    maxWidth: 290,
  },
  bottom: {
    textAlign: 'center',
    padding: '0 3em',
  },
  title: {
    margin: '1em 0 0.5em 0',
  },
  description: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    marginBottom: '2em',
  },
  icon: {
    width: 16,
    height: 16,
  },
  arrowButton: {
    '& :first-child': {
      marginRight: '0.25em',
    },
  },
  link: {
    placeSelf: 'center',
  },
  enterButton: {
    padding: '0 3em',
    marginBottom: '4em',
    '& *': {
      verticalAlign: 'center',
    },
  },
  mobileStepper: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
  },
  logo: {
    maxHeight: 60,
    maxWidth: 200,
    textAlign: 'center',
    marginBottom: 20,
  },
}));

const OnboardingScene = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const company = useSelector(authCompany);
  const employee = useSelector(authEmployee);
  const isSuperAdmin = useSelector(isSuperAdminSelector);

  const handleStepChange = useCallback(step => {
    setActiveStep(step);
  }, []);

  const employeeName = useMemo(
    () => (isSuperAdmin ? 'Super Admin' : employee?.name || ''),
    [employee, isSuperAdmin],
  );

  return (
    <section className={classes.root}>
      <Paper className={classes.paper} elevation={24}>
        <AutoPlaySwipeableViews
          axis="x"
          interval={5000}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          className={classes.imgWrapper}
          containerStyle={{
            flexGrow: 1,
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            alignContent: 'stretch',
          }}
          slideStyle={{ display: 'flex' }}
        >
          {imgs.map((img, step) => (
            <div
              key={activeStep}
              className={classNames(classes[`step${step}`], classes.img)}
              style={
                step === 0 && company.onBoardingImage
                  ? {
                      backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 0.4)), url(${company.onBoardingImage})`,
                    }
                  : {}
              }
            >
              {step === 0 && company.logo ? (
                <img className={classes.logo} src={company.logo} alt={company.name} />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={ONBOARDING_STEPS}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
        />
        <AutoPlaySwipeableViews
          axis="x"
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {[0, 1, 2, 3].map(step => (
            <div key={step} className={classes.stepWrapper}>
              <section className={classes.bottom}>
                <Typography className={classes.title} gutterBottom variant="h5">
                  {I18n.t(`Onboarding_Screen.Slide_${step + 1}_Title`, {
                    name: employeeName,
                  }).replace(' !', '!')}
                </Typography>
                <Typography variant="body1" className={classes.description}>
                  {I18n.t(`Onboarding_Screen.Slide_${step + 1}_Description`, {
                    name: company.name,
                  })}
                </Typography>
              </section>
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <Link to="/welcome/legal" className={classes.link}>
          <Button
            variant="contained"
            className={classNames(classes.enterButton, classes.arrowButton)}
            color="primary"
          >
            <span>{I18n.t('Onboarding_Screen.Enter')}</span>
            <SvgIcon className={classes.icon}>
              <Next />
            </SvgIcon>
          </Button>
        </Link>
      </Paper>
    </section>
  );
};

export default deferRender(OnboardingScene);
