import Pagination from 'components/controls/Pagination';
import React, { Component } from 'react';
import { object, string } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import { MANDATORY_ACKNOWLEDGES_LIMIT } from 'services/api/Mandatory';
import ReadProgressBar from './ReadProgressBar';
import AcknowledgeList from './AcknowledgeList';
import connector from './connector';
import SendToAllButton from './SendToAllButton';

const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  progressSection: {
    borderRadius: 5,
    display: 'flex',
    border: '1px solid #ebeff0',
    padding: 15,
  },
};

class Analytics extends Component {
  componentDidMount() {
    const { actions, mandatoryId } = this.props;
    actions.drawer.analyticsDrawer.fullMandatory.load(mandatoryId, 1);
  }

  handleSend = employee => {
    const { mandatoryId, actions } = this.props;
    actions.drawer.analyticsDrawer.fullMandatory.remind(mandatoryId, employee);
  };

  changePage = ({ selected: page }) => {
    const { actions, mandatoryId } = this.props;
    actions.drawer.analyticsDrawer.fullMandatory.load(mandatoryId, page + 1);
  };

  render() {
    const {
      classes,
      analyticsDrawer: { fullMandatory },
      employees: allEmployees,
      message,
    } = this.props;

    if (!fullMandatory) return null;

    if (fullMandatory.loading) return <Loading />;

    if (!fullMandatory.mandatory) return 'Error';

    const {
      mandatory: {
        acknowledges,
        employeesReadCount,
        employeesTotalCount,
        employees: employeesFromMandatory,
      },
      page,
    } = fullMandatory;

    const employees = employeesFromMandatory.map(e => allEmployees[e._id]).filter(e => e);

    return (
      <div className={classes.root}>
        <div className={classes.progressSection}>
          <ReadProgressBar read={employeesReadCount} total={employeesTotalCount} />
          <SendToAllButton
            onSend={this.handleSend}
            total={employeesTotalCount - employeesReadCount}
          />
        </div>
        <AcknowledgeList
          onSend={this.handleSend}
          acknowledges={acknowledges}
          employees={employees}
          message={message}
        />
        <Pagination
          page={page - 1}
          perPage={MANDATORY_ACKNOWLEDGES_LIMIT}
          total={employeesTotalCount}
          onChange={this.changePage}
        />
      </div>
    );
  }
}

Analytics.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  mandatoryId: string.isRequired,
  message: object.isRequired,
  employees: object.isRequired,
  analyticsDrawer: object.isRequired,
};

export default withStyles(styles)(connector(Analytics));
