import {
  Breadcrumbs,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import GridToolbarSearch from 'components/common/GridToolbarSearch';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import { parseURLQuery } from 'components/common/OmmnioDataGrid/OmmnioDataGrid';
import StyledRadio from 'components/controls/StyledRadio';
import { array, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import connector from './connector';

// eslint-disable-next-line react/prop-types
const CustomToolbar = ({ type, setType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleChange = e => {
    if (type !== e.target.value) {
      setType(e.target.value);
    }
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const currentType = query.get('t') || 'file';
    if (currentType !== type) {
      query.set('t', type);
      navigate(
        {
          search: `?${query.toString()}`,
        },
        { replace: true },
      );
    }
  }, [type]);
  return (
    <GridToolbarSearch fullWidth={false} sx={{ flex: 1 }} forceSearch>
      <FormControl sx={{ ml: 2 }}>
        <FormLabel id="type-radio-label">
          <Typography variant="caption">{I18n.t('ChatDocuments.Show')}</Typography>
        </FormLabel>
        <RadioGroup row aria-labelledby="type-radio-label" onChange={handleChange}>
          {['doc', 'media', 'file'].map(t => (
            <FormControlLabel
              key={t}
              value={t}
              control={<StyledRadio />}
              label={I18n.t(`ChatDocuments.types.${t}`)}
              checked={type === t}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </GridToolbarSearch>
  );
};

const ChatDocuments = ({ chats, documents, total, actions, channelId, folder }) => {
  const [pageSize, setPageSize] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'desc' }]);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const chat = chats[id];
  const location = useLocation();
  const [type, setType] = useState(parseURLQuery(location).type || 'file');

  useEffect(() => {
    if (pageSize) {
      actions.media.docs(
        page + 1,
        pageSize,
        id,
        sortModel?.[0]?.field || 'createdAt',
        sortModel?.[0]?.sort || 'desc',
        type,
      );
    }
  }, [actions.media, id, page, pageSize, sortModel, type]);
  useEffect(() => {
    if (!location.pathname.match(/\/home\/documents\/[^/]+\/[\w]+/)) {
      actions.media.reset();
    }
  }, [actions.media, location.pathname]);
  const rows = channelId === id ? documents.map(d => ({ ...d, kind: 'Document' })) : [];
  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      renderCell: renderFolderCell(folder, { search: location.search }, 'chats'),
    },
    {
      field: 'createdAt',
      type: 'string',
      flex: 1,
      editable: false,
      renderCell: renderDateTimeCell,
    },
  ];
  return (
    <Grid
      item
      sx={{ padding: 2, paddingBottom: 0, flex: 1, display: 'flex', flexDirection: 'column' }}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          to={{ pathname: `/home/documents/${folder}/`, search: location.state?.search || '' }}
          onClick={() => setPage(0)}
        >
          <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
            {I18n.t(`DocumentsScene.Folders.${folder}`)}
          </Typography>
        </Link>
        <Typography variant="h3">{chat.name}</Typography>
      </Breadcrumbs>
      <OmmnioDataGrid
        loading={!pageSize}
        sx={{ marginTop: 2 }}
        page={page}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        onPageChange={setPage}
        columns={columns}
        rows={rows}
        rowCount={total}
        paginationMode="server"
        getRowId={r => r._id}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        defaultSortModel={[{ field: 'createdAt', sort: 'desc' }]}
        updateHistory
        disableEvenOdd
        components={{ Toolbar: () => <CustomToolbar type={type} setType={setType} /> }}
        extraUrlParams={{ t: type }}
      />
    </Grid>
  );
};

ChatDocuments.propTypes = {
  chats: object.isRequired,
  actions: object.isRequired,
  documents: array.isRequired,
  total: number.isRequired,
  channelId: string.isRequired,
  folder: string.isRequired,
};

export default connector(ChatDocuments);
