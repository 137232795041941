/** @flow */
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  label?: string;
  labelPrefix?: string;
  name: string;
  control?: Node;
};
const ControlLabel = forwardRef(
  ({ label, labelPrefix, name, control: Control, ...rest }: Props, ref) => {
    return (
      <>
        <label htmlFor={name}>
          <Typography>{label || I18n.t(`${labelPrefix}.${name.split('.').pop()}`)}</Typography>
        </label>
        <Control id={name} name={name} ref={ref} {...rest} />
      </>
    );
  },
);

ControlLabel.defaultProps = {
  control: TextField,
  label: '',
  labelPrefix: '',
};

export default ControlLabel;
