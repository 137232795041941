/* eslint-disable no-underscore-dangle */
import { Button, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import LineClampTooltip from 'components/common/LineClampTooltip';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import deferRender from 'defer-render-hoc';
import isObject from 'lodash/isObject';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import HelpCircleOutlineIcon from 'mdi-react/HelpCircleOutlineIcon';
import { func, object, string } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { readXlsxFromFile, readXlsxFromFileData, toJson } from 'utils/xlsxUtils';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflowY: 'auto',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '& $tabs': {
      marginTop: theme.spacing(2),
    },
    padding: 20,
  },
  grid: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  colHeader: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
  check: {
    width: 14,
    color: theme.palette.ok,
  },
  notFound: {
    width: 14,
    color: theme.palette.danger,
  },
  iconWrapper: {
    display: 'flex',
  },
  'status-created': {
    color: lighten(theme.palette.success.main, 0.8),
  },
  'status-updated': {
    color: lighten(theme.palette.info.main, 0.8),
  },
  'status-skipped': {
    color: lighten(theme.palette.error.main, 0.8),
  },
  tabs: {},
  tabTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span + span': {
      marginLeft: theme.spacing(2),
    },
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& button + button': {
      marginLeft: theme.spacing(2),
    },
  },
}));

const renderHeader = (fieldName, classes) => params => {
  let Icon = () => null;
  if (params.field !== '_message') {
    Icon = fieldName ? CheckCircleIcon : HelpCircleOutlineIcon;
  }
  const className = fieldName ? classes.check : classes.notFound;
  return (
    <div className={classes.colHeader}>
      <Tooltip
        title={I18n.t(fieldName ? 'ImportsScene.FieldFound' : 'ImportsScene.FieldNotFound', {
          name: params.field,
        })}
      >
        <div className={classes.iconWrapper}>
          <Icon className={className} />
        </div>
      </Tooltip>
      &nbsp;
      <Typography variant="tableHeader">{params.colDef.headerName}</Typography>
    </div>
  );
};

const ImportPreview = ({
  importPreview,
  file,
  onCancel,
  downloadResumedImport,
  fileData,
  onStart,
  resultStatus,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [, setFailed] = useState(null);
  const [sortModel, setSortModel] = useState([{ field: '_status', sort: 'asc' }]);
  const [status, setStatus] = useState(resultStatus || Object.keys(importPreview?.data || {})[0]);
  const [statuses, setStatuses] = useState([]);

  const isResult = !!resultStatus;

  const processWb = useCallback((XLSX, wb) => {
    const sheet = wb.SheetNames[0];
    const res = toJson(XLSX, wb);
    const newData = res[sheet];
    setData(newData);
  }, []);

  useEffect(() => {
    new Promise(resolve => {
      if (file) {
        resolve(readXlsxFromFile(file));
      } else if (!fileData && importPreview?.downloadUrl) {
        downloadResumedImport(importPreview.downloadUrl);
        resolve({});
      } else if (fileData) {
        resolve(readXlsxFromFileData(fileData));
      }
      return {};
    })
      .then(({ XLSX, wb }) => wb && processWb(XLSX, wb))
      .catch(error => {
        console.log(error);
        setFailed({
          type: 'failed',
          error,
        });
      });
  }, [downloadResumedImport, file, fileData, importPreview.downloadUrl, processWb]);

  useEffect(() => {
    if (importPreview?.data) {
      const newStatuses = [];
      Object.entries(importPreview?.data).forEach(([_status, indexes]) => {
        indexes.forEach(idx => {
          const { message: _message, index } = isObject(idx) ? idx : { index: idx };
          newStatuses[index] = {
            _status,
            _message,
          };
        });
      });
      setStatuses(newStatuses);
    } else {
      navigate('/home/people', { replace: true });
    }
  }, [importPreview?.data, navigate]);

  const rows = data
    ?.map((d, idx) => ({
      _key: `${status}-${idx}`,
      ...statuses[idx],
      ...d,
    }))
    .filter((d, idx) => statuses[idx]?._status === status);

  const values = { created: 2, updated: 1, skipped: 0 };
  const columns =
    importPreview?.employeeImport?.headers &&
    [...(status === 'skipped' ? ['_message'] : []), ...importPreview?.employeeImport?.headers].map(
      (field, idx) => {
        const fieldName =
          importPreview.employeeImport.headerMap[idx - (status === 'skipped' ? 1 : 0)];
        let headerName = fieldName ? I18n.t(`ImportsScene.Fields.${fieldName}`) : `${field}`;
        if (['_status', '_message', '_id'].includes(field)) {
          headerName = I18n.t(`ImportsScene.Fields.${field}`);
        }
        return {
          field,
          ...(field === '_status' ? { sortComparator: (a, b) => values[a] - values[b] } : {}),
          headerName,
          editable: false,
          minWidth: field === '_message' ? 300 : 150,
          type: field === 'trackTime' ? 'boolean' : 'string',
          ...(field === 'trackTime'
            ? {
                type: 'boolean',
                valueGetter: value =>
                  ['true', '1', 'si', 'sí', 'cierto', 'cert', 'yes'].includes(
                    `${value}`.toLowerCase().trim(),
                  ),
              }
            : {
                renderCell: params => (
                  <LineClampTooltip
                    lines={params.value?.indexOf && params.value?.indexOf(' ') > -1 ? 2 : 1}
                  >
                    {params.formattedValue}
                  </LineClampTooltip>
                ),
              }),
          ...({
            _status: {
              renderCell: params => (
                <div className={classes[`status-${params.value}`]}>
                  {I18n.t(`ImportsScene.Statuses.${params.value}`)}
                </div>
              ),
            },
          }[field] || {}),
          renderHeader: renderHeader(fieldName, classes),
        };
      },
    );
  const handleChangeStatus = (e, val) => setStatus(val);
  const handleCancel = () => onCancel(importPreview?.employeeImport);
  const handleStart = () => onStart(importPreview?.employeeImport);
  return (
    <div className={classes.root}>
      <Typography component="div" className={classes.title} variant="h5">
        {isResult ? I18n.t(`ImportsScene.Import results`) : I18n.t(`ImportsScene.Import preview`)}
      </Typography>
      <Tabs value={status} onChange={handleChangeStatus} indicatorColor="primary" centered>
        {Object.entries(importPreview?.data || {}).map(([k, v]) => (
          <Tab
            className={classes.tabs}
            key={k}
            label={
              <div className={classes.tabTitle}>
                <Typography variant="button">
                  {I18n.t(`ImportsScene.${isResult ? 'ResultStatuses' : 'Statuses'}.${k}`, {
                    count: v.length,
                  })}
                </Typography>
                <Typography variant="caption">{v.length}</Typography>
              </div>
            }
            value={k}
          />
        ))}
      </Tabs>
      {columns && rows && (
        <OmmnioDataGrid
          getRowClassName={params => classes[`statusBg-${params.row._status}`]}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          getRowId={m => m._key}
          columns={columns}
          rows={rows}
        />
      )}
      {!isResult && (
        <div className={classes.actions}>
          <Button variant="outlined" type="button" color="secondary" onClick={handleCancel}>
            {I18n.t('ImportsScene.Cancel import')}
          </Button>
          <Button variant="contained" type="button" color="primary" onClick={handleStart}>
            {I18n.t('ImportsScene.Start import')}
          </Button>
        </div>
      )}
    </div>
  );
};

ImportPreview.propTypes = {
  downloadResumedImport: func.isRequired,
  importPreview: object,
  file: object,
  onCancel: func.isRequired,
  onStart: func.isRequired,
  fileData: object,
  resultStatus: string,
};

ImportPreview.defaultProps = {
  importPreview: null,
  file: null,
  fileData: null,
  resultStatus: null,
};

export default deferRender(ImportPreview);
