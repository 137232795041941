import React, { Component } from 'react';
import { func, object, string } from 'prop-types';
import { IconButton, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SearchIcon from 'assets/icons/search.svg';
import SearchField from 'components/controls/SearchField';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  searchIconButton: {
    marginLeft: 5,
  },
  searchIcon: {
    fontSize: 16,
  },
};

class Search extends Component {
  state = {
    isVisible: false,
  };

  toggleVisible = () => {
    this.setState(state => ({ isVisible: !state.isVisible }));
  };

  render() {
    const { classes, onChange } = this.props;
    const { isVisible } = this.state;
    return (
      <div className={classes.root}>
        {isVisible && <SearchField onChange={onChange} />}

        <IconButton className={classes.searchIconButton} onClick={this.toggleVisible} size="large">
          <SvgIcon color="primary" className={classes.searchIcon}>
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </div>
    );
  }
}

Search.propTypes = {
  classes: object.isRequired,
  onChange: func.isRequired,
};
Search.defaultProps = {
  value: '',
};

export default withStyles(styles)(Search);
