import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'formik';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  buttons: {
    display: 'flex',
    justifySelf: 'flex-end',
    padding: '20px 0',
  },
  cancel: {
    color: theme.palette.primary.ultraDark,
    marginRight: 10,
  },
});

class ActionButtons extends Component {
  handleReset = e => {
    const { onReset, formik } = this.props;
    onReset();
    formik.handleReset(e);
  };

  render() {
    const { classes, formik, isEdit, disabled } = this.props;
    return (
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          onClick={this.handleReset}
          disabled={disabled || formik.isSubmitting}
          className={classes.cancel}
          type="reset"
          color="secondary"
        >
          {I18n.t('TeamDrawer.Cancel')}
        </Button>
        <Button
          variant="contained"
          disabled={disabled || formik.isSubmitting}
          color="primary"
          type="submit"
        >
          {formik.isSubmitting
            ? `${isEdit ? I18n.t('TeamDrawer.Updating') : I18n.t('TeamDrawer.Creating')}...`
            : `${isEdit ? I18n.t('TeamDrawer.Update') : I18n.t('TeamDrawer.Create')}`}
        </Button>
      </div>
    );
  }
}

ActionButtons.propTypes = {
  classes: object.isRequired,
  formik: object.isRequired,
  onReset: func.isRequired,
  isEdit: bool.isRequired,
  disabled: bool,
};

ActionButtons.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(connect(ActionButtons));
