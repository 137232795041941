import { GET_USER_DATA_FULFILLED } from 'store/app/auth/action';
import { LOAD_COMPANY_FULFILLED } from 'store/app/entities/companies/action';
import { LOAD_EMPLOYEE_FULFILLED } from 'store/app/entities/employees/action';
import { SET_USER, TYPING, UPDATE_PASSWORD_FULFILLED } from 'store/app/entities/users/action';
import arrayToObject from 'utils/arrayToObject';

const createUser = u => ({
  _id: u._id,
  phone: u.phone,
  role: u.role,
  lastSeen: u.lastSeen,
  createdAt: u.createdAt,
  isSuperAdmin: u.isSuperAdmin,
  isOnline: u.isOnline,
  isTyping: u.isTyping,
  isApp: u.isApp,
  loginType: u.loginType,
});

const createUsers = users => arrayToObject(users.map(u => createUser(u)));

const usersReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_USER:
    case GET_USER_DATA_FULFILLED: {
      const users = { ...state };
      users[payload._id] = createUser({
        ...payload,
        isSuperAdmin: payload.role === 2,
      });

      return users;
    }

    case TYPING: {
      const users = { ...state };
      const isTyping = users[payload.user._id]?.isTyping || {};
      isTyping[payload.channel] = payload.status;
      users[payload.user._id] = createUser({
        ...payload.user,
        isSuperAdmin: payload.role === 2,
        isTyping,
      });
      return users;
    }

    case LOAD_COMPANY_FULFILLED: {
      const employees = payload.employees?.filter(e => e.user?._id) || [];

      return {
        ...state,
        ...createUsers(employees.map(e => e.user)),
      };
    }

    case LOAD_EMPLOYEE_FULFILLED: {
      if (!payload.user) return state;

      return {
        ...state,
        [payload.user._id]: createUser(payload.user),
      };
    }

    case UPDATE_PASSWORD_FULFILLED: {
      return {
        ...state,
        [payload._id]: { ...state[payload._id], loginType: payload.loginType },
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
