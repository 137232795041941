import { Button, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(() => ({
  plus: {
    fontSize: 18,
    marginRight: 5,
  },
}));

const AddFormatButton = props => {
  const classes = useStyles();
  return (
    <Button variant="contained" color="primary" {...props}>
      <SvgIcon className={classes.plus}>
        <PlusCircleIcon />
      </SvgIcon>
      {I18n.t('HrBot.New template')}
    </Button>
  );
};

export default AddFormatButton;
