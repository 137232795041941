import PickEmojiButton from 'components/@home/@messages/ChatPanel/SendForm/buttons/PickEmojiButton';
import { bool, object } from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import insertAtCaret from 'utils/insertAtCaret';
import ChatField from '../../controls/ChatField';
import ClipboardImageDialog from './ClipboardImageDialog';
import connector from './connector';

const FormikChatField = ({ field, form, inputRef: inputRefProp, ...props }) => {
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [file, setFile] = useState(null);
  const inputRef = useRef();

  const setInputRef = useMemo(() => {
    const result = elem => {
      inputRef.current = elem;
      if (typeof inputRefProp === 'function') {
        inputRefProp(elem);
      }
      if (typeof inputRefProp === 'object') {
        // eslint-disable-next-line no-param-reassign
        inputRefProp.current = elem;
      }
      result.current = elem;
    };
    return result;
  }, [inputRefProp]);

  const findSomePicture = clipboardItems => {
    const items = Array.from(clipboardItems);
    return items.find(item => item.type.includes('image'));
  };

  const openDialog = f => {
    setOpenDialog(true);
    setFile(f);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setFile(null);
  };

  const handlePaste = ({ clipboardData }) => {
    const f = findSomePicture(clipboardData.files);
    if (f) openDialog(f);
  };

  const saveAttachment = async attachment => {
    const { values, submitForm, setFieldValue } = form;

    await setFieldValue('attachments', [...values.attachments, attachment]);
    setTimeout(submitForm, 1);

    closeDialog();
  };

  const setNativeValue = (element, value) => {
    const valueSetter = Object.getOwnPropertyDescriptor(element, 'value').set;
    const prototype = Object.getPrototypeOf(element);
    const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set;

    if (valueSetter && valueSetter !== prototypeValueSetter) {
      prototypeValueSetter.call(element, value);
    } else {
      valueSetter.call(element, value);
    }
  };

  const onPick = picked => {
    insertAtCaret(inputRef.current, picked, newValue => {
      inputRef.current.blur();
      inputRef.current.focus();
      inputRef.current.select();
      inputRef.current.setSelectionRange(
        inputRef.current.value.length + 1,
        inputRef.current.value.length + 1,
      );
      const ev1 = new Event('select', { bubbles: true });
      ev1.simulated = true;
      inputRef.current.dispatchEvent(ev1);
      setTimeout(() => {
        setNativeValue(inputRef.current, newValue);
        const ev2 = new Event('change', { bubbles: true });
        ev2.simulated = true;
        inputRef.current.dispatchEvent(ev2);
      }, 1);
    });
  };

  const generatePlaceholder = sentAsChannel => {
    const { channel, authEmployee, isCompanyAdmin } = props;
    const name = sentAsChannel ? channel.name : authEmployee?.name;

    if (isCompanyAdmin)
      return `${I18n.t('SendMessageForm.Write message as')} ${name || 'Super admin'}`;

    return I18n.t('SendMessageForm.Write message or attach file');
  };

  const { name, value, onBlur, onChange } = field;
  const { values, handleSubmit } = form;

  return (
    <>
      <ChatField
        {...props}
        onPaste={handlePaste}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={generatePlaceholder(values.sentAsChannel)}
        onSend={handleSubmit}
        value={value}
        name={name}
        inputRef={setInputRef}
      />
      <PickEmojiButton onPick={onPick} />
      <ClipboardImageDialog
        isOpen={isOpenDialog}
        file={file}
        onConfirm={saveAttachment}
        onClose={closeDialog}
        channel={props.channel}
      />
    </>
  );
};

FormikChatField.propTypes = {
  suggestionsPortal: object.isRequired,
  field: object.isRequired,
  form: object.isRequired,
  isCompanyAdmin: bool.isRequired,
  authEmployee: employeeShape,
  channel: channelShape.isRequired,
  inputRef: object,
};
FormikChatField.defaultProps = {
  authEmployee: null,
  inputRef: null,
};

export default connector(FormikChatField);
