/* eslint-disable no-nested-ternary,max-len,no-param-reassign */
import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import Group from 'services/api/Channel';
import * as Yup from 'yup';

class ChannelSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      members,
      membersRemoved,
      groupTeams,
      groupTeamsRemoved,
      isReadOnly,
      isOptedOut,
      name,
      description,
    } = this.values;
    const { authEmployee, isSuperAdmin } = this.formikBag.props;

    const membersWithAdmin = members
      .filter(m => !m.createdFromTeams)
      .map(m => ({
        employee: m._id,
        isAdmin: m.groupRole === 'admin' ? 1 : 0,
        canWriteAsChannel: m.groupRole === 'writer' ? 1 : 0,
      }));
    if (!isSuperAdmin && (isReadOnly || isOptedOut[0] !== 'on')) {
      membersWithAdmin.push({
        employee: authEmployee._id,
        isAdmin: 1,
        canWriteAsChannel: 0,
      });
    } else if (!isSuperAdmin && isOptedOut[0] === 'on') {
      membersRemoved.push(authEmployee);
    }

    return {
      name,
      description,
      members: membersWithAdmin,
      groupTeams: groupTeams.map(gt => ({
        team: gt.value.id,
        isAdmin: gt.value.groupRole === 'admin' ? 1 : 0,
        canWriteAsChannel: gt.value.groupRole === 'writer' ? 1 : 0,
      })),
      groupTeamsRemoved,
      membersRemoved: membersRemoved.map(e => ({ employee: e._id, user: e.user?._id })),
      isActive: 1,
      isReadOnly: isReadOnly ? 1 : 0,
    };
  };

  sendRequest = (form, preview) => {
    const {
      props: { channel, isEdit },
    } = this.formikBag;
    if (preview) {
      return Group.updatePreview(isEdit ? channel._id : 'new', form).then(data => {
        if (data.members) {
          this.formikBag.setFieldValue(
            'members',
            data.members.map(({ employee: _id, ...m }) => ({ _id, ...m })),
          );
          this.formikBag.setFieldValue('preview', false);
        }
      });
    }
    if (isEdit) {
      return Group.update(channel._id, form);
    }
    return Group.create(form);
  };

  uploadAvatar = channelId => {
    const { setSubmitting, setErrors } = this.formikBag;
    const { avatar } = this.values;
    if (avatar) {
      Group.upload(channelId, avatar).catch(() => {
        setSubmitting(false);
        setErrors({ server: I18n.t('Picture was not uploaded for some reason') });
      });
    }
  };

  displayErrors = err => {
    const { name } = this.values;
    const { setErrors } = this.formikBag;

    // eslint-disable-next-line no-console
    console.error(err);
    if (err.status === 409) {
      setErrors({ server: `Channel "${name}" already exists` });
    } else {
      setErrors({ server: I18n.t('Something went wrong with sending data') });
    }
  };
}

export default withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .max(50, () => I18n.t('ChannelDrawer.Should not exceed', { count: 50 }))
      .trim()
      .required(() => I18n.t('ChannelDrawer.Please enter name for the channel')),

    description: Yup.string()
      .max(200, () => I18n.t('ChannelDrawer.Should not exceed', { count: 200 }))
      .trim(),

    isReadOnly: Yup.bool().required(() => I18n.t('ChannelDrawer.You need to select type')),

    isOptedOut: Yup.array().max(1),
  }),

  mapPropsToValues: ({ channel, isSuperAdmin, authEmployee: ae, isEdit }) => {
    if (!channel) isEdit = false;
    const isDirectMember = channel?.employees.some(m => m._id === ae?._id);
    const fromTeams = !!channel?.employees.find(m => m._id === ae?._id)?.fromTeams?.length > 0;
    const ret = {
      name: isEdit ? channel.name : '',
      description: isEdit ? channel.description : '',
      isReadOnly: isEdit ? channel.isReadOnly : false,
      members: isEdit
        ? channel.employees
            .filter(e => e._id !== ae?._id)
            .map(e => ({
              _id: e._id,
              groupRole: e.groupRole,
              fromTeams: e.fromTeams,
              createdFromTeams: e.createdFromTeams,
            }))
        : [],
      me: isEdit ? channel.employees.filter(e => e._id === ae?._id)[0] : null,
      groupTeams: isEdit
        ? channel.groupTeams?.map(gt => ({
            label: gt.name,
            value: {
              id: gt.team,
              name: gt.name,
              groupRole: gt.groupRole,
            },
          })) || []
        : [],
      groupTeamsRemoved: [],
      membersRemoved: [],
      optOutDisabled:
        isSuperAdmin ||
        !ae?.isAdmin ||
        fromTeams ||
        (isEdit && channel.isMember && !isDirectMember),
      isOptedOut: isEdit && (isSuperAdmin || !channel.isMember) ? ['on'] : [],
      avatar: isEdit ? channel.avatar : '',
      isAdmin: isEdit ? channel.isAdmin : true,
    };
    return ret;
  },

  handleSubmit: async (values, formikBag) => {
    const {
      props: { actions, isEdit, channel, navigate },
      setSubmitting,
    } = formikBag;

    const handler = new ChannelSubmit(values, formikBag);

    const form = handler.assembleForm();

    try {
      const rawChannel = await handler.sendRequest(form, values.preview);
      if (!values.preview) {
        if (!rawChannel.isMember) {
          actions.channels.remove(rawChannel._id);
        } else {
          actions.channels.set(rawChannel);
        }

        if (!rawChannel.isMember) {
          setTimeout(() => {
            navigate('/home/messages/');
          }, 200);
        } else if (!isEdit) {
          setTimeout(() => navigate(`/home/messages/${rawChannel._id}`), 200);
        }

        handler.uploadAvatar(rawChannel._id);

        actions.drawers.channel.close();
      }
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
  },
  displayName: 'ChannelForm',
});
