/* eslint-disable react/prop-types */
import { Paper, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import isArray from 'lodash/isArray';
import { any, arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { forwardRef } from 'react';
import { I18n } from 'react-redux-i18n';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexGrow: 1,
  },
  input: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    minHeight: 18,
    height: 'auto',
    padding: 2,
  },
  placeholder: {
    position: 'absolute',
    left: 10,
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.1px',
    color: theme.palette.text.secondary,
  },
  menu: {
    zIndex: 2,
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    marginTop: theme.spacing(0.5),
    left: 0,
    right: 0,
  },
  selectInput: {},
}));

const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography className={selectProps.classes.placeholder} {...innerProps}>
    {children}
  </Typography>
);

const Option = ({ innerProps, isDisabled, isSelected, children }) => {
  return (
    <MenuItem selected={isSelected} disabled={isDisabled} {...innerProps}>
      {children}
    </MenuItem>
  );
};

const Control = ({ selectProps, children, innerProps }) => (
  <TextField
    fullWidth
    error={selectProps.error}
    helperText={selectProps.helperText}
    size="small"
    InputProps={{
      inputComponent,
      inputProps: { className: selectProps.classes.input, children, ...innerProps },
    }}
  />
);

const Menu = ({ children, ...props }) => {
  return (
    <components.Menu {...props}>
      <Paper elevation={8}>{children}</Paper>
    </components.Menu>
  );
};

const getOptions = suggestions =>
  suggestions && suggestions[0]?.label
    ? suggestions
    : suggestions.map(s => ({
        label: s,
        value: s,
      }));

const getValue = (value, suggestions) => {
  if (!suggestions) {
    return undefined;
  }
  if (isArray(value)) {
    return value.map(v => getValue(v, suggestions));
  }
  const found = suggestions.find(s => {
    return s.value === value || (s.options && getValue(value, s.options));
  });
  if (found) {
    return found;
  }
  return value === '' ? '' : undefined;
};

const Autosuggest = forwardRef(
  (
    {
      onChange,
      value,
      suggestions,
      onCreate,
      creatable,
      components: componentsIn,
      classes: classesIn,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const Component = creatable ? Creatable : Select;
    const classes = useStyles();
    if (props.disabled) {
      return (
        <TextField
          className={classes.root}
          value={getValue(value, suggestions)?.label}
          {...props}
        />
      );
    }
    return (
      <Component
        ref={ref}
        menuPlacement="auto"
        classes={{ ...classes, ...classesIn }}
        onCreateOption={onCreate}
        className={classesIn.root || classes.root}
        options={getOptions(suggestions)}
        components={{
          Control,
          Menu,
          Placeholder,
          Option,
          ...componentsIn,
        }}
        placeholder={props.placeholder || I18n.t('SelectPlaceholder')}
        value={getValue(value, suggestions)}
        onChange={onChange}
        {...props}
        styles={{
          menu: provided => ({
            ...provided,
            backgroundColor: 'transparent',
            boxShadow: 'none',
            zIndex: 2,
          }),
          container: provided => ({
            ...provided,
            flexGrow: 1,
          }),
          ...props.styles,
        }}
        theme={th => ({
          ...th,
          colors: {
            ...th.colors,
            primary:
              theme.palette.mode === 'dark'
                ? theme.palette.primary.lighter
                : theme.palette.primary.main,
            primary75: theme.palette.primary.lighter,
            primary50: theme.palette.primary.extraLight,
            primary25: theme.palette.primary.ultraLight,
            neutral10: alpha(theme.palette.text.primary, 0.1),
            neutral15: alpha(theme.palette.text.primary, 0.15),
            neutral20: alpha(theme.palette.text.primary, 0.2),
            neutral30: alpha(theme.palette.text.primary, 0.3),
            neutral40: alpha(theme.palette.text.primary, 0.4),
            neutral50: alpha(theme.palette.text.primary, 0.5),
            neutral60: alpha(theme.palette.text.primary, 0.6),
            neutral70: alpha(theme.palette.text.primary, 0.7),
            neutral80: alpha(theme.palette.text.primary, 0.8),
            neutral90: alpha(theme.palette.text.primary, 0.9),
          },
        })}
      />
    );
  },
);

Autosuggest.propTypes = {
  suggestions: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    }),
  ).isRequired,
  value: any,
  onChange: func.isRequired,
  onCreate: func,
  creatable: bool,
  classes: object,
  components: object,
};
Autosuggest.defaultProps = {
  value: null,
  creatable: false,
  onCreate: () => {},
  classes: {},
  components: undefined,
};

export default Autosuggest;
