import { SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LockIcon from 'mdi-react/LockIcon';
import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
  },
});

const JoinToParticipate = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <SvgIcon color="secondary">
        <LockIcon />
      </SvgIcon>
      <Typography variant="caption">{I18n.t('Join to participate')}</Typography>
    </div>
  );
};

export default memo(JoinToParticipate);
