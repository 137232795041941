import { useFormikContext } from 'formik';
import StarIcon from 'mdi-react/StarIcon';
import React, { memo, useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import acknowledgeCreateDrawer from 'store/app/ui/drawers/acknowledgeCreate/action';
import useActions from 'utils/useActions';
import OptionButton from '../OptionButton';

const NewAcknowledgeButton = () => {
  const {
    values: { sentAsChannel },
  } = useFormikContext();
  const open = useActions(acknowledgeCreateDrawer.open);
  const openModal = useCallback(() => {
    open({ sentAsChannel });
  }, [open, sentAsChannel]);
  return (
    <OptionButton icon={<StarIcon />} onClick={openModal}>
      {I18n.t('SendMessageForm.Acknowledge message')}
    </OptionButton>
  );
};

export default memo(NewAcknowledgeButton);
