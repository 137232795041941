import { Box, Button } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { I18n } from 'react-redux-i18n';
import rulesActions from 'store/app/ui/drawers/rules/action';
import useActions from 'utils/useActions';

const styles = {
  buttons: {
    display: 'flex',
    justifySelf: 'flex-end',
    gap: 1,
  },
  cancel: {
    color: 'primary.ultraDark',
  },
};
const ActionButtons = () => {
  const {
    formState: { isSubmitting },
  } = useFormContext();
  const close = useActions(rulesActions.close);
  return (
    <Box sx={styles.buttons}>
      <Button
        variant="outlined"
        onClick={close}
        disabled={isSubmitting}
        sx={styles.cancel}
        type="reset"
        color="secondary"
      >
        {I18n.t('RulesDrawer.Cancel')}
      </Button>
      <Button variant="contained" disabled={isSubmitting} color="primary" type="submit">
        {isSubmitting ? I18n.t('RulesDrawer.Saving') : I18n.t('RulesDrawer.Save')}
      </Button>
    </Box>
  );
};

export default ActionButtons;
