/* eslint-disable class-methods-use-this */
import Http from 'services/Http';
import parseFileName from 'utils/getFileExtension';
import toURIParams from 'utils/toURIParams';

class Companies {
  async find(id, loadAllChannels = false) {
    const params = toURIParams({ loadAllChannels, skipMembers: true });
    const { data } = await Http.get(`/v1/company/${id}/?${params}`);
    return data.company;
  }

  async analytics(id) {
    const { data } = await Http.get(`/v1/company/${id}/analytics`);
    return data;
  }

  async findByName(subdomain) {
    const { data } = await Http.get(`/v1/company/by-name/${subdomain}`);
    return data.company;
  }

  async addTag(id, tag) {
    const { data } = await Http.post(`/v1/company/${id}/tag`, { tag });
    return data.tags;
  }

  async acceptTerms(id) {
    const { data } = await Http.patch(`/v1/company/${id}/acceptTerms`);
    return data;
  }

  async toggleAcceptTerms(id, accepted, employeeId) {
    const action = accepted ? 'acceptTerms' : 'unacceptTerms';
    const { data } = await Http.patch(`/v1/company/${id}/${action}/${employeeId}`);
    return data;
  }

  async update(id, values) {
    const { data } = await Http.patch(`/v1/company/${id}`, values);
    return data;
  }

  async employees(id, options = {}) {
    const params = toURIParams(options);
    const { data } = await Http.get(`/v1/company/${id}/employees?${params}`);
    return data;
  }

  async getCustomer(id) {
    const { data } = await Http.get(`/v1/company/${id}/customer`);
    return data;
  }

  async createPaymentInent(id) {
    const { data } = await Http.post(`/v1/company/${id}/payment-intent`);
    return data;
  }

  async buyDigitalSignatures(id, quantity) {
    const { data } = await Http.post(`/v1/company/${id}/buy-digital-signatures`, { quantity });
    return data;
  }

  async createCustomerPortalSession(id) {
    const { data } = await Http.post(`/v1/company/${id}/create-customer-portal-session`);
    return data;
  }

  async createCheckoutSession(id) {
    const { data } = await Http.post(`/v1/company/${id}/create-checkout-session`);
    return data;
  }

  async onboarding() {
    const { data } = await Http.get('/v1/company/current/onboarding');
    return data;
  }

  async upload(company, file, onUploadProgress, getCancelFunc) {
    const { type: mimeType, name } = file;
    const { ext } = parseFileName(name);
    const {
      data: { headers, uploadUrl, url },
    } = await Http.post(`/v1/company/${company}/get-upload-url`, { mimeType, ext });
    await Http.putS3(uploadUrl, file, {
      headers,
      timeout: 0,
      onUploadProgress,
      getCancelFunc,
    });
    return url;
  }
}

export default new Companies();
