import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { AxisBottom, AxisRight } from '@vx/axis';
import { GridRows } from '@vx/grid';
import { Group } from '@vx/group';
import { scaleLinear, scaleOrdinal, scaleTime } from '@vx/scale';
import { BarRounded } from '@vx/shape';
import amex from 'assets/images/cards/americanexpress.svg';
import diners from 'assets/images/cards/diners.svg';
import discover from 'assets/images/cards/discover.svg';
import jcb from 'assets/images/cards/jcb.svg';
import mastercard from 'assets/images/cards/mastercard.svg';
import unionpay from 'assets/images/cards/unionpay.svg';
import unknown from 'assets/images/cards/unknown.svg';
import visa from 'assets/images/cards/visa.svg';
import classnames from 'classnames';
import ChartSection from 'components/@home/@analytics/ChartSection';
import SectionBody from 'components/@home/@settings/Tabs/SectionBody';
import SectionTitle from 'components/@home/@settings/Tabs/SectionTitle';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import ErrorAlert from 'components/alerts/ErrorAlert';
import DeleteDialog from 'components/common/DeleteDialog';
import Loading from 'components/common/Loading';
import CountryCode from 'components/controls/CountryCode';
import Language from 'components/controls/Language';
import TimeZone from 'components/controls/TimeZone';
import DeleteIcon from 'mdi-react/DeleteIcon';
import ExternalLinkIcon from 'mdi-react/ExternalLinkIcon';
import moment from 'moment';
import { arrayOf, bool, date, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import Company from 'services/api/Company';
import companyShape from 'shapes/company';
import customerShape from 'shapes/customerShape';
import employeeShape from 'shapes/employee';
import { formatLocale, multiTimeFormat } from 'utils/timeFormat';
import useWindowSize from 'utils/useWindowSize';
import connector from './connector';

const margin = { left: 0, top: 24, bottom: 0, right: 0 };

const cardLogos = {
  amex,
  diners,
  discover,
  jcb,
  mastercard,
  unionpay,
  unknown,
  visa,
};

const useStyles = makeStyles(th => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: th.palette.background.paper,
    flexDirection: 'column',
  },
  grid: {
    padding: th.spacing(3),
    maxWidth: 600,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  employeeName: {
    color: th.palette.primary.main,
  },
  listItemRoot: {
    alignItems: 'baseline',
    '& $title': {
      fontWeight: 'bold',
    },
  },
  title: {
    marginTop: th.spacing(2),
  },
  listItem: {
    minHeight: 0,
  },
  employee: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  employeeMain: {
    flex: 1,
  },
  actions: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  delete: {
    color: '#8b98ab',
    fontSize: 11,
  },
  circle: {},
  pie: {},
  incomplete: {},
  incomplete_expired: {},
  trialing: {},
  active: {},
  past_due: {},
  canceled: {},
  unpaid: {},
  subscriptionIcon: {
    width: 32,
    height: 32,
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > $circle': {
      width: 13,
      height: 13,
      borderRadius: '50%',
      border: '1.5px solid white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'content-box',
      '& > $pie': {
        width: 10,
        height: 10,
        borderRadius: '50%',
      },
    },
    '&$incomplete': {
      backgroundColor: '#fc526a',
    },
    '&$incomplete_expired': {
      backgroundColor: '#fc526a',
    },
    '&$trialing': {
      backgroundColor: '#8f81fe',
    },
    '&$active': {
      backgroundColor: '#48c404',
    },
    '&$past_due': {
      backgroundColor: '#fc526a',
    },
    '&$canceled': {
      backgroundColor: '#87919f',
    },
    '&$unpaid': {
      backgroundColor: '#fc526a',
    },
  },
  tag: {
    marginLeft: '1em',
    padding: '1px 6px',
    borderRadius: 4,
    '&$incomplete': {
      backgroundColor: '#ffe7f2',
      color: '#b3063d',
    },
    '&$incomplete_expired': {
      backgroundColor: '#ffe7f2',
      color: '#b3063d',
    },
    '&$trialing': {
      backgroundColor: '#cff5f6',
      color: '#0055bc',
    },
    '&$active': {
      color: '#05690d',
      backgroundColor: '#d7f7c2',
    },
    '&$past_due': {
      backgroundColor: '#ffe7f2',
      color: '#b3063d',
    },
    '&$canceled': {
      backgroundColor: '#ebeef1',
      color: '#545a69',
    },
    '&$unpaid': {
      backgroundColor: '#ffe7f2',
      color: '#b3063d',
    },
  },
  digitalSignatures: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  buyButton: {
    marginLeft: th.spacing(1),
    display: 'inline',
  },
  creditCard: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: th.spacing(2),
    marginBottom: th.spacing(2),
    backgroundColor: th.palette.secondary.ultraLight,
  },
  creditCardLogo: {
    width: 48,
  },
  creditCardRow: {
    marginBottom: 5,
    display: 'flex',
  },
  creditCardNumber: {
    flex: 1,
    textAlign: 'right',
  },
  creditCardExpiration: {
    '& > span': {
      fontSize: 10,
      fontWeight: 700,
      textTransform: 'uppercase',
      letterSpacing: '.3px',
      opacity: 0.8,
    },
    '& > p': {
      margin: 0,
    },
  },
  strike: {
    textDecoration: 'line-through',
  },
  payButton: {
    marginTop: th.spacing(1),
  },
  gMain: {
    pointerEvents: 'bounding-box',
  },
  axis: {
    stroke: th.palette.secondary.main,
  },
  tickLabelX: {
    display: 'block',
    textAlign: 'center',
    color: th.palette.secondary.main,
    fontSize: 12,
    fontWeight: 'normal',
    textAnchor: 'middle',
  },
  tickLabelY: {
    textAnchor: 'end',
    fill: th.palette.secondary.main,
    fontSize: 12,
    fontWeight: 'normal',
  },
}));

const GeneralSettings = ({
  actions,
  admins: adminsProp,
  adminsLoadedAt,
  adminsLoading,
  customer: {
    id: customerId,
    subscriptions,
    usages = [],
    // digitalSignaturesBought,
    paymentMethod,
    consumed: { digitalSignatures: digitalSignaturesConsumed = 0 },
    stock: { digitalSignatures: digitalSignaturesStock = 0 },
  },
  subscriptionsLoadedAt,
  subscriptionsLoading,
  authCompany,
  isCompanyAdmin,
  locale,
  isSuperAdmin,
}) => {
  const theme = useTheme();
  const [timezoneFilter, setTimezoneFilter] = useState(null);
  const [admins, setAdmins] = useState(adminsProp);
  const [error, setError] = useState(null);
  const [toRevoke, setToRevoke] = useState(null);
  const [payDialogOpen, setPayDialogOpen] = useState(false);
  const [quantity, setQuantity] = useState(100);
  const [confirm, setConfirm] = useState(false);
  const [buying, setBuying] = useState(false);
  const windowSize = useWindowSize();

  const width = windowSize.width > 800 ? (windowSize.width - 260) * 0.6 : windowSize.width - 100;
  const height = windowSize.width > 800 ? width / 2 : width;

  useEffect(() => {
    setAdmins(adminsProp);
  }, [adminsProp]);

  useEffect(() => {
    if ((!adminsLoadedAt || moment().diff(adminsLoadedAt, 'minutes') > 1) && !adminsLoading) {
      actions.admins.list();
    }
  }, [actions, adminsLoading, adminsLoadedAt]);

  useEffect(() => {
    if (
      isCompanyAdmin &&
      (!subscriptionsLoadedAt || moment().diff(subscriptionsLoadedAt, 'minutes') > 1) &&
      !subscriptionsLoading
    ) {
      actions.subscriptions.getCustomer();
    }
  }, [actions, subscriptionsLoading, subscriptionsLoadedAt, isCompanyAdmin]);

  const classes = useStyles();

  const onLanguageChange = event => {
    actions.companies.update({ language: event.value });
  };

  const onCountryChange = event => {
    setTimezoneFilter(event.value.tz);
    actions.companies.update({ country: event.value.code });
  };

  const onCountriesLoad = codes => {
    const country = codes.en?.find(c => c.code === authCompany.country);
    if (country) {
      setTimezoneFilter(country.tz);
    }
  };

  const onTimezoneChange = event => {
    actions.companies.update({ timeZone: event.value });
  };

  const handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  const revokeAdmin = admin => {
    if (admins.length > 1) {
      setToRevoke(admin);
    } else {
      setError(I18n.t('GeneralSettings.CantDeleteLastAdmin'));
    }
  };

  const confirmDelete = () => {
    actions.employees.update(toRevoke._id, { ...toRevoke, isAdmin: false });
    setAdmins(admins.filter(a => a._id !== toRevoke._id));
    setToRevoke(null);
  };

  const closeDialog = () => {
    setToRevoke(null);
  };

  const openCustomerPortal = async () => {
    const { url } = await Company.createCustomerPortalSession(authCompany._id);
    window.open(url);
  };

  const handleChangeQuantity = event => {
    setQuantity(event.target.value);
  };

  const createSubscription = async () => {
    const { url } = await Company.createCheckoutSession(authCompany._id, quantity);
    window.location = url;
  };

  const buyMore = () => {
    setBuying(false);
    setPayDialogOpen(true);
    setConfirm(false);
  };

  const handleCancel = () => {
    setQuantity(100);
    setPayDialogOpen(false);
    setConfirm(false);
    setBuying(false);
  };

  const handleOk = async () => {
    if (confirm) {
      setBuying(true);
      await Company.buyDigitalSignatures(authCompany._id, quantity);
      actions.subscriptions.getCustomer();
      setPayDialogOpen(false);
      setBuying(false);
    } else {
      setConfirm(true);
    }
  };

  const price = quantity < 500 ? 1 : 0.8;
  const CardLogo = cardLogos[paymentMethod?.brand] || unknown;

  // const consumedRemaining = digitalSignaturesConsumed;
  const dateFormat = ['es', 'ca'].includes(locale) ? 'D [de] MMMM' : 'MMM Do';
  const total = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'eur',
  }).format(quantity * price);

  const maxY = Math.max(...usages.map(cur => cur.count));

  const yScale = scaleLinear({
    domain: [0, maxY],
    nice: true,
  });

  const xMax = width;
  const yMax = height - margin.top - 40;

  const numTicks = 6;

  // eslint-disable-next-line react/prop-types
  const tickComponent = ({ x, y, className, formattedValue }) => {
    const tickWidth = Math.min(100, (width - margin.left - margin.right) / numTicks - 6);
    return (
      <foreignObject
        x={x - tickWidth / 2}
        y={y - 10}
        className={className}
        width={tickWidth}
        height={40}
      >
        <span>{formattedValue}</span>
      </foreignObject>
    );
  };

  const x = d => new Date(d?.firstDayOfMonth);

  const xScale = scaleTime({
    domain: [
      moment().startOf('month').subtract(13, 'month').toDate(),
      moment().startOf('month').toDate(),
    ],
  });

  const numberFormat = new Intl.NumberFormat(locale, { minimumFractionDigits: 0 });

  const xTickFormat = multiTimeFormat(locale);

  xScale.rangeRound([40, xMax - 40]);
  yScale.range([yMax, 0]);

  const color = scaleOrdinal({
    domain: ['count'],
    range: theme.palette.activePendingBlockedChartSeries,
  });

  const chart = (onMouseMove, onMouseLeave) => (
    <svg width={width} height={height}>
      <g className={classes.gMain} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
        <linearGradient id="gradActive" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style={{ stopColor: '#5A61DC', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#8C5ADC', stopOpacity: 1 }} />
        </linearGradient>
        <GridRows
          top={margin.top}
          left={margin.left}
          scale={yScale}
          width={xMax}
          height={yMax}
          stroke={theme.palette.secondary.extraLight}
          className={classes.gridRows}
          numTicks={4}
        />
        <AxisRight
          top={margin.top}
          left={xMax - 5}
          scale={yScale}
          hideTicks
          hideAxisLine
          numTicks={4}
          className={classes.axis}
          tickFormat={numberFormat.format}
          tickLabelProps={() => ({
            dy: -10,
            x: 0,
            className: classes.tickLabelY,
          })}
        />
        <AxisBottom
          hideTicks
          hideAxisLine
          numTicks={numTicks}
          top={yMax + margin.top}
          scale={xScale}
          className={classes.axis}
          tickComponent={tickComponent}
          tickFormat={xTickFormat}
          tickLabelProps={() => ({
            className: classes.tickLabelX,
          })}
        />
        <Group top={margin.top}>
          {usages.map(d => (
            <BarRounded
              key={x(d)}
              data={usages}
              x={xScale(x(d))}
              y={yScale(d.count)}
              height={yMax - yScale(d.count)}
              width={10}
              strokeWidth={0}
              radius={4}
              fill="url(#gradActive)"
              top
            />
          ))}
        </Group>
      </g>
    </svg>
  );

  return (
    <div className={classes.root}>
      {isCompanyAdmin && (
        <>
          <SectionTitle title={I18n.t('GeneralSettings.GeneralConfig')} />
          <Grid container className={classes.grid} spacing={1}>
            <Grid item xs={4} className={classes.label}>
              <Typography>{`${I18n.t('GeneralSettings.Country')}`}</Typography>
            </Grid>
            <Grid item xs={8}>
              <CountryCode
                value={authCompany.country}
                onChange={onCountryChange}
                onLoad={onCountriesLoad}
                className={classes.field}
                name="country"
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>{`${I18n.t('GeneralSettings.Language')}`}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Language
                value={authCompany.language}
                onChange={onLanguageChange}
                className={classes.field}
                name="language"
              />
            </Grid>
            <Grid item xs={4} className={classes.label}>
              <Typography>{I18n.t('GeneralSettings.Time zone')}</Typography>
            </Grid>
            <Grid item xs={8}>
              <TimeZone
                value={authCompany.timeZone}
                onChange={onTimezoneChange}
                className={classes.field}
                filter={timezoneFilter}
                name="timeZone"
              />
            </Grid>
          </Grid>
        </>
      )}
      <div>
        <SectionTitle title={I18n.t('GeneralSettings.AccountOwnership')} />
        <SectionBody>
          {adminsLoading ? (
            <div className={classes.loading}>
              <Loading size={56} />
            </div>
          ) : (
            <List>
              {admins.map(employee => (
                <ListItem key={employee._id}>
                  <ListItemIcon>
                    <EmployeeAvatar
                      status={employee.isAcceptTerms ? 'default' : 'pending'}
                      employee={employee}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ component: 'div' }}
                    primary={
                      <div className={classes.employee}>
                        <div className={classes.employeeMain}>
                          <Typography className={classes.employeeName} component="div">
                            {`${employee.firstName} ${employee.surName}`}
                          </Typography>
                          <Typography>
                            {employee.jobTitle}
                            {employee.jobTitle && employee.department && ', '}
                            {employee.department}
                          </Typography>
                          <Typography variant="caption" className={classes.timeCaption}>
                            <span className={classes.time}>{employee.location}</span>
                          </Typography>
                        </div>
                        <div className={classes.actions}>
                          {isCompanyAdmin && (
                            <IconButton
                              onClick={() => revokeAdmin(employee)}
                              className={classes.delete}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </SectionBody>
      </div>
      {isCompanyAdmin && (
        <div>
          <SectionTitle title={I18n.t('GeneralSettings.Subscription')}>
            {isCompanyAdmin && !!customerId && (
              <div className={classes.actions}>
                <Button onClick={openCustomerPortal} color="primary">
                  <Typography>{I18n.t('GeneralSettings.Open billing portal')}</Typography>
                  &nbsp;
                  <ExternalLinkIcon />
                </Button>
              </div>
            )}
          </SectionTitle>
          <SectionBody>
            {subscriptionsLoading ? (
              <div className={classes.loading}>
                <Loading size={56} />
              </div>
            ) : (
              <>
                <List>
                  {subscriptions?.map(subscription => (
                    <ListItem classes={{ root: classes.listItemRoot }} key={subscription.id}>
                      <ListItemIcon>
                        <div
                          className={classnames([
                            classes.subscriptionIcon,
                            classes[subscription.status],
                          ])}
                        >
                          <div className={classes.circle}>
                            <div
                              className={classes.pie}
                              style={{
                                background: `conic-gradient(rgb(255, 255, 255) ${subscription.tpc}%, transparent 0%)`,
                              }}
                            />
                          </div>
                        </div>
                      </ListItemIcon>
                      <ListItemText
                        className={classes.listItem}
                        primaryTypographyProps={{ component: 'div' }}
                        primary={
                          <div className={classes.employee}>
                            <div className={classes.employeeMain}>
                              <Typography className={classes.employeeName} component="div">
                                {`${subscription.product?.name}`}
                                <span
                                  className={classnames(classes.tag, classes[subscription.status])}
                                >
                                  {I18n.t(`GeneralSettings.status.${subscription.status}`, {
                                    count: subscription.trialDays || 0,
                                  })}
                                </span>
                              </Typography>
                              {!!subscription.nextBilling &&
                                !subscription.status === 'canceled' && (
                                  <Typography>
                                    {I18n.t('GeneralSettings.Billing', {
                                      interval: I18n.t(`GeneralSettings.${subscription.interval}`, {
                                        count: subscription.intervalCount,
                                      }),
                                      date: moment(subscription.nextBilling).format(dateFormat),
                                      price: new Intl.NumberFormat(locale, {
                                        style: 'currency',
                                        currency: subscription.currency,
                                      }).format(subscription.amount / 100),
                                    })}
                                  </Typography>
                                )}
                              {!subscription.nextBilling && subscription.status === 'unpaid' && (
                                <Button
                                  onClick={createSubscription}
                                  variant="text"
                                  color="primary"
                                  className={classes.payButton}
                                >
                                  {I18n.t('GeneralSettings.Pay subscription')}
                                </Button>
                              )}
                              {!subscription.product.digitalSignatures &&
                                !['canceled', 'unpaid'].includes(subscription.status) && (
                                  <>
                                    <Typography className={classes.digitalSignatures}>
                                      {I18n.t('GeneralSettings.DigitalSignatures', {
                                        remaining:
                                          digitalSignaturesStock - digitalSignaturesConsumed,
                                        count: digitalSignaturesStock,
                                      })}
                                      {paymentMethod && (
                                        <Link
                                          component="button"
                                          color="primary"
                                          className={classes.buyButton}
                                          onClick={buyMore}
                                        >
                                          {I18n.t('GeneralSettings.BuyMore')}
                                        </Link>
                                      )}
                                    </Typography>
                                    {/* digitalSignaturesBought.map(({ date: on, quantity: count }) => {
                            const remaining = Math.max(0, count - consumedRemaining);
                            consumedRemaining = Math.max(consumedRemaining - count, 0);
                            return (
                              <Typography
                                className={classnames({ [classes.strike]: remaining === 0 })}
                                key={on}
                              >
                                {I18n.t('GeneralSettings.DigitalSignaturePack', {
                                  on: moment(on).format('LL'),
                                  count,
                                  remaining,
                                })}
                              </Typography>
                            );
                          }) */}
                                  </>
                                )}
                            </div>
                          </div>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                {isSuperAdmin && usages?.length > 0 && (
                  <ChartSection
                    sx={{ marginLeft: '40px !important' }}
                    keys={['count']}
                    tickFormat={formatLocale(locale, locale === 'en' ? '%B %Y' : '%B de %Y')}
                    color={color}
                    data={usages}
                    xScale={xScale}
                    chart={chart}
                    title={I18n.t('GeneralSettings.DigitalSignaturesUsage')}
                    totals={{ count: usages.map(d => d.count).reduce((acc, c) => acc + c, 0) }}
                    x={x}
                  />
                )}
              </>
            )}
          </SectionBody>
        </div>
      )}
      <ErrorAlert isOpen={error} label={error} onClose={handleErrorClose} />
      <DeleteDialog
        message={I18n.t('GeneralSettings.ConfirmRevoke', {
          name: toRevoke && `${toRevoke.firstName} ${toRevoke.surName}`,
        })}
        isOpen={toRevoke}
        onConfirm={confirmDelete}
        onClose={closeDialog}
      />
      <Dialog open={payDialogOpen}>
        <DialogTitle>
          {I18n.t(
            confirm ? 'GeneralSettings.BuyMoreDialogConfirm' : 'GeneralSettings.BuyMoreDialog',
            { total },
          )}
        </DialogTitle>
        <DialogContent dividers>
          <>
            {!confirm && <Typography gutterBottom>{I18n.t('GeneralSettings.HowMany')}</Typography>}
            {confirm && (
              <Paper className={classes.creditCard}>
                <div className={classes.creditCardRow}>
                  <CardLogo className={classes.creditCardLogo} />
                  <Typography className={classes.creditCardNumber}>
                    •••• •••• •••• {paymentMethod.last4}
                  </Typography>
                </div>
                <div className={classes.creditCardRow}>
                  <div className={classes.creditCardExpiration}>
                    <Typography component="span">Expiration</Typography>
                    <Typography>{paymentMethod.expiration}</Typography>
                  </div>
                </div>
              </Paper>
            )}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{I18n.t('GeneralSettings.PricingTable.Product')}</TableCell>
                    <TableCell align="right">
                      {I18n.t('GeneralSettings.PricingTable.Quantity')}
                    </TableCell>
                    <TableCell align="right">
                      {I18n.t('GeneralSettings.PricingTable.UnitPrice')}
                    </TableCell>
                    <TableCell align="right">
                      {I18n.t('GeneralSettings.PricingTable.Total')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {I18n.t('GeneralSettings.PricingTable.Products.DigitalSignatures')}
                    </TableCell>
                    <TableCell align="right">
                      {confirm ? (
                        quantity
                      ) : (
                        <Select value={quantity} onChange={handleChangeQuantity}>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={250}>250</MenuItem>
                          <MenuItem value={500}>500</MenuItem>
                          <MenuItem value={1000}>1000</MenuItem>
                        </Select>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: 'eur',
                      }).format(price)}
                    </TableCell>
                    <TableCell align="right">{total}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            {I18n.t('GeneralSettings.Cancel')}
          </Button>
          <Button onClick={handleOk} color="primary" disabled={buying}>
            {buying ? (
              <Loading debounce={0} size={20} />
            ) : (
              I18n.t(confirm ? 'GeneralSettings.Confirm' : 'GeneralSettings.Review')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
GeneralSettings.propTypes = {
  admins: arrayOf(employeeShape),
  customer: customerShape,
  authCompany: companyShape.isRequired,
  actions: object.isRequired,
  isCompanyAdmin: bool.isRequired,
  adminsLoadedAt: date,
  adminsLoading: bool,
  subscriptionsLoadedAt: date,
  subscriptionsLoading: bool,
  locale: string.isRequired,
  isSuperAdmin: bool.isRequired,
};

GeneralSettings.defaultProps = {
  admins: [],
  adminsLoadedAt: null,
  adminsLoading: false,
  customer: {
    subscriptions: [],
    paymentMethod: {},
    // digitalSignaturesBought: [],
    consumed: { digitalSignatures: 0 },
    stock: { digitalSignatures: 0 },
  },
  subscriptionsLoadedAt: null,
  subscriptionsLoading: false,
};
export default connector(GeneralSettings);
