import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import messages from 'store/app/entities/messages/action';
import * as chatPanel from 'store/app/ui/ChatPanelSlice';
import employeeList from 'store/app/ui/drawers/employeeList/action';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = (state, ownProps) => {
  return {
    currentChannel: currentChannel(state),
    messageId: ownProps.message._id,
    canDelete: ownProps.message.canDelete,
    canEdit: ownProps.message.canEdit,
    canForward: ownProps.message.canForward,
    permissionsState: ownProps.message.permissionsState || null,
    temp: ownProps.message.temp,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    messages: bindActionCreators(messages, dispatch),
    chatPanel: bindActionCreators(chatPanel, dispatch),
    drawers: {
      employeeList: bindActionCreators(employeeList, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
