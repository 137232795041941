import React from 'react';
import { number, object } from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: 5,
  },
  counterText: {
    fontWeight: 600,
  },
};

const PeopleSelected = ({ classes, selected }) => (
  <div className={classes.root}>
    {selected > 0 ? (
      <div>
        <Typography
          className={classes.counterText}
          component="div"
          color="primary"
          variant="subtitle1"
        >
          <span>
            {selected} {I18n.t('TeamDrawer.EmployeesTable.people selected')}
          </span>
        </Typography>
      </div>
    ) : null}
  </div>
);
PeopleSelected.propTypes = {
  classes: object.isRequired,
  selected: number.isRequired,
};

export default withStyles(styles)(PeopleSelected);
