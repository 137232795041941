/**
 * @flow
 */
import { Box, Button, FormControlLabel, FormHelperText, Stack, Typography } from '@mui/material';
import Modal from 'components/@home/modals/Modal';
import NameField from 'components/@whistleblower-admin/pages/Portals/NameField';
import SlugField from 'components/@whistleblower-admin/pages/Portals/SlugField';
import validateName from 'components/@whistleblower-admin/pages/Portals/validateName';
import validateSlug from 'components/@whistleblower-admin/pages/Portals/validateSlug';
import { Field, Formik } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { useCreateWbPortalMutation } from 'store/app/entities/WbSlice';
import { v4 as uuidv4 } from 'uuid';

const CONFLICT = 409;

const AddNewModal = ({ isOpen, close }: { isOpen: boolean; close: Function }) => {
  const [create] = useCreateWbPortalMutation();
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <Box sx={{ minWidth: 600, maxWidth: '90vh' }}>
        <Typography
          id="add-portal-dialog-title"
          component="h2"
          level="inherit"
          fontSize="1.25em"
          mb="0.25em"
        >
          {I18n.t('WhistleblowerPortalConfig.AddPortalDialog.Title')}
        </Typography>
        {!!isOpen && (
          <Formik
            initialValues={{
              name: '',
              slug: uuidv4(),
            }}
            validate={values => {
              let errors = {};
              errors = validateName(values, errors);
              errors = validateSlug(values, errors);
              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setFieldError }) => {
              const { error } = await create(values);
              setSubmitting(false);
              if (error) {
                if (error.status === CONFLICT) {
                  error.data?.key?.forEach(field => {
                    setFieldError(
                      field,
                      I18n.t('WhistleblowerPortalConfig.errors.Duplicate', {
                        field: I18n.t(`WhistleblowerPortalConfig.atts.${field}`).toLowerCase(),
                      }),
                    );
                  });
                }
              } else {
                close();
              }
            }}
          >
            {({ errors, handleSubmit, isSubmitting, submitCount }) => (
              <form onSubmit={handleSubmit}>
                <Stack spacing={1} sx={{ mt: 4, mb: 2 }}>
                  <FormControlLabel
                    sx={{ alignItems: 'flex-start', m: 0 }}
                    labelPlacement="top"
                    control={
                      <>
                        <FormHelperText error>
                          {errors?.name && submitCount > 0 ? errors.name : ' '}
                        </FormHelperText>
                        <Field component={NameField} name="name" />
                      </>
                    }
                    label={
                      <Typography sx={{ fontWeight: 500 }}>
                        {I18n.t('WhistleblowerPortalConfig.atts.name')}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    sx={{ alignItems: 'flex-start' }}
                    labelPlacement="top"
                    control={
                      <>
                        <FormHelperText error>
                          {errors?.slug && submitCount > 0 ? errors.slug : ' '}
                        </FormHelperText>
                        <Field component={SlugField} name="slug" />
                      </>
                    }
                    label={
                      <Typography sx={{ fontWeight: 500 }}>
                        {I18n.t('WhistleblowerPortalConfig.atts.slug')}
                      </Typography>
                    }
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="button" onClick={close}>
                      {I18n.t('WhistleblowerPortalConfig.AddPortalDialog.Cancel')}
                    </Button>
                    <Button disabled={isSubmitting} type="submit">
                      {I18n.t('WhistleblowerPortalConfig.AddPortalDialog.Create')}
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          </Formik>
        )}
      </Box>
    </Modal>
  );
};

export default AddNewModal;
