import React, { Component } from 'react';
import { array, func, object } from 'prop-types';
import { List } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import AcknowledgeListItem from './AcknowledgeListItem';

const styles = {
  root: {},
};

class AcknowledgeList extends Component {
  handleSend = employee => () => {
    const { onSend } = this.props;
    onSend(employee);
  };

  render() {
    const { classes, employees, acknowledges, message } = this.props;
    return (
      <List className={classes.root}>
        {employees.map((employee, index) => (
          <AcknowledgeListItem
            key={employee._id}
            selected={!!(index % 2)}
            employee={{
              ...employee,
              confirmedAt: acknowledges.find(a => a.user === employee.user_id)?.confirmedAt,
            }}
            onSend={this.handleSend(employee)}
            message={message}
          />
        ))}
      </List>
    );
  }
}

AcknowledgeList.propTypes = {
  classes: object.isRequired,
  employees: array.isRequired,
  acknowledges: array.isRequired,
  message: object.isRequired,
  onSend: func.isRequired,
};

export default withStyles(styles)(AcknowledgeList);
