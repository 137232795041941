/**
 * @flow
 */
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import QuestionSummary from 'components/@home/drawers/FormAnalyticsDrawer/QuestionSummary';
import TabPanel from 'components/@home/drawers/FormAnalyticsDrawer/TabPanel';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'scroll',
  },
  pct: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: 'bold',
  },
  questions: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
}));

type Props = {
  form: Object,
  summary: Object,
  tab: number,
  index: number,
  setQuestion: Function,
  setAnswer: Function,
  setTab: Function,
};

const TabSummary = ({ form, summary, tab, index, setQuestion, setAnswer, setTab }: Props) => {
  const classes = useStyles();
  const pct = summary.totals.totalUsers
    ? (100 * summary.totals.uniqueAnswers) / summary.totals.totalUsers
    : 0;
  const showAnswers = (question, answer = 'All') => {
    setTab(index + 1);
    setQuestion(question);
    setAnswer(answer);
  };
  return (
    <TabPanel value={tab} index={index} className={classes.root}>
      <Typography className={classes.pct}>{pct.toFixed()}%</Typography>
      <Typography>{I18n.t('FormAnalyticsDrawer.Completion rate')}</Typography>
      <div className={classes.questions}>
        {summary &&
          form.questions?.map((question, idx) => (
            <QuestionSummary
              key={question._id}
              index={idx}
              question={question}
              summary={summary[question._id]}
              showAnswers={showAnswers}
            />
          ))}
      </div>
    </TabPanel>
  );
};
export default TabSummary;
