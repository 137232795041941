import React from 'react';
import { object, string, arrayOf } from 'prop-types';
import { Typography, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import LocationIcon from 'assets/icons/location.svg';
import PhoneIcon from 'assets/icons/phone.svg';
import EmailIcon from 'assets/icons/mail.svg';
import TagsIcon from 'assets/icons/tags.svg';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 100,
    marginBottom: 50,
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40,
  },
  greyIcon: {
    fontSize: 20,
    marginRight: 10,
    color: theme.palette.secondary.dark,
  },
});

const InfoList = ({ classes, location, phone, email, tags }) => (
  <div className={classes.root}>
    <Typography className={classes.line}>
      <SvgIcon className={classes.greyIcon}>
        <LocationIcon />
      </SvgIcon>
      {location || I18n.t('ProfileDrawer.no data')}
    </Typography>
    <Typography className={classes.line}>
      <SvgIcon className={classes.greyIcon}>
        <PhoneIcon />
      </SvgIcon>
      {phone || I18n.t('ProfileDrawer.no data')}
    </Typography>
    <Typography className={classes.line}>
      <SvgIcon className={classes.greyIcon}>
        <EmailIcon />
      </SvgIcon>
      {email || I18n.t('ProfileDrawer.no data')}
    </Typography>
    <Typography className={classes.line}>
      <SvgIcon className={classes.greyIcon}>
        <TagsIcon />
      </SvgIcon>
      {tags?.join(',') || I18n.t('ProfileDrawer.no data')}
    </Typography>
  </div>
);
InfoList.propTypes = {
  classes: object.isRequired,
  location: string,
  phone: string,
  email: string,
  tags: arrayOf(string),
};

InfoList.defaultProps = {
  location: null,
  phone: null,
  email: null,
  tags: null,
};
export default withStyles(styles)(InfoList);
