import {
  Box,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { WhistleblowerProvider } from 'components/@whistleblower-admin/WhistleblowerContext';
import AccountMultipleOutlineIcon from 'mdi-react/AccountMultipleOutlineIcon';
import FileDocumentMultipleOutlineIcon from 'mdi-react/FileDocumentMultipleOutlineIcon';
import LinkIcon from 'mdi-react/LinkIcon';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useGetMeQuery } from 'store/app/entities/WbSlice';

const icons = {
  portals: <LinkIcon />,
  occurrences: <FileDocumentMultipleOutlineIcon />,
  users: <AccountMultipleOutlineIcon />,
};
const WhistleblowerAdminLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data: me = { permissions: {} } } = useGetMeQuery();
  const navItems = ['occurrences', 'portals', 'users'].filter(item => {
    return me.permissions[item] > 0;
  });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        height: '100vh',
      }}
    >
      <Grid container sx={{ flex: 1 }}>
        <Grid
          item
          xs={2}
          sx={{
            p: 1,
            borderRight: '1px solid',
            borderColor: 'divider',
          }}
        >
          <Typography variant="h4" sx={{ p: 2 }}>
            Whistleblower
          </Typography>
          <nav aria-label={navItems.join(' ')}>
            <List>
              {navItems.map(item => (
                <ListItemButton
                  selected={location.pathname.startsWith(`/wb-admin/${item}`)}
                  key={item}
                  onClick={() => navigate(`/wb-admin/${item}`)}
                >
                  <ListItemIcon>{icons[item]}</ListItemIcon>
                  <ListItemText
                    sx={{ borderBottom: 0, minHeight: 0, paddingTop: 0, paddingBottom: 0 }}
                    primary={I18n.t(`WhistleblowerAdmin.Sections.${item}`)}
                  />
                </ListItemButton>
              ))}
            </List>
          </nav>
        </Grid>
        <Grid item xs={10} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <WhistleblowerProvider me={me}>
            <Outlet />
          </WhistleblowerProvider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhistleblowerAdminLayout;
