import DigitalSignatureSwitch from 'components/@home/modals/acknowledgment/PreviewModal/Form/DigitalSignatureSwitch';
import React, { Component } from 'react';
import { Field, Form } from 'formik';
import { I18n } from 'react-redux-i18n';
import messageShape from 'shapes/message';
import { func, object } from 'prop-types';
import attachmentShape from 'shapes/attachment';
import { Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TextField from 'components/formik/TextField';
import Title from './Title';
import PdfPreview from '../../../PdfPhonePreview';
import DraftButton from './DraftButton';
import PublishButton from './PublishButton';
import DiscardButton from './DiscardButton';
import Actions from './Actions';
import connector from './connector';
import formik from './formik';

const styles = () => ({
  root: {
    width: '80vw',
    maxWidth: '850px',
    minHeight: '620px',
    display: 'flex',
    flexDirection: 'column',
  },

  previewSection: {
    flex: 1,
    padding: '25px 15px',
  },

  textField: {
    margin: '0 0 25px',
    width: '100%',
  },
});

class PreviewModalForm extends Component {
  saveAsDraft = async e => {
    const { setFieldValue, submitForm, onClose } = this.props;
    setFieldValue('isDraft', true);
    await Promise.resolve();
    submitForm(e);
    onClose();
  };

  discard = () => {
    const { message, onClose, actions } = this.props;

    if (message) actions.messages.destroy(message._id);

    onClose();
  };

  getPdf = () => {
    const { message, tempAttachment } = this.props;
    if (!message) return tempAttachment.httpLink;
    return message.attachments[0].httpLink;
  };

  render() {
    const { classes } = this.props;
    return (
      <Form className={classes.root}>
        <div className={classes.previewSection}>
          <Title>{I18n.t('AcknowledgementCreate.Acknowledge message title')}</Title>
          <Field
            component={TextField}
            className={classes.textField}
            name="title"
            placeholder={I18n.t('AcknowledgementCreate.Acknowledge document title')}
          />
          <PdfPreview src={this.getPdf()} />
        </div>
        <Divider light />
        <Actions>
          <DiscardButton type="reset" onClick={this.discard} />
          <DigitalSignatureSwitch />
          <div>
            <DraftButton onClick={this.saveAsDraft} />
            <PublishButton type="submit" />
          </div>
        </Actions>
      </Form>
    );
  }
}

PreviewModalForm.propTypes = {
  classes: object.isRequired,
  tempAttachment: attachmentShape,
  message: messageShape,
  onClose: func.isRequired,
  setFieldValue: func.isRequired,
  submitForm: func.isRequired,
  actions: object.isRequired,
};
PreviewModalForm.defaultProps = {
  tempAttachment: null,
  message: null,
};

export default withStyles(styles)(connector(formik(PreviewModalForm)));
