/** @flow */
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import LauncherAppImageUpload from 'components/@home/drawers/LauncherAppDrawer/LauncherAppImageUpload';
import ControlLabel from 'components/common/ControlLabel/ControlLabel';
import { useDrawer } from 'components/common/Drawer/DrawerContext';
import Autosuggest from 'components/controls/Autosuggest';
import React, { useMemo } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import populateTeams from 'store/selectors/populateTeams';

const formatOptionLabel = option => (
  <Box
    sx={{
      whiteSpace: 'pre',
      width: 'calc(100%)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {'   '.repeat(option.level)}
    {option.label}
  </Box>
);

const useStyles = makeStyles(theme => ({
  linkWrapper: {
    width: 800,
    maxWidth: '80vw',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    flexDirection: 'column',
    gap: 16,
    borderColor: theme.palette.divider,
    padding: 16,
    alignItems: 'center',
    '& label p': {
      whiteSpace: 'nowrap',
    },
  },
  autocomplete: {
    width: 250,
  },
  imageFields: {
    display: 'grid',
    gridTemplateColumns: 'minmax(min-content, 1fr) auto 2fr',
    gap: 16,
    alignItems: 'center',
  },
}));

const LauncherAppLinkDrawerContent = () => {
  const methods = useFormContext();
  const { state: index } = useDrawer('LauncherAppLinkDrawer');
  const field = useMemo(() => `links.${index}`, [index]);
  const teams = useSelector(populateTeams);
  const locale = useSelector(state => state.i18n.locale);
  const params = useMemo(
    () =>
      [
        'code',
        'firstName',
        'surName',
        'division',
        'jobTitle',
        'department',
        'location',
        'phone',
        'email',
        'reportsTo',
        'notes',
        'tags',
      ]
        .map(value => ({
          value,
          label: I18n.t(`LauncherApp.params.${value}`),
        }))
        .sort((a, b) =>
          a.label.localeCompare(b.label, locale, { sensitivity: 'base', ignorePunctuation: true }),
        ),
    [locale],
  );
  const suggestions = useMemo(
    () =>
      teams?.map(team => ({
        value: team._id,
        label: team.name,
      })) || [],
    [teams],
  );
  const classes = useStyles();
  return (
    <Box className={classes.linkWrapper}>
      <ControlLabel
        labelPrefix="LauncherApp.fields.link"
        {...methods.register(`${field}.text`)}
        fullWidth
        size="small"
      />
      <label htmlFor={`LauncherApp.fields.link.${field}.image`}>
        <Typography>{I18n.t('LauncherApp.fields.link.imageLight')}</Typography>
      </label>
      <Box className={classes.imageFields}>
        <LauncherAppImageUpload
          id={`LauncherApp.fields.link.${field}.imageLight`}
          {...methods.register(`${field}.imageLight`)}
          control={LauncherAppImageUpload}
        />
        <ControlLabel
          labelPrefix="LauncherApp.fields.link"
          {...methods.register(`${field}.imageDark`)}
          control={LauncherAppImageUpload}
        />
      </Box>
      <ControlLabel
        labelPrefix="LauncherApp.fields.link"
        placeholder="https://..."
        {...methods.register(`${field}.url`)}
        fullWidth
        size="small"
      />
      <ControlLabel
        labelPrefix="LauncherApp.fields.link"
        {...methods.register(`${field}.urlApp`)}
        placeholder={I18n.t('LauncherApp.ifBlankLinkWillBeUsed')}
        fullWidth
        size="small"
      />
      <Controller
        name={`${field}.embed`}
        render={({ field: { onChange, value, ...rest } }) => (
          <ControlLabel
            labelPrefix="LauncherApp.fields.link"
            control={Switch}
            onChange={e => {
              onChange(e.target.checked);
            }}
            checked={value}
            {...rest}
          />
        )}
      />
      <Controller
        name={`${field}.params`}
        render={({ field: { value, onChange, ...rest } }) => (
          <ControlLabel
            control={Autosuggest}
            isMulti
            isClearable
            value={value}
            onChange={values =>
              onChange({ target: { value: values.map(({ value: v }) => v), name: rest.name } })
            }
            {...rest}
            controlProps={{ classes: { formControl: classes.autocomplete } }}
            autoWidth
            suggestions={params}
            formatOptionLabel={formatOptionLabel}
            label={I18n.t('LauncherApp.fields.link.params')}
          />
        )}
      />
      <Controller
        name={`${field}.teams`}
        render={({ field: { value, onChange, ...rest } }) => (
          <ControlLabel
            control={Autosuggest}
            isMulti
            isClearable
            value={value}
            onChange={values =>
              onChange({ target: { value: values.map(({ value: v }) => v), name: rest.name } })
            }
            {...rest}
            controlProps={{ classes: { formControl: classes.autocomplete } }}
            autoWidth
            suggestions={suggestions}
            formatOptionLabel={formatOptionLabel}
            label={I18n.t('LauncherApp.fields.link.teams')}
          />
        )}
      />
    </Box>
  );
};

export default LauncherAppLinkDrawerContent;
