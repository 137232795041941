import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormikAutosuggest from 'components/formik/Autosuggest';
import TextField from 'components/formik/TextField';
import { Field, FieldArray, useFormikContext } from 'formik';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(3),
  },
  tableField: {
    padding: theme.spacing(1),
    paddingLeft: 0,
  },
  addButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  valueContainer: {
    paddingLeft: 0,
  },
}));

const ChatFlowParametersTable = ({ name }) => {
  const classes = useStyles();
  const { getFieldProps } = useFormikContext();
  const { value } = getFieldProps(name);
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          {value?.length > 0 && (
            <Table size="small" padding="none" className={classes.table}>
              <TableHead>
                <TableRow hover={false}>
                  <TableCell style={{ width: '30%' }}>
                    {I18n.t('ChatFlowSettings.Parameter')}
                  </TableCell>
                  <TableCell style={{ width: '20%' }}>{I18n.t('ChatFlowSettings.Type')}</TableCell>
                  <TableCell style={{ width: '50%' }}>{I18n.t('ChatFlowSettings.Value')}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {value.map((p, idx) => (
                  <TableRow key={p._id} hover={false}>
                    <TableCell>
                      <Field
                        component={TextField}
                        name={`${name}.${idx}.id`}
                        placeholder={I18n.t('ChatFlowSettings.parameters.id')}
                        className={classes.tableField}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={FormikAutosuggest}
                        components={{
                          IndicatorsContainer: () => null,
                        }}
                        suggestions={['any', 'date', 'number', 'integer'].map(value => ({
                          value,
                          label: value,
                        }))}
                        placeholder={I18n.t('ChatFlowSettings.parameters.type')}
                        name={`${name}.${idx}.type`}
                        classes={{ ValueContainer: classes.valueContainer }}
                        className={classes.tableField}
                      />
                    </TableCell>
                    <TableCell>
                      <Field
                        component={TextField}
                        name={`${name}.${idx}.value`}
                        placeholder={I18n.t('ChatFlowSettings.parameters.value')}
                        className={classes.tableField}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => arrayHelpers.remove(idx)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Button
            variant="text"
            color="primary"
            classes={{ root: classes.addButton }}
            onClick={() => arrayHelpers.push({})}
          >
            {I18n.t('ChatFlowSettings.Add parameter')}
          </Button>
        </div>
      )}
    />
  );
};

ChatFlowParametersTable.propTypes = {
  name: string.isRequired,
};

export default ChatFlowParametersTable;
