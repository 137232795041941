/* eslint-disable react/no-danger */
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import NextButton from 'components/@auth/NextButton';
import Loading from 'components/common/Loading';
import { object, string } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import Heading from '../Heading';
import connector from './connector';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100vh - 125px)',
  },
  IAgreeButton: {
    margin: '10px 0 25px 0',
  },
  article: {
    display: 'flex',
    flexGrow: 1,
    overflowY: 'auto',
  },
  loading: {
    top: 0,
    position: 'absolute',
    left: '-.5em',
  },
};

class PrivacyScene extends Component {
  componentDidMount() {
    const { actions, locale } = this.props;
    actions.form.loadContent('privacy', locale);
  }

  handleAccept = () => {
    const { actions } = this.props;
    actions.form.update({ terms: true });
  };

  render() {
    const { classes, content } = this.props;
    return (
      <div className={classes.root}>
        <Heading headline={I18n.t('Privacy.headline')} subheading={I18n.t('Privacy.subheading')} />
        <Typography component="article" className={classes.article}>
          {!content.privacy ? (
            <Loading size={80} className={classes.loading} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: content.privacy }} />
          )}
        </Typography>

        <Link to="/auth/login/confirmation">
          <NextButton
            className={classes.IAgreeButton}
            color="primary"
            fullWidth
            onClick={this.handleAccept}
            variant="contained"
          >
            {I18n.t('Privacy.next')}
          </NextButton>
        </Link>
      </div>
    );
  }
}

PrivacyScene.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  locale: string.isRequired,
  content: object.isRequired,
};

export default withStyles(styles)(connector(PrivacyScene));
