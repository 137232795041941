import { Button, Dialog, DialogContent, DialogTitle, InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import ArticleConfigPopover from 'components/@home/@messages/ArticlePanel/ArticleConfigPopover';
import ArticleMenu from 'components/@home/@messages/ArticlePanel/ArticleMenu';
import CloseButton from 'components/@home/drawers/CloseButton';
import ArticleEditor from 'components/common/ArticleEditor';
import useConfirm from 'components/common/GlobalConfirmDialog/useConfirm';
import Loading from 'components/common/Loading';
import ShortcutTooltip from 'components/common/ShortcutTooltip/ShortcutTooltip';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useBlocker, useMatch, useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteArticleMutation,
  useGetArticleQuery,
  useUpdateArticleMutation,
} from 'store/app/entities/ArticlesSlice';
import companiesAction from 'store/app/entities/companies/action';
import messagesActions from 'store/app/entities/messages/action';
import authEmployee from 'store/selectors/authEmployee';
import currentChannel from 'store/selectors/currentChannel';
import uniqid from 'uniqid';
import stringTruncate from 'utils/stringTruncate';
import useActions from 'utils/useActions';
import useKeyDown from 'utils/useKeyDown';
import useOpenClose from 'utils/useOpenClose';

const MAX_LENGTH = 200;

const initialValues = {
  title: '',
  subtitle: '',
  preview: {
    title: null,
    subtitle: null,
    image: null,
  },
};

const styles = {
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
    p: 2,
  },
  article: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    fontSize: 16,
    height: '100%',
    '& .bn-editor': {
      pl: 2,
      pr: 6,
    },
  },
  title: {
    outline: 'none',
    fontFamily: 'var(--bn-font-family)',
    fontSize: 'var(--level)',
    fontWeight: 700,
    color: 'var(--bn-colors-editor-text)',
    p: 2,
    pr: 6,
    '&:empty::before': {
      content: 'attr(placeholder)',
      color: 'var(--bn-colors-disabled-text)',
    },
  },
  header: {
    borderBottomWidth: 0,
    borderBottomStyle: 'solid',
    borderBottomColor: 'divider',
    p: 2,
    pt: 0,
    display: 'flex',
    alignItems: 'center',
  },
  headerButtons: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 1,
  },
};

const ArticlePanel = () => {
  const [saving, setSaving] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const { articleId } = useParams();
  const employee = useSelector(authEmployee);
  const channel = useSelector(currentChannel);
  const canEdit = useMemo(
    () => employee.isAdmin || channel.isAdmin || channel.canWriteAsChannel,
    [channel.canWriteAsChannel, channel.isAdmin, employee.isAdmin],
  );
  const isNew = useMemo(() => articleId === 'new', [articleId]);
  const { data: article = initialValues, isFetching = false } = useGetArticleQuery(
    { channelId: channel._id, articleId },
    { skip: articleId === 'new' || deleted },
  );
  const [updateArticle] = useUpdateArticleMutation();
  const [removeArticle] = useDeleteArticleMutation();
  const match = useMatch('/home/messages/:id/*');
  const navigate = useNavigate();
  const titleRef = React.useRef();
  const editorRef = React.useRef();
  const contentRef = React.useRef();
  const sendMessage = useActions(messagesActions.send);
  const messagesLoad = useActions(messagesActions.load);
  const companyLoad = useActions(companiesAction.load);
  const [contentChanged, setContentChanged] = useState(false);
  const methods = useForm({
    values: article,
  });
  const handleTitleRef = useCallback(ref => {
    titleRef.current = ref;
    if (titleRef.current) {
      setTimeout(() => {
        titleRef.current?.focus();
      }, 100);
    }
  }, []);

  const { open: openConfirm, close: closeConfirm } = useConfirm();
  const handleBack = useCallback(() => {
    navigate(match.pathnameBase, { replace: true });
  }, [match.pathnameBase, navigate]);

  const shouldBlock = useCallback(() => {
    return (contentChanged || methods.formState.isDirty) && !deleted && !saving;
  }, [contentChanged, deleted, methods.formState.isDirty, saving]);
  const blocker = useBlocker(shouldBlock);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      openConfirm({
        message: I18n.t('ArticleEditor.You have unsaved changes'),
        children: I18n.t('ArticleEditor.Are you sure you want to leave'),
        confirmText: I18n.t('ArticleEditor.Discard'),
        cancelText: I18n.t('ArticleEditor.Continue editing'),
        onConfirm: () => {
          blocker.proceed();
          closeConfirm();
        },
        onClose: () => {
          blocker.reset();
        },
      });
    }
  }, [blocker, closeConfirm, openConfirm]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !shouldBlock) {
      blocker.reset();
    }
  }, [blocker, shouldBlock]);

  const handleEnter = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const next = contentRef.current.querySelector('.bn-editor');
        if (next) {
          next.focus();
        }
      }
    },
    [contentRef],
  );

  const handleEditorContentChange = useCallback(() => {
    const blocks = editorRef.current?.topLevelBlocks;
    if (!blocks) return;
    if (!contentChanged) {
      setContentChanged(true);
    }
    let newSubtitle = '';
    // Keep adding text from blocks until we reach MAX_LENGTH characters
    for (let i = 0; i < blocks.length && newSubtitle.length < MAX_LENGTH; i += 1) {
      const block = blocks[i];
      if (block.type !== 'table') {
        newSubtitle += `${
          block.content
            ?.map(
              c => (c.type === 'link' ? c.content.map(c2 => c2.text || '').join('') : c.text) || '',
            )
            .join('') || ''
        } `;
      }
    }
    if (newSubtitle) {
      methods.setValue('subtitle', stringTruncate(newSubtitle, MAX_LENGTH));
    }
    const newImage = blocks.find(b => b.type === 'image')?.props.url || null;
    methods.setValue('image', newImage);
  }, [contentChanged, methods]);

  const handleDelete = useCallback(() => {
    setDeleted(true);
    removeArticle({ channelId: channel._id, articleId }).then(() => {
      companyLoad(channel.company_id);
      setTimeout(() => {
        messagesLoad(channel._id, { replace: true });
      }, 1000);
      handleBack();
    });
  }, [articleId, channel._id, handleBack, messagesLoad, removeArticle]);

  const handleSave = useCallback(
    ({ title, subtitle: subtitleIn, image, preview }) => {
      if (saving) return;
      setSaving(true);
      setTimeout(() => {
        const subtitle = subtitleIn?.trim();
        const blocks = editorRef.current?.topLevelBlocks;

        if (isNew) {
          const msg = {
            _id: uniqid(),
            text: '',
            channel_id: channel._id,
            company_id: channel.company,
            previews: {},
            createdAt: Date.now(),
            employee_id: employee._id,
            user_id: employee.user._id,
            isDeletedBy: [],
            isDraft: false,
            isEdited: false,
            isMandatory: false,
            temp: true,
            seen: false,
            senderWasDeleted: false,
            sentAsChannel: channel.isReadOnly,
            article: {
              title,
              subtitle,
              image,
              preview,
              blocks,
            },
          };

          sendMessage(msg);
          setTimeout(handleBack, 10);
        } else {
          updateArticle({
            channelId: channel._id,
            articleId,
            article: {
              title,
              subtitle,
              image,
              preview,
              blocks,
            },
          })
            .unwrap()
            .then(() => {
              setTimeout(() => messagesLoad(channel._id), 10);
            });
          setTimeout(handleBack, 10);
        }
      }, 10);
    },
    [
      articleId,
      channel._id,
      channel.company,
      employee._id,
      employee.user._id,
      handleBack,
      isNew,
      messagesLoad,
      saving,
      sendMessage,
      updateArticle,
    ],
  );
  const [isPreviewOpen, openPreview, closePreview] = useOpenClose();

  const handlePreSubmit = useCallback(
    (...args) => {
      if (isNew) {
        openPreview();
      } else {
        handleSave(...args);
      }
    },
    [handleSave, isNew, openPreview],
  );

  const targetRef = useKeyDown(
    { Escape: handleBack, 'Ctrl+s,Meta+s': methods.handleSubmit(handlePreSubmit) },
    { actInsideInput: true },
  );

  const title = methods.watch('title');
  return (
    <Box sx={styles.root} ref={targetRef}>
      <ShortcutTooltip title={I18n.t('ArticleEditor.Discard')} shortcut="Esc">
        <Box sx={{ m: 2 }}>
          <CloseButton onClick={handleBack} />
        </Box>
      </ShortcutTooltip>
      <FormProvider {...methods}>
        <Box
          component="form"
          onSubmit={methods.handleSubmit(handlePreSubmit)}
          sx={styles.content}
          ref={contentRef}
        >
          {!canEdit || (
            <Box sx={styles.header}>
              <Box sx={styles.headerButtons}>
                <>
                  <ShortcutTooltip
                    title={I18n.t(isNew ? 'ArticleEditor.Publish' : 'ArticleEditor.Save')}
                    shortcut={['Ctrl', 'S']}
                  >
                    <Box>
                      <Button variant="contained" color="primary" type="submit" disabled={!title}>
                        {I18n.t(isNew ? 'ArticleEditor.Publish' : 'ArticleEditor.Save')}
                      </Button>
                    </Box>
                  </ShortcutTooltip>
                  <ArticleMenu article={article} handleDelete={handleDelete} />
                </>
              </Box>
            </Box>
          )}
          {isFetching || (!isNew && !title) ? (
            <Loading />
          ) : (
            <ArticleEditor
              editable={canEdit}
              editorRef={editorRef}
              article={article}
              sx={styles.article}
              onEditorContentChange={handleEditorContentChange}
            >
              <InputBase
                {...methods.register('title')}
                fullWidth
                readOnly={!canEdit}
                inputRef={handleTitleRef}
                tabIndex="-1"
                multiline
                className="bn-container"
                data-color-scheme="dark"
                data-level="1"
                onKeyDown={handleEnter}
                placeholder={I18n.t('ArticleEditor.Your article title')}
                sx={styles.title}
              />
            </ArticleEditor>
          )}
        </Box>
        <Dialog open={isPreviewOpen} onClose={closePreview}>
          <DialogTitle>{I18n.t('ArticleEditor.Message preview')}</DialogTitle>
          <DialogContent>
            <ArticleConfigPopover
              saveText={
                <>
                  {I18n.t('ArticleEditor.Publish')}
                  <Loading size={14} color="white" sx={{ display: saving ? 'block' : 'none' }} />
                </>
              }
              disabled={saving}
              discardText={I18n.t('ArticleEditor.Continue editing')}
              isOpen={isPreviewOpen}
              close={closePreview}
              onSubmit={methods.handleSubmit(handleSave)}
            />
          </DialogContent>
        </Dialog>
      </FormProvider>
    </Box>
  );
};

export default ArticlePanel;
