import { FormControlLabel } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Switch from 'components/formik/Switch';
import { connect, Field } from 'formik';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = {
  root: {
    justifySelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  },
  switch: {
    marginBottom: 0,
    marginTop: 0,
  },
};

const DigitalSignatureSwitch = ({ classes, formik }) => (
  <div className={classes.root}>
    <FormControlLabel
      control={
        <Field
          className={classes.switch}
          component={Switch}
          disabled={formik.values.digitalSignature}
          name="digitalSignature"
        />
      }
      disabled={false}
      label={I18n.t('AcknowledgementCreate.DigitalSignatureSwitch.label')}
    />
  </div>
);

DigitalSignatureSwitch.propTypes = {
  classes: object.isRequired,
  formik: object.isRequired,
};

export default withStyles(styles)(connect(DigitalSignatureSwitch));
