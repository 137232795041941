import { GET_USER_DATA_FULFILLED } from 'store/app/auth/action';
import { LOAD_COMPANY_FULFILLED, LOAD_COMPANY_PENDING } from 'store/app/entities/companies/action';

const initialState = {
  isLoadedShortCompanies: false,
  isLoadingCompany: true,
  isLoadedCompany: false,
  loadedCompanyAt: null,
};

const loadersReducer = (state = initialState, { type }) => {
  switch (type) {
    case GET_USER_DATA_FULFILLED:
      return {
        ...state,
        isLoadedShortCompanies: true,
      };

    case LOAD_COMPANY_PENDING:
      return {
        ...state,
        isLoadingCompany: true,
      };

    case LOAD_COMPANY_FULFILLED:
      return {
        ...state,
        isLoadingCompany: false,
        isLoadedCompany: true,
        loadedCompanyAt: Date.now(),
      };

    default:
      return state;
  }
};

export default loadersReducer;
