import { Checkbox, MenuItem, Select, SvgIcon, TableCell, TableRow, Tooltip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import InviteIcon from 'assets/icons/invite.svg';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';

const styles = theme => ({
  root: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  inactive: {
    color: theme.palette.secondary.main,
  },
  checkbox: {
    position: 'relative',
    paddingRight: 30,
  },
  icon: {
    width: 16,
    top: 12,
    position: 'absolute',
    left: 65,
    color: theme.palette.secondary.main,
  },
});

class EmployeeRow extends Component {
  select = () => {
    const { employee, onSelect } = this.props;

    onSelect(employee._id);
  };

  inactiveStyle = isInactive => {
    const { classes } = this.props;
    return isInactive ? classes.inactive : undefined;
  };

  onChangeRole = e => {
    this.props.onSetRole(this.props.employee, e.target.value);
  };

  render() {
    const { classes, member, employee, defaultRole } = this.props;
    const isInactive = !employee.isAcceptTerms;

    const role = member?.role;
    const cellClassName = isInactive ? classes.inactive : classes.cell;

    const getName = e => e.name + (isEmpty(e.code) ? '' : ` - ${e.code}`);

    return (
      <TableRow hover className={classes.root} onClick={this.select} selected={!!member}>
        <TableCell padding="checkbox" className={classes.checkbox}>
          {isInactive && (
            <SvgIcon className={classes.icon}>
              <InviteIcon />
            </SvgIcon>
          )}
          <Checkbox color="primary" checked={!!member} />
        </TableCell>
        <TableCell className={this.inactiveStyle(isInactive)} padding="normal">
          <Tooltip title={getName(employee)} placement="top">
            <span>{truncate(getName(employee), { length: 18 })}</span>
          </Tooltip>
        </TableCell>
        <TableCell className={this.inactiveStyle(isInactive)}>
          <Tooltip title={employee.department} placement="top">
            <span>{truncate(employee.department, { length: 18 })}</span>
          </Tooltip>
        </TableCell>
        <TableCell className={this.inactiveStyle(isInactive)}>
          <Tooltip title={employee.jobTitle} placement="top">
            <span>{truncate(employee.jobTitle, { length: 18 })}</span>
          </Tooltip>
        </TableCell>
        <TableCell className={this.inactiveStyle(isInactive)}>{employee.location}</TableCell>
        <TableCell className={cellClassName}>
          {!!member && (
            <Select
              MenuProps={{ onClick: e => e.stopPropagation() }}
              style={{ width: 100 }}
              value={role || defaultRole}
              defaultValue={defaultRole}
              onChange={this.onChangeRole}
            >
              {['member', 'admin', 'guest'].map(r => (
                <MenuItem key={r} value={r}>
                  {I18n.t(`Roles.${r}`)}
                </MenuItem>
              ))}
            </Select>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

EmployeeRow.propTypes = {
  classes: object.isRequired,
  employee: employeeShape.isRequired,
  member: object.isRequired,
  defaultRole: string,
  onSelect: func.isRequired,
  onSetRole: func.isRequired,
};

EmployeeRow.defaultProps = {
  defaultRole: null,
};

export default withStyles(styles)(EmployeeRow);
