import {
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TooltipIfDisabled from 'components/common/TooltipIfDisabled';
import Autosuggest from 'components/controls/Autosuggest';
import TableHead, { columnsShape } from 'components/controls/TableHead';
import { connect } from 'formik';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import CloseIcon from 'mdi-react/CloseIcon';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

const styles = theme => ({
  root: {
    margin: '15px 0 30px 0',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  row: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
      backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))',
    },
  },
  field: {
    flexGrow: 1,
    padding: 5,
  },
  form: {
    display: 'flex',
  },
  button: {
    margin: 5,
    minWidth: 100,
    height: 40,
  },
  tag: {
    height: 35,
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.ultraLight,
    marginRight: 5,
    marginBottom: 10,
  },
});

const TableField = ({
  onChange,
  onCheckboxChange,
  name,
  classes,
  suggestions,
  value: valueProp,
  columns,
  formik,
  buttonText,
  error,
  helperText,
  removeDisabled,
  controlsDisabled,
  disabledTooltip,
  placeholder,
}) => {
  const [suggestion, setSuggestion] = useState('');
  const [order, setOrder] = useState({ by: 'name', isReverse: false });
  const sortFunction = (first, second) => {
    const f = get(first.value, order.by);
    const s = get(second.value, order.by);
    let ret = 0;
    if (typeof f === typeof s && typeof f === 'string') {
      ret = f.localeCompare(s);
    } else if (typeof f === typeof s && typeof f === 'undefined') {
      ret = 0;
    } else if (typeof f === 'undefined') {
      ret = -1;
    } else if (typeof s === 'undefined') {
      ret = 1;
    }
    return order.isReverse ? -ret : ret;
  };
  const [value, setValue] = useState(valueProp.sort(sortFunction));
  useEffect(() => {
    const sorted = valueProp.sort(sortFunction);
    setValue(sorted);
  }, [order, sortFunction, valueProp]);
  const handleChange = newSuggestion => {
    setSuggestion(newSuggestion);
  };

  const handleAdd = () => {
    if (!suggestion) return false;
    setSuggestion('');
    let rt = formik.values[`${name}Removed`];
    if (formik.values[`${name}Removed`].find(t => t === suggestion.value._id)) {
      rt = [...formik.values[`${name}Removed`]].filter(v => v !== suggestion.value._id);
      formik.setFieldValue(`${name}Removed`, rt);
    }
    onChange(name, uniqBy([...value, suggestion]), rt);
    return true;
  };

  const handleCheckboxChange = (formikP, nameP, valueP, index, key) => e => {
    const newValue = [...valueP];
    newValue[index].value[key] = e.target.checked;
    formikP.setFieldValue(nameP, newValue);
    onCheckboxChange(formikP, valueP, index, key, e.target.checked);
  };

  const handleSelectChange = (formikP, nameP, valueP, index, key) => e => {
    const newValue = [...valueP];
    newValue[index].value[key] = e.target.value;
    formikP.setFieldValue(nameP, newValue);
    onCheckboxChange(formikP, valueP, index, key, e.target.checked);
  };

  const handleRemove = (formikP, nameP, valueP, index) => () => {
    if (removeDisabled(valueP)) {
      return;
    }
    const newValue = [...valueP];
    const removedId = newValue[index].value.id;
    newValue.splice(index, 1);
    formikP.setFieldValue(nameP, newValue);
    let rt = formikP.values[`${nameP}Removed`];
    if (!formikP.values[`${nameP}Removed`].find(t => t === removedId)) {
      rt = [...formikP.values[`${nameP}Removed`], removedId];
      formikP.setFieldValue(`${nameP}Removed`, rt);
    }
    onChange(nameP, newValue, rt);
  };

  const getComponent = (type, label, val, index, key, options, defaultValue) => {
    switch (type) {
      case 'dropdown':
        return (
          <Select
            variant="standard"
            MenuProps={{ onClick: e => e.stopPropagation() }}
            style={{ width: 100 }}
            value={val[key]}
            onChange={handleSelectChange(formik, name, value, index, key)}
            disabled={controlsDisabled(val)}
            defaultValue={typeof defaultValue === 'function' ? defaultValue(val) : defaultValue}
          >
            {options.map(o => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        );
      case 'checkbox':
        return (
          <Checkbox
            name={`${label}-${key}`}
            checked={!!(val[key] === null || val[key])}
            onChange={handleCheckboxChange(formik, name, value, index, key)}
            color="primary"
            disabled={val[key] === null || controlsDisabled(val)}
          />
        );
      case 'remove':
        return (
          <IconButton
            disabled={removeDisabled(val)}
            onClick={handleRemove(formik, name, value, index)}
          >
            <CloseIcon />
          </IconButton>
        );
      default:
        return null;
    }
  };
  const handleSort = by => {
    if (order.by === by) {
      setOrder({ ...order, isReverse: !order.isReverse });
    } else {
      setOrder({ by, isReverse: false });
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <Autosuggest
          error={error}
          helperText={helperText}
          className={classes.field}
          suggestions={differenceBy(suggestions, value, 'label')}
          value={suggestion}
          onChange={handleChange}
          placeholder={placeholder}
        />
        <Button variant="outlined" color="secondary" onClick={handleAdd} className={classes.button}>
          {buttonText}
        </Button>
      </div>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          {value.length ? <TableHead order={order} columns={columns} onSort={handleSort} /> : null}
          <TableBody>
            {value.map(({ label, value: val }, index) => {
              return (
                <TableRow key={`${val.id || Math.random()}`} hover className={classes.row}>
                  {columns.map(({ key, type, options, defaultValue }) => (
                    <TooltipIfDisabled
                      key={key}
                      disabled={type === 'remove' && removeDisabled(val)}
                      title={disabledTooltip}
                    >
                      <TableCell
                        key={`${label}-${key}`}
                        style={type === 'remove' ? { textAlign: 'right' } : undefined}
                      >
                        {getComponent(type, label, val, index, key, options, defaultValue) || (
                          <Typography>{val[key]}</Typography>
                        )}
                      </TableCell>
                    </TooltipIfDisabled>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

TableField.propTypes = {
  classes: object.isRequired,
  onChange: func,
  value: array,
  name: string.isRequired,
  suggestions: array.isRequired,
  columns: columnsShape.isRequired,
  formik: object.isRequired,
  onCheckboxChange: func,
  buttonText: string.isRequired,
  error: bool,
  helperText: string,
  removeDisabled: func,
  controlsDisabled: func,
  disabledTooltip: string,
  placeholder: string,
};

TableField.defaultProps = {
  onChange: () => {},
  value: [],
  error: false,
  helperText: null,
  onCheckboxChange: () => {},
  removeDisabled: () => false,
  controlsDisabled: () => false,
  disabledTooltip: null,
  placeholder: null,
};

export default withStyles(styles)(connect(TableField));
