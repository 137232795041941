import withStyles from '@mui/styles/withStyles';
import { func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import WorkingDays from 'components/controls/WorkingDays';

import SelectTitle from '../SelectTitle';

const styles = theme => ({
  root: {
    marginTop: '20px',
    paddingRight: '20px',
  },
  check: {
    color: theme.palette.primary.main,
  },
  items: {
    boxShadow: 'none',
  },
});

const WorkingDaysSettings = props => {
  const { classes, value, onChange } = props;

  return (
    <div className={classes.root}>
      <SelectTitle headline={I18n.t('SettingsDrawer.Hours')} />
      <WorkingDays value={value} onChange={onChange} />
    </div>
  );
};

WorkingDaysSettings.propTypes = {
  classes: object.isRequired,
  value: object.isRequired,
  onChange: func.isRequired,
};

WorkingDaysSettings.defaultProps = {};

export default withStyles(styles)(WorkingDaysSettings);
