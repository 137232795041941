/**
 * @flow
 */
import { SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import BoltIcon from 'assets/icons/bolt.svg';
import AddQuestionButton from 'components/@home/drawers/FormDrawer/AddQuestionButton';
import { Form } from 'formik';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(8),
    minHeight: '66%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '50%',
  },
  icon: {
    fontSize: 48,
    color: theme.palette.greyBlue,
  },
  title: {
    margin: '20px 0 20px 0',
    whiteSpace: 'pre-line',
  },
  infoBox: {
    background: theme.palette.secondary.ultraUltraLight,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    borderRadius: 6,
    padding: theme.spacing(4),
    '& ul': {
      padding: '0 0 0 15px',
      listStylePosition: 'outside',
      marginBottom: 0,
      color: theme.palette.secondary.light,
    },
  },
  characteristic: {
    color: theme.palette.black,
    verticalAlign: 'middle',
  },
}));

type Props = {
  onAddQuestion: Function,
};
const FormDrawerQuestionsEmpty = ({ onAddQuestion }: Props) => {
  const classes = useStyles();
  return (
    <Form className={classes.root}>
      <div className={classes.column}>
        <SvgIcon className={classes.icon}>
          <BoltIcon />
        </SvgIcon>
        <Typography variant="h5" component="h1" className={classes.title}>
          {I18n.t('FormDrawer.Welcome forms')}
        </Typography>
        <AddQuestionButton onAddQuestion={onAddQuestion} isEmpty initiallyOpen />
      </div>
      <div className={classes.column}>
        <div className={classes.infoBox}>
          <Typography><strong>{I18n.t('FormDrawer.infoBox.title')}</strong></Typography>
          <ul>
            {[1, 2, 3, 4].map(char => (
              <li key={`char.${char}`}>
                <Typography display="inline" className={classes.characteristic}>
                  {I18n.t(`FormDrawer.infoBox.characteristics.${char}`)}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Form>
  );
};

export default FormDrawerQuestionsEmpty;
