import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import employees from 'store/app/entities/employees/action';
import alert from 'store/app/ui/Alert/action';
import employeeImports from 'store/app/ui/drawers/employeeImports/action';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  actions: {
    alert: bindActionCreators(alert, dispatch),
    employees: bindActionCreators(employees, dispatch),
    drawers: {
      employeeImports: bindActionCreators(employeeImports, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
