import { I18n } from 'react-redux-i18n';

const validateSlug = (values, errors) => {
  if (!values.slug) {
    return {
      ...errors,
      slug: I18n.t('WhistleblowerPortalConfig.errors.Required', {
        field: I18n.t('WhistleblowerPortalConfig.atts.slug'),
      }),
    };
  }
  if (!values.slug.match(/^[\w-]{1,40}$/)) {
    return {
      ...errors,
      slug: I18n.t('WhistleblowerPortalConfig.errors.FolderPattern', {
        field: I18n.t('WhistleblowerPortalConfig.atts.slug'),
      }),
    };
  }
  return errors;
};

export default validateSlug;
