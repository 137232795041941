import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import auth from 'store/app/auth/action';
import channels from 'store/app/entities/channels/action';
import companies from 'store/app/entities/companies/action';
import employees from 'store/app/entities/employees/action';
import { employeesApiEndpoints } from 'store/app/entities/EmployeesApiSlice';
import user from 'store/app/entities/users/action';
import employeeDrawer from 'store/app/ui/drawers/employee/action';
import authCompany from 'store/selectors/authCompany';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';

const mapStateToProps = state => ({
  authCompany: authCompany(state),
  isCompanyAdmin: isCompanyAdmin(state),
});

const mapDispatchToProps = dispatch => ({
  actions: {
    auth: bindActionCreators(auth, dispatch),
    channels: bindActionCreators(channels, dispatch),
    companies: bindActionCreators(companies, dispatch),
    employeeDrawer: bindActionCreators(employeeDrawer, dispatch),
    employees: bindActionCreators(employees, dispatch),
    employeeApi: {
      create: body => dispatch(employeesApiEndpoints.create.initiate(body)),
      update: (_id, body) => dispatch(employeesApiEndpoints.update.initiate({ ...body, _id })),
    },
    user: bindActionCreators(user, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
