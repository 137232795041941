import { Button } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import GetStepContent from 'components/@home/drawers/HrBotDrawer/GetStepContent';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import { getFileName } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import throttle from 'lodash/throttle';
import moment from 'moment';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Attachments from 'services/api/Attachments';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import authUser from 'store/selectors/authUser';
import StepFiles from './StepFiles';
import StepMessage from './StepMessage';
import StepSend from './StepSend';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  error: {
    marginLeft: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  '@global': {
    '.textLayerDiv': {
      position: 'relative',
      display: 'block !important',
      '& span': {
        position: 'absolute',
        whiteSpace: 'pre',
        transformOrigin: '0% 0%',
      },
    },
  },
}));

const HrBotSend = () => {
  const classes = useStyles();
  const user = useSelector(authUser);
  const dispatch = useDispatch();
  const resets = useRef(0);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [parsingPct, setParsingPct] = useState(0);
  const [userItems, setUserItems] = useState({});
  const [error, setError] = useState(null);
  const [nextEnabled, setNextEnabled] = useState(false);
  const { closeDrawer, files, message, setFiles, setUploadedPct, format, setDroppedFiles } =
    useContext(HrBotContext);
  const steps = ['Files', 'Message', 'Send'].map(step => I18n.t(`HrBot.Steps.${step}`));

  useEffect(() => {
    if (format?.items) {
      format.items
        .filter(i => i.selector === 'user' && i.defaultValue)
        .forEach(item => {
          let date = moment();
          let value;
          if (item.defaultValue === 'previousMonth') {
            date = date.subtract(1, 'months');
          }
          if (item.name === 'month') {
            value = date.format('MM');
          } else if (item.name === 'year') {
            value = date.format('YYYY');
          } else if (item.name === 'day') {
            value = date.format('D');
          }
          setUserItems(ui => ({
            ...ui,
            [item.name]: value,
          }));
        });
    }
  }, [format]);

  const totalSteps = useCallback(() => {
    return steps.length;
  }, [steps.length]);

  const completedSteps = useCallback(() => {
    return Object.keys(completed).length;
  }, [completed]);

  const isLastStep = useCallback(() => {
    return activeStep === totalSteps() - 1;
  }, [activeStep, totalSteps]);

  const allStepsCompleted = useCallback(() => {
    return completedSteps() === totalSteps();
  }, [completedSteps, totalSteps]);

  const handleSend = useCallback(async () => {
    setNextEnabled(false);
    const filesToSend = files.filter(
      f => !f.error && (format.sendBy === 'id' ? f.employee?.id : f.employee?.user),
    );
    const filesMap = filesToSend.reduce(
      (acc, file) => acc.set(file.file, [...(acc.get(file.file) || []), file]),
      new Map(),
    );
    const filesToUpload = Array.from(filesMap.entries());
    const uploaded = filesToUpload.map(() => 0);
    const updatePct = throttle(() => {
      const pct = Math.round(uploaded.reduce((acc, curr) => acc + curr, 0) / uploaded.length);
      setUploadedPct(pct);
      if (pct === 100) {
        setNextEnabled(true);
      }
    }, 200);
    const attachmentsArr = await Promise.all(
      filesToUpload.map(async ([file, origFiles], idx) => {
        const fileName = origFiles?.length === 1 ? origFiles[0].newFileName : null;
        const { _id: attachment } = await Attachments.upload(
          user.company,
          user._id,
          file,
          null,
          progressEvent => {
            uploaded[idx] = (progressEvent.loaded * 100) / progressEvent.total;
            updatePct();
          },
          null,
          fileName,
        );
        return filesMap.get(file).map(f => ({
          ...f,
          attachment,
        }));
      }),
    );

    dispatch(
      bulkMessagings.hrBotSend({
        text: message,
        format: format._id,
        files: attachmentsArr.flat().map(a => {
          return {
            attachment: a.attachment,
            ...(format.type === 'single-pdf' ? { pageNum: a.pageNum } : {}),
            ...(format.sendBy === 'id'
              ? { employeeId: a.employee.id }
              : {
                  user: typeof a.employee.user === 'object' ? a.employee.user._id : a.employee.user,
                }),
            ...(a.dni ? { password: a.dni } : {}),
            fileName: getFileName({ ...a, ...userItems }, format),
          };
        }),
      }),
    );
  }, [dispatch, files, format, message, setUploadedPct, user._id, user.company, userItems]);

  const handleNext = useCallback(async () => {
    if (isLastStep()) {
      await handleSend();
    } else {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep, handleSend, isLastStep]);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  const handleStep = useCallback(
    step => () => {
      setActiveStep(step);
    },
    [],
  );

  const handleComplete = useCallback(async () => {
    setCompleted({ ...completed, [activeStep]: true });
    await handleNext();
  }, [activeStep, completed, handleNext]);

  const handleReset = useCallback(() => {
    setFiles(null);
    setActiveStep(0);
    setCompleted({});
    setUploadedPct(0);
    setNextEnabled(false);
    setDroppedFiles({});
    resets.current += 1;
    dispatch(bulkMessagings.clear());
  }, [dispatch, setDroppedFiles, setFiles, setUploadedPct]);

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)} completed={completed[index]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>

      <div className={classes.instructions}>
        <GetStepContent step={activeStep}>
          <StepFiles
            resets={resets}
            parsingPct={parsingPct}
            setParsingPct={setParsingPct}
            setError={setError}
            nextEnabled={nextEnabled}
            setNextEnabled={setNextEnabled}
            userItems={userItems}
          />
          <StepMessage
            setError={setError}
            setNextEnabled={setNextEnabled}
            userItems={userItems}
            setUserItems={setUserItems}
          />
          <StepSend setError={setError} setNextEnabled={setNextEnabled} userItems={userItems} />
        </GetStepContent>
      </div>
      <div className={classes.navigationButtons}>
        <Button
          disabled={activeStep === 0 && !files}
          onClick={allStepsCompleted() || activeStep === 0 ? handleReset : handleBack}
          className={classes.button}
        >
          {I18n.t(allStepsCompleted() || activeStep === 0 ? 'HrBot.Reset' : 'HrBot.Back')}
        </Button>
        {!allStepsCompleted() && (
          <Button
            disabled={!nextEnabled || !!error}
            variant="contained"
            color="primary"
            onClick={handleComplete}
          >
            {isLastStep() ? I18n.t('HrBot.Send') : I18n.t('HrBot.Next')}
          </Button>
        )}
        {allStepsCompleted() && (
          <Button disabled={!nextEnabled} variant="contained" color="primary" onClick={closeDrawer}>
            {I18n.t('HrBot.Close')}
          </Button>
        )}
        {!!parsingPct && parsingPct < 100 && (
          <Typography variant="caption" className={classes.error}>
            {I18n.t('HrBot.parsing', { pct: parsingPct })}
          </Typography>
        )}
        {(!parsingPct || parsingPct === 100) && error && (
          <Typography color="error" variant="caption" className={classes.error}>
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default memo(HrBotSend);
