import LauncherAppPreview from 'components/@home/drawers/LauncherAppDrawer/LauncherAppPreview';
import DrawerProvider from 'components/common/Drawer/DrawerProvider';
import Loading from 'components/common/Loading';
import React, { useCallback, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import AppData from 'services/api/AppData';
import { LAUNCHER_APP_ID } from 'services/constants';
import { useGetQuery, useUpsertMutation } from 'store/app/entities/AppDataSlice';
import launcherAppDrawer from 'store/app/ui/drawers/launcherApp/action';
import useActions from 'utils/useActions';

const LauncherAppDrawer = () => {
  const drawerRef = useRef();
  const visible = useSelector(state => state.drawers.launcherApp.isOpen);
  const {
    data: defaultValues,
    isFetching,
    isUninitialized,
    isError,
  } = useGetQuery({ appId: LAUNCHER_APP_ID }, { skip: !visible });
  const methods = useForm({ defaultValues });

  useEffect(() => {
    if (!isUninitialized && defaultValues) {
      methods.reset({
        ...defaultValues,
        signingSecret: I18n.t('LauncherApp.signingSecretShownOnlyOnCreation'),
      });
    }
  }, [defaultValues, isUninitialized, methods]);

  useEffect(() => {
    if (isError) {
      AppData.regenerateSecret(LAUNCHER_APP_ID).then(({ secret }) =>
        methods.reset({ ...defaultValues, signingSecret: secret }),
      );
    }
  }, [defaultValues, isError, methods]);

  const [upsert] = useUpsertMutation();
  useEffect(() => {
    if (visible) {
      drawerRef.current?.show();
    }
  }, [visible]);
  const hide = useActions(launcherAppDrawer.close);
  const save = useCallback(
    ({ links, name, signingSecret, teams }) =>
      upsert({ appId: LAUNCHER_APP_ID, data: { links, name, signingSecret, teams } }),
    [upsert],
  );
  const handleSubmit = useCallback(
    ({ signingSecret, ...appData }) => {
      save(appData);
      hide();
    },
    [hide, save],
  );

  return (
    <DrawerProvider
      name="LauncherAppDrawer"
      visible={visible}
      onClose={hide}
      title={I18n.t('LauncherApp.title')}
      onSubmit={methods.handleSubmit(handleSubmit)}
      sx={{ width: 1000, maxWidth: '90vw' }}
      drawerContent={
        <FormProvider {...methods}>
          {isFetching ? <Loading /> : <LauncherAppPreview save={methods.handleSubmit(save)} />}
        </FormProvider>
      }
    />
  );
};

export default LauncherAppDrawer;
