import { I18n } from 'react-redux-i18n';

const makeBold = (str, bold) => (bold ? `<b>${str}</b>` : str);

const joinWithFinalAnd = (arr, bold = false) => {
  const count = arr.length;
  let ret = '';
  for (let idx = 0; idx < count; idx += 1) {
    ret += makeBold(arr[idx], bold);
    if (idx === count - 2) {
      ret += I18n.t('and');
    } else if (idx < count - 2) {
      ret += ', ';
    }
  }
  return ret;
};

export default joinWithFinalAnd;
