/* eslint-disable function-paren-newline,react/destructuring-assignment */
import {
  Alert,
  Button,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import { PeopleSceneContext } from 'components/@home/@people/PeopleSceneContext';
import Loading from 'components/common/Loading';
import Pagination from 'components/controls/Pagination';
import DownloadIcon from 'mdi-react/FileDownloadOutlineIcon';
import { arrayOf, func, object } from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { BACKEND_URL } from 'services/constants';
import employeeShape from 'shapes/employee';
import { ImportStatus } from 'store/app/entities/employeeImports/reducer';
import populateEmployees from 'store/selectors/populateEmployees';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  tableWrapper: {
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  intro: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    '& a': {
      fontWeight: 500,
      color: theme.palette.primary.main,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: `calc(50% - ${theme.spacing(1)})`,
    '& + $column': {
      marginLeft: theme.spacing(2),
    },
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  infoBox: {
    background: theme.palette.secondary.ultraUltraLight,
    border: `1px solid ${theme.palette.secondary.extraLight}`,
    borderRadius: 6,
    padding: theme.spacing(4),
    '& ol': {
      padding: '0 0 0 15px',
      listStylePosition: 'outside',
      marginBottom: 0,
      color: theme.palette.secondary.main,
      fontSize: '0.8125rem',
      lineHeight: 1.47,
    },
  },
  characteristic: {
    color: theme.palette.black,
  },
  downloadLinks: {
    display: 'inline-flex',
    minWidth: 450,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  row: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 10,
  },
  xsHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  edit: {
    marginRight: 5,
    color: '#8b98ab',
    fontSize: 11,
  },
  delete: {
    color: '#8b98ab',
    fontSize: 11,
  },
  pending: {
    color: theme.palette.secondary.main,
  },
  tag: {
    margin: theme.spacing(1),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    marginTop: theme.spacing(1),
    '& button': {
      padding: theme.spacing(1),
      paddingTop: 2,
      paddingBottom: 2,
      minHeight: 0,
    },
    '& button + button': {
      marginLeft: theme.spacing(2),
    },
  },
  download: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    paddingBottom: 2,
    paddingTop: 2,
    minHeight: 0,
    '& + $button': {
      marginLeft: theme.spacing(2),
    },
  },
  statusCell: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  menuItem: {
    minHeight: 0,
    borderBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
}));

const DetailsLink = ({ row, onClick }) => {
  const classes = useStyles();
  const anchorEl = useRef();
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen(o => !o), []);
  const close = useCallback(() => setOpen(false), []);
  return (
    <>
      <Button ref={anchorEl} onClick={toggle} style={{ fontWeight: 500 }} variant="text">
        {I18n.t('ImportsScene.ImportsTable.Details')}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableRestoreFocus
      >
        <MenuItem disabled>{I18n.t('ImportsScene.Result')}</MenuItem>
        <MenuList sx={{ width: 200, maxWidth: '100%' }}>
          {['created', 'updated', 'skipped'].map(status => (
            <MenuItem dense key={status} onClick={() => onClick(status)}>
              <ListItemText className={classes.menuItem}>
                {I18n.t(`ImportsScene.ResultStatuses.${status}`)}
              </ListItemText>
              <Typography display="inline" variant="body2" color="text.secondary">
                {row.result[status]?.length || 0}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
};

DetailsLink.propTypes = {
  row: object.isRequired,
  onClick: func.isRequired,
};

const ImportsTable = ({ onDiscard, employeeImports, onContinue, onClickShowResults }) => {
  const classes = useStyles();
  const employees = useSelector(populateEmployees);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'createdAt', sort: 'desc' }]);

  const { downloadReport } = useContext(PeopleSceneContext);

  const columns = [
    {
      field: 'createdAt',
      type: 'string',
      flex: 1,
      editable: false,
      headerName: I18n.t('ImportsScene.ImportsTable.Date'),
      renderCell: renderDateTimeCell,
    },
    {
      field: 'fileName',
      flex: 2,
      editable: false,
      headerName: I18n.t('ImportsScene.ImportsTable.FileName'),
      renderCell: params =>
        params.row.isExpired ? (
          <Tooltip title={I18n.t('ImportsScene.ImportsTable.FileNotAvailable')}>
            <Typography>{params.value}</Typography>
          </Tooltip>
        ) : (
          <Typography>
            <a
              href={`${BACKEND_URL}/api/v1/employee-import/${params.id}/d/${params.value}`}
              download={params.value}
              className={classes.download}
            >
              {params.value}
              <SvgIcon className={classes.icon}>
                <DownloadIcon />
              </SvgIcon>
            </a>
          </Typography>
        ),
    },
    {
      field: 'creator',
      flex: 1,
      editable: false,
      headerName: I18n.t('ImportsScene.ImportsTable.User'),
      valueGetter: value => {
        const creator = Object.values(employees).find(e => e.user?._id === value);
        return creator ? `${creator.firstName} ${creator.surName}` : `(${I18n.t('Deleted user')})`;
      },
    },
    {
      field: 'status',
      type: 'singleSelect',
      valueOptions: ['uploaded', 'importing', 'finished'],
      valueFormatter: value => I18n.t(`ImportsScene.ImportsTable.Statuses.${value}`),
      flex: 1,
      headerName: I18n.t('ImportsScene.ImportsTable.Status'),
      renderCell: params => (
        <div className={classes.statusCell}>
          <Typography>{params.formattedValue}</Typography>
          {params.value === 'importing' && <Loading size={14} />}
        </div>
      ),
    },
    {
      field: 'action',
      flex: 1,
      disablePadding: false,
      headerName: '',
      renderCell: params => {
        const notActive = params.row.status === ImportStatus.uploaded;
        const finished = params.row.status === ImportStatus.finished;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', height: 20, alignItems: 'center' }}>
            {notActive && (
              <Button
                onClick={() => onContinue(params.row)}
                variant="contained"
                color="primary"
                className={classes.button}
                size="small"
              >
                {I18n.t('ImportsScene.Continue')}
              </Button>
            )}
            {notActive && (
              <Button
                onClick={() => onDiscard(params.row)}
                variant="outlined"
                color="secondary"
                className={classes.button}
                size="small"
              >
                {I18n.t('ImportsScene.Discard')}
              </Button>
            )}
            {finished && (
              <DetailsLink
                row={params.row}
                onClick={status => onClickShowResults(params.row, status)}
              />
            )}
          </Box>
        );
      },
    },
  ];

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const changeRowsPerPage = e => {
    setRowsPerPage(e.target.value);
  };

  const importsCount = employeeImports.length;
  const pendingImport = employeeImports?.find(ei => ei.status === 'uploaded');

  const handleDeletePending = () => onDiscard(pendingImport);
  const handleContinue = imp => onContinue(imp);
  const continuePending = () => handleContinue(pendingImport);

  const rows = employeeImports.map(r => {
    return {
      ...r,
      id: r._id,
      createdAt: new Date(r.createdAt),
    };
  });

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Typography component="div" className={classes.title} variant="h5">
          {I18n.t('ImportsScene.ImportsTable.Imports')}
        </Typography>
        <div className={classes.intro}>
          <div className={classes.column}>
            <Typography whiteSpace="pre-wrap">
              {I18n.t('ImportsScene.ImportsTable.Intro')}
            </Typography>
            <div className={classes.downloadLinks}>
              <Typography display="inline">
                <Button
                  onClick={() => downloadReport(true)}
                  endIcon={
                    <SvgIcon className={classes.icon}>
                      <DownloadIcon />
                    </SvgIcon>
                  }
                >
                  {I18n.t('ImportsScene.ImportsTable.Download template')}
                </Button>
              </Typography>
              <Typography display="inline">
                <Button
                  onClick={() => downloadReport(false)}
                  endIcon={
                    <SvgIcon className={classes.icon}>
                      <DownloadIcon />
                    </SvgIcon>
                  }
                >
                  {I18n.t('ImportsScene.ImportsTable.Download current database')}
                </Button>
              </Typography>
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.infoBox}>
              <Typography>
                <strong>{I18n.t('ImportsScene.ImportsTable.How does import')}</strong>
              </Typography>
              <ol>
                {[1, 2, 3, 4].map(char => (
                  <li key={`char.${char}`}>
                    <Typography className={classes.characteristic}>
                      {I18n.t(`ImportsScene.ImportsTable.ImportSteps.step${char}`)}
                    </Typography>
                  </li>
                ))}
              </ol>
            </div>
          </div>
        </div>
        <Toolbar total={importsCount} pendingImport={pendingImport} />
        {pendingImport && (
          <Alert severity="warning" className={classes.alert}>
            <Typography>{I18n.t('ImportsScene.You have unfinished')}</Typography>
            <div className={classes.actions}>
              <Button variant="contained" color="primary" size="small" onClick={continuePending}>
                {I18n.t('ImportsScene.Continue')}
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleDeletePending}
              >
                {I18n.t('ImportsScene.Discard')}
              </Button>
            </div>
          </Alert>
        )}
        <DataGrid
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          columns={columns}
          rows={rows}
          autoPageSize
          onPageSizeChange={setRowsPerPage}
          getRowId={m => m._id}
          getRowHeight={() => 48}
          columnHeaderHeight={48}
          paginationModel={{ page, pageSize: rowsPerPage }}
          onPaginationModelChange={({ page: newPage, pageSize: newPageSize }) => {
            setPage(newPage);
            setRowsPerPage(newPageSize);
          }}
          slots={{ footer: Pagination }}
          slotProps={{
            footer: {
              page,
              perPage: rowsPerPage,
              total: importsCount,
              onChange: changePage,
              onChangePerPage: changeRowsPerPage,
            },
          }}
        />
      </div>
    </div>
  );
};

ImportsTable.propTypes = {
  employeeImports: arrayOf(employeeShape).isRequired,
  onDiscard: func.isRequired,
  onContinue: func.isRequired,
  onClickShowResults: func.isRequired,
};

export default ImportsTable;
