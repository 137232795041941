import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'services/Cookie';
import authActions from 'store/app/auth/action';
import authCompanyId from 'store/selectors/authCompanyId';
import authUser from 'store/selectors/authUser';
import autologout from 'utils/autologout';
import redirect from 'utils/redirect';
import useActions from 'utils/useActions';
import connector from './connector';

const AuthGate = ({ actions, authToken, keepSessionOpen, component: Component, ...props }) => {
  useEffect(() => {
    const cancelWorker = autologout(
      authToken,
      keepSessionOpen,
      actions.auth.tokenRefreshed,
      actions.visibility.setActive,
    );
    return () => {
      if (cancelWorker) {
        cancelWorker();
      }
    };
  }, [authToken, actions.auth, keepSessionOpen, actions.visibility.setActive]);

  const token = Cookie.get('token');
  const company = Cookie.get('company');
  const isGuest = isEmpty(company) && isEmpty(token);
  const user = useSelector(authUser);
  const storedCompanyId = useSelector(authCompanyId);
  const flushUserData = useActions(authActions.flushUserData);

  actions.authConfig.setReturnURL(window.location.href);

  useEffect(() => {
    if (isGuest) redirect('/auth/login');
  }, [isGuest]);

  if (storedCompanyId !== company?._id) {
    flushUserData();
  }

  return !isGuest && !!user && <Component />;
};

AuthGate.propTypes = {
  actions: PropTypes.object.isRequired,
  keepSessionOpen: PropTypes.bool.isRequired,
  authToken: PropTypes.object.isRequired,
  component: PropTypes.elementType.isRequired,
};

export default connector(AuthGate);
