/* eslint-disable class-methods-use-this */
import qs from 'querystring';
import Http from 'services/Http';
import toRequest from 'utils/toRequest';

export const MESSAGES_PER_PAGE = 25;

const messageRequest = message =>
  toRequest({
    attachments: message.attachments?.map(a => a._id),
    channel: message.channel_id,
    text: message.text,
    mentions: message.mentions,
    title: message.title,
    links: message.links,
    isDraft: message.isDraft,
    sentAsChannel: message.sentAsChannel,
    isMandatory: message.isMandatory,
    digitalSignature: message.digitalSignature,
    form: message.form,
    formVisibleAll: message.formVisibleAll,
    signX: message.signX,
    signY: message.signY,
    replyTo: message.replyTo,
    article: message.article,
  });

class Message {
  async list(id, { sort = 'desc', ...options } = {}) {
    const { data } = await Http.get(
      `/v3/message/${id}?${qs.stringify({
        perPage: MESSAGES_PER_PAGE,
        sortBy: 'createdAt',
        sort,
        ...options,
      })}`,
    );

    return data;
  }

  async search(search) {
    const { data } = await Http.get(
      `/v3/message/?${qs.stringify({
        search,
      })}`,
    );

    return data;
  }

  async send(message) {
    const response = await Http.post('/v1/message', messageRequest(message));
    return response.data.message;
  }

  async read(channelId, messages) {
    if (channelId) {
      const response = await Http.patch(`/v1/message/${channelId}/read`, {
        id: messages.map(m => m._id),
      });
      return response.data;
    }
    return null;
  }

  async update(message) {
    const payload = messageRequest(message);
    delete payload.channel; // Message should be an unique identifier, no need to send the channelId
    const response = await Http.patch(`/v1/message/${message._id}`, payload);
    return response.data.message;
  }

  async delete(messageId) {
    const response = await Http.delete(`/v1/message/${messageId}/force`);
    return response.data;
  }

  async confirm(messageId) {
    const response = await Http.patch(`/v1/message/${messageId}/confirm`);
    return response.data;
  }

  async digitalSignature(messageId) {
    const response = await Http.patch(`/v1/message/${messageId}/signature`);
    return response.data;
  }

  async sendForm(messageId, payload) {
    const response = await Http.patch(`/v1/message/${messageId}/form`, payload);
    return response.data;
  }

  async messageAction(messageId, blockId, actionId, payload) {
    const response = await Http.post(
      `/v1/message/${messageId}/block/${blockId}/action/${actionId}`,
      payload,
    );
    return response;
  }

  async permissions(messageId) {
    const response = await Http.get(`/v1/message/${messageId}/permissions`);
    return response.data;
  }

  async pin(messageId) {
    const response = await Http.patch(`/v1/message/${messageId}/pin`);
    return response.data;
  }

  async unpin(messageId) {
    const response = await Http.patch(`/v1/message/${messageId}/unpin`);
    return response.data;
  }

  async forward(messageIds, channelIds) {
    const response = await Http.post(`/v1/message/forward`, {
      messageIds,
      channelIds,
    });
    return response.data;
  }
}

export default new Message();
