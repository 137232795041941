import { Avatar, Button } from '@mui/material';
import Box from '@mui/material/Box';
import makeStyles from '@mui/styles/makeStyles';
import SectionBody from 'components/@home/@settings/Tabs/SectionBody';
import SectionTitle from 'components/@home/@settings/Tabs/SectionTitle';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, useNavigate } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';
import channelsActions from 'store/app/entities/channels/action';
import { invalidateGroupsTags, useListQuery } from 'store/app/entities/GroupsSlice';
import memberChannelsStatus from 'store/selectors/memberChannelsStatus';
import populateChannels from 'store/selectors/populateChannels';
import initialsFromUsername from 'utils/initialsFromUsername';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
}));

const defaultSortModel = [{ field: 'lastMessage.createdAt', sort: 'desc' }];

const ChatsSettings = () => {
  const { locale } = useSelector(state => state.i18n);
  const { isFetching: isFetchingMembers } = useSelector(memberChannelsStatus);
  const memberChannels = useSelector(populateChannels);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [filterModel, setFilterModel] = useState(undefined);
  const search = filterModel?.quickFilterValues.join(' ');
  const { data, isFetching } = useListQuery(
    {
      search,
      page: page + 1,
      perPage: pageSize,
      sortBy: sortModel?.[0]?.field || 'lastMessage.createdAt',
      sort: sortModel?.[0]?.sort || 'desc',
    },
    { skip: pageSize < 2 },
  );
  const { groups, totalCount } = data || { totalCount: 0 };
  const join = useActions(channelsActions.join);
  const classes = useStyles();

  const navigate = useNavigate();

  const joinChannel = async channelId => {
    await join(channelId);
    setTimeout(() => navigate(`/home/messages/${channelId}`, { channelId }), 200);
  };

  return (
    <div className={classes.root}>
      <SectionTitle title={I18n.t('ChatsSettings.Chats')} />
      <SectionBody>
        <OmmnioDataGrid
          loading={isFetching || isFetchingMembers}
          paginationMode="server"
          getRowId={r => r._id}
          sortingMode="server"
          filterMode="server"
          rowCount={totalCount}
          page={page}
          onPageChange={setPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          defaultSortModel={defaultSortModel}
          onSortModelChange={setSortModel}
          updateHistory
          columns={[
            {
              field: 'avatar',
              headerName: I18n.t('ChatsSettings.Avatar'),
              sortable: false,
              renderCell: ({ row }) => (
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                  <Avatar src={row.avatar}>
                    {row.avatar ? null : initialsFromUsername(row.name)}
                  </Avatar>
                </Box>
              ),
            },
            {
              field: 'name',
              flex: 1,
              headerName: I18n.t('ChatsSettings.Name'),
            },
            {
              field: 'description',
              flex: 1,
              headerName: I18n.t('ChatsSettings.Description'),
            },
            {
              field: 'lastMessage.createdAt',
              minWidth: 200,
              headerName: I18n.t('ChatsSettings.LastMessage'),
              renderCell: ({ row }) =>
                row.lastMessage?.createdAt ? (
                  <ReactTimeAgo date={new Date(row.lastMessage.createdAt)} locale={locale} />
                ) : null,
            },
            {
              field: 'actions',
              headerName: '',
              minWidth: 100,
              renderCell: ({ row }) => (
                <Box sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                  <Button
                    color="primary"
                    component={Link}
                    type="text"
                    to={`/home/messages/${row._id}`}
                    state={{ channelId: row._id }}
                    onClick={e => {
                      if (!memberChannels[row._id]?.isMember) {
                        e.preventDefault();
                        joinChannel(row._id);
                      }
                    }}
                  >
                    {I18n.t(`ChatsSettings.${memberChannels[row._id]?.isMember ? 'Open' : 'Join'}`)}
                  </Button>
                </Box>
              ),
            },
          ]}
          rows={groups || []}
        />
      </SectionBody>
    </div>
  );
};

export default ChatsSettings;
