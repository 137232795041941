import PostIcon from '@mui/icons-material/Article';
import { SvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from 'assets/icons/arrow-foward.svg';
import Image from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/LinkPreview/Image';
import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import getMoment from 'utils/getMoment';
import msToMinutes from 'utils/msToMin';

const styles = {
  root: {
    textDecoration: 'none !important',
    '&:hover, &:active, &:visited, &:focus': {
      textDecoration: 'none !important',
    },
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'min(40vw, 600px)',
    width: 800,
    color: 'primary.main',
    backgroundColor: 'background.paper',
    p: 0.25,
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      borderRadius: '15px',
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '30vh',
  },
  content: {
    color: 'primary.main',
    padding: 1.25,
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  icon: {
    m: 1.5,
    mr: 0,
  },
  textWrapper: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'primary.ultraDark',
    display: '-webkit-box',
    flexDirection: 'column',
    gap: 1,
    flexGrow: 1,
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '-0.4px',
    color: 'secondary.dark',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    mb: 2,
  },
  info: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '1.43',
    letterSpacing: '-0.4px',
    color: 'secondary.medium',
    display: 'flex',
    gap: 1,
  },
  description: {
    color: 'secondary.main',
  },
  arrow: {
    fontSize: 12,
  },
  button: {
    borderRadius: 2,
    backgroundColor: 'background.paper',
    color: 'primary.main',
    m: 1,
  },
  forwardIcon: {
    mr: 2,
    fontSize: 12,
  },
  subheading: {
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: '5px',
    color: 'primary.main',
  },
};

const ArticleSnippet = ({ message }) => {
  if (!message.article) return null;
  return (
    <Box component={Link} to={`article/${message.article._id}`} sx={styles.root}>
      <Box>
        <Image
          fallback={
            <Box
              sx={{
                width: '100%',
                py: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1.25,
                backgroundColor: 'secondary.extraLight',
              }}
            >
              <PostIcon />
              <Box sx={styles.subheading}>{I18n.t('ArticleSnippet.Article')}</Box>
            </Box>
          }
          src={message.article.preview?.image || message.article.image}
          sx={styles.image}
        />
        <Box sx={styles.textWrapper}>
          <Box sx={styles.text}>
            <Typography sx={styles.title}>
              {message.article.preview?.title || message.article.title}
            </Typography>
            {(!message.article.preview?.subtitle && !message.article.subtitle) || (
              <Typography sx={styles.subtitle}>
                {message.article.preview.subtitle || message.article.subtitle}
              </Typography>
            )}
            {!message.article.readingTime || (
              <Typography sx={styles.info}>
                <span>
                  {I18n.t('ArticleSnippet.readingTime', {
                    time: msToMinutes(message.article.readingTime),
                    min: I18n.t('ArticleSnippet.min'),
                  })}
                </span>
                <span>&middot;</span>
                <time dateTime={message.article.updatedAt}>
                  {getMoment(message.article.updatedAt).calendar(null, {
                    sameDay() {
                      // eslint-disable-next-line react/no-this-in-sfc
                      return `[${this.fromNow()}]`;
                    },
                    lastDay: `[${I18n.t('Ago.Yesterday')} ${I18n.t('Ago.at')}] LT`,
                    nextDay: `[${I18n.t('Ago.Tomorrow')} ${I18n.t('Ago.at')}] LT`,
                    lastWeek: `dddd [${I18n.t('Ago.at')}] LT`,
                    nextWeek: `dddd [${I18n.t('Ago.at')}] LT`,
                    sameElse: `L [${I18n.t('Ago.at')}] LT`,
                  })}
                </time>
              </Typography>
            )}
          </Box>
          <SvgIcon sx={styles.forwardIcon}>
            <ArrowForwardIcon />
          </SvgIcon>
        </Box>
      </Box>
    </Box>
  );
};

ArticleSnippet.propTypes = {
  message: PropTypes.object.isRequired,
};

export default ArticleSnippet;
