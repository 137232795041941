import { Drawer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import { bool, object } from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import employeesStatus from 'store/selectors/employeesStatus';
import CloseButton from '../CloseButton';

import connector from './connector';
import ChannelForm from './Form';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    maxWidth: 'calc(100vw - 40px)',
    [theme.breakpoints.up('md')]: {
      width: 1100,
    },
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
}));

const ChannelDrawer = ({ channel, isOpen, isEdit, actions }) => {
  const classes = useStyles();
  const { isLoading } = useSelector(employeesStatus);
  useEffect(() => {
    if (isEdit && isOpen && channel?._id && !channel.loading && !channel.loaded) {
      actions.channels.find(channel._id);
    }
  }, [isEdit, isOpen, channel, actions]);

  const handleClose = () => {
    actions.drawers.channel.close();
  };

  const ChannelFormComponent = () => {
    if (isLoading || (isEdit && channel.loading)) {
      return <Loading />;
    }
    if (!isEdit || channel.loaded) {
      return <ChannelForm />;
    }
    return null;
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <ChannelFormComponent />
        </div>
      </div>
    </Drawer>
  );
};

ChannelDrawer.propTypes = {
  channel: object.isRequired,
  actions: object.isRequired,
  isOpen: bool.isRequired,
  isEdit: bool.isRequired,
};

const areEqual = (prevProps, nextProps) =>
  prevProps.channel?._id === nextProps.channel?._id &&
  prevProps.channel?.loading === nextProps.channel?.loading &&
  prevProps.channel?.loaded === nextProps.channel?.loaded &&
  prevProps.channel?.employees.length === nextProps.channel?.employees.length &&
  prevProps.channel?.groupTeams?.length === nextProps.channel?.groupTeams?.length &&
  prevProps.isOpen === nextProps.isOpen &&
  prevProps.isEdit === nextProps.isEdit;

export default connector(memo(ChannelDrawer, areEqual));
