/* eslint-disable no-nested-ternary,max-len,no-param-reassign,max-classes-per-file */
import questionTypes, { hasOptions } from 'components/@home/drawers/FormDrawer/questionTypes';
import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import Form from 'services/api/Form';
import uniqid from 'uniqid';
import * as Yup from 'yup';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      formikBag: {
        props: { isEdit, channel },
      },
      values,
    } = this;
    const { adminOnly, anonymous, title } = values;
    const questions = values.questions.map(q => {
      const ret = { ...q };
      if (ret.options.length < 2) {
        delete ret.options;
      }
      if (!isEdit) {
        delete ret._id;
        if (ret.options) {
          ret.options = ret.options
            .filter(({ label }) => !!label)
            .map(({ label }) => ({
              label,
            }));
        } else {
          delete ret.options;
        }
      }
      return ret;
    });

    return {
      channel: channel._id,
      title,
      questions,
      anonymous: anonymous.length ? 1 : 0,
      adminOnly: adminOnly.length ? 1 : 0,
    };
  };

  sendRequest = form => {
    const {
      props: { form: formProp, isEdit },
    } = this.formikBag;
    if (isEdit) return Form.update(formProp._id, form);
    return Form.create(form);
  };
}

class MessageSubmit {
  constructor(formId, values, formikBag) {
    this.formId = formId;
    this.formikBag = formikBag;
    this.title = values.title;
    this.adminOnly = values.adminOnly;
  }

  assembleForm = () => {
    const {
      formikBag: {
        props: { channel, authCompany, authUser, authEmployee, sentAsChannel },
      },
      adminOnly,
    } = this;
    return {
      _id: uniqid(),
      text: this.title,
      title: this.title,
      acknowledges: [],
      attachments: [],
      channel_id: channel._id,
      company_id: authCompany._id,
      previews: {},
      createdAt: Date.now(),
      employee_id: authEmployee?._id,
      user_id: authUser._id,
      isDeletedBy: [],
      isDraft: false,
      isEdited: false,
      isMandatory: false,
      links: [],
      temp: true,
      seen: false,
      updatedAt: Date.now(),
      senderWasDeleted: false,
      sentAsChannel,
      form: this.formId,
      formVisibleAll: !adminOnly.length ? 1 : 0,
    };
  };

  sendRequest = form => {
    const { actions } = this.formikBag.props;
    return actions.messages.send(form);
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const validateAllExceptLastElement = value => {
  const stringValidationSchema = Yup.object().shape({ label: Yup.string().required() });
  for (let i = 0; i < value.length - 1; i += 1) {
    if (!stringValidationSchema.isValidSync(value[i])) {
      return false;
    }
  }
  return value.length > 1;
};

function unique(message, mapper = a => a) {
  return this.test('unique', message, list => list.length === new Set(list.map(mapper)).size);
}
Yup.addMethod(Yup.array, 'unique', unique);

export default withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required(() => I18n.t('FormDrawer.Errors.Please enter the form title')),
    questions: Yup.array().of(
      Yup.object().shape({
        title: Yup.string().required(() =>
          I18n.t('FormDrawer.Errors.Please enter the question title'),
        ),
        type: Yup.string().oneOf(questionTypes),
        options: Yup.array().when('type', (type, schema) =>
          hasOptions(type)
            ? schema
                .min(3, () => I18n.t('FormDrawer.Errors.Please enter at least two options'))
                .test(
                  'testOptions',
                  () => I18n.t('FormDrawer.Errors.Please enter the option label'),
                  validateAllExceptLastElement,
                )
                .unique(
                  () => I18n.t('FormDrawer.Errors.Options contain duplicate values'),
                  o => o.label,
                )
            : schema,
        ),
      }),
    ),
  }),

  mapPropsToValues: ({ form, isEdit, channel }) => {
    if (!form) isEdit = false;

    const adminOnlyDefault = channel?.isReadOnly ? ['on'] : [];
    return {
      adminOnlyDisabled: channel?.isReadOnly,
      isEdit,
      questions: isEdit ? form.questions : [],
      adminOnly: isEdit ? form.adminOnly : adminOnlyDefault,
      anonymous: isEdit ? form.anonymous : [],
    };
  },

  handleSubmit: async (values, formikBag) => {
    const {
      props: { actions },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);

    const form = handler.assembleForm();

    try {
      const rawForm = await handler.sendRequest(form);

      const messageHandler = new MessageSubmit(rawForm._id, values, formikBag);

      const message = messageHandler.assembleForm();
      await messageHandler.sendRequest(message);

      setSubmitting(false);

      resetForm();

      actions.drawers.form.close();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
  },
  displayName: 'FormForm',
});
