import { SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import StarSignIcon from 'assets/icons/star-sign.svg';
import StarIcon from 'assets/icons/star.svg';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import DeleteDraftButton from './DeleteDraftButton';
import EditDraftButton from './EditDraftButton';

const styles = theme => ({
  root: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    padding: '30px 20px',
    minWidth: '360px',
    boxShadow: '0 7px 22px 0 rgba(2, 2, 3, 0.13)',
    border: 'solid 1px rgba(221, 226, 235, 0.1)',
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.secondary.light,
    marginBottom: '10px',
  },
  subheading: {
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: '5px',
    color: theme.palette.secondary.light,
  },

  title: {
    textAlign: 'center',
    flex: '0 0 auto',
    width: '90%',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '20px',
    color: theme.palette.secondary.light,
    margin: '0 0 10px',
  },

  label: {
    position: 'absolute',
    display: 'block',
    top: '10px',
    right: '10px',
    padding: '5px 10px',
    backgroundColor: theme.palette.secondary.extraLight,
    fontSize: '11px',
    fontWeight: '500',
    color: '#738195',
    textTransform: 'uppercase',
  },
});

const Draft = ({ classes, message }) => (
  <div className={classes.root}>
    <span className={classes.label}>{I18n.t('AcknowledgementMessage.Draft')}</span>
    <SvgIcon className={classes.icon}>
      {message.digitalSignature ? <StarSignIcon /> : <StarIcon />}
    </SvgIcon>
    <span className={classes.subheading}>
      {message.digitalSignature
        ? I18n.t('AcknowledgementMessage.DIGITAL SIGNATURE')
        : I18n.t('AcknowledgementMessage.MANDATORY READ')}
    </span>
    <h3 className={classes.title}>{message.text}</h3>
    <EditDraftButton message={message} />
    <DeleteDraftButton message={message} />
  </div>
);
Draft.propTypes = {
  classes: object.isRequired,
  message: object.isRequired,
};

export default withStyles(styles)(Draft);
