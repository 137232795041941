import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { ChatFlowContext } from 'components/@home/@settings/Tabs/ChatFlowContext';
import ChatFlowEdit from 'components/@home/@settings/Tabs/ChatFlowEdit';
import ChatFlowPresentation from 'components/@home/@settings/Tabs/ChatFlowPresentation';
import connector from 'components/@home/@settings/Tabs/connector';
import RightPane from 'components/@home/@settings/Tabs/RightPane';
import DeleteDialog from 'components/common/DeleteDialog';
import DropzoneBox from 'components/common/DropzoneBox';
import OverflowTip from 'components/common/OverflowTip';
import orderBy from 'lodash/orderBy';
import AddIcon from 'mdi-react/AddIcon';
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import EditIcon from 'mdi-react/EditIcon';
import ExportIcon from 'mdi-react/ExportIcon';
import ImportIcon from 'mdi-react/ImportIcon';
import ms from 'ms';
import { object, objectOf } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { chatFlowEntityTypeShape, chatFlowIntentShape, chatFlowShape } from 'shapes/chatFlowShapes';
import joinWithFinalAnd from 'utils/joinWithFinalAnd';
import useArray from 'utils/useArray';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  nav: {
    width: '100%',
    height: 'calc(100vh - 64px)',
    overflowY: 'scroll',
    backgroundColor: theme.palette.background.paper,
    borderRight: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  item: {
    minHeight: 0,
    borderBottom: 0,
  },
  primary: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemSecondaryAction: {
    visibility: 'hidden',
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.extraLight,
      '& $listItemSecondaryAction': {
        visibility: 'inherit',
      },
    },
  },
  modal: {
    maxWidth: 500,
  },
  title: {
    padding: '0 10px',
  },
  warningIcon: {
    fontSize: 15,
    color: theme.palette.danger,
    marginLeft: 5,
  },
}));

const ChatFlowSettings = ({
  actions,
  chatFlows,
  chatFlowIntents,
  chatFlowEntityTypes,
  chatFlowWebhooks,
}) => {
  const classes = useStyles();

  const [numImports, setNumImports] = useState(0);
  const incNumImports = () => setNumImports(n => n + 1);
  const [selectedFlow, setSelectedFlow] = useState(null);
  const [toDelete, setToDelete] = useState(null);
  const [stack, current, push, pop, clear, set] = useArray();
  const [dotsMenuOpened, setDotsMenuOpened] = useState(null);
  const [addFlowMenuOpened, setAddFlowMenuOpened] = useState(null);
  const [flowDots, setFlowDots] = useState(null);
  const addFlowButton = useRef();
  const openAddFlowMenu = () => setAddFlowMenuOpened(true);
  const closeAddFlowMenu = () => setAddFlowMenuOpened(false);
  const [importModalOpen, setImportModalOpen] = useState(false);

  const closeImportModal = () => setImportModalOpen(false);
  const openImportModal = () => {
    closeAddFlowMenu();
    setImportModalOpen(true);
  };

  const context = { stack, current, push, pop, clear, set: v => set([v]), length: stack.length };

  useEffect(() => {
    actions.chatFlows.load();
    actions.chatFlowEntityTypes.load();
    actions.chatFlowIntents.load();
    actions.chatFlowWebhooks.load();
  }, [numImports, actions.chatFlowEntityTypes, actions.chatFlowIntents, actions.chatFlows]);

  useEffect(() => {
    actions.teams.load();
  }, [actions.teams]);

  const addIntent = () => {
    context.set({ type: 'intent', intent: 'new' });
  };

  const addEntityType = () => {
    context.set({ type: 'entity-type', entityType: 'new' });
  };

  const addWebhook = () => {
    context.set({ type: 'webhook', webhook: 'new' });
  };

  const editFlow =
    (flow = { _id: 'new' }) =>
    async () => {
      closeAddFlowMenu();
      setDotsMenuOpened(false);
      context.set({
        type: 'flow',
        flow: flow._id,
        callback: newFlow => {
          context.pop();
          setSelectedFlow(newFlow._id);
        },
      });
    };

  const createFlow = editFlow();

  const exportFlow = flow => async () => {
    setDotsMenuOpened(null);
    const { value } = await actions.chatFlows.exportFlow(flow._id);
    const json = JSON.stringify(value);
    const blob = new Blob([json], { type: 'application/json' });
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = `${flow.name}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const importFlow = async files => {
    await actions.chatFlows.importFlow(files[0]);
    closeImportModal();
    incNumImports();
  };

  const deleteFlow = flow => () => {
    setDotsMenuOpened(null);
    setToDelete(flow);
  };

  const confirmDelete = async () => {
    try {
      if (selectedFlow === toDelete._id) {
        setSelectedFlow(null);
      } else if (context.current?.intent === toDelete._id) {
        context.pop();
      }
      if (chatFlows[toDelete._id]) {
        await actions.chatFlows.destroy(toDelete._id);
      } else if (chatFlowIntents[toDelete._id]) {
        await actions.chatFlowIntents.destroy(toDelete._id);
      } else if (chatFlowEntityTypes[toDelete._id]) {
        await actions.chatFlowEntityTypes.destroy(toDelete._id);
      } else if (chatFlowWebhooks[toDelete._id]) {
        await actions.chatFlowWebhooks.destroy(toDelete._id);
      } else if (selectedFlow && chatFlows[selectedFlow].pages?.find(p => p._id === toDelete._id)) {
        await actions.chatFlowPages.destroy(selectedFlow, toDelete._id);
        await actions.chatFlows.find(selectedFlow);
      } else if (selectedFlow && toDelete.page) {
        await actions.chatFlowPages.removeRoute(selectedFlow, toDelete.page, toDelete._id);
        actions.chatFlows.find(selectedFlow);
      }
    } catch (err) {
      if (err.data?.type === 'UnprocessableEntityError') {
        actions.alert.show({
          label: I18n.t('ChatFlowSettings.Could not delete'),
          message: (
            <Translate
              value="ChatFlowSettings.The element was used in"
              count={err.data.key?.join ? err.data.key.length : 0}
              used={err.data.key?.join ? joinWithFinalAnd(err.data.key, true) : err.data.key}
              dangerousHTML
            />
          ),
          caption: I18n.t('ChatFlowSettings.Please remove all usages'),
          variant: 'error',
          autoHideDuration: ms('15 seconds'),
        });
      }
    }
    setToDelete(null);
  };

  const deleteEntityType = entityType => () => {
    setDotsMenuOpened(null);
    setToDelete(entityType);
  };

  const deleteIntent = intent => () => {
    setDotsMenuOpened(null);
    setToDelete(intent);
  };

  const deleteWebhook = webhook => () => {
    setDotsMenuOpened(null);
    setToDelete(webhook);
  };

  const chatFlowEntityTypessList = orderBy(Object.values(chatFlowEntityTypes), [
    i => i.name.toLowerCase(),
  ]);

  const chatFlowWebhooksList = orderBy(Object.values(chatFlowWebhooks), [
    wh => wh.name.toLowerCase(),
  ]);
  const chatFlowIntentsList = orderBy(Object.values(chatFlowIntents), [i => i.name.toLowerCase()]);
  const chatFlowsList = orderBy(Object.values(chatFlows), [i => i.name.toLowerCase()]);

  return (
    <ChatFlowContext.Provider value={{ context, setToDelete }}>
      <Grid container>
        <Grid item xs={2} className={classes.label}>
          <List component="nav" aria-labelledby="flows-subheader" className={classes.nav}>
            <ListSubheader component="div" id="flows-subheader">
              {I18n.t('ChatFlowSettings.Flows')}
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="add"
                  onClick={openAddFlowMenu}
                  ref={addFlowButton}
                  size="large"
                >
                  <AddIcon />
                </IconButton>
                <Menu
                  anchorEl={addFlowButton.current}
                  keepMounted
                  open={addFlowMenuOpened}
                  onClose={closeAddFlowMenu}
                >
                  <MenuItem onClick={createFlow}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {I18n.t('ChatFlowSettings.Create flow')}
                    </Typography>
                  </MenuItem>
                  <MenuItem onClick={openImportModal}>
                    <ListItemIcon>
                      <ImportIcon />
                    </ListItemIcon>
                    <Typography variant="inherit">
                      {I18n.t('ChatFlowSettings.Import flow')}
                    </Typography>
                  </MenuItem>
                </Menu>
              </ListItemSecondaryAction>
            </ListSubheader>
            {chatFlowsList.map(flow => (
              <ListItem
                button
                selected={selectedFlow === flow._id}
                key={flow._id}
                onClick={() => setSelectedFlow(flow._id)}
                classes={{ container: classes.listItem }}
              >
                <ListItemText
                  classes={{ root: classes.item, primary: classes.primary }}
                  primary={
                    <>
                      <OverflowTip value={flow.name} />
                      {!flow.lastVersionTrained && (
                        <Tooltip title={I18n.t('ChatFlowSettings.Needs training')}>
                          <SvgIcon className={classes.warningIcon}>
                            <AlertCircleOutlineIcon />
                          </SvgIcon>
                        </Tooltip>
                      )}
                    </>
                  }
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  <IconButton
                    edge="end"
                    size="small"
                    aria-label="more"
                    onClick={e => {
                      setFlowDots(e.currentTarget);
                      setDotsMenuOpened(flow._id);
                    }}
                  >
                    <DotsVerticalIcon />
                  </IconButton>
                  <Menu
                    anchorEl={flowDots}
                    keepMounted
                    open={dotsMenuOpened === flow._id}
                    onClose={() => setDotsMenuOpened(null)}
                  >
                    <MenuItem onClick={editFlow(flow)}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Edit')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={exportFlow(flow)}>
                      <ListItemIcon>
                        <ExportIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Export')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={deleteFlow(flow)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Delete')}</Typography>
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListSubheader component="div" id="entity-types-subheader">
              {I18n.t('ChatFlowSettings.Entity types')}
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" onClick={addEntityType} size="large">
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListSubheader>
            {chatFlowEntityTypessList.map(entityType => (
              <ListItem
                button
                selected={context?.entityType === entityType._id}
                key={entityType._id}
                onClick={() => context.set({ type: 'entity-type', entityType: entityType._id })}
                classes={{ container: classes.listItem }}
              >
                <ListItemText
                  classes={{ root: classes.item }}
                  primary={<OverflowTip value={entityType.name} />}
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  <IconButton
                    edge="end"
                    size="small"
                    aria-label="more"
                    onClick={e => {
                      setFlowDots(e.currentTarget);
                      setDotsMenuOpened(entityType._id);
                    }}
                  >
                    <DotsVerticalIcon />
                  </IconButton>
                  <Menu
                    anchorEl={flowDots}
                    keepMounted
                    open={dotsMenuOpened === entityType._id}
                    onClose={() => setDotsMenuOpened(null)}
                  >
                    <MenuItem onClick={deleteEntityType(entityType)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Delete')}</Typography>
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {chatFlowIntentsList.length > 0 && (
              <ListSubheader component="div" id="intents-subheader">
                {I18n.t('ChatFlowSettings.Intents')}
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="add" onClick={addIntent} size="large">
                    <AddIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListSubheader>
            )}
            {chatFlowIntentsList.map(intent => (
              <ListItem
                button
                selected={context?.intent === intent._id}
                key={intent._id}
                onClick={() => context.set({ type: 'intent', intent: intent._id })}
                classes={{ container: classes.listItem }}
              >
                <ListItemText
                  classes={{ root: classes.item }}
                  primary={<OverflowTip value={intent.name} />}
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  <IconButton
                    edge="end"
                    size="small"
                    aria-label="more"
                    onClick={e => {
                      setFlowDots(e.currentTarget);
                      setDotsMenuOpened(intent._id);
                    }}
                  >
                    <DotsVerticalIcon />
                  </IconButton>
                  <Menu
                    anchorEl={flowDots}
                    keepMounted
                    open={dotsMenuOpened === intent._id}
                    onClose={() => setDotsMenuOpened(null)}
                  >
                    <MenuItem onClick={deleteIntent(intent)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Delete')}</Typography>
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <ListSubheader component="div" id="webhooks-subheader">
              {I18n.t('ChatFlowSettings.Webhooks')}
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="add" onClick={addWebhook} size="large">
                  <AddIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListSubheader>
            {chatFlowWebhooksList.map(webhook => (
              <ListItem
                button
                selected={context?.webhook === webhook._id}
                key={webhook._id}
                onClick={() => context.set({ type: 'webhook', webhook: webhook._id })}
                classes={{ container: classes.listItem }}
              >
                <ListItemText
                  classes={{ root: classes.item }}
                  primary={<OverflowTip value={webhook.name} />}
                />
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                  <IconButton
                    edge="end"
                    size="small"
                    aria-label="more"
                    onClick={e => {
                      setFlowDots(e.currentTarget);
                      setDotsMenuOpened(webhook._id);
                    }}
                  >
                    <DotsVerticalIcon />
                  </IconButton>
                  <Menu
                    anchorEl={flowDots}
                    keepMounted
                    open={dotsMenuOpened === webhook._id}
                    onClose={() => setDotsMenuOpened(null)}
                  >
                    <MenuItem onClick={deleteWebhook(webhook)}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{I18n.t('ChatFlowSettings.Delete')}</Typography>
                    </MenuItem>
                  </Menu>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={context.current ? 4 : 10}>
          {selectedFlow ? (
            <ChatFlowEdit flow={chatFlows[selectedFlow]} />
          ) : (
            !context.current && <ChatFlowPresentation />
          )}
        </Grid>
        <Grid item xs={context.current ? 6 : 0}>
          {context.current && (
            <RightPane
              context={context}
              chatFlowEntityTypes={chatFlowEntityTypes}
              chatFlowIntents={chatFlowIntents}
              chatFlowWebhooks={chatFlowWebhooks}
              chatFlows={chatFlows}
              selectedFlow={selectedFlow}
            />
          )}
        </Grid>
        <DeleteDialog
          typeBack={toDelete?.name}
          message={I18n.t('ChatFlowSettings.ConfirmDelete', { name: toDelete?.name })}
          isOpen={toDelete}
          onConfirm={confirmDelete}
          onClose={() => setToDelete(null)}
        />
      </Grid>
      <Dialog open={importModalOpen} onClose={closeImportModal}>
        <DialogTitle>{I18n.t('ChatFlowSettings.Import flow')}</DialogTitle>
        <DropzoneBox
          accept="application/json"
          onDrop={importFlow}
          onClick={e => e.preventDefault()}
        />
      </Dialog>
    </ChatFlowContext.Provider>
  );
};

ChatFlowSettings.propTypes = {
  actions: object.isRequired,
  chatFlows: objectOf(chatFlowShape),
  chatFlowIntents: objectOf(chatFlowIntentShape),
  chatFlowEntityTypes: objectOf(chatFlowEntityTypeShape),
  chatFlowWebhooks: object,
};

ChatFlowSettings.defaultProps = {
  chatFlows: {},
  chatFlowIntents: {},
  chatFlowEntityTypes: {},
  chatFlowWebhooks: {},
};

export default connector(ChatFlowSettings);
