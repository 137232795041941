import { I18n } from 'react-redux-i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import messageShape from 'shapes/message';
import connector from './connector';

const MessageFrom = ({ message, authEmployee, channel, children }) => {
  const { sentAsChannel } = message;

  const from = message.from?._id ? message.from : message.employee;

  let name = '';

  if (sentAsChannel) {
    name = channel?.name || '';
  } else {
    if (authEmployee) {
      name = from?.name ? from.name : from?.firstName && `${from?.firstName} ${from?.surName}`;
      name = authEmployee.user._id === from?._id ? I18n.t('LastMessage.You') : name;
    }

    if (!from?._id) name = I18n.t('Deleted User');

    name = message.isSuperAdmin ? I18n.t('Super admin') : name;
  }
  if (typeof children === 'function') {
    return children(name);
  }

  return name;
};

MessageFrom.propTypes = {
  message: messageShape.isRequired,
  authEmployee: employeeShape.isRequired,
  channel: channelShape.isRequired,
};

export default connector(MessageFrom);
