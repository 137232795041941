/* eslint-disable no-console */
import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { bool, array, object, shape, func } from 'prop-types';
import Autocomplete from 'components/controls/AutocompleteField';
import { I18n } from 'react-redux-i18n';
import connector from './connector';
import SelectTitle from '../SelectTitle';

const styles = () => ({
  root: {
    paddingRight: '20px',
  },
});

class TimeZone extends Component {
  componentWillMount() {
    const {
      actions,
      timeZones: { timeZones },
    } = this.props;

    if (!timeZones.length) {
      actions.config.timeZones.loadTimeZones();
    }
  }

  generateTimeZoneLabel = option => `${option.gmt} ${option.zone}`;

  findTimeZoneValue = (options, value) => options.find(option => option.zone === value);

  render() {
    const {
      classes,
      timeZones: { timeZones, loading },
      settings: { timeZone },
      onChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <SelectTitle headline={I18n.t('SettingsDrawer.Time zone')} />
        <Autocomplete
          autoWidth
          placeholder={
            loading
              ? I18n.t('SettingsDrawer.Loading time zones')
              : I18n.t('SettingsDrawer.Choose time zone')
          }
          options={timeZones.map(option => ({
            ...option,
            label: this.generateTimeZoneLabel(option),
            value: option.zone,
          }))}
          value={timeZone}
          onChange={onChange}
          labelGenerator={this.generateTimeZoneLabel}
          findValue={this.findTimeZoneValue}
        />
      </div>
    );
  }
}

TimeZone.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  timeZones: shape({
    timeZones: array,
    loading: bool,
  }).isRequired,
  settings: object.isRequired,
  onChange: func.isRequired,
};

TimeZone.defaultProps = {};

export default withStyles(styles)(connector(TimeZone));
