import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SquareEditOutlineIcon from 'mdi-react/SquareEditOutlineIcon';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import direct from 'store/app/ui/drawers/direct/action';
import availableEmployees from 'store/selectors/availableEmployees';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {},
  editIcon: {
    color: theme.palette.primary.main,
  },
}));

const DirectButton = () => {
  const openDirectDrawer = useActions(direct.open);
  const available = useSelector(availableEmployees);
  const classes = useStyles();
  if (!available.length) {
    return null;
  }
  return (
    <div className={classes.root}>
      <IconButton onClick={openDirectDrawer} size="large">
        <SquareEditOutlineIcon className={classes.editIcon} />
      </IconButton>
    </div>
  );
};

export default memo(DirectButton);
