import { Drawer, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import SearchField from 'components/controls/SearchField';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import availableEmployeesSelector from 'store/selectors/availableEmployees';
import employeesStatus from 'store/selectors/employeesStatus';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import directDrawer from 'store/app/ui/drawers/direct/action';
import authEmployee from 'store/selectors/authEmployee';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import arrayToObject from 'utils/arrayToObject';
import groupByAlphabet from 'utils/groupByAlphabet';
import removeAccents from 'utils/removeAccents';
import searchEmployees from 'utils/searchEmployees';
import useActions from 'utils/useActions';
import CloseButton from '../CloseButton';
import DirectsList from './DirectsList';
import NewChannelArea from './NewChannelArea';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minWidth: 450,
  },
  container: {
    flexGrow: 1,
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  headline: {
    paddingBottom: 25,
  },

  letter: {
    paddingLeft: 8,
  },
}));

const DirectDrawer = () => {
  const classes = useStyles();
  const [filteredIds, setFilteredIds] = useState([]);
  const [searchString, setSearchString] = useState(null);
  const [handleClose, openCreateChannel] = useActions([directDrawer.close, channelDrawer.open]);
  const isOpen = useSelector(state => state.drawers.direct.isOpen);
  const availableEmployees = useSelector(availableEmployeesSelector);
  const { isLoading } = useSelector(employeesStatus);
  const search = useCallback(event => {
    setSearchString(event.target.value);
  }, []);
  const employee = useSelector(authEmployee);
  const filterEmployees = useCallback(
    employees => {
      const sort = emps =>
        orderBy(emps, e =>
          removeAccents(`${e.firstName} ${e.surName}`).toLowerCase().trim().replace(/ +/g, ' '),
        );
      if (isEmpty(filteredIds) && isEmpty(searchString))
        return sort(employees.filter(r => r._id !== employee?._id));
      if (isEmpty(filteredIds) && !isEmpty(searchString)) return [];

      const result = filteredIds.map(_id => arrayToObject(employees)[_id]);

      // Sometimes result returns [ undefined ]. I don't know why
      return sort(result.filter(r => !!r && r._id !== employee?._id));
    },
    [employee?._id, filteredIds, searchString],
  );

  useEffect(() => {
    if (searchString) {
      const newFilteredIds = searchEmployees(searchString, availableEmployees || []);
      setFilteredIds(newFilteredIds);
    } else {
      setFilteredIds([]);
    }
  }, [availableEmployees, searchString]);

  useEffect(() => {
    if (!isOpen) {
      setFilteredIds([]);
      setSearchString('');
    }
  }, [isOpen]);

  const filtered = useMemo(
    () => filterEmployees(availableEmployees || []),
    [availableEmployees, filterEmployees],
  );
  const grouped = useMemo(() => Object.entries(groupByAlphabet(filtered)), [filtered]);
  const sorted = useMemo(
    () => grouped.sort((prev, next) => prev[0].localeCompare(next[0])),
    [grouped],
  );
  if (!isOpen) return null;

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleClose}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={handleClose} />
        </div>
        <div className={classes.container}>
          <Typography variant="h5" className={classes.headline}>
            {I18n.t('EmployeesDrawer.New Chat')}
          </Typography>
          <SearchField onChange={search} />
          {isCompanyAdmin && <NewChannelArea onClick={openCreateChannel} />}
          {isLoading ? (
            <Loading />
          ) : (
            sorted.map(([letter, employeesArray]) => (
              <div key={letter}>
                <Typography className={classes.letter} color="secondary" variant="subtitle1">
                  {letter.toUpperCase()}
                </Typography>
                <DirectsList employees={employeesArray} />
              </div>
            ))
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default memo(DirectDrawer);
