import React, { memo } from 'react';
import { object, arrayOf } from 'prop-types';
import employeeShape from 'shapes/employee';
import EmployeesTable from '../../controls/EmployeesTable';

const FormikEmployeesTable = ({ field, form, employees, teams, ...props }) => {
  const { name } = field;
  const { values, errors, setFieldValue, submitCount } = form;

  const handleChange = (fieldIn, valueIn, shouldValidateIn = false) =>
    setFieldValue(fieldIn, valueIn, shouldValidateIn);

  return (
    <EmployeesTable
      {...props}
      name={name}
      employees={employees}
      teams={teams}
      error={submitCount > 0 ? errors[name] : ''}
      value={values[name]}
      onChange={handleChange}
    />
  );
};

FormikEmployeesTable.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  employees: arrayOf(employeeShape).isRequired,
  teams: arrayOf(object).isRequired,
};

const areEqual = (
  { employees, teams, form: { submitCount, values, errors }, field: { name } },
  props2,
) =>
  employees === props2.employees &&
  teams === props2.teams &&
  submitCount === props2.form.submitCount &&
  values[name] === props2.form.values[name] &&
  errors[name] === props2.form.errors[name];

export default memo(FormikEmployeesTable, areEqual);
