import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import FileSaver from 'file-saver';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import moment from 'moment';
import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import ReactTooltip from 'react-tooltip';
import companyShape from 'shapes/company';
import connector from './connector';
import PeoplesTable from './PeoplesTable';

const SPREADSHEET_MIME_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  month: {
    width: 170,
    display: 'inline-block',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
  arrowIcon: {
    fontSize: 24,
    color: theme.palette.primary.dark,
  },
  loader: {
    display: 'inline-block',
    marginLeft: 10,
    width: 'auto',
  },
  controls: {
    display: 'flex',
    flex: 1,
  },
  calendar: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    display: 'flex',
  },
});

const TimeTrackingScene = ({
  classes,
  employees,
  company,
  timeTrackerLog: { loaded, loading, timeTrackerLog },
  actions,
}) => {
  const initialState = moment().startOf('month');

  const [month, setMonth] = useState(initialState);

  useEffect(() => {
    actions.timeTrackerLog.loadMonth(month.toDate());
  }, [actions.timeTrackerLog, month]);

  const emp =
    timeTrackerLog?.map(t => ({
      ...employees[t.employee],
      timeTrackerLog: t,
    })) || [];

  const downloadReport = () => {
    import('xlsx').then(XLSX => {
      const data = [['name', 'code', 'action', 'timestamp', 'expectedTime', 'deviation']];
      const fixedColumns = data[0].length;
      emp.forEach(e => {
        Object.values(e.timeTrackerLog.days).forEach(l => {
          l.shifts.forEach(s => {
            if (s.data && typeof s.data === 'object') {
              Object.keys(s.data).forEach(key => {
                if (!data[0].includes(key)) {
                  data[0].push(key);
                }
              });
            }
          });
        });
      });
      const additionalColumns = data[0].length - fixedColumns;
      emp.forEach(e => {
        Object.values(e.timeTrackerLog.days).forEach(l => {
          l.shifts.forEach(s => {
            const row = [
              e.name,
              e.code,
              s.type,
              s.date,
              s.data?.expectedTime,
              s.deviation,
              ...new Array(additionalColumns).fill(null),
            ];
            if (s.data && typeof s.data === 'object') {
              Object.entries(s.data).forEach(([key, value]) => {
                const idx = data[0].findIndex(c => c === key);
                row[idx] = value;
              });
            }
            data.push(row);
          });
        });
      });
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Logs');
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const fileData = new Blob([excelBuffer], { type: SPREADSHEET_MIME_TYPE });
      FileSaver.saveAs(fileData, `${month.format('YYYY_MM')}_time_tracking_export.xlsx`);
    });
  };

  const changeMonth = num => {
    setMonth(month.clone().add(num, 'month'));
  };

  const resetMonth = () => {
    setMonth(initialState);
  };

  if (!company || !loaded) {
    return (
      <div className={classes.loading}>
        <Loading size={56} />
      </div>
    );
  }

  const calendar = (
    <div className={classes.controls}>
      <div className={classes.calendar}>
        <Button
          color="secondary"
          variant="outlined"
          className={classes.button}
          onClick={resetMonth}
        >
          {I18n.t('TimeTracking.Current')}
        </Button>
        <Button className={classes.button} onClick={() => changeMonth(-1)}>
          <SvgIcon className={classes.arrowIcon}>
            <ChevronLeftIcon />
          </SvgIcon>
        </Button>
        <div className={classes.month}>
          {month.format('MMMM YYYY')}
          {loading && <Loading className={classes.loader} size={12} />}
        </div>
        <Button className={classes.button} onClick={() => changeMonth(1)}>
          <SvgIcon className={classes.arrowIcon}>
            <ChevronRightIcon />
          </SvgIcon>
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <PeoplesTable
        company={company}
        employees={emp}
        additionalControls={calendar}
        onExport={downloadReport}
      />
      {!loading && <ReactTooltip />}
    </div>
  );
};

TimeTrackingScene.propTypes = {
  company: companyShape,
  classes: object.isRequired,
  actions: object.isRequired,
  timeTrackerLog: object.isRequired,
  employees: object.isRequired,
};

TimeTrackingScene.defaultProps = {
  company: null,
};

export default deferRender(withStyles(styles)(connector(TimeTrackingScene)));
