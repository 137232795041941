import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { useSelector } from 'react-redux';
import baseQuery from 'store/app/entities/baseQuery';
import authCompanyId from 'store/selectors/authCompanyId';
import useCachedQuery from 'store/useCachedQuery';
import s from 'utils/seconds';

const initialState = {
  groups: {},
};

const groupEndpoints = build => ({
  list: build.query({
    query: ({
      search,
      searchBy = 'name description',
      page = 1,
      perPage = 25,
      sortBy = 'name',
      sort = 'asc',
    } = {}) => ({
      url: `v1/group${
        search ? `?search=${search}&searchBy=${searchBy}` : '?'
      }&page=${page}&perPage=${perPage}&sortBy=${sortBy}&sort=${sort}`,
    }),
    transformResponse: response => response.data,
    providesTags: result =>
      result?.groups
        ? [
            ...result.groups.map(({ _id: id }) => ({ type: 'Groups', id })),
            { type: 'Groups', id: 'PARTIAL-LIST' },
          ]
        : [{ type: 'Groups', id: 'PARTIAL-LIST' }],
  }),
});

const Groups = createApi({
  reducerPath: 'groups',
  baseQuery,
  tagTypes: ['Groups'],
  initialState,
  keepUnusedDataFor: 30,
  endpoints: groupEndpoints,
});

export const {
  useListQuery,
  util: { invalidateTags: invalidateGroupsTags },
} = Groups;

export const { middleware: groupsMiddleware, reducer: groupsReducer } = Groups;
