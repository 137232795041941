import { List } from '@mui/material';
import { array } from 'prop-types';
import React from 'react';
import EmployeeListItem from './EmployeeListItem';

const EmployeesList = ({ employees }) => (
  <List>
    {employees.map(employee => (
      <EmployeeListItem key={employee._id} employee={employee} />
    ))}
  </List>
);

EmployeesList.propTypes = {
  employees: array.isRequired,
};

export default EmployeesList;
