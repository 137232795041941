import requiredError from 'components/@home/@settings/Tabs/requiredError';
import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: { name, language },
    } = this;

    return { name, language };
  };

  sendRequest = async form => {
    let ret;
    const { flow: { _id } = { _id: 'new' }, actions } = this.formikBag.props;
    if (_id === 'new') {
      const newForm = { ...form };
      delete newForm._id;
      ret = await actions.chatFlows.create(newForm);
      actions.chatFlows.load();
    } else {
      ret = await actions.chatFlows.update(_id, form);
      actions.chatFlows.load();
    }
    ret = await actions.chatFlows.find(ret.value._id);
    return ret.value;
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const chatFlowFormik = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    name: Yup.string().required(requiredError('name')),
    language: Yup.string().when(
      'languages',
      (languages, schema) => schema.oneOf(languages.map(l => l.shortName)),
      requiredError('options'),
    ),
  }),
  mapPropsToValues: ({ flow, languages, authCompany, context }) => {
    const options = flow?.options || [];
    options.push({ _id: `${Math.random()}`, name: '', texts: [] });
    return {
      _id: flow?._id || 'new',
      name: flow?.name || '',
      language: flow?.language || authCompany?.language || '',
      languages,
      context,
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { context },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    try {
      const ret = await handler.sendRequest(form);
      if (context.current.callback) {
        context.current.callback(ret);
      }
      context.pop();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});

export default chatFlowFormik;
