import { Box, Button, ListItemIcon, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import FileInput from 'components/@home/@messages/ChatPanel/SendForm/controls/AttachFile/FileInput';
import Alert from 'components/alerts/Alert';
import Loading from 'components/common/Loading';
import isEmpty from 'lodash/isEmpty';
import mean from 'lodash/mean';
import AlertCircleIcon from 'mdi-react/AlertCircleIcon';
import FileUploadIcon from 'mdi-react/FileUploadIcon';
import FolderUploadIcon from 'mdi-react/FolderUploadIcon';
import KeyboardArrowDownIcon from 'mdi-react/KeyboardArrowDownIcon';
import UploadIcon from 'mdi-react/UploadIcon';
import { arrayOf, bool, number, string } from 'prop-types';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SharedFile from 'services/api/SharedFile';
import {
  invalidateSharedFilesTags,
  useSharedFilesCreateFoldersMutation,
} from 'store/app/entities/SharedFilesSlice';
import compressImages from 'utils/compressImages';
import getNestedFromPaths from 'utils/getNestedFromPaths';

const ButtonIcon = ({ isError, progress }) => {
  if (!isEmpty(progress)) {
    return (
      <Box sx={{ marginRight: 1 }}>
        <Loading value={mean(progress) || null} debounce={10} size={18} />
      </Box>
    );
  }
  return (
    <SvgIcon
      sx={{
        fontSize: 18,
        marginRight: 1,
        color: isError ? 'danger' : 'primary.main',
      }}
    >
      {isError ? <AlertCircleIcon /> : <UploadIcon />}
    </SvgIcon>
  );
};

ButtonIcon.propTypes = {
  isError: bool,
  progress: arrayOf(number),
};

ButtonIcon.defaultProps = {
  isError: false,
  progress: undefined,
};

const UploadButton = ({ folder, id }) => {
  const fileInput = useRef();
  const [progress, setProgress] = useState([]);
  const cancelFuncs = useRef([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isError, setError] = useState(false);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const [sharedFilesCreateFolders] = useSharedFilesCreateFoldersMutation();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const getCancelFunc = idx => cancelFunc => {
    cancelFuncs.current[idx] = cancelFunc;
  };
  const onUploadProgress = idx => progressEvent => {
    let tpc = null;
    if (progressEvent?.lengthComputable) {
      tpc = (progressEvent.loaded * 100) / progressEvent.total;
    }
    setProgress(p => {
      const ret = [...p];
      ret[idx] = tpc;
      return ret;
    });
  };
  const upload = async files => {
    const compressed = await compressImages(files);
    setProgress(compressed.map(() => 0));
    cancelFuncs.current = [];
    await Promise.all(
      compressed.map(({ file, idx }) =>
        SharedFile.upload(file.folderId || id, file, onUploadProgress(idx), getCancelFunc(idx)),
      ),
    );
    setProgress([]);
  };
  const openFiles = isFolder => () => {
    if (isFolder) {
      fileInput.current.setAttribute('webkitdirectory', true);
    } else {
      fileInput.current.removeAttribute('webkitdirectory');
    }
    handleClose();
    fileInput.current?.click();
  };

  const clearInput = () => {
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };
  const handleChange = async e => {
    try {
      const files = Array.from(e.target.files).filter(f => f.name !== '.DS_Store');
      const folderSet = new Set();
      files.forEach((f, idx) => {
        const folderPath = f.webkitRelativePath.replace(/\/[^/]+$/, '');
        if (folderPath) {
          files[idx].folderPath = folderPath;
          folderSet.add(files[idx].folderPath);
        }
      });
      if (folderSet.size > 0) {
        const folders = getNestedFromPaths(folderSet);
        const {
          data: {
            data: { folderMap },
          },
        } = await sharedFilesCreateFolders({ id, body: { folders } });
        files.forEach((f, idx) => {
          files[idx].folderId = folderMap[f.folderPath];
        });
      }
      await upload(files);
      dispatch(
        invalidateSharedFilesTags(
          id
            ? [
                { type: 'SharedFiles', id: `LIST-${folder}-${id}` },
                { type: 'SharedFiles', id },
              ]
            : [{ type: 'SharedFiles' }],
        ),
      );
    } catch (error) {
      setProgress([]);
      if (error.status === 400 && error.data.field === 'mimeType') {
        setError({ message: I18n.t('UploadButton.errors.mimeType') });
      } else {
        setError(error);
      }
    }
    clearInput();
  };

  return (
    <Box>
      <Button
        id="upload-button"
        aria-controls={open ? 'upload-button-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="text"
        color="secondary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        <ButtonIcon isError={!!isError} progress={progress} />
        {I18n.t('UploadButton.Upload')}
      </Button>
      <Menu
        id="upload-button-menu"
        MenuListProps={{
          'aria-labelledby': 'upload-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled={!id} onClick={openFiles(false)} disableRipple>
          <ListItemIcon>
            <FileUploadIcon />
          </ListItemIcon>
          <Typography>{I18n.t('UploadButton.File')}</Typography>
        </MenuItem>
        <MenuItem onClick={openFiles(true)} disableRipple>
          <ListItemIcon>
            <FolderUploadIcon />
          </ListItemIcon>
          <Typography>{I18n.t('UploadButton.Folder')}</Typography>
        </MenuItem>
      </Menu>
      <FileInput
        style={{ visibility: 'hidden' }}
        inputRef={fileInput}
        onChange={handleChange}
        multiple
      />
      <Alert
        isOpen={isError}
        variant="error"
        onClose={() => setError(false)}
        label={I18n.t('UploadButton.Error')}
        caption={isError.message}
      />
    </Box>
  );
};

UploadButton.propTypes = {
  folder: string.isRequired,
  id: string,
};

UploadButton.defaultProps = {
  id: null,
};

export default UploadButton;
