/* eslint-disable react/jsx-one-expression-per-line */
import { Button, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { arrayOf, object, string } from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import { connect, disconnect, isConnected } from 'services/LoginSocket';
import connector from '../connector';
import Heading from '../Heading';
import ConfirmationForm from './ConfirmationForm';

const styles = () => ({
  root: {},
});

const ConfirmationScene = ({ classes, loginType, confirmToken, deviceNames, actions, form }) => {
  const navigate = useNavigate();

  const onLoginRejected = useCallback(() => {
    navigate('/auth/login/rejected');
  }, [navigate]);

  useEffect(() => {
    if (!isConnected() && confirmToken) {
      connect(confirmToken, actions.auth.loginSuccess, onLoginRejected);
    }
    return () => disconnect();
  }, [actions.auth.loginSuccess, confirmToken, onLoginRejected]);

  const count = deviceNames.length;
  let devices = '';
  for (let idx = 0; idx < deviceNames.length; idx += 1) {
    devices += `<b>${deviceNames[idx]}</b>`;
    if (idx === deviceNames.length - 2) {
      devices += I18n.t('LoginConfirmation.and');
    } else if (idx < deviceNames.length - 2) {
      devices += ', ';
    }
  }
  const type = loginType === 'sms' && confirmToken ? 'app' : loginType;

  const onSendSMS = async () => {
    await actions.login.sendSMS(`${form.prefix}-${form.phone}`, { forceSms: true });
    navigate('/auth/login/confirmation', { replace: true });
  };

  return (
    <div className={classes.root}>
      <Heading
        type={type}
        headline={I18n.t(`LoginConfirmation.headline ${type}`)}
        subheading={I18n.t(`LoginConfirmation.subheading ${type}`, {
          devices,
          count,
          dangerousHTML: true,
        })}
      />
      {type === 'app' ? (
        <>
          <Typography gutterBottom>{I18n.t('LoginConfirmation.If you did not')}</Typography>
          <Button color="primary" onClick={onSendSMS}>
            {I18n.t('LoginConfirmation.Send SMS')}
          </Button>
        </>
      ) : (
        <ConfirmationForm />
      )}
    </div>
  );
};
ConfirmationScene.propTypes = {
  classes: object.isRequired,
  loginType: string,
  confirmToken: string,
  deviceNames: arrayOf(string),
  actions: object.isRequired,
  form: object.isRequired,
};

ConfirmationScene.defaultProps = {
  loginType: null,
  confirmToken: null,
  deviceNames: [],
};

export default withStyles(styles)(connector(ConfirmationScene));
