import {
  Button,
  FormControlLabel,
  Grid,
  RadioGroup,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import makeStyles from '@mui/styles/makeStyles';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import Autosuggest from 'components/controls/Autosuggest';
import StyledRadio from 'components/controls/StyledRadio';
import InfoCircleIcon from 'mdi-react/InfoCircleIcon';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { HR_BOT_APP_ID } from 'services/constants';
import apps from 'store/app/entities/apps/action';
import documentFormats from 'store/app/entities/documentFormats/action';
import employeesWithUser from 'store/selectors/employeesWithUser';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
    alignItems: 'flex-start',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  radio: {
    flexDirection: 'row',
    '& label': {
      marginRight: theme.spacing(4),
    },
  },
  switch: {
    margin: 0,
  },
  info: {
    fontSize: 17,
    color: theme.palette.greyBlue,
    verticalAlign: 'text-bottom',
    display: 'inline',
    marginLeft: theme.spacing(1),
  },
  message: {
    minWidth: 300,
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));

const EditTemplate = () => {
  const classes = useStyles();
  const app = useSelector(state => state.apps.apps[HR_BOT_APP_ID]);
  const employees = useSelector(employeesWithUser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(apps.getGroups(app._id));
  }, [app._id, dispatch]);

  const { format, formatId, setFormatId, navigateBack } = useContext(HrBotContext);
  let sendReportTo = format ? { value: -1 } : {};
  if (format?.sendReportToGroup) {
    sendReportTo = { type: 'group', value: format.sendReportToGroup };
  }
  if (format?.sendReportToEmployee) {
    sendReportTo = { type: 'employee', value: format.sendReportToEmployee };
  }
  const [newTemplateName, setNewTemplateName] = useState(format?.name || '');
  const [newTemplateType, setNewTemplateType] = useState(format?.type?.split('-').pop() || 'pdf');
  const [newTemplateReportTo, setNewTemplateReportTo] = useState(sendReportTo);
  const [newTemplatePrefix, setNewTemplatePrefix] = useState(format?.prefix || '');
  const [newTemplateSendBy, setNewTemplateSendBy] = useState(format?.sendBy || 'code');
  const [newTemplateDefaultMessage, setNewTemplateDefaultMessage] = useState(
    format?.defaultMessage || I18n.t('HrBot.defaultMessage'),
  );
  const [newTemplateSplit, setNewTemplateSplit] = useState(format?.type === 'single-pdf');

  const handleSubmit = async () => {
    let type;
    if (newTemplateType === 'xml') {
      type = 'xml';
    } else if (newTemplateSplit) {
      type = 'single-pdf';
    } else {
      type = 'multi-pdf';
    }
    const form = {
      name: newTemplateName,
      type,
      sendBy: newTemplateSendBy,
      prefix: newTemplatePrefix,
      defaultMessage: newTemplateDefaultMessage,
    };
    if (newTemplateReportTo?.type === 'group' && newTemplateReportTo.value !== -1) {
      form.sendReportToGroup = newTemplateReportTo.value;
    }
    if (newTemplateReportTo?.type === 'employee' && newTemplateReportTo.value !== -1) {
      form.sendReportToEmployee = newTemplateReportTo.value;
    }
    if (formatId) {
      await dispatch(documentFormats.update(formatId, form));
    } else {
      const resp = await dispatch(documentFormats.create(form));
      setFormatId(resp.value.documentFormat._id);
    }
    navigateBack();
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.Name')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              value={newTemplateName}
              onChange={e => setNewTemplateName(e.target.value)}
              className={classes.input}
            />
          </Grid>
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.Send report to')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Autosuggest
              fullWidth
              value={newTemplateReportTo?.value}
              onChange={v => {
                setNewTemplateReportTo(v);
              }}
              suggestions={[
                {
                  label: I18n.t('HrBot.EditTemplate.Do not send'),
                  value: -1,
                },
                {
                  label: I18n.t('HrBot.EditTemplate.Groups'),
                  options:
                    app?.groups?.map(channel => ({
                      value: channel._id,
                      type: 'group',
                      label: channel.name,
                    })) || [],
                },
                {
                  label: I18n.t('HrBot.EditTemplate.People'),
                  options: employees.map(e => ({
                    label: e.name,
                    type: 'employee',
                    value: e._id,
                  })),
                },
              ]}
              className={classes.select}
            />
          </Grid>
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.Type')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              className={classes.radio}
              onChange={e => setNewTemplateType(e.target.value)}
            >
              {['pdf', 'xml'].map(type => (
                <FormControlLabel
                  key={type}
                  value={type}
                  checked={newTemplateType === type}
                  control={<StyledRadio />}
                  label={I18n.t(`HrBot.EditTemplate.Types.${type}`)}
                />
              ))}
            </RadioGroup>
          </Grid>
          {newTemplateType === 'pdf' && (
            <>
              <Grid item xs={2} className={classes.label}>
                <Typography>{`${I18n.t('HrBot.EditTemplate.Split pages')}`}</Typography>
                <Tooltip title={I18n.t('HrBot.EditTemplate.Split pages info')} enterDelay={300}>
                  <SvgIcon className={classes.info}>
                    <InfoCircleIcon />
                  </SvgIcon>
                </Tooltip>
              </Grid>
              <Grid item xs={10}>
                <Switch
                  checked={newTemplateSplit}
                  onChange={e => setNewTemplateSplit(e.target.checked)}
                  className={classes.switch}
                />
              </Grid>
            </>
          )}
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.SendBy')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <RadioGroup
              className={classes.radio}
              onChange={e => setNewTemplateSendBy(e.target.value)}
            >
              {['code', 'id'].map(sendBy => (
                <FormControlLabel
                  key={sendBy}
                  value={sendBy}
                  checked={newTemplateSendBy === sendBy}
                  control={<StyledRadio />}
                  label={I18n.t(`HrBot.EditTemplate.SendByOptions.${sendBy}`)}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.Prefix')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              placeholder="Prefix"
              value={newTemplatePrefix}
              onChange={e => setNewTemplatePrefix(e.target.value)}
            />
          </Grid>
          <Grid item xs={2} className={classes.label}>
            <Typography>{`${I18n.t('HrBot.EditTemplate.Default message')}`}</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              className={classes.message}
              multiline
              rows={14}
              value={newTemplateDefaultMessage}
              onChange={e => setNewTemplateDefaultMessage(e.target.value)}
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.navigationButtons}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={navigateBack}
          className={classes.button}
        >
          {I18n.t('HrBot.Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!newTemplateName || !newTemplateReportTo?.value}
          onClick={handleSubmit}
          className={classes.button}
        >
          {I18n.t('HrBot.Save')}
        </Button>
      </div>
    </div>
  );
};

export default memo(EditTemplate);
