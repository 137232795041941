import { MenuItem, Select, Toolbar, Typography } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { makeStyles } from '@mui/styles';
import { GridToolbarContainer, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import ArrowDown from 'assets/icons/arrow-down.svg';
import ImportEmployeesButton from 'components/@home/@people/PeoplesTable/Toolbar/ImportEmployeesButton';
import MassiveInviteButton from 'components/@home/@people/PeoplesTable/Toolbar/MassiveInviteButton';
import usePrevious from 'components/common/usePrevious';
import SearchField from 'components/controls/SearchField';
import { arrayOf, bool, func, number, object, string } from 'prop-types';
import React, { Component, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useLocation } from 'react-router-dom';
import authEmployee from 'store/selectors/authEmployee';
import authUser from 'store/selectors/authUser';
import employeeShape from 'shapes/employee';
import AddEmployeeButton from './AddEmployeeButton';
import Search from './Search';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
    '& .MuiButton-root': {
      textWrap: 'nowrap',
      minWidth: 'auto',
      lineHeight: 1,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'flex-end',
  },
  title: {
    minWidth: 180,
    fontWeight: 600,
  },
  filterBy: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0 10px 0 20px',
    alignItems: 'baseline',
  },
  filterBySelect: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    paddingLeft: 10,
    minWidth: 150,
  },
  filterByLabel: {
    paddingLeft: 20,
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    marginRight: 20,
  },
  arrow: {
    display: 'inline-block',
    width: '12px',
    top: 'calc(50% - 6px)',
    right: '6px',
    position: 'absolute',
    pointerEvents: 'none',
  },
}));

const ArrowDownComponent = () => {
  const classes = useStyles();
  return <ArrowDown className={classes.arrow} />;
};

const defaultSearchValueParser = searchText => searchText.split(' ').filter(word => word !== '');

const GridToolbarSearch = ({ debounceMs = 500, children, total, search, filters, ...other }) => {
  const classes = useStyles();
  const { isSuperAdmin } = useSelector(authEmployee);
  const locale = useSelector(state => state.i18n.locale);
  const apiRef = useGridApiContext();
  const gridState = useGridSelector(
    apiRef,
    ({
      filter: {
        filterModel: { quickFilterValues, items: filterItems },
      },
      pagination: { pageCount },
    }) => ({
      quickFilterValues,
      filterItems,
      pageCount,
    }),
  );
  const location = useLocation();
  const prevPathname = usePrevious(location.pathname);

  const [value, setValue] = useState(gridState.quickFilterValues.join(' '));
  const updateSearchValue = React.useCallback(
    newSearchValue => {
      apiRef.current.setQuickFilterValues(defaultSearchValueParser(newSearchValue));
    },
    [apiRef],
  );

  const updateStatus = React.useCallback(
    e => {
      apiRef.current.setFilterModel({
        quickFilterValues: gridState.quickFilterValues,
        items:
          e.target.value === 'All'
            ? []
            : [
                {
                  field: 'status',
                  operator: 'is',
                  value: e.target.value,
                },
              ],
      });
    },
    [apiRef, gridState.quickFilterValues],
  );

  useEffect(() => {
    if (prevPathname !== location.pathname) {
      updateSearchValue('');
      setValue('');
    }
  }, [location.pathname, prevPathname, updateSearchValue]);

  const debouncedUpdateSearchValue = React.useMemo(
    () => debounce(updateSearchValue, debounceMs),
    [updateSearchValue, debounceMs],
  );

  const handleSearchValueChange = React.useCallback(
    event => {
      const newSearchValue = event.target.value;
      debouncedUpdateSearchValue(newSearchValue);
      setValue(newSearchValue);
    },
    [debouncedUpdateSearchValue],
  );

  return (
    <GridToolbarContainer className={classes.root}>
      <Typography component="div" className={classes.title} variant="h5">
        {I18n.t('Header.tabs.PeopleCount', { count: total.toLocaleString(locale) })}
      </Typography>
      <div className={classes.actions}>
        <div className={classes.filterBy}>
          <Select
            className={classes.filterBySelect}
            variant="standard"
            disableUnderline
            onChange={updateStatus}
            value={gridState.filterItems[0]?.value || 'All'}
            IconComponent={ArrowDownComponent}
          >
            {['All', 'Active', 'Inactive', 'Pending', 'Blocked'].map(val => (
              <MenuItem key={val} value={val}>
                {I18n.t(`PeoplesScene.FilterBy.status.${val}`)}
              </MenuItem>
            ))}
          </Select>
        </div>
        <SearchField value={value} onChange={handleSearchValueChange} {...other} sx={{ mr: 1 }} />
        {isSuperAdmin && <MassiveInviteButton total={total} search={search} filters={filters} />}
        {children}
        <ImportEmployeesButton />
        <AddEmployeeButton />
      </div>
    </GridToolbarContainer>
  );
};

class EnhancedTableToolbar extends Component {
  state = {
    searchValue: '',
    filters: {
      status: 'All',
    },
  };

  handleSearch = e => {
    const { onSearch } = this.props;
    this.setState({ searchValue: e.target.value }, () => {
      const { searchValue, filters } = this.state;
      onSearch(searchValue, filters);
    });
  };

  handleFilterChange = name => e => {
    const { onSearch } = this.props;
    this.setState(
      ({ filters }) => ({ filters: { ...filters, [name]: e.target.value } }),
      () => {
        const { searchValue, filters } = this.state;
        onSearch(searchValue, filters);
      },
    );
  };

  render() {
    const { classes, total, locale, children, isSuperAdmin, filteredEmployees } = this.props;
    const {
      searchValue,
      filters: { status },
    } = this.state;
    return (
      <Toolbar className={classes.root}>
        <Typography component="div" className={classes.title} variant="h5">
          {I18n.t('Header.tabs.PeopleCount', { count: total.toLocaleString(locale) })}
        </Typography>
        <div className={classes.actions}>
          <div className={classes.filterBy}>
            <Select
              className={classes.filterBySelect}
              variant="standard"
              disableUnderline
              onChange={this.handleFilterChange('status')}
              value={status}
              IconComponent={ArrowDownComponent}
            >
              {['All', 'Active', 'Inactive', 'Pending', 'Blocked'].map(value => (
                <MenuItem key={value} value={value}>
                  {I18n.t(`PeoplesScene.FilterBy.status.${value}`)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Search
            onChange={this.handleSearch}
            value={searchValue}
            inputProps={{
              id: 'searchEmployees',
              name: 'searchEmployees',
              autoSave: 'searchEmployees',
            }}
          />
          {isSuperAdmin && <MassiveInviteButton employees={filteredEmployees} />}
          {children}
          <ImportEmployeesButton />
          <AddEmployeeButton />
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  children: object,
  classes: object.isRequired,
  onSearch: func.isRequired,
  total: number.isRequired,
  locale: string.isRequired,
  filteredEmployees: arrayOf(employeeShape).isRequired,
  isSuperAdmin: bool.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  children: null,
};

export default GridToolbarSearch;
