/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import locales from 'locale/index';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import createDebounce from 'redux-debounced';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import promise from 'redux-promise-middleware';
import { analyticsMiddleware } from 'store/app/entities/AnalyticsSlice';
import { appDataMiddleware } from 'store/app/entities/AppDataSlice';
import { articlesMiddleware } from 'store/app/entities/ArticlesSlice';
import { companiesMiddleware } from 'store/app/entities/CompaniesSlice';
import { contentsMiddleware } from 'store/app/entities/ContentsSlice';
import { CANCEL_EMPLOYEE_IMPORT } from 'store/app/entities/employeeImports/action';
import { CREATE_IMPORT } from 'store/app/entities/employees/action';
import { employeesApiMiddleware } from 'store/app/entities/EmployeesApiSlice';
import { groupsMiddleware } from 'store/app/entities/GroupsSlice';
import { sharedFilesMiddleware } from 'store/app/entities/SharedFilesSlice';
import { teamMembershipRulesMiddleware } from 'store/app/entities/TeamMembershipRulesSlice';
import { usersMiddleware } from 'store/app/entities/UsersSlice';
import { wbMiddleware } from 'store/app/entities/WbSlice';
import { OPEN_TEAM_DRAWER } from 'store/app/ui/drawers/team/action';
import { SET_FOCUS } from 'store/app/ui/focus/action';
import asyncDispatchMiddleware from 'store/asyncDispatchMiddleware';
import { unauthenticatedMiddleware } from 'store/unauthenticatedMiddleware';
import preferredLocale from 'utils/preferredLocale';
import { firebaseBeaconMiddleware, gaBeaconMiddleware } from 'utils/reduxBeaconConfig';

import createRootReducer from './app/reducer';

export const history = createBrowserHistory();

const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware => {
    const middlewareArray = getDefaultMiddleware({
      immutableCheck: {
        ignoredPaths: ['drawers.employeeImports.file'],
      },
      serializableCheck: {
        ignoredPaths: ['firebaseBeaconReducer', 'gaBeaconReducer', 'drawers.employeeImports.file'],
        ignoredActionPaths: [
          'payload',
          'meta.baseQueryMeta.request',
          'meta.baseQueryMeta.response',
        ],
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          CREATE_IMPORT,
          `${CREATE_IMPORT}_PENDING`,
          `${CREATE_IMPORT}_FULFILLED`,
          `${CREATE_IMPORT}_REJECTED`,
          CANCEL_EMPLOYEE_IMPORT,
          `${CANCEL_EMPLOYEE_IMPORT}_PENDING`,
          `${CANCEL_EMPLOYEE_IMPORT}_FULFILLED`,
          `${CANCEL_EMPLOYEE_IMPORT}_REJECTED`,
          SET_FOCUS,
          OPEN_TEAM_DRAWER,
        ],
      },
    }).concat(
      unauthenticatedMiddleware,
      promise,
      createDebounce(),
      analyticsMiddleware,
      appDataMiddleware,
      articlesMiddleware,
      companiesMiddleware,
      contentsMiddleware,
      employeesApiMiddleware,
      firebaseBeaconMiddleware,
      gaBeaconMiddleware,
      groupsMiddleware,
      sharedFilesMiddleware,
      teamMembershipRulesMiddleware,
      usersMiddleware,
      wbMiddleware,
      asyncDispatchMiddleware,
    );
    return middlewareArray;
  },
});

export const persistor = persistStore(store);

syncTranslationWithStore(store);

store.dispatch(loadTranslations({ ...locales }));
store.dispatch(setLocale(preferredLocale()));

export default store;
