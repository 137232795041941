/**
 * @flow
 */
import { Box, Button, Drawer, Typography } from '@mui/material';
import CloseButton from 'components/@home/drawers/CloseButton';
import { useDrawer } from 'components/common/Drawer/DrawerContext';
import React, { forwardRef, Node, useCallback, useImperativeHandle, useRef } from 'react';
import { Translate } from 'react-redux-i18n';

const DrawerContent = forwardRef(
  (
    {
      drawer,
      title,
      actions,
      children,
      onSubmit,
      onClose,
      sx,
    }: {
      drawer: String;
      title: String;
      actions?: Node;
      children: Node;
      onSubmit?: Function;
      onClose?: Function;
      sx?: Object;
    },
    ref,
  ) => {
    const onSubmitRef = useRef(onSubmit);
    const { visible, show, hide, state } = useDrawer(drawer);
    const handleOnClose = useCallback(() => {
      hide();
      if (onClose) {
        onClose();
      }
    }, [hide, onClose]);
    const handleSubmit = useCallback(
      e => {
        e.preventDefault();
        e.stopPropagation();
        hide();
        if (onSubmitRef.current) {
          onSubmitRef.current();
        }
      },
      [hide],
    );
    useImperativeHandle(ref, () => ({
      visible,
      show,
      hide,
      state,
      setOnSubmit: newOnSubmit => {
        onSubmitRef.current = newOnSubmit;
      },
    }));
    return (
      <Drawer anchor="right" open={visible} onClose={handleOnClose}>
        <Box sx={{ display: 'flex', ...sx }}>
          <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
            <CloseButton onClick={hide} />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              flexGrow: 1,
              height: '100vh',
            }}
            role="none"
          >
            <Box
              component="form"
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
              onSubmit={handleSubmit}
            >
              <Typography variant="h5" sx={{ mt: 2.5, ml: 2, mb: 2 }}>
                {title}
              </Typography>
              <Box
                sx={{
                  px: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                }}
              >
                {children}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: 2,
                  gap: 1,
                }}
              >
                {typeof actions === 'undefined' ? (
                  <>
                    <Button type="button" variant="outlined" color="secondary" onClick={hide}>
                      <Translate value="Cancel" />
                    </Button>
                    <Button type="submit" variant="contained">
                      <Translate value="Save" />
                    </Button>
                  </>
                ) : typeof actions === 'function' ? (
                  actions({ visible, show, hide, state })
                ) : (
                  actions
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
    );
  },
);

DrawerContent.defaultProps = {
  actions: undefined,
  sx: {},
  onClose: null,
  onSubmit: null,
};

export default DrawerContent;
