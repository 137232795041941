import { Chip, Tooltip, Typography } from '@mui/material';
import ColorScale from 'color-scales';
import categories from 'components/@whistleblower/translations/categories';
import moment from 'moment';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import getMoment from 'utils/getMoment';
import renderDateTimeCell from 'utils/renderDateTimeCell';

const colorScale = new ColorScale(0, 7, ['#ffd666', '#9cc745']);
const colorScale2 = new ColorScale(8, 90, ['#9cc745', '#57bb8a']);

const renderNextSlaBreachCell = params => {
  const days = getMoment(params.value).diff(moment(), 'days', true);
  let color =
    days > 7 ? colorScale2.getColor(days).toHexString() : colorScale.getColor(days).toHexString();
  if (days < 0) {
    color = '#e75448';
  }
  return (
    <Tooltip title={moment(params.value).format('LLLL')} enterDelay={1000}>
      <Typography
        sx={{
          color,
          fontWeight: 600,
        }}
      >
        {params.value && getMoment(params.value).fromNow()}
      </Typography>
    </Tooltip>
  );
};

const columns = locale => [
  {
    field: 'number',
    flex: 0.25,
    editable: false,
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.Number'),
  },
  {
    field: 'status',
    flex: 0.5,
    editable: false,
    renderCell: params => (
      <Chip label={I18n.t(`WhistleblowerAdmin.OccurrenceList.Status.${params.value}`)} />
    ),
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.Status'),
  },
  {
    field: 'title',
    flex: 1,
    editable: false,
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.Title'),
  },
  {
    field: 'category',
    flex: 1,
    editable: false,
    renderCell: params => categories[locale][params.value],
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.Category'),
  },
  {
    field: 'severity',
    flex: 0.5,
    editable: false,
    renderCell: params => categories[locale][params.value],
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.Severity'),
  },
  {
    field: 'nextSlaBreachAt',
    flex: 1,
    editable: false,
    renderCell: renderNextSlaBreachCell,
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.NextSlaBreach'),
  },
  {
    field: 'lastActivityAt',
    flex: 1,
    editable: false,
    renderCell: renderDateTimeCell,
    headerName: I18n.t('WhistleblowerAdmin.OccurrenceList.Columns.LastActivity'),
  },
];

export default columns;
