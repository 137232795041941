import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Switch from 'components/formik/Switch';
import { connect, Field } from 'formik';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = {
  root: {
    justifySelf: 'flex-start',
    display: 'flex',
    alignItems: 'center',
  },
};

const InviteSwitch = ({ classes, formik }) => (
  <div className={classes.root}>
    <Field component={Switch} label="" disabled={formik.values.inviteDisabled} name="invite" />
    <div>
      <Typography variant="subtitle1">{I18n.t('EmployeeDrawer.InviteSwitch.label')}</Typography>
      <Typography variant="caption">{I18n.t('EmployeeDrawer.InviteSwitch.caption')}</Typography>
    </div>
  </div>
);
InviteSwitch.propTypes = {
  classes: object.isRequired,
  formik: object.isRequired,
};

export default withStyles(styles)(connect(InviteSwitch));
