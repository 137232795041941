import { SvgIcon } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { element, number, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.secondary.extraLight}`,
    backgroundColor: theme.palette.background.paper,
    width: 250,
  },

  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20,
  },
  listRoot: {
    padding: 10,
  },
  itemRoot: {
    borderRadius: 5,
    '& + & $itemTextRoot': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.extraLight,
      '& $itemTextRoot': {
        borderColor: 'transparent !important',
      },
      '& + $itemRoot $itemTextRoot': {
        borderColor: 'transparent !important',
      },
    },
  },
  itemSelected: {
    backgroundColor: theme.palette.secondary.ultraLight,
    '& $itemTextRoot': {
      borderColor: 'transparent !important',
    },
    '& $itemTextPrimary': {
      fontWeight: 600,
    },
    '& + $itemRoot $itemTextRoot': {
      borderColor: 'transparent !important',
    },
  },
  itemTextRoot: {
    minHeight: 50,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    borderBottom: 0,
  },
  itemTextPrimary: {},
  icon: props => {
    return {
      fontSize: 32,
      color: theme.palette.icons[props.index],
    };
  },
}));

const SettingsItem = ({ icon, index, name }) => {
  const classes = useStyles({ index });
  const location = useLocation();
  return (
    <ListItem
      classes={{ root: classes.itemRoot, selected: classes.itemSelected }}
      button
      component={Link}
      to={`/home/settings/${name}`}
      key={name}
      selected={location.pathname.endsWith(`/settings/${name}`)}
    >
      <ListItemIcon>
        <SvgIcon className={classes.icon}>{icon}</SvgIcon>
      </ListItemIcon>
      <ListItemText
        classes={{ root: classes.itemTextRoot, primary: classes.itemTextPrimary }}
        primary={I18n.t(`SettingsMenu.${name}`)}
      />
    </ListItem>
  );
};

SettingsItem.propTypes = {
  name: string.isRequired,
  icon: element.isRequired,
  index: number.isRequired,
};

export default SettingsItem;
