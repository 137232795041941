import clone from 'lodash/clone';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import {
  FLUSH_USER_DATA,
  GET_USER_DATA_FULFILLED,
  LOGOUT_USER_PENDING,
} from 'store/app/auth/action';
import { INSTALL_APP_FULFILLED } from 'store/app/entities/apps/action';
import { SET_ARCHIVED_CHANNEL_FULFILLED } from 'store/app/entities/channels/action';
import { ADD_MESSAGE, SEND_MESSAGE_FULFILLED } from 'store/app/entities/messages/action';
import { CREATE_TEAM_FULFILLED, DESTROY_TEAM_FULFILLED } from 'store/app/entities/teams/action';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

import {
  ACCEPT_COMPANY_TERMS_FULFILLED,
  ACCEPT_COMPANY_TERMS_PENDING,
  ACCEPT_COMPANY_TERMS_REJECTED,
  ADD_COMPANY_TAG_FULFILLED,
  CREATE_PAYMENT_INTENT_FULFILLED,
  LOAD_COMPANY_FULFILLED,
  LOAD_ONBOARDING_FULFILLED,
  REMOVE_COMPANY,
  SET_COMPANY,
  TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED,
  UPDATE_COMPANY_FULFILLED,
} from './action';

const createCompany = c =>
  omitBy(
    {
      _id: c._id,
      name: c.name,
      subDomain: c.subDomain,
      isActive: c.isActive,
      waitAcceptTerms: c.waitAcceptTerms,
      webSiteUrl: c.webSiteUrl,
      createdAt: c.createdAt,
      logo: c.logo,
      logoDark: c.logoDark,
      onBoardingImage: c.onBoardingImage,
      termsFile: c.termsFile,
      privacy: c.privacy,
      terms: c.terms,
      tags: c.tags || [],
      isAcceptTerms: c.isAcceptTerms,
      allowAnonymousEmployees: c.allowAnonymousEmployees,
      channels: c.channels,
      teams: c.teams,
      workingHours: c.workingHours,
      employees: c.employees,
      language: c.language,
      languages: c.languages,
      timeZone: c.timeZone,
      country: c.country,
      apps: c.apps,
      divisions: c.divisions,
      employee: c.employee && {
        ...c.employee,
        name: `${c.employee.firstName} ${c.employee.lastName}`,
      },
    },
    isUndefined,
  );

const companiesReducer = (state = {}, { type, payload, meta }) => {
  switch (type) {
    case SET_COMPANY:
      return {
        ...state,
        [payload._id]: merge(state[payload._id], createCompany(payload)),
      };

    case INSTALL_APP_FULFILLED: {
      const company = { ...state[meta.companyId] };
      if (meta.installed && !company.apps?.includes(meta.appId)) {
        company.apps = [...company.apps, meta.appId];
      } else if (!meta.installed && company.apps?.includes(meta.appId)) {
        company.apps = company.apps.filter(app => app !== meta.appId);
      }
      return {
        ...state,
        [meta.companyId]: company,
      };
    }

    case GET_USER_DATA_FULFILLED: {
      const companies = payload.companies.map(c => createCompany(merge(state[c._id], c)));

      return merge(state, arrayToObject(companies));
    }

    case UPDATE_COMPANY_FULFILLED: {
      const companies = clone(state);

      companies[payload.company._id] = createCompany(
        merge(state[payload.company._id], omitBy(payload.company, isUndefined)),
      );

      return companies;
    }

    case TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED: {
      const companies = clone(state);

      companies[payload.company._id] = createCompany(
        merge(state[payload.company._id], { waitAcceptTerms: payload.company.waitAcceptTerms }),
      );

      return companies;
    }

    case LOAD_COMPANY_FULFILLED: {
      const companies = clone(state);

      companies[payload._id] = createCompany(
        merge(state[payload._id], omitBy(payload, isUndefined)),
      );

      return companies;
    }

    case ADD_COMPANY_TAG_FULFILLED:
      return {
        ...state,
        [meta.company_id]: {
          ...state[meta.company_id],
          tags: uniq([...state[meta.company_id].tags, ...(payload || [])]),
        },
      };

    case ACCEPT_COMPANY_TERMS_PENDING:
    case ACCEPT_COMPANY_TERMS_FULFILLED:
    case ACCEPT_COMPANY_TERMS_REJECTED:
      return merge(state, {
        [meta.company_id]: {
          ...state[meta.company_id],
          isAcceptTerms: true,
        },
      });

    case REMOVE_COMPANY: {
      const companies = { ...state };
      delete companies[payload];
      return companies;
    }

    case CREATE_TEAM_FULFILLED: {
      const company = state[payload.company];
      const teamNameSort = (a, b) => a.name.localeCompare(b.name);
      const newState = {
        ...state,
        [payload.company]: {
          ...state[payload.company],
          teams: company.teams ? [...company.teams, payload].sort(teamNameSort) : company.teams,
        },
      };
      return newState;
    }

    case DESTROY_TEAM_FULFILLED: {
      const newState = Object.values(state).reduce(
        (ret, company) => ({
          ...ret,
          [company._id]: {
            ...company,
            teams: company.teams?.filter(t => !payload.teams.includes(t._id)),
          },
        }),
        {},
      );
      return newState;
    }

    case ADD_MESSAGE:
    case SEND_MESSAGE_FULFILLED: {
      const { employee } = state[payload.company];
      let archivedChannels = employee.archivedChannels || [];
      const wasArchived = archivedChannels.includes(payload.channel);
      if (wasArchived) {
        archivedChannels = archivedChannels.filter(c => c !== payload.channel);
      }
      return {
        ...state,
        [payload.company]: {
          ...state[payload.company],
          employee: { ...employee, archivedChannels },
        },
      };
    }

    case SET_ARCHIVED_CHANNEL_FULFILLED: {
      const { employee } = state[meta.companyId];
      let archivedChannels = employee.archivedChannels || [];
      const wasArchived = archivedChannels.includes(meta.channelId);
      if (meta.archived && !wasArchived) {
        archivedChannels = [...archivedChannels, meta.channelId];
      } else if (!meta.archived && wasArchived) {
        archivedChannels = archivedChannels.filter(c => c !== meta.channelId);
      }
      return {
        ...state,
        [meta.companyId]: { ...state[meta.companyId], employee: { ...employee, archivedChannels } },
      };
    }

    case CREATE_PAYMENT_INTENT_FULFILLED: {
      return { ...state, clientSecret: payload.clientSecret };
    }

    case FLUSH_USER_DATA:
    case LOGOUT_USER_PENDING: {
      storage('ommnio').removeItem('persist:companies');
      return {};
    }

    case LOAD_ONBOARDING_FULFILLED: {
      const companies = clone(state);

      companies[payload.company._id] = createCompany(
        merge(state[payload.company._id], omitBy(payload.company, isUndefined)),
      );

      return companies;
    }

    default:
      return state;
  }
};

const persistConfig = {
  key: 'companies',
  storage: storage('ommnio'),
  throttle: 200,
  version: 0,
};

export default persistReducer(persistConfig, companiesReducer);
