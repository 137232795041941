import { ListItem, SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import FileSignIcon from 'assets/icons/file-sign.svg';
import FileAckIcon from 'assets/icons/file.svg';
import moment from 'moment';
import { func, node, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';
import connector from './connector';

const styles = theme => ({
  root: {
    display: 'block',
  },
  row: {
    height: '70px',
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
  },
  fileIcon: {
    fontSize: 24,
    marginRight: 10,
  },
  chevronIcon: {
    fontSize: 14,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameText: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.black,
  },
  ack: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.primary.main,
  },
  divider: {
    margin: 0,
    marginLeft: 30,
    backgroundColor: theme.palette.secondary.extraLight,
    height: 1,
    border: 0,
    flex: 'none',
  },
});

const DocumentItem = ({ doc, classes, employee, actions, children }) => {
  const preview = () => {
    actions.acknowledgmentReadModal.open(doc, doc.channel, employee);
  };

  const Icon = doc.digitalSignature ? FileSignIcon : FileAckIcon;
  const confirmedAt = doc.acknowledges.find(a => a.user === employee.user_id)?.confirmedAt;
  return (
    <ListItem classes={{ root: classes.root }} onClick={preview}>
      <div className={classes.row}>
        <SvgIcon className={classes.fileIcon}>
          <Icon />
        </SvgIcon>
        <div className={classes.textWrapper}>
          <Typography className={classes.nameText} component="div">
            {children}
          </Typography>
          {confirmedAt && (
            <Typography className={classes.ack} component="div">
              {I18n.t(
                doc.digitalSignature
                  ? 'Documents.Digital signature made on'
                  : 'Documents.Acknowledge made on',
                {
                  date: moment.unix(confirmedAt).format('LL'),
                },
              )}
            </Typography>
          )}
        </div>
        <SvgIcon className={classes.chevronIcon}>
          <ChevronRightIcon />
        </SvgIcon>
      </div>
      <hr className={classes.divider} />
    </ListItem>
  );
};

DocumentItem.propTypes = {
  employee: employeeShape.isRequired,
  classes: object.isRequired,
  children: node.isRequired,
  doc: object.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(DocumentItem));
