import React from 'react';
import { object, func, bool, number } from 'prop-types';
import { Button, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import formik from './formik';
import connector from './connector';

const styles = theme => ({
  input: {
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    padding: '20px 0',
  },
  errorContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const LoginForm = ({
  classes,
  values,
  errors,
  submitCount,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
}) => {
  const hasSubmitted = submitCount > 0;
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.input}>
        <TextField
          fullWidth
          error={!!(errors.name && hasSubmitted)}
          helperText={hasSubmitted && errors.name}
          type="text"
          name="name"
          placeholder={I18n.t('NoInvitation.Name and surname')}
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={classes.input}>
        <TextField
          fullWidth
          error={!!(errors.company && hasSubmitted)}
          helperText={hasSubmitted && errors.company}
          type="text"
          name="company"
          placeholder={I18n.t('NoInvitation.Company name')}
          value={values.company}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={classes.input}>
        <TextField
          fullWidth
          error={!!(errors.email && hasSubmitted)}
          helperText={hasSubmitted && errors.email}
          type="email"
          name="email"
          placeholder={I18n.t('NoInvitation.Your email address')}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <Button fullWidth variant="contained" type="submit" color="primary" disabled={isSubmitting}>
        {I18n.t('NoInvitation.Send')}
      </Button>
      <div className={classes.errorContainer}>
        {errors.server && (
          <Typography align="right" color="error" className={classes.errorMessage}>
            {errors.server}
          </Typography>
        )}
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  classes: object.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  handleSubmit: func.isRequired,
  isSubmitting: bool.isRequired,
  submitCount: number.isRequired,
};

export default withStyles(styles)(connector(formik(LoginForm)));
