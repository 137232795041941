import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MessageGroup from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup';
import Label from 'components/@home/drawers/AppDrawer/Form/Label';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import { getFileName } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import IphonePreview from 'components/common/IphonePreview';
import { bool, func, object } from 'prop-types';
import React, { memo, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { HR_BOT_APP_ID } from 'services/constants';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: 40,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-around',
  },
  input: {
    width: 290,
  },
  label: {
    textTransform: 'capitalize',
  },
  chat: {
    zoom: 0.73,
    flexDirection: 'column',
    padding: 10,
    flexGrow: 1,
    backgroundColor: theme.palette.secondary.ultraLight,
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 14,
  },
}));

const StepMessage = ({ setError, setNextEnabled, userItems, setUserItems }) => {
  const classes = useStyles();
  const app = useSelector(state => state.apps.apps[HR_BOT_APP_ID]);
  const { format, files, setMessage } = useContext(HrBotContext);
  const { message } = useContext(HrBotContext);
  const file = useMemo(
    () => files?.find(f => !f.error && f.employee?.[format.sendBy]) || { file: {} },
    [files, format.sendBy],
  );
  const name = useMemo(
    () => getFileName({ ...file, ...userItems }, format),
    [file, format, userItems],
  );
  const formatUserItems = useMemo(() => format.items.filter(i => i.selector === 'user'), [format]);
  useEffect(() => {
    setNextEnabled(!!message && formatUserItems.every(i => userItems[i.name]));
    setError(null);
  }, [formatUserItems, message, setError, setNextEnabled, userItems]);
  return (
    <div className={classes.root}>
      <div className={classes.form}>
        <div>
          <Label>{I18n.t('HrBot.Message')}</Label>
          <TextField
            className={classes.input}
            multiline
            rows={20}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          {formatUserItems.map(item => (
            <>
              <Label className={classes.label}>{I18n.t(`HrBot.fields.${item.name}`)}</Label>
              <TextField
                key={item.name}
                className={classes.input}
                rows={20}
                value={userItems[item.name]}
                onChange={e => {
                  const val = e.target?.value || '';
                  setUserItems(ui => {
                    return {
                      ...ui,
                      [item.name]: val,
                    };
                  });
                }}
              />
            </>
          ))}
        </div>
        <IphonePreview>
          <div className={classes.chat}>
            {message && (
              <MessageGroup
                msgRef={() => {}}
                message={{
                  text: message,
                  employee: {
                    avatar: app.avatar,
                    firstName: app.name,
                  },
                }}
              />
            )}
            {file && (
              <MessageGroup
                msgRef={() => {}}
                message={{
                  attachments: [
                    {
                      name,
                      httpLink: name,
                      mimeType: 'application/pdf',
                      type: 'doc',
                      size: file.file.size,
                    },
                  ],
                  employee: {
                    avatar: app.avatar,
                    firstName: app.name,
                  },
                }}
              />
            )}
          </div>
        </IphonePreview>
      </div>
    </div>
  );
};

StepMessage.propTypes = {
  setError: func.isRequired,
  setNextEnabled: func.isRequired,
  userItems: object.isRequired,
  setUserItems: func.isRequired,
};

export default memo(StepMessage);
