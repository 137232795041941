import { withFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { I18n } from 'react-redux-i18n';
import Message from 'services/api/Message';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: { answers },
    } = this;
    return { answers };
  };

  sendRequest = form => {
    const {
      props: { message },
    } = this.formikBag;
    return Message.sendForm(message._id, form);
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

export default withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validate(values, props) {
    const currentQuestionId = props.form.questions[values.currentQuestion]?._id;
    return currentQuestionId && isEmpty(values.answers[currentQuestionId])
      ? { [`answers.${currentQuestionId}`]: I18n.t('FillFormDrawer.Please answer the question') }
      : null;
  },
  mapPropsToValues: ({ form = {}, message, channel }) => {
    const questions = form.questions || [];
    const answers = questions.reduce(
      (ret, curr) => ({ ...ret, [curr._id]: form.answers?.[curr._id] }),
      {},
    );
    return {
      disabled: !isEmpty(form.answers),
      currentQuestion: 0,
      form,
      channel,
      message,
      title: message?.title,
      answers,
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { actions },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    if (values.disabled && values.currentQuestion === values.form.questions.length - 1) {
      actions.drawers.fillForm.close();
      resetForm();
      return false;
    }

    if (values.currentQuestion < values.form.questions.length) {
      const currentQuestion = values.currentQuestion + 1;
      formikBag.resetForm({ values });
      formikBag.setFieldValue('currentQuestion', currentQuestion);
      return false;
    }

    try {
      await handler.sendRequest(form);
      actions.drawers.fillForm.close();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});
