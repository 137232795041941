/**
 * @flow
 */
import { Box, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { localPoint } from '@vx/event';
import { LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend';
import { TooltipWithBounds, useTooltip } from '@vx/tooltip';
import classNames from 'classnames';
import findLast from 'lodash/findLast';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n, Localize } from 'react-redux-i18n';
import compactNumber from 'utils/compactNumber';

type Props = {
  title: string;
  description: string;
  data: Array<Object>;
  totals: Object;
  chart: Object;
  keys: Array<string>;
  color: Function;
  xScale?: Object;
  x?: Function;
  tickFormat?: Function;
  controls?: Object;
  sx?: Object;
  yTickFormats?: {
    [string]: Function;
  };
};

// eslint-disable-next-line no-shadow
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 40px 0 110px',
    flexWrap: 'wrap',
    '@media (max-width: 800px)': {
      marginLeft: 20,
    },
  },
  legend: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 40,
    justifyContent: 'center',
    flex: 1,
  },
  chart: {
    flex: 1,
    backgroundColor: theme.palette.secondary.ultraLight,
    borderRadius: 20,
    padding: '20px 30px',
    position: 'relative',
    marginTop: 30,
    marginBottom: 30,
  },
  tooltipWrapper: {
    opacity: 0,
    transition: 'all .25s ease-in-out',
    '&$visible': {
      opacity: 1,
    },
    pointerEvents: 'none',
  },
  tooltip: {
    position: 'absolute',
    padding: 15,
    borderRadius: 5,
    border: `1px solid ${theme.palette.secondary.light}`,
    minWidth: 210,
    background: alpha(theme.palette.background.paper, 0.9),
    boxShadow: '0 1px 22px 0 rgba(2,2,3,0.25)',
    backdropFilter: 'blur(2px)',
  },
  visible: {},
  legendSeries: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 22,
  },
  legendItem: {
    alignItems: 'flex-start',
    marginTop: '20px !important',
  },
  legendLabel: {
    margin: '0 60px 0 10px !important',
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  legendValue: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  tooltipLabel: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    marginBottom: 15,
  },
  tooltipSeries: {
    fontSize: 14,
    display: 'flex',
    color: theme.palette.text.primary,
    alignItems: 'center',
    '& > div + div': {
      marginLeft: 10,
    },
    marginBottom: 10,
  },
  tooltipValue: {
    fontWeight: 'bold',
    justifyContent: 'flex-end',
    flex: 1,
    display: 'flex',
  },
}));

const ChartSection = ({
  title,
  description,
  keys,
  color,
  data,
  chart,
  xScale,
  x,
  tickFormat,
  totals,
  controls,
  sx,
  yTickFormats,
}: Props) => {
  const classes = useStyles();
  const locale = useSelector(state => state.i18n.locale);
  const numberFormat = new Intl.NumberFormat(locale, { minimumFractionDigits: 0 });
  const {
    tooltipData: ttd,
    tooltipLeft: ttl,
    tooltipTop: ttt,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  const [tooltipLeft, setTooltipLeft] = useState(ttl);
  const [tooltipTop, setTooltipTop] = useState(ttt);
  const [tooltipData, setTooltipData] = useState(ttt);

  useEffect(() => {
    if (tooltipOpen) {
      setTooltipLeft(ttl);
      setTooltipTop(ttt);
      setTooltipData(ttd);
    }
  }, [ttt, ttl, ttd, tooltipOpen]);

  const handleMouseOver = event => {
    const domain = xScale.domain();
    const paddingOuter = xScale(domain[0]);
    const coords = localPoint(event.target?.ownerSVGElement, event);
    if (!coords) return;
    let td;
    let ttipLeft;
    let ttipTop;
    if (!data.length) {
      ttipLeft = event.nativeEvent.offsetX;
      ttipTop = event.nativeEvent.offsetY;
      td = data;
    } else if (typeof xScale.step === 'function') {
      const eachBand = xScale.step();
      const index = Math.round((coords.x - paddingOuter) / eachBand);
      const val = domain[Math.max(0, Math.min(index, domain.length - 1))];
      td = data.find(d => x(d) === val);
    } else {
      const val = xScale.invert(coords?.x);
      td = findLast(data, d => x(d) <= val);
    }
    if (td) {
      ttipLeft = ttipLeft || xScale(x(td)) + 25;
      ttipTop = ttipTop || coords.y;
      showTooltip({
        tooltipLeft: ttipLeft,
        tooltipTop: ttipTop,
        tooltipData: td,
      });
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      hideTooltip();
    }, 100);
  };

  return (
    <Box component="section" sx={sx} className={classes.root}>
      <div className={classes.legend}>
        <Typography component="h6" className={classes.title} variant="h6">
          {title}
        </Typography>
        <Typography component="div" className={classes.title} variant="subtitle2">
          {description}
        </Typography>
        {!!color && (
          <div className={classes.legendSeries}>
            <LegendOrdinal
              scale={color}
              itemDirection="row-reverse"
              direction="column"
              labelMargin="0 15px 0 0"
            >
              {labels => {
                return labels.map((label, idx) => {
                  const size = 10;
                  const value = compactNumber(totals[label.text]);
                  return (
                    <LegendItem
                      className={classes.legendItem}
                      alignItems="baseline"
                      key={`legend-${label.value}`}
                    >
                      <div
                        style={{
                          backgroundColor: label.value,
                          width: size,
                          height: size,
                          borderRadius: '50%',
                          margin: '2px 0',
                        }}
                      />
                      <LegendLabel className={classes.legendLabel}>
                        <Typography component="div">
                          {I18n.t(`AnalyticsScene.labels.${label.text}`)}
                        </Typography>
                        <Typography component="div" className={classes.legendValue} title={value.n}>
                          {yTickFormats[keys[idx]] ? (
                            yTickFormats[keys[idx]](+totals[label.text])
                          ) : (
                            <>
                              <Localize value={value.n} options={{ maximumFractionDigits: 2 }} />
                              {value.suffix}
                            </>
                          )}
                        </Typography>
                      </LegendLabel>
                    </LegendItem>
                  );
                });
              }}
            </LegendOrdinal>
          </div>
        )}
      </div>
      <div className={classes.chart}>
        {controls}
        {chart(handleMouseOver, handleMouseLeave, tooltipData, tooltipOpen && tooltipLeft)}
        {!!tooltipLeft && (
          <div
            className={classNames({
              [classes.tooltipWrapper]: true,
              [classes.visible]: tooltipOpen,
            })}
          >
            <TooltipWithBounds
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              offsetLeft={25}
              left={tooltipLeft}
              className={classes.tooltip}
              style={null}
            >
              <div className={classes.tooltipLabel}>
                {tickFormat && tooltipData && tickFormat(x(tooltipData))}
              </div>
              {keys.map(key => (
                <div key={key} className={classes.tooltipSeries}>
                  <div
                    style={{
                      backgroundColor: color(key),
                      width: 10,
                      height: 10,
                      borderRadius: '50%',
                    }}
                  />
                  <div>{I18n.t(`AnalyticsScene.labels.${key}`)}</div>
                  <div className={classes.tooltipValue}>
                    {(yTickFormats[key] || numberFormat.format)(tooltipData?.[key])}
                  </div>
                </div>
              ))}
            </TooltipWithBounds>
          </div>
        )}
      </div>
    </Box>
  );
};

ChartSection.defaultProps = {
  tickFormat: x => x.toString(),
  controls: null,
  sx: null,
  x: null,
  xScale: null,
  yTickFormats: {},
};

export default ChartSection;
