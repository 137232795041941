import { Button, SvgIcon, Typography } from '@mui/material';
import SendComment from 'assets/icons/send-comment.svg';
import { func } from 'prop-types';
import React, { useState, useEffect } from 'react';
import { I18n } from 'react-redux-i18n';

const SendButton = ({ onSend }) => {
  let timer;

  useEffect(() => () => (timer ? clearTimeout(timer) : null), []);

  const [isSent, setSent] = useState(false);

  const handleSend = e => {
    setSent(true);
    timer = setTimeout(() => setSent(0), 30000);
    onSend(e);
  };

  if (isSent) return <Typography>{I18n.t('AnalyticsDrawer.Reminder is sent')}</Typography>;

  return (
    <Button title={I18n.t('AnalyticsDrawer.Send again')} onClick={handleSend}>
      <SvgIcon>
        <SendComment />
      </SvgIcon>
    </Button>
  );
};

SendButton.propTypes = {
  onSend: func.isRequired,
};

export default SendButton;
