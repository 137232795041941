/**
 * @flow
 */
import SyncAlt from '@mui/icons-material/SyncAlt';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { BACKEND_URL } from 'services/constants';
import Cookie from 'services/Cookie';

const AccordionSummaryBold = withStyles({
  expanded: {
    '& p': {
      fontWeight: 'bold',
    },
  },
})(AccordionSummary);

type Props = {
  app: Object;
  scopes: Array<string>;
  state: string;
  redirectUri: string;
};

const useStyles = makeStyles(theme => ({
  logos: {
    width: 'initial',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(3),
    justifyContent: 'center',
    flex: '1 0 auto',
  },
  logo: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.white,
  },
  accordionWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  cancel: {
    color: theme.palette.primary.ultraDark,
  },
}));

const OAuthAuthorize = ({ app, scopes, redirectUri, state }: Props) => {
  const classes = useStyles();

  const allow = () => {
    const token = Cookie.get('token');

    const form = document.createElement('form');

    const params = {
      access_token: token.hash,
      client_id: app._id,
      scope: scopes.join(' '),
      rediect_uri: redirectUri,
      response_type: 'code',
      grants: 'authorization_code',
      state,
    };

    form.method = 'POST';
    form.action = `${BACKEND_URL}/api/v1/oauth/authorize`;

    Object.entries(params).forEach(([name, value]) => {
      const input = document.createElement('input');
      input.name = name;
      input.value = String(value);
      input.type = 'hidden';
      form.appendChild(input);
    });

    if (document.body) {
      document.body.appendChild(form);
    }
    form.submit();
  };

  const cancel = () => {};

  const scopeGroupsMap = {
    attachment: 'message',
    company: 'company',
    employee: 'company',
    group: 'company',
    message: 'message',
  };

  const scopeGroups: {
    read: { [string]: Array<string> };
    write: { [string]: Array<string> };
  } = scopes.reduce(
    (acc, scope) => {
      const [entity, level] = scope.split(':');
      const group = scopeGroupsMap[entity];
      if (group) {
        const ret = { ...acc };
        ret[level][group] = [...(ret[level][group] || []), scope];
        return ret;
      }
      return acc;
    },
    { read: {}, write: {} },
  );

  const ScopesAccordion = ({ level }: { level: string }) => {
    if (Object.keys(scopeGroups[level]).length === 0) {
      return null;
    }
    return (
      <Grid item xs={12}>
        <Typography variant="h3">
          {I18n.t(`OAuth.scopeLevels.${level}`, { name: app.name })}
        </Typography>
        <div className={classes.accordionWrapper}>
          {Object.keys(scopeGroups[level]).map(group => (
            <Accordion key={group}>
              <AccordionSummaryBold expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>
                  {I18n.t(`OAuth.scopeGroup.${group}.${level}`)}
                </Typography>
              </AccordionSummaryBold>
              {scopeGroups[level][group].map(scope => (
                <AccordionDetails key={scope}>
                  <Typography>{I18n.t(`OAuth.scope.${scope.split(':').join('.')}`)}</Typography>
                </AccordionDetails>
              ))}
            </Accordion>
          ))}
        </div>
      </Grid>
    );
  };

  return (
    <div>
      <Grid spacing={2} container item className={classes.logos}>
        <Grid item>
          <img alt={app.name} src={app.avatar} className={classes.logo} />
        </Grid>
        <Grid item>
          <SyncAlt />
        </Grid>
        <Grid item>
          <img
            alt="Ommnio"
            src="https://web.ommnio.com/ommnio-favicon.svg"
            className={classes.logo}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h2" align="center" gutterBottom>
          {I18n.t('OAuth.App requests permission', { name: app.name })}
        </Typography>
      </Grid>
      <ScopesAccordion level="read" />
      <ScopesAccordion level="write" />
      <Grid spacing={2} container item className={classes.logos}>
        <Grid item>
          <Button onClick={cancel} variant="outlined" color="secondary" className={classes.cancel}>
            {I18n.t('OAuth.Cancel')}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={allow} variant="contained" color="primary" autoFocus>
            {I18n.t('OAuth.Allow')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default OAuthAuthorize;
