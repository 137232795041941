import Box from '@mui/material/Box';
import { useTheme } from '@mui/styles';
import React from 'react';

const styles = {
  root: {
    alignItems: 'center',
    display: {
      xs: 'none',
      md: 'flex',
    },
  },
  logo: mode => ({
    width: '100px',
    filter: mode === 'dark' ? 'invert(1) hue-rotate(180deg)' : 'invert(0)',
  }),
};

const Logo = () => {
  const theme = useTheme();
  return (
    <Box sx={styles.root}>
      <Box component="img" src="/ommnio.svg" alt="Ommnio" sx={styles.logo(theme.palette.mode)} />
    </Box>
  );
};

export default Logo;
