import { Table, TableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import { connect } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';
import removeAccents from 'utils/removeAccents';
import searchEmployees from 'utils/searchEmployees';
import EmployeeRow from './EmployeeRow';
import TableHead from './TableHead';
import Toolbar from './Toolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: 'calc(100%- 100px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  notFound: {
    fontSize: 13,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    paddingTop: '2em',
  },
});

class EmployeesTable extends React.Component {
  state = {
    order: {
      by: 'name',
      isReverse: false,
    },
    filteredEmployees: [],
    page: 0,
    rowsPerPage: 10,
    searchString: '',
  };

  selectAll = enabled => {
    const {
      name,
      employees,
      onChange,
      value,
      formik: { values },
    } = this.props;
    const filtered = this.filterEmployees(employees);
    if (enabled) {
      onChange(name, filtered);
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(_id => value.some(v => v === _id)),
      );
    } else {
      onChange(name, []);
      onChange(`${name}Removed`, [...values[`${name}Removed`], ...value]);
    }
  };

  setRole = (employee, groupRole) => {
    const { name, value, onChange } = this.props;
    const newValue = value.map(m => (m._id === employee._id ? { ...m, groupRole } : m));
    onChange(name, newValue);
  };

  toggleSelect = employee => {
    const {
      name,
      value,
      onChange,
      formik: { values },
    } = this.props;

    if (value.find(m => m._id === employee._id)) {
      onChange(
        name,
        value.filter(m => m._id !== employee._id),
      );
      onChange(`${name}Removed`, [...values[`${name}Removed`], employee]);
    } else {
      onChange(name, [...value, { groupRole: 'member', ...employee }]);
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(e => e._id !== employee._id),
      );
    }
  };

  sort = by => {
    const { order } = this.state;

    this.setState({
      order: { by, isReverse: !order.isReverse },
    });
  };

  search = searchString => {
    const { employees } = this.props;
    const filteredEmployees = searchEmployees(
      searchString,
      employees.map(e => ({
        ...e,
        searchBlob: removeAccents(
          `${e.firstName} ${e.surName} ${e.division} ${e.code} ${e.department} ${e.jobTitle} ${
            e.location
          } ${e.reportsTo} ${e.user?.phone} ${e.tags?.join(' ')} ${e.notes} ${e.teams
            ?.map(t => t.name)
            .join(' ')} ${e.response}`,
        ).toLowerCase(),
      })),
      {},
      { fullDoc: true },
    );

    this.setState({ searchString, filteredEmployees, page: 0 });
  };

  changePage = ({ selected: page }) => {
    this.setState({ page });
  };

  filterEmployees = employees => {
    const { filteredEmployees, searchString } = this.state;
    if (isEmpty(filteredEmployees) && isEmpty(searchString)) return employees;
    if (isEmpty(filteredEmployees) && !isEmpty(searchString)) return [];

    return filteredEmployees;
  };

  sortEmployees = employees => {
    const { order } = this.state;
    const { value } = this.props;
    const getRoleValue = _id => {
      const member = value.find(m => m._id === _id) || {};
      switch (member.groupRole) {
        case 'admin':
          return '3';
        case 'writer':
          return '2';
        case 'member':
          return '1';
        default:
          return '0';
      }
    };
    const sorted = employees.sort((prev, next) => {
      let prevElement = prev[order.by] || '';
      let nextElement = next[order.by] || '';
      if (typeof prevElement.join === 'function') {
        prevElement = prevElement.join(',');
        nextElement = nextElement.join(',');
      }
      if (order.by === 'role') {
        prevElement = getRoleValue(prev._id) + prev.name;
        nextElement = getRoleValue(next._id) + next.name;
      }
      return prevElement.localeCompare(nextElement) || prev.name.localeCompare(next.name);
    });

    return order.isReverse ? sorted.reverse() : sorted;
  };

  paginateEmployees = employees => {
    const { page, rowsPerPage } = this.state;
    return employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  render() {
    const { classes, error, value, employees, teams } = this.props;
    const { order, rowsPerPage, page, searchString } = this.state;
    const selectedCount = value.length;

    const filtered = this.filterEmployees(employees);
    const sorted = this.sortEmployees(filtered);
    const paginated = this.paginateEmployees(sorted);

    const employeesCount = filtered.length;

    return (
      <div className={classes.root}>
        <Toolbar error={error} selected={selectedCount} onSearch={this.search} />
        <Table className={classes.table} aria-labelledby="employeesTable">
          <TableHead
            order={order}
            selected={selectedCount}
            rows={employeesCount}
            onSort={this.sort}
            onSelectAll={this.selectAll}
          />
          <TableBody className={classes.tableBody}>
            {paginated.map(e => (
              <EmployeeRow
                key={e._id}
                employee={e}
                member={value.find(m => m._id === e._id)}
                onSelect={this.toggleSelect}
                onSetRole={this.setRole}
                searchString={searchString}
                teams={teams}
              />
            ))}
          </TableBody>
        </Table>
        <Pagination
          total={employeesCount}
          page={page}
          perPage={rowsPerPage}
          onChange={this.changePage}
        />
        {employeesCount === 0 && searchString ? (
          <div className={classes.notFound}>{I18n.t('ChannelDrawer.EmployeesTable.NotFound')}</div>
        ) : null}
      </div>
    );
  }
}

EmployeesTable.propTypes = {
  classes: object.isRequired,
  name: string.isRequired,
  value: arrayOf(string).isRequired,
  error: string,
  employees: arrayOf(employeeShape).isRequired,
  onChange: func.isRequired,
  formik: object.isRequired,
  teams: arrayOf(object).isRequired,
};
EmployeesTable.defaultProps = {
  error: null,
};

export default withStyles(styles)(connect(EmployeesTable));
