import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import isTeamAdminSelector from 'store/selectors/isTeamAdmin';
import channel from 'store/app/ui/drawers/channel/action';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
  },
  label: {
    color: theme.palette.primary.dark,
  },
}));

const NewChannelButton = () => {
  const classes = useStyles();
  const isTeamAdmin = useSelector(isTeamAdminSelector);
  const openChannelDrawer = useActions(channel.open);

  if (!isTeamAdmin) return null;

  return (
    <Button onClick={openChannelDrawer} classes={classes} color="primary" fullWidth>
      {I18n.t('ChannelsPanel.New channel')}
    </Button>
  );
};

export default memo(
  NewChannelButton,
  (prevProps, nextProps) => prevProps.isTeamAdmin === nextProps.isTeamAdmin,
);
