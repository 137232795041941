import React, { Component } from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, func, number, shape, string, bool } from 'prop-types';
import TitleCells from './TitleCells';
import SelectAllCheckbox from './SelectAllCheckbox';

const styles = theme => ({
  root: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  head: {
    height: 48,
  },
});

class EnhancedTableHead extends Component {
  selectAll = e => {
    const { onSelectAll } = this.props;
    onSelectAll(e.target.checked);
  };

  render() {
    const { classes, rows, selected, order, onSort } = this.props;

    return (
      <TableHead className={classes.root}>
        <TableRow classes={{ head: classes.head }}>
          <TableCell padding="checkbox">
            <SelectAllCheckbox
              rows={rows}
              key="checkbox"
              selected={selected}
              onChange={this.selectAll}
            />
          </TableCell>
          <TitleCells order={order} onSort={onSort} />
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  classes: object.isRequired,
  rows: number.isRequired,
  selected: number.isRequired,
  order: shape({ by: string, isReverse: bool }).isRequired,
  onSort: func.isRequired,
  onSelectAll: func.isRequired,
};

export default withStyles(styles)(EnhancedTableHead);
