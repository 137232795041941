import { Alert, Box, Breadcrumbs, Button, SvgIcon, Toolbar, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import renderFolderCell from 'components/@home/@documents/renderFolderCell';
import FolderDialog from 'components/@home/@documents/SharedDocuments/FolderDialog';
import UploadButton from 'components/@home/@documents/SharedDocuments/UploadButton';
import Loading from 'components/common/Loading';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import FolderPlusIcon from 'mdi-react/FolderPlusIcon';
import { string } from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  useCreateSharedFileMutation,
  useListSharedFilesQuery,
} from 'store/app/entities/SharedFilesSlice';
import isTeamAdminSelector from 'store/selectors/isTeamAdmin';
import renderDateTimeCell from 'utils/renderDateTimeCell';
import renderPermissionsCell from 'utils/renderPermissionsCell';
import useOpenClose from 'utils/useOpenClose';

const SharedDocuments = ({ folder }) => {
  const [newFolderIsOpen, newFolderOpen, newFolderClose] = useOpenClose();
  const [perPage, setPerPage] = useState(0);
  const [sortModel, setSortModel] = useState(undefined);
  const [filterModel, setFilterModel] = useState(undefined);
  const [page, setPage] = useState(0);
  const { id } = useParams();
  const isTeamAdmin = useSelector(isTeamAdminSelector);

  const [createSharedFile, { isLoading: isLoadingCreate }] = useCreateSharedFileMutation();
  const search = filterModel?.quickFilterValues.join(' ');
  const { data: { data } = {}, isLoading } = useListSharedFilesQuery({
    search,
    folder,
    page: page + 1,
    perPage,
    id,
    sortBy: sortModel?.[0]?.field || 'name',
    sort: sortModel?.[0]?.sort || 'asc',
  });

  const location = useLocation();

  const rows = data?.sharedFiles || [];
  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      renderCell: renderFolderCell(
        folder,
        {
          search: [...(location.state?.search || []), location.search],
        },
        'shared',
      ),
      headerName: I18n.t('DocumentsScene.DocumentsTable.Name'),
    },
    {
      field: 'createdAt',
      type: 'string',
      flex: 1,
      editable: false,
      maxWidth: 200,
      renderCell: renderDateTimeCell,
      headerName: I18n.t('DocumentsScene.DocumentsTable.CreatedAt'),
    },
    ...(folder === 'shared'
      ? [
          {
            field: 'permissions',
            flex: 1,
            editable: false,
            sortable: false,
            renderCell: renderPermissionsCell,
            headerName: I18n.t('DocumentsScene.DocumentsTable.SharedWith'),
          },
        ]
      : []),
  ];
  const handleNewFolderSubmit = async name => {
    await createSharedFile({
      kind: 'Folder',
      name,
      parent: data?.parent?._id,
      permissions: data?.parent?.permissions.map(p => ({ team: p.team, role: p.role })) || [],
    });
  };
  return (
    <Grid item sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', maxHeight: '100vh' }}>
      {folder !== 'deleted' && ((!data?.parent && isTeamAdmin) || data?.parent?.isAdmin) && (
        <Toolbar
          sx={{
            minHeight: '0 !important',
            overflow: 'hidden',
            borderBottom: '1px solid',
            borderColor: 'divider',
            '& .MuiButton-root': {
              borderRadius: 0,
              minHeight: 49,
              whiteSpace: 'nowrap',
              '& .MuiCircularProgress-root': {
                marginLeft: 1,
              },
            },
          }}
        >
          <Button variant="text" color="secondary" onClick={newFolderOpen}>
            <SvgIcon
              sx={{
                fontSize: 18,
                marginRight: 1,
                color: 'primary.main',
              }}
            >
              <FolderPlusIcon />
            </SvgIcon>
            {I18n.t('DocumentsScene.New folder')}
            {isLoadingCreate && <Loading size={15} />}
          </Button>
          <UploadButton folder={folder} id={id} />
        </Toolbar>
      )}
      <Box
        sx={{
          padding: 2,
          paddingBottom: 0,
          flex: 1,
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        {data?.parent ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to={{
                pathname: `/home/documents/${folder}/`,
                search: location.state?.search?.[0] || '',
              }}
            >
              <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
                {I18n.t(`DocumentsScene.Folders.${folder}`)}
              </Typography>
            </Link>
            {data.parent.ancestors.map((ancestor, idx) => (
              <Link
                key={ancestor._id}
                underline="hover"
                color="inherit"
                to={{
                  pathname: `/home/documents/${folder}/${ancestor._id}/`,
                  search: location.state?.search?.[idx + 1] || '',
                }}
                state={{ search: location.state?.search?.slice(0, -1) }}
              >
                <Typography variant="h3" sx={{ fontWeight: 'normal' }}>
                  {ancestor.name}
                </Typography>
              </Link>
            ))}
            <Typography variant="h3">{data.parent.name}</Typography>
          </Breadcrumbs>
        ) : (
          <Typography variant="h3"> {I18n.t(`DocumentsScene.Folders.${folder}`)}</Typography>
        )}
        {folder === 'deleted' && (
          <Alert severity="info" sx={{ marginTop: 2, marginBottom: 1 }}>
            {I18n.t('DocumentsScene.Items in the trash')}
          </Alert>
        )}
        <OmmnioDataGrid
          loading={isLoading}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={rows}
          rowCount={data?.totalCount || 0}
          paginationMode="server"
          getRowId={r => r._id}
          sortingMode="server"
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          onPageChange={setPage}
          onPageSizeChange={setPerPage}
          updateHistory
          disableEvenOdd
        />
      </Box>
      <FolderDialog
        edit
        onSubmit={handleNewFolderSubmit}
        onClose={newFolderClose}
        open={newFolderIsOpen}
      />
    </Grid>
  );
};

SharedDocuments.propTypes = {
  folder: string.isRequired,
};

export default SharedDocuments;
