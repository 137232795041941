import { Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import CompanyDrawer from 'components/@admin/@companies/CompanyDrawer';
import ComposedIcon from 'components/common/ComposedIcon';
import DeleteDialog from 'components/common/DeleteDialog';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import AddBoldIcon from 'mdi-react/AddBoldIcon';
import CompanyIcon from 'mdi-react/CompanyIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import EditIcon from 'mdi-react/EditIcon';
import LockIcon from 'mdi-react/LockIcon';
import LockOpenOutlineIcon from 'mdi-react/LockOpenOutlineIcon';
import moment from 'moment';
import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';
import {
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useListCompaniesQuery,
} from 'store/app/entities/CompaniesSlice';
import useOpenClose from 'utils/useOpenClose';

const defaultSortModel = [{ field: 'createdAt', sort: 'desc' }];

const AdminCompaniesScene = () => {
  const [company, setCompany] = useState(undefined);
  const [isDeleteConfirmOpen, openDeleteConfirm, closeDeleteConfirm, { companyToRemove }] =
    useOpenClose();
  const companyIsOpen = !!company;
  const handleCompanyClose = () => {
    setCompany(undefined);
  };
  const [perPage, setPerPage] = useState(0);
  const [sortModel, setSortModel] = useState(defaultSortModel);
  const [filterModel, setFilterModel] = useState(undefined);
  const [page, setPage] = useState(0);
  const search = filterModel?.quickFilterValues.join(' ');
  const { data = {}, isFetching } = useListCompaniesQuery({
    search,
    page: page + 1,
    perPage,
    sortBy: sortModel?.[0]?.field || 'name',
    sort: sortModel?.[0]?.sort || 'asc',
  });
  const [editCompany] = useEditCompanyMutation();
  const [deleteCompany] = useDeleteCompanyMutation();

  const handleLock = (_id, isActive) => () => {
    editCompany({ _id, body: { isActive: isActive ? 1 : 0 } });
  };

  const confirmDelete = () => {
    closeDeleteConfirm();
    deleteCompany(companyToRemove._id);
  };

  const columns = [
    {
      field: 'name',
      flex: 1,
      editable: false,
      headerName: I18n.t('AdminCompaniesScene.DocumentsTable.Name'),
    },

    {
      field: 'createdAt',
      type: 'string',
      width: 140,
      editable: false,
      maxWidth: 200,
      renderCell: params => moment.unix(params.value).format('L'),
      headerName: I18n.t('AdminCompaniesScene.DocumentsTable.CreatedAt'),
    },
    {
      field: 'isActive',
      sortable: false,
      width: 140,
      editable: false,
      renderCell: params => (params.value ? 'Active' : 'Blocked'),
      headerName: I18n.t('AdminCompaniesScene.DocumentsTable.Status'),
    },
  ];

  ['activeEmployees', 'activeChannels'].forEach(field => {
    columns.push({
      field,
      width: 140,
      editable: false,
      sortable: false,
      type: 'number',
      headerName: I18n.t(`AdminCompaniesScene.DocumentsTable.${field}`),
    });
  });

  columns.push({
    field: 'actions',
    flex: 1,
    type: 'actions',
    editable: false,
    sortable: false,
    renderCell: params => (
      <Box sx={{ display: 'flex', gap: 2 }}>
        <IconButton onClick={() => setCompany(params.row)}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleLock(params.row._id, !params.row.isActive)}>
          {params.row.isActive ? <LockIcon /> : <LockOpenOutlineIcon />}
        </IconButton>
        <IconButton
          sx={{ color: 'danger' }}
          onClick={() => {
            openDeleteConfirm({ companyToRemove: params.row });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
    headerName: I18n.t('AdminCompaniesScene.DocumentsTable.Actions'),
  });

  const rows = data?.companies || [];
  return (
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Toolbar
        sx={{
          minHeight: '0 !important',
          overflow: 'hidden',
          boxShadow: 'inset 0 -0.5px 0 0 rgba(2, 2, 3, 0.2);',
          '& .MuiButton-root': {
            borderRadius: 0,
            minHeight: 49,
            whiteSpace: 'nowrap',
            '& .MuiCircularProgress-root': {
              marginLeft: 1,
            },
          },
        }}
      >
        <Button variant="text" color="secondary" onClick={() => setCompany({})}>
          <ComposedIcon size={18} icon={<CompanyIcon />} extraIcon={<AddBoldIcon />} />

          {I18n.t('SuperAdminMenu.New company')}
        </Button>
      </Toolbar>
      <Box sx={{ padding: 2, paddingBottom: 5, flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h3">{I18n.t('SuperAdminMenu.companies')}</Typography>
        <OmmnioDataGrid
          loading={isFetching}
          sx={{ marginTop: 2 }}
          columns={columns}
          rows={rows}
          rowCount={data?.totalCount || 0}
          paginationMode="server"
          getRowId={r => r._id}
          sortingMode="server"
          filterMode="server"
          filterModel={filterModel}
          onFilterModelChange={setFilterModel}
          onSortModelChange={setSortModel}
          onPageChange={setPage}
          onPageSizeChange={setPerPage}
          updateHistory
          disableEvenOdd
          defaultSortModel={defaultSortModel}
        />
        <CompanyDrawer onClose={handleCompanyClose} isOpen={companyIsOpen} company={company} />
      </Box>
      <DeleteDialog
        message={I18n.t('AdminCompaniesScene.ConfirmDelete', {
          name: companyToRemove?.name,
        })}
        typeBack={companyToRemove?.name}
        isOpen={isDeleteConfirmOpen}
        onConfirm={confirmDelete}
        onClose={closeDeleteConfirm}
      />
    </Box>
  );
};

export default AdminCompaniesScene;
