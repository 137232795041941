/* eslint-disable no-console */
import withStyles from '@mui/styles/withStyles';
import KeyboardArrowRightIcon from 'mdi-react/TickIcon';
import { func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import Item from '../Item';
import SelectTitle from '../SelectTitle';

const styles = theme => ({
  root: {
    paddingRight: '20px',
  },
  check: {
    color: theme.palette.primary.main,
  },
});

const MobileNotifications = props => {
  const {
    classes,
    settings: { dnd, isOpenCommunication },
    onChange,
  } = props;

  let notificationsCheckedValue = 'Restricted';
  if (dnd) {
    notificationsCheckedValue = 'Never';
  } else if (isOpenCommunication) {
    notificationsCheckedValue = 'Always';
  }
  const radioProps = ['Never', 'Always', 'Restricted'].map(value => ({
    label: I18n.t(`SettingsDrawer.Notifications_${value}`),
    value,
  }));

  return (
    <div className={classes.root}>
      <SelectTitle headline={I18n.t('SettingsDrawer.Mobile_Notifications')} />
      {radioProps.map(obj => (
        <Item
          key={obj.label}
          headline={obj.label}
          checkbox={
            notificationsCheckedValue === obj.value ? (
              <KeyboardArrowRightIcon className={classes.check} />
            ) : null
          }
          onClick={() => onChange(obj.value)}
        />
      ))}
    </div>
  );
};

MobileNotifications.propTypes = {
  classes: object.isRequired,
  settings: object.isRequired,
  onChange: func.isRequired,
};

export default withStyles(styles)(MobileNotifications);
