import { Badge, SvgIcon, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckIcon from 'assets/icons/check.svg';
import InviteIcon from 'assets/icons/invite.svg';
import StarIcon from 'assets/icons/star.svg';
import capitalize from 'lodash/capitalize';
import { any, bool, func, node, object, oneOf } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  badge: {
    backgroundColor: 'transparent',
    display: 'inherit',
  },
  iconPending: {
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
  iconAdmin: {
    borderRadius: '50%',
    backgroundColor: theme.palette.admin,
    color: theme.palette.white,
    fontSize: 16,
    padding: 3,
  },
  iconActive: {
    borderRadius: '50%',
    backgroundColor: theme.palette.ok,
    color: theme.palette.white,
    fontSize: 16,
  },
}));

const icons = {
  active: CheckIcon,
  admin: StarIcon,
  pending: InviteIcon,
};

const BadgeIcon = ({ status, classes }) => {
  const IconComponent = icons[status];
  if (!IconComponent) {
    return null;
  }
  return (
    <SvgIcon className={classes[`icon${capitalize(status)}`]}>
      <IconComponent />
    </SvgIcon>
  );
};

BadgeIcon.propTypes = {
  classes: object.isRequired,
  status: oneOf(['pending', 'active', 'admin']).isRequired,
};

const AvatarBadge = ({ children, enable, className, status, onClick }) => {
  const classes = useStyles();
  return enable ? (
    <Tooltip className={className} placement="top" title={I18n.t(`AvatarBadge.${status}`)}>
      <Badge
        onClick={onClick}
        badgeContent={<BadgeIcon status={status} classes={classes} />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        overlap="circular"
        color="primary"
        classes={{ badge: classes.badge }}
      >
        {children}
      </Badge>
    </Tooltip>
  ) : (
    <div className={className}>{children}</div>
  );
};

AvatarBadge.propTypes = {
  onClick: func,
  children: node.isRequired,
  enable: bool.isRequired,
  className: any,
  status: oneOf(['pending', 'active', 'admin']),
};

AvatarBadge.defaultProps = {
  onClick: () => {},
  className: null,
  status: 'active',
};

export default memo(AvatarBadge);
