import { Button, Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import CloseIcon from 'mdi-react/CloseIcon';
import moment from 'moment';
import { array, bool, func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    flex: '1 0 auto',
    marginBottom: 25,
    alignItems: 'baseline',
  },
  shift: {
    marginBottom: 5,
  },
  addShift: {
    minHeight: 0,
    padding: 0,
  },
  heading: {
    verticalAlign: 'baseline',
    height: '22px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    letterSpacing: '-0.5px',
    lineHeight: 1.47,
    marginRight: '20px',
    textTransform: 'capitalize',
  },
  textField: {
    verticalAlign: 'baseline',
  },
  textFieldLoading: {
    '& input': {
      color: 'transparent',
    },
  },
  separator: {
    verticalAlign: 'baseline',
    padding: '0 10px',
  },
  placeholder: {
    verticalAlign: 'baseline',
    marginBottom: 5,
  },
  hidden: {
    visibility: 'hidden',
  },
  label: {
    color: theme.palette.primary.main,
  },
});

const WorkingHours = ({ shifts, day, classes, onChange, loading }) => {
  const getTimeStr = seconds => moment.unix(seconds).utc().format('HH:mm');

  const removeShift = idx => () => {
    const newValue = [...shifts];
    newValue.splice(idx, 1);
    onChange(newValue);
  };

  const addShift = () => {
    const newValue = [...shifts];
    newValue.push({ from: 32400, to: 61200 });
    onChange(newValue);
  };

  const handleTimeChange = (which, idx, event) => {
    const [hours, minutes] = event.target.value.split(':');
    const value = hours * 3600 + minutes * 60;
    const newValue = [...shifts];
    newValue[idx][which] = value;
    onChange(newValue);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={2} className={classes.heading}>
        {day}
      </Grid>
      <Grid item className={classes.shifts}>
        <div className={classes.shift}>
          {shifts.map &&
            shifts.map(({ from, to }, idx) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={loading.toString() + idx} className={classes.placeholder}>
                <TextField
                  type="time"
                  defaultValue={getTimeStr(from)}
                  className={loading ? classes.textFieldLoading : classes.textField}
                  onChange={event => handleTimeChange('from', idx, event)}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                  disabled={loading}
                />
                <span className={classes.separator}>-</span>
                <TextField
                  type="time"
                  value={getTimeStr(to)}
                  className={loading ? classes.textFieldLoading : classes.textField}
                  onChange={event => handleTimeChange('to', idx, event)}
                  inputProps={{
                    step: 60, // 1 min
                  }}
                  disabled={loading}
                />
                <Button onClick={removeShift(idx)}>
                  <CloseIcon style={{ width: 15 }} />
                </Button>
              </div>
            ))}
        </div>
        <Button className={classes.addShift} onClick={addShift}>
          <Typography className={classes.label} variant="caption">
            {I18n.t('EmployeeDrawer.Add shift')}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

WorkingHours.propTypes = {
  shifts: array.isRequired,
  classes: object.isRequired,
  day: string.isRequired,
  onChange: func.isRequired,
  loading: bool,
};

WorkingHours.defaultProps = {
  loading: false,
};

export default withStyles(styles)(WorkingHours);
