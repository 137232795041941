import { useFeatureToggle } from '@flopflip/react-redux';
import Settings from '@mui/icons-material/Settings';
import { Button, Toolbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChartIcon from 'assets/icons/chart.svg';
import MessagesIcon from 'assets/icons/messages.svg';
import PeopleIcon from 'assets/icons/people.svg';
import AppsIcon from 'mdi-react/AppsIcon';
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon';
import FileIcon from 'mdi-react/FileIcon';
import { bool } from 'prop-types';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import { LAUNCHER_APP_ID } from 'services/constants';
import Cookie from 'services/Cookie';
import authActions from 'store/app/auth/action';
import { useGetQuery } from 'store/app/entities/AppDataSlice';
import authCompanySelector from 'store/selectors/authCompany';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import isTeamAdminSelector from 'store/selectors/isTeamAdmin';
import useActions from 'utils/useActions';
import CompaniesDropdown from './CompaniesDropdown';
import HeaderTab from './HeaderTab';
import HeaderTabs from './HeaderTabs';
import Logo from './Logo';
import MenuIcon from './MenuIcon';
import Separator from './Separator';
import UserMenu from './UserMenu';

const TIME_TRACKING_APP = '5fe8c4103beba1318a4cfb66';

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.ultraDark,
    backgroundColor: theme.palette.background.paper,
    boxShadow: `inset 0 -1px 0 0 ${theme.palette.divider}`,
    '@media print': {
      display: 'none',
    },
  },
  toolbar: {
    justifyContent: 'center',
  },
  leftItems: {
    display: 'flex',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
  },
  settings: {
    color: theme.palette.primary.main,
    display: 'flex',
    padding: 12,
  },
}));

const Header = ({ hideNavigation }) => {
  const classes = useStyles();
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const isTeamAdmin = useSelector(isTeamAdminSelector);
  const authCompany = useSelector(authCompanySelector);
  const flushUserData = useActions(authActions.flushUserData);
  const areDocumentsEnabled = useFeatureToggle('documents');
  const locale = useSelector(state => state.i18n.locale);
  const isLauncherAppInstalled = !!authCompany?.apps?.includes(LAUNCHER_APP_ID);
  const { data } = useGetQuery(
    { appId: LAUNCHER_APP_ID, include: 'launcher-links' },
    { skip: !isLauncherAppInstalled },
  );
  const impersonate = useMemo(() => Cookie.get('impersonate'), []);
  const handleUnImpersonate = useCallback(() => {
    Cookie.set('impersonate', '');
    flushUserData();
    setTimeout(() => {
      window.location = '/home/messages';
    }, 300);
  }, [flushUserData]);
  return (
    <header className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftItems}>
          <MenuIcon />
          <Logo />
          <Separator />
          <CompaniesDropdown />
        </div>
        {!hideNavigation ? (
          <HeaderTabs>
            <HeaderTab value="/home/messages" icon={<MessagesIcon />}>
              {I18n.t('Header.tabs.Messages')}
            </HeaderTab>
            <HeaderTab value="/home/people" visible={isTeamAdmin} icon={<PeopleIcon />}>
              {I18n.t('Header.tabs.People')}
            </HeaderTab>
            <HeaderTab
              value="/home/time-tracking"
              visible={isTeamAdmin && authCompany.apps?.includes(TIME_TRACKING_APP)}
              icon={<ClockOutlineIcon />}
            >
              {I18n.t('Header.tabs.TimeTracking')}
            </HeaderTab>
            <HeaderTab value="/home/analytics" visible={isTeamAdmin} icon={<ChartIcon />}>
              {I18n.t('Header.tabs.Analytics')}
            </HeaderTab>
            <HeaderTab
              value="/home/documents"
              icon={<FileIcon />}
              visible={areDocumentsEnabled || isSuperAdmin}
            >
              {I18n.t('Header.tabs.Documents')}
            </HeaderTab>
            <HeaderTab
              value="/home/launcher"
              icon={<AppsIcon />}
              visible={isLauncherAppInstalled && data?.active}
              data={data}
            >
              {data?.name?.[locale] || I18n.t('Header.tabs.Launcher')}
            </HeaderTab>
          </HeaderTabs>
        ) : null}
        <div className={classes.rightItems}>
          {!hideNavigation && isTeamAdmin && (
            <Link to="/home/settings/general" className={classes.settings}>
              <Settings />
            </Link>
          )}
          {!!isSuperAdmin && (
            <Button variant="text" sx={{ marginRight: 2 }}>
              <Link to="admin">Admin</Link>
            </Button>
          )}
          {impersonate && (
            <Button variant="text" sx={{ marginRight: 2 }} onClick={handleUnImpersonate}>
              <Link to="admin">Back to Superadmin</Link>
            </Button>
          )}
          <UserMenu disabled={hideNavigation} />
        </div>
      </Toolbar>
    </header>
  );
};

Header.propTypes = {
  hideNavigation: bool,
};

Header.defaultProps = {
  hideNavigation: false,
};

export default memo(
  Header,
  (prevProps, nextProps) => prevProps.hideNavigation === nextProps.hideNavigation,
);
