import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import axios from 'axios';
import DownloadIcon from 'mdi-react/DownloadIcon';
import { any, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  icon: {
    fontSize: 18,
    marginRight: 10,
    color: theme.palette.primary.main,
  },
});

const DownloadButton = ({ src, name, className, mimeType, classes }) => {
  const handleClick = () => {
    axios({
      url: src,
      method: 'GET',
      responseType: 'blob', // important
      withCredentials: true,
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <Button variant="outlined" className={className} onClick={handleClick}>
      <SvgIcon className={classes.icon}>
        <DownloadIcon />
      </SvgIcon>
      {I18n.t('Documents.Download')}
    </Button>
  );
};

DownloadButton.propTypes = {
  src: string.isRequired,
  name: string,
  mimeType: string.isRequired,
  color: string,
  variant: string,
  className: any,
  classes: any.isRequired,
};

DownloadButton.defaultProps = {
  name: 'file.pdf',
  color: 'primary',
  variant: 'contained',
  className: null,
};

export default withStyles(styles)(DownloadButton);
