import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import memberDrawer from 'store/app/ui/drawers/employeeList/action';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  channel: currentChannel(state),
  message: state.drawers.employeeList.message,
  title: state.drawers.employeeList.title || I18n.t('ChannelDrawer.Members'),
  isOpen: state.drawers.employeeList.isOpen,
  isCurrentChannel: !!state.elements.ChannelsPanel.currentId,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    drawers: {
      employeeList: bindActionCreators(memberDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
