import TableField from 'components/controls/TableField';
import { func, object } from 'prop-types';
import React from 'react';

const FormikTableField = ({ field, form, ...props }) => {
  const { name, onBlur } = field;
  const { errors, values, submitCount, setFieldValue } = form;

  const handleChange = (fieldName, fieldValue, fieldRemovedValue) => {
    setFieldValue(fieldName, fieldValue);
    if (typeof props.onChange === 'function') {
      props.onChange(form, fieldName, fieldValue, fieldRemovedValue);
    }
  };

  return (
    <TableField
      fullWidth
      {...props}
      onChange={handleChange}
      onBlur={onBlur}
      value={values[name]}
      name={name}
      error={!!(errors[name] && submitCount > 0)}
      helperText={submitCount > 0 && errors[name]}
    />
  );
};

FormikTableField.propTypes = {
  field: object.isRequired,
  form: object.isRequired,
  onChange: func,
};

FormikTableField.defaultProps = {
  onChange: null,
};

export default FormikTableField;
