/* eslint-disable max-len */
import React from 'react';
import { bool, object, number } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '15px',
  },
  subheading: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    marginBottom: '8px',
  },
  description: {
    fontSize: '14px',
  },
  readDescription: {
    color: theme.palette.primary.dark,
  },
  unreadDescription: {
    color: '#8b98ab',
    textAlign: 'center',
    display: 'block',
  },
});

const Information = ({ classes, confirmedAt, isDigitalSignature }) => (
  <div className={classes.root}>
    <span className={classes.subheading}>
      {I18n.t(
        isDigitalSignature
          ? 'AcknowledgementReadModal.DIGITAL SIGNATURE'
          : 'AcknowledgementReadModal.MANDATORY READ',
      )}
    </span>
    <span
      className={classNames({
        [classes.description]: true,
        [classes.readDescription]: confirmedAt,
        [classes.unreadDescription]: !confirmedAt,
      })}
    >
      {confirmedAt
        ? `${I18n.t(
            isDigitalSignature
              ? 'AcknowledgementReadModal.Digital signature made on'
              : 'AcknowledgementReadModal.Acknowledge made on',
          )} ${moment.unix(confirmedAt).format('LL')}`
        : I18n.t(
            isDigitalSignature
              ? 'AcknowledgementReadModal.Digital signature description'
              : 'AcknowledgementReadModal.Please, read what follows and confirm reception by clicking the button below',
          )}
    </span>
  </div>
);

Information.propTypes = {
  classes: object.isRequired,
  confirmedAt: number,
  isDigitalSignature: bool,
};

Information.defaultProps = {
  confirmedAt: null,
  isDigitalSignature: false,
};

export default withStyles(styles)(Information);
