import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import timeTrackerLog from 'store/app/entities/timeTrackerLog/action';
import authCompany from 'store/selectors/authCompany';
import populateEmployees from 'store/selectors/populateEmployees';
import populateTimeTrackerLog from 'store/selectors/populateTimeTrackerLog';

const mapStateToProps = state => ({
  company: authCompany(state),
  timeTrackerLog: populateTimeTrackerLog(state),
  employees: populateEmployees(state),
  locale: state.i18n.locale,
});

const mapDispatchToProps = dispatch => ({
  actions: {
    timeTrackerLog: bindActionCreators(timeTrackerLog, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
