import React, { Component } from 'react';
import { object, bool } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import messageShape from 'shapes/message';
import connector from './connector';

const styles = theme => ({
  root: {
    minWidth: '110px',
    color: theme.palette.primary.ultraDark,
  },
});

class MonitorButton extends Component {
  openAnalyticsDrawer = () => {
    const { actions, message } = this.props;
    if (message.form) {
      actions.drawers.formAnalytics.open({ message });
    } else {
      actions.drawers.analytics.open(message);
    }
  };

  render() {
    const {
      classes,
      isCompanyAdmin,
      isTeamAdmin,
      message: { formVisibleAll, form },
      channel: { isAdmin, isDirect },
    } = this.props;
    if (!formVisibleAll && !isCompanyAdmin && !(isTeamAdmin && isDirect) && !isAdmin) return null;

    return (
      <Button
        variant="outlined"
        color="secondary"
        className={classes.root}
        onClick={this.openAnalyticsDrawer}
      >
        {I18n.t(`AcknowledgementMessage.${form ? 'Results' : 'Monitor'}`)}
      </Button>
    );
  }
}

MonitorButton.propTypes = {
  message: messageShape.isRequired,
  classes: object.isRequired,
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  actions: object.isRequired,
  channel: object.isRequired,
};

export default withStyles(styles)(connector(MonitorButton));
