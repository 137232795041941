import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import AddNewModal from 'components/@whistleblower-admin/pages/Portals/AddNewModal';
import PortalConfig from 'components/@whistleblower-admin/pages/Portals/PortalConfig';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import Loading from 'components/common/Loading';
import PlusIcon from 'mdi-react/PlusIcon';
import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetWbPortalsQuery } from 'store/app/entities/WbSlice';
import useOpenClose from 'utils/useOpenClose';

const Portals = () => {
  const canEdit = useCanEdit('portals');
  const [isAddNewOpen, openAddNew, closeAddNew] = useOpenClose();
  const { data, isLoading } = useGetWbPortalsQuery();
  const navigate = useNavigate();
  const { slug } = useParams();
  useEffect(() => {
    if (data?.[0] && !slug) {
      navigate(`/wb-admin/portals/${data[0].slug}`);
    }
  }, [data, slug]);
  if (isLoading) return <Loading />;
  if (!slug) return null;
  const portal = data.find(d => d.slug === slug);
  if (!portal) {
    navigate('/wb-admin/portals', { replace: true });
  }
  const handleTabChange = (event, newValue) => {
    navigate(`/wb-admin/portals/${newValue}`);
  };
  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: 3 }}>
        <Typography variant="h2">{I18n.t('WhistleblowerAdmin.Sections.portals')}</Typography>
        {canEdit ? (
          <Button variant="contained" startIcon={<PlusIcon size={16} />} onClick={openAddNew}>
            {I18n.t('WhistleblowerAdmin.AddNew')}
          </Button>
        ) : null}
      </Box>
      <Tabs value={slug} onChange={handleTabChange} sx={{ mr: 3, pl: 3 }}>
        {data.map(p => (
          <Tab value={p.slug} key={p.slug} label={p.name} />
        ))}
      </Tabs>
      <PortalConfig key={portal?._id} data={portal} />
      <AddNewModal isOpen={isAddNewOpen} close={closeAddNew} />
    </Box>
  );
};

export default Portals;
