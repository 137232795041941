import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import CloseButton from 'components/@home/drawers/CloseButton';
import DeleteDialog from 'components/common/DeleteDialog';
import LineClampTooltip from 'components/common/LineClampTooltip';
import OmmnioDataGrid from 'components/common/OmmnioDataGrid';
import CheckIcon from 'mdi-react/CheckIcon';
import RemoveIcon from 'mdi-react/RemoveIcon';
import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ReactTimeAgo from 'react-time-ago';
import authActions from 'store/app/auth/action';
import useActions from 'utils/useActions';
import useOpenClose from 'utils/useOpenClose';

const SessionsDrawer = (props, ref) => {
  const locale = useSelector(state => state.i18n.locale);
  const auth = useActions(authActions);
  const [employee, setEmployee] = useState();
  const [sessions, setSessions] = useState(null);
  useEffect(() => {
    if (employee?.user && !sessions) {
      auth.listSessions(employee.user._id).then(({ value: { data } }) => {
        if (data) {
          setSessions(data.tokens);
        }
      });
    }
  }, [auth, employee, sessions]);
  const close = () => {
    setSessions(null);
    setEmployee(null);
  };

  const isOpen = !!employee;

  useImperativeHandle(ref, () => ({ open: setEmployee, close }));

  const renderTimeAgo = params => (
    <LineClampTooltip lines={1} title={moment(params.value).toLocaleString()}>
      <ReactTimeAgo date={new Date(params.value)} locale={locale} />
    </LineClampTooltip>
  );

  const [isConfirmOpen, openConfirm, closeConfirm] = useOpenClose();
  const [sessionToRemove, setSessionToRemove] = useState(null);

  const handleClear = () => {
    setSessionToRemove(null);
    openConfirm();
  };
  const handleRemoveSession = r => {
    setSessionToRemove(r._id);
    openConfirm();
  };

  const confirmDelete = () => {
    closeConfirm();
    if (employee?.user) {
      if (sessionToRemove) {
        auth.deleteSession(employee.user?._id, sessionToRemove);
      } else {
        auth.deleteSessions(employee.user?._id);
      }
      setSessions(null);
    }
  };

  const columns = [
    {
      field: '_id',
    },
    {
      field: 'createdAt',
      type: 'string',
      renderCell: renderTimeAgo,
    },
    {
      field: 'updatedAt',
      type: 'string',
      renderCell: renderTimeAgo,
    },
    {
      field: 'expiresAt',
      type: 'string',
      renderCell: renderTimeAgo,
    },
    {
      field: 'userIp',
    },
    {
      field: 'fireBaseToken',
      renderCell: params => (params.value ? <CheckIcon /> : null),
    },
    {
      field: 'deviceName',
      renderCell: params => <LineClampTooltip>{params.value}</LineClampTooltip>,
    },
    {
      field: 'userAgent',
      flex: 1,
      renderCell: params => <LineClampTooltip>{params.value}</LineClampTooltip>,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 1,
      renderCell: params => (
        <IconButton onClick={() => handleRemoveSession(params.row)}>
          <RemoveIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Drawer anchor="right" open={isOpen} onClose={close}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: { xs: '100%', md: 1100 },
        }}
      >
        <Box sx={{ paddingTop: 2.5, paddingLeft: 2.5 }}>
          <CloseButton onClick={close} />
        </Box>
        <Box
          sx={{ display: 'flex', flex: 1, minWidth: 500, flexDirection: 'column', padding: 2.5 }}
        >
          <Box sx={{ display: 'flex', mb: 1 }}>
            <Typography sx={{ flex: 1 }} variant="h6" component="h1">
              {I18n.t(`SessionsDrawer.Sessions`)}
            </Typography>
            <Button onClick={handleClear} variant="contained">
              {I18n.t(`SessionsDrawer.Clear`)}
            </Button>
          </Box>
          <OmmnioDataGrid
            sx={{
              '& .MuiDataGrid-cell': {
                whiteSpace: 'normal !important',
              },
            }}
            getRowId={r => r._id}
            columns={columns}
            rows={sessions || []}
            defaultSortModel={[{ field: 'updatedAt', sort: 'desc' }]}
          />
        </Box>
      </Box>
      <DeleteDialog
        message={
          sessionToRemove
            ? I18n.t('SessionsDrawer.ConfirmDelete', {
                id: sessionToRemove,
              })
            : I18n.t('SessionsDrawer.ConfirmClear', {
                name: `${employee?.firstName} ${employee?.surName}`,
              })
        }
        isOpen={isConfirmOpen}
        onConfirm={confirmDelete}
        onClose={closeConfirm}
        confirmText={I18n.t(`SessionsDrawer.${sessionToRemove ? 'Delete' : 'Clear'}`)}
      />
    </Drawer>
  );
};

export default forwardRef(SessionsDrawer);
