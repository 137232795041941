/* eslint-disable react/no-access-state-in-setstate */
import { Table, TableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import { connect } from 'formik';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import arrayToObject from 'utils/arrayToObject';
import searchEmployees from 'utils/searchEmployees';
import EmployeeRow from './EmployeeRow';
import TableHead from './TableHead';
import Toolbar from './Toolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: 'calc(100%- 100px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  notFound: {
    fontSize: 13,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    paddingTop: '2em',
  },
});

class EmployeesTable extends React.Component {
  state = {
    order: {
      by: null,
      isReverse: false,
    },
    filteredIds: [],
    page: 0,
    rowsPerPage: 10,
    searchString: '',
  };

  componentDidMount() {
    this.search('');
    this.setState({
      order: { by: this.props.form.anonymous ? 'respondedAt' : 'name', isReverse: false },
    });
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(prevProps.question?._id, this.props.question?._id) ||
      !isEqual(prevProps.answer, this.props.answer) ||
      !isEqual(prevProps.answersByUser, this.props.answersByUser)
    ) {
      this.search(this.state.searchString);
    }
  }

  sort = by => {
    this.setState(prevState => ({
      order: {
        by,
        isReverse: prevState.order.by === by ? !prevState.order.isReverse : false,
      },
    }));
  };

  search = searchString => {
    const { answer = 'All', answersByUser, question } = this.props;
    const employees = Object.values(answersByUser).map(e => ({
      ...e.employee,
      ...e.answers[question._id],
    }));
    const filteredIds = searchEmployees(searchString, employees, { answer });

    this.setState({ searchString, filteredIds, page: 0 });
  };

  changePage = ({ selected: page }) => {
    this.setState({ page });
  };

  changeRowsPerPage = e => {
    this.setState({ rowsPerPage: e.target.value });
  };

  filterEmployees = (employees, ans) => {
    const { filteredIds, searchString } = this.state;
    if (isEmpty(filteredIds) && isEmpty(searchString) && ans === 'All') return employees;
    if (isEmpty(filteredIds) && !isEmpty(searchString) && ans !== 'All') return [];

    return filteredIds.map(_id => arrayToObject(employees)[_id]).filter(e => e?.response);
  };

  sortEmployees = employees => {
    const { order } = this.state;
    const sorted = employees.sort((prev, next) => {
      let prevElement = prev[order.by] || '';
      let nextElement = next[order.by] || '';
      if (order.by === 'respondedAt') {
        return moment(prevElement).diff(moment(nextElement));
      }
      if (typeof prevElement.join === 'function') {
        prevElement = prevElement.join(',');
        nextElement = nextElement.join(',');
      }
      return prevElement?.localeCompare(nextElement) || prev.name?.localeCompare(next?.name);
    });

    return order.isReverse ? sorted.reverse() : sorted;
  };

  paginateEmployees = employees => {
    const { page, rowsPerPage } = this.state;
    return employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  exportResponses = () => {
    const { form, answersByUser } = this.props;
    const showName = !form.anonymous;
    import('xlsx').then(XLSX => {
      const data = [['respondedAt']];
      form.questions.forEach(q => {
        data[0].push(q.title);
      });
      if (showName) {
        data[0].unshift('givenName', 'familyName', 'code');
      }
      Object.values(answersByUser).forEach(({ employee, answers }) => {
        const item = [];
        let maxRespondedAt = new Date(0).valueOf();
        form.questions.forEach(q => {
          const a = answers[q._id];
          const response = isArray(a.response)
            ? a.response.map(({ label }) => label).join(', ')
            : a.response;
          item.push(response);
          const respondedAt = new Date(a.respondedAt).valueOf();
          if (respondedAt > maxRespondedAt) {
            maxRespondedAt = respondedAt;
          }
        });
        item.unshift(new Date(maxRespondedAt));
        if (showName) {
          item.unshift(employee.firstName, employee.surName, employee.code);
        }
        data.push(item);
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, 'Logs');
      const now = moment();
      XLSX.writeFile(wb, `${now.format('YYYY_MM_DD')}_${form.title}.xlsx`, {
        bookType: 'xlsx',
        type: 'array',
      });
    });
  };

  render() {
    const { classes, error, answer, form, answersByUser, question } = this.props;
    const { order, rowsPerPage, page } = this.state;
    const showName = !form.anonymous;
    const employees = Object.values(answersByUser)
      .filter(e => e.answers[question._id])
      .map(e => ({
        ...e.employee,
        ...e.answers[question._id],
      }));
    const filtered = this.filterEmployees(employees, answer);
    const sorted = this.sortEmployees(filtered);
    const paginated = this.paginateEmployees(sorted);

    const employeesCount = filtered.length;

    return (
      <div className={classes.root}>
        <Toolbar error={error} onSearch={this.search} onExport={this.exportResponses} />
        <Table className={classes.table} aria-labelledby="employeesTable">
          <TableHead
            order={order}
            rows={employeesCount}
            onSort={this.sort}
            onSelectAll={this.selectAll}
            showName={showName}
          />
          <TableBody className={classes.tableBody}>
            {paginated.map(e => (
              <EmployeeRow showName={showName} key={e._id} employee={e} />
            ))}
          </TableBody>
        </Table>
        <Pagination
          total={employeesCount}
          page={page}
          perPage={rowsPerPage}
          onChange={this.changePage}
        />
        {employeesCount === 0 ? (
          <div className={classes.notFound}>
            {I18n.t('FormAnalyticsDrawer.EmployeesTable.NotFound')}
          </div>
        ) : null}
      </div>
    );
  }
}

EmployeesTable.propTypes = {
  classes: object.isRequired,
  error: string,
  answer: string,
  question: string,
  form: object.isRequired,
  answersByUser: object.isRequired,
};
EmployeesTable.defaultProps = {
  error: null,
  answer: null,
};

export default withStyles(styles)(connect(EmployeesTable));
