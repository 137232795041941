import { I18n } from 'react-redux-i18n';

export const SingleChoice = 'SingleChoice';
export const MultipleChoice = 'MultipleChoice';
export const ImageUpload = 'ImageUpload';
export const FileUpload = 'FileUpload';
export const Confirm = 'Confirm';
export const Text = 'Text';

const questionTypes = [SingleChoice, MultipleChoice, ImageUpload, FileUpload, Confirm, Text];

export const confirmOptions = [
  ['Accept', () => I18n.t('FillFormDrawer.I Accept')],
  ['Reject', () => I18n.t('FillFormDrawer.I do not Accept')],
];

export const hasOptions = type => [SingleChoice, MultipleChoice].includes(type);

export default questionTypes;
