/* eslint-disable react/destructuring-assignment,jsx-a11y/anchor-is-valid */
import { Button, SvgIcon } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import DownloadIcon from 'assets/icons/download_icon.svg';
import Next from 'assets/icons/next.svg';
import QrCodeIcon from 'assets/icons/qrCode_icon.svg';
import AppleDownload from 'assets/images/apple_download.svg';
import GoogleDownload from 'assets/images/google_download.svg';
import classNames from 'classnames';
import android from 'is-android';
import iOS from 'is-ios';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: 500,
    margin: '0 auto',
    minHeight: '100vh',
    padding: '20px 20px',
    textAlign: 'center',
  },

  Logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 67,
  },
  LogoImg: {
    display: 'block',
    height: 52,
    filter: theme.palette.mode === 'dark' ? 'invert(1) hue-rotate(180deg)' : 'invert(0)',
  },
  RedirectHeading: {
    fontSize: 28,
    fontWeight: 'bold',
    color: theme.palette.primary.ultraDark,
    letterSpacing: -0.7,
    marginTop: 0,
    marginBottom: 21,
  },
  AppBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  AppBtnsLink: {
    display: 'block',
  },
  AppBtnsLinkIcon: {
    display: 'block',
    height: 42,
    width: '100%',
  },
  NextBlock: {},
  NextBlockText: {
    fontSize: 20,
    margin: 0,
    marginBottom: 14,
    fontWeight: 'bold',
    lineHeight: 1.3,
    letterSpacing: -0.5,
    color: theme.palette.primary.ultraDark,
  },
  NextBlockBtns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  NextBlockBtnsItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 160,
    height: 160,
    padding: '38px 20px 0',
    backgroundColor: theme.palette.secondary.ultraLight,
    border: '1px solid transparent',
    borderRadius: 8,
  },
  NextBlockBtnsItemIcon: {
    display: 'block',
    width: 32,
    height: 32,
    marginBottom: 14,
  },
  NextBlockBtnsItemText: {
    fontSize: 15,
    lineHeight: 1.47,
    letterSpacing: -0.5,
    textAlign: 'center',
    color: theme.palette.black,
    margin: 0,
  },
  picture: {
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    margin: '1em 0 0.5em 0',
  },
  description: {
    color: '#8b98ab',
    fontWeight: 'normal',
    marginBottom: '2em',
  },
  icon: {
    width: 16,
    height: 16,
  },
  link: {
    placeSelf: 'center',
  },
  arrowButton: {
    '& :first-child': {
      marginRight: '0.25em',
    },
  },
  enterButton: {
    padding: '0 3em',
    marginBottom: '4em',
    '& *': {
      verticalAlign: 'center',
    },
  },
}));

const GOOGLE_PLAY_URL = 'itms-apps://play.google.com/store/apps/details?id=com.ommnio';
const APPLE_STORE_URL = 'https://itunes.apple.com/es/app/ommnio/id1481362031?mt=8';

const InviteScene = () => {
  const location = useLocation();
  const navigate = href => {
    if (window) {
      window.location.href = href;
    }
  };
  useEffect(() => {
    const staging = window?.location.hostname.match(/local/) ? '.staging' : '';
    const token = location.pathname.replace('/invite/', '').trim();
    if (!isEmpty(token)) {
      if (android) {
        navigate(
          `intent://ommnio/?token=${token}#Intent;scheme=ommnio;package=com.ommnio${staging};end`,
        );
        setTimeout(() => {
          navigate(GOOGLE_PLAY_URL);
        }, 250);
      } else if (iOS) {
        navigate(`ommnio://${token}`);
        setTimeout(() => {
          navigate(APPLE_STORE_URL);
        }, 250);
      }
    }
  });

  const token = location.pathname.replace(/\/invite\/?/, '').trim();

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.Logo}>
        <img src="/ommnio.svg" className={classes.LogoImg} alt="Ommnio" />
      </div>
      {iOS || android ? (
        <h3 className={classes.RedirectHeading}>
          <Translate value="InviteScene.Redirecting to" />
        </h3>
      ) : (
        <section>
          <Typography className={classes.title} gutterBottom variant="h5">
            {I18n.t('InviteScene.Title')}
          </Typography>
          <Typography variant="body1" className={classes.description}>
            {I18n.t('InviteScene.Description')}
          </Typography>
        </section>
      )}
      <div className={classes.AppBtns}>
        {android ? (
          <a href={GOOGLE_PLAY_URL} className={classes.AppBtnsLink}>
            <GoogleDownload className={classes.AppBtnsLinkIcon} />
          </a>
        ) : null}
        {iOS ? (
          <a href={APPLE_STORE_URL} className={classes.AppBtnsLink}>
            <AppleDownload className={classes.AppBtnsLinkIcon} />
          </a>
        ) : null}
      </div>
      {!iOS && !android ? (
        <Link to={`/accept-invite/${token}`} className={classes.link}>
          <Button
            variant="contained"
            className={classNames(classes.enterButton, classes.arrowButton)}
            color="primary"
          >
            <span>
              <Translate value="InviteScene.Button Text" />
            </span>
            <SvgIcon className={classes.icon}>
              <Next />
            </SvgIcon>
          </Button>
        </Link>
      ) : null}
      {iOS || android ? (
        <div className={classes.NextBlock}>
          <p className={classes.NextBlockText}>
            <Translate value="InviteScene.Whats next" />
          </p>
          <div className={classes.NextBlockBtns}>
            <div className={classes.NextBlockBtnsItem}>
              <DownloadIcon className={classes.NextBlockBtnsItemIcon} />
              <p className={classes.NextBlockBtnsItemText}>
                1.
                <Translate value="InviteScene.Install the app" />
              </p>
            </div>
            <div className={classes.NextBlockBtnsItem}>
              <QrCodeIcon className={classes.NextBlockBtnsItemIcon} />
              <p className={classes.NextBlockBtnsItemText}>
                2.
                <Translate value="InviteScene.Scan QR" />
              </p>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default InviteScene;
