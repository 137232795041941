/**
 * @flow
 */
import { Box, Button, Typography } from '@mui/material';
import ConfirmRecordingDialog from 'components/@whistleblower-admin/drawers/OccurrenceDrawer/ConfirmRecordingDialog';
import Loading from 'components/common/Loading';
import MicrophoneOutlineIcon from 'mdi-react/MicrophoneOutlineIcon';
import StopCircleOutlineIcon from 'mdi-react/StopCircleOutlineIcon';
import React, { useCallback, useEffect, useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import distortAudio from 'utils/distortAudio';
import useAudioRecorder from 'utils/useAudioRecorder';
import useOpenClose from 'utils/useOpenClose';

const RecordAudio = ({ onRecorded, progress }: { onRecorded: Function; progress: number }) => {
  const [isConfirmOpen, openConfirm, closeConfirm] = useOpenClose();
  const playerRef = useRef();
  const [playing, onStart, onStop] = useOpenClose();
  const [audioBlob] = React.useState(null);

  const { recordingBlob, isRecording, recordingTime, startRecording, stopRecording } =
    useAudioRecorder();

  const recordingMinutes = `${Math.floor(recordingTime / 60)}`;
  const recordingSeconds = `${recordingTime % 60}`;
  const recordingStr = `${recordingMinutes.padStart(2, '0')}:${recordingSeconds.padStart(2, '0')}`;

  useEffect(() => {
    if (recordingBlob) {
      openConfirm();
    }
  }, [openConfirm, recordingBlob]);

  useEffect(() => {
    if (audioBlob) {
      playerRef.current.src = URL.createObjectURL(audioBlob);
    }
  }, [audioBlob]);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener('ended', onStop);
      playerRef.current.addEventListener('pause', onStop);
      playerRef.current.addEventListener('play', onStart);
    }
  }, [onStart, onStop]);

  const handleClick = useCallback(() => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording(true);
    }
  }, [isRecording, stopRecording, startRecording]);

  const handlePlay = () => {
    if (playing) {
      playerRef.current.stop();
    } else {
      playerRef.current.play();
    }
  };

  const handleSubmit = async ({ distort }) => {
    const newBlob = distort ? await distortAudio(recordingBlob) : recordingBlob;
    const file = new File([newBlob], `audio_recording_${recordingStr}.wav`, {
      type: 'audio/wav',
    });
    onRecorded(file);
    closeConfirm();
  };
  const processing = progress > 0;
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
      <Button
        sx={{ minHeight: 44 }}
        startIcon={
          <Box sx={{ color: 'primary.main' }}>
            {!!processing && <Loading size={20} value={progress} />}
            {!processing &&
              (isRecording ? (
                <StopCircleOutlineIcon size={16} />
              ) : (
                <MicrophoneOutlineIcon size={16} />
              ))}
          </Box>
        }
        variant="outlined"
        color="secondary"
        onClick={handleClick}
      >
        {I18n.t(`OccurrenceDrawer.${isRecording ? 'stop' : 'record'}`)}
      </Button>
      {audioBlob && (
        <Button variant="outlined" color="secondary" onClick={handlePlay}>
          {playing ? 'Stop' : 'Play'}
        </Button>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio ref={playerRef} />
      {isRecording && <Typography component="span">{recordingStr}</Typography>}
      <ConfirmRecordingDialog isOpen={isConfirmOpen} close={closeConfirm} onSubmit={handleSubmit} />
    </Box>
  );
};

export default RecordAudio;
