/**
 * @flow
 */
import { TextField } from '@mui/material';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const NameField = (props: Object) => {
  return (
    <TextField
      fullWidth
      name="name"
      placeholder={I18n.t('WhistleblowerPortalConfig.namePlaceholder')}
      autoFocus
      {...props}
      {...props.field}
    />
  );
};

export default NameField;
