/** @flow */
import { ContentCopy } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import React, { useCallback } from 'react';

const Wrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.disabled,
  opacity: 0.6,
  fontSize: 12,
  marginTop: 'auto',
  display: 'flex',
  alignItems: 'center',
  gap: 1,
  '& button': {
    color: theme.palette.text.disabled,
    '& svg': {
      fontSize: 14,
    },
  },
}));

const CopyId = ({ id, ...rest }: { id: String }) => {
  const copyId = useCallback(() => {
    navigator.clipboard.writeText(id);
  }, [id]);

  return (
    <Wrapper {...rest}>
      <span>ID: {id}</span>
      <IconButton size="small" onClick={copyId()}>
        <ContentCopy fontSize="small" color="text.disabled" />
      </IconButton>
    </Wrapper>
  );
};

export default CopyId;
