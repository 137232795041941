import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import documentModalActions from 'store/app/ui/modals/document/action';
import useActions from 'utils/useActions';
import Modal from '../Modal';
import DownloadButton from './DownloadButton';

const useStyles = makeStyles({
  root: {},
  showAcks: {
    marginLeft: 10,
  },
  understood: { width: '100%', marginTop: '-5px', display: 'flex', justifyContent: 'center' },
  noPreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 100,
    minWidth: 400,
    marginBottom: 15,
    backgroundColor: '#eee',
  },
  iframeShadow: {
    maxWidth: 860,
    width: '90vw',
    height: '70vh',
    maxHeight: 'calc(100vh - 250px)',
    position: 'relative',
    '&::before': {
      content: `''`,
      position: 'absolute',
      boxShadow: 'inset 0px -10px 10px -10px rgba(0,0,0,0.5)',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
    },
  },
  iframe: {
    zIndex: 'initial',
    border: 0,
    width: '100%',
    height: '100%',
  },
});

const DocumentModal = () => {
  const classes = useStyles();
  const { isOpen, src, messageText = '', mimeType } = useSelector(state => state.modals.document);
  const documentModal = useActions(documentModalActions);
  const isPdf = src?.endsWith('pdf') || mimeType === 'application/pdf';

  if (!src || !isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={documentModal.close}>
      <div className={classes.root}>
        {isPdf ? (
          <div className={classes.iframeShadow}>
            <iframe className={classes.iframe} title="pdf" src={src} />
          </div>
        ) : (
          <div className={classes.noPreview}>
            <Typography>{I18n.t('Documents.NoPreview')}</Typography>
          </div>
        )}
        <Box sx={{ marginTop: 1 }}>
          <DownloadButton src={src} name={messageText} mimeType={mimeType} />
        </Box>
      </div>
    </Modal>
  );
};

export default DocumentModal;
