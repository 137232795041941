/* eslint-disable no-unused-expressions, react/destructuring-assignment */
import { Tab, Tabs } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { object, oneOf } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import connector from './connector';
import Documents from './Documents';
import Forms from './Forms';
import Links from './Links';
import Media from './Media';
import TabContent from './TabContent';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    margin: '15px',
  },
  indicator: {
    backgroundColor: theme.palette.black,
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
});

class MediaTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: props.tab,
    };
  }

  changeTab = (e, value) => {
    this.props.actions.media.change({ type: value });
    this.setState({ tab: value });
  };

  render() {
    const { classes } = this.props;
    const { tab } = this.state;

    return (
      <div className={classes.root}>
        <Tabs
          value={tab}
          variant="fullWidth"
          onChange={this.changeTab}
          classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
          <Tab value="doc" label={I18n.t('MediaDrawer.Docs')} />
          <Tab value="media" label={I18n.t('MediaDrawer.Media')} />
          <Tab value="link" label={I18n.t('MediaDrawer.Links')} />
          <Tab value="form" label={I18n.t('MediaDrawer.Forms')} />
        </Tabs>
        <TabContent current={tab} value="doc">
          <Documents />
        </TabContent>
        <TabContent current={tab} value="media">
          <Media />
        </TabContent>
        <TabContent current={tab} value="link">
          <Links />
        </TabContent>
        <TabContent current={tab} value="form">
          <Forms />
        </TabContent>
      </div>
    );
  }
}

MediaTabs.propTypes = {
  classes: object.isRequired,
  tab: oneOf(['media', 'doc', 'link']).isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(MediaTabs));
