import { Box, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import NextIcon from 'assets/icons/next.svg';
import PreviousIcon from 'assets/icons/previous.svg';
import classNames from 'classnames';
import { func, number, object } from 'prop-types';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    padding: 0,
    margin: 0,
  },
  page: {
    border: `1px solid ${theme.palette.secondary.light}`,
    marginLeft: -1,
    display: 'flex',
    '& > a': {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      marginLeft: -1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      height: 34,
      minWidth: 39,
      ...theme.typography.body1,
      outline: 'none',
      color: theme.palette.primary.main,
    },
    '&$disabled + $page': {
      borderRadius: '5px 0 0 5px',
    },
    '&:nth-last-child(2)$active': {
      borderRadius: '0 5px 5px 0',
    },
    '&$disabled + &:nth-last-child(2)$active': {
      borderRadius: 5,
    },
  },
  disabled: {
    visibility: 'hidden',
  },
  previous: {
    borderRadius: '5px 0 0 5px',
  },
  next: {
    borderRadius: '0 5px 5px 0',
  },
  active: {
    '& a': {
      color: theme.palette.black,
    },
  },
  arrow: {
    width: 16,
    marginLeft: 10,
    marginRight: 10,
  },
  nextText: {
    marginLeft: 10,
    marginRight: 20,
  },
  previousText: {
    marginRight: 10,
    marginLeft: 20,
  },
});

const Pagination = ({ total, perPage = 25, page, onChange, classes }) =>
  total > 0 && perPage > 0 ? (
    <div className={classes.wrapper}>
      <ReactPaginate
        containerClassName={classes.container}
        pageClassName={classes.page}
        previousLabel={
          <div>
            <SvgIcon className={classes.arrow}>
              <PreviousIcon />
            </SvgIcon>
            <span className={classes.previousText}>{I18n.t('Pagination.Previous')}</span>
          </div>
        }
        nextLabel={
          <div>
            <span className={classes.nextText}>{I18n.t('Pagination.Next')}</span>
            <SvgIcon className={classes.arrow}>
              <NextIcon />
            </SvgIcon>
          </div>
        }
        previousClassName={classNames(classes.page, classes.previous)}
        nextClassName={classNames(classes.page, classes.next)}
        breakClassName={classes.page}
        pageCount={Math.ceil(total / perPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={onChange}
        activeClassName={classes.active}
        forcePage={page}
        disabledClassName={classes.disabled}
      />
    </div>
  ) : (
    <Box sx={{ height: 36 }} />
  );

Pagination.propTypes = {
  onChange: func.isRequired,
  page: number.isRequired,
  perPage: number,
  total: number.isRequired,
  classes: object.isRequired,
};

Pagination.defaultProps = {
  perPage: 25,
};

export default withStyles(styles)(Pagination);
