/**
 * @flow
 */
import Add from '@mui/icons-material/Add';
import { Button, MenuItem, Popover, SvgIcon, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import QuestionTypeIcon from 'components/@home/drawers/FormDrawer/QuestionTypeIcon';
import questionTypes from 'components/@home/drawers/FormDrawer/questionTypes';
import React, { useEffect, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    maxWidth: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  optionIcon: type => ({
    marginRight: theme.spacing(1),
    borderRadius: 5,
    backgroundColor: theme.palette.questions[type],
  }),
  menu: {
    minWidth: 270,
    padding: '5px 0 5px 0',
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  questionIcon: {
    marginRight: theme.spacing(1),
  },
  tooltipArrow: {
    marginLeft: 5,
    backgroundColor: theme.palette.secondary.darker,
  },
  arrow: {
    color: theme.palette.secondary.darker,
  },
  tooltipText: {
    fontSize: 12,
    padding: 4,
    color: theme.palette.white,
  },
}));

type Props = {
  onAddQuestion: Function;
  isEmpty?: boolean;
  disabled?: boolean;
  initiallyOpen?: boolean;
};

const AddQuestionButton = ({ onAddQuestion, isEmpty, disabled, initiallyOpen }: Props) => {
  const anchorEl = useRef();
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => setOpen(initiallyOpen), 500);
  }, [initiallyOpen]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(true);
  };
  const addQuestion = type => () => {
    onAddQuestion(type);
    handleClose();
  };
  return (
    <>
      <Button
        aria-controls="new-question-menu"
        aria-haspopup="true"
        variant="outlined"
        color="secondary"
        onClick={handleClick}
        ref={anchorEl}
        disabled={disabled}
      >
        <SvgIcon className={classes.icon}>
          <Add />
        </SvgIcon>
        {I18n.t(`FormDrawer.Add ${isEmpty ? 'first' : 'new'} question`)}
      </Button>

      <Popover
        id="new-question-menu"
        anchorEl={anchorEl.current}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.menu }}
      >
        {questionTypes.map(type => (
          <Tooltip
            key={type}
            classes={{ arrow: classes.arrow, tooltipArrow: classes.tooltipArrow }}
            title={
              <Typography className={classes.tooltipText}>
                {I18n.t(`FormDrawer.QuestionTypes.${type}.tooltip`)}
              </Typography>
            }
            placement="right"
            arrow
          >
            <MenuItem onClick={addQuestion(type)}>
              <QuestionTypeIcon type={type} className={classes.questionIcon} />
              <Typography>{I18n.t(`FormDrawer.QuestionTypes.${type}.title`)}</Typography>
            </MenuItem>
          </Tooltip>
        ))}
      </Popover>
    </>
  );
};

AddQuestionButton.defaultProps = {
  initiallyOpen: false,
  disabled: false,
  isEmpty: false,
};

export default AddQuestionButton;
