import { Button, Drawer, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import CloseButton from 'components/@home/drawers/CloseButton';
import TimeTrackingBotHelp from 'components/@home/drawers/TimeTrackingDrawer/TimeTrackingBotHelp';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { TIME_TRACKING_APP_ID } from 'services/constants';
import appActions from 'store/app/entities/apps/action';
import companyActions from 'store/app/entities/companies/action';
import timeTrackingActions from 'store/app/ui/drawers/timeTracking/action';
import authCompany from 'store/selectors/authCompany';
import timeTrackingEmployee from 'store/selectors/timeTrackingEmployee';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '95vw',
      maxWidth: 1280,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    height: '100vh',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  container: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'scroll',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '20px 0 20px 0',
    height: 'auto',
  },
  label: {
    marginLeft: '0 !important',
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 20,
    gap: 10,
  },
}));

const TimeTrackingDrawer = () => {
  const company = useSelector(authCompany);
  const isOpen = useSelector(state => state.drawers.timeTracking.isOpen);
  const [, close] = useActions([timeTrackingActions.open, timeTrackingActions.close]);
  const app = useSelector(state => state.apps.apps[TIME_TRACKING_APP_ID]);
  const emp = useSelector(timeTrackingEmployee);
  const [active, setActive] = useState(!!emp?.isActive);

  const [addEmployee, removeEmployee] = useActions([
    appActions.addEmployee,
    appActions.removeEmployee,
  ]);

  const getCompany = useActions(companyActions.load);

  const classes = useStyles();

  const handleSubmit = async () => {
    await (active ? addEmployee(TIME_TRACKING_APP_ID) : removeEmployee(TIME_TRACKING_APP_ID));
    setTimeout(() => getCompany(company._id), 300);
  };

  const handleChange = (event, value) => {
    setActive(value);
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={close}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          <CloseButton onClick={close} />
        </div>
        <div className={classes.content} role="none">
          <Container component="main" className={classes.container} maxWidth={false}>
            <div className={classes.paper}>
              <div className={classes.titleWrapper}>
                <Typography display="inline" component="h1" variant="h5" className={classes.title}>
                  {app?.name}
                </Typography>
              </div>
              <TimeTrackingBotHelp />
              <FormControl classes={{ root: classes.formControl }} component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    classes={{ root: classes.label }}
                    control={<Switch checked={active} onChange={handleChange} />}
                    label={I18n.t('TimeTrackingDrawer.Active')}
                  />
                </FormGroup>
              </FormControl>
            </div>
          </Container>
          <div className={classes.navigationButtons}>
            <Button variant="outlined" color="secondary" onClick={close} className={classes.button}>
              {I18n.t('HrBot.Cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              className={classes.button}
            >
              {I18n.t('HrBot.Save')}
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default TimeTrackingDrawer;
