import { Drawer } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import connector from 'components/@home/@people/connector';
import ImportPreview from 'components/@home/@people/ImportPreview';
import ImportsTable from 'components/@home/@people/ImportsTable';
import BackButton from 'components/@home/drawers/BackButton';
import CloseButton from 'components/@home/drawers/CloseButton';
import Alert from 'components/alerts/Alert';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import isEmpty from 'lodash/isEmpty';
import ms from 'ms';
import { arrayOf, bool, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';

const autoHideDuration = ms('10 seconds');

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 40px)',
    },
  },
  close: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  content: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    position: 'relative',
  },
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ImportsDrawer = ({
  actions,
  employeeImports,
  employees,
  isLoading,
  isOpen,
  file,
  importPreview,
  fileData,
  loading,
  summary,
  status: resultStatus,
  error,
}) => {
  const [alertOpen, setAlertOpen] = useState(!!summary);
  useEffect(() => {
    if (error) {
      actions.alert.show({
        label: I18n.t('ImportUsers.Error'),
        message: I18n.t('ImportUsers.AnUnexpectedErrorOccurredWhileImporting'),
        variant: 'error',
        autoHideDuration: ms('5 seconds'),
      });
    }
  }, [actions.alert, error]);
  useEffect(() => {
    if (summary && !alertOpen) {
      setAlertOpen(true);
    }
  }, [alertOpen, summary]);
  const closeAlert = () => setAlertOpen(false);
  const classes = useStyles();
  useEffect(() => {
    if (isOpen) {
      if (isEmpty(employees)) {
        actions.companies.listEmployees();
      }
      if (employeeImports === null) {
        actions.employeeImports.list();
      }
    }
  }, [actions.companies, actions.employeeImports, employeeImports, employees, isOpen]);

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Loading size={56} />
      </div>
    );
  }

  const handleContinue = async ({ _id }) => {
    await actions.employeeImports.resume(_id);
  };

  const handleCancel = ({ _id }) => {
    actions.employeeImports.cancel(_id);
  };

  const handleStart = ({ _id }) => {
    actions.employeeImports.start(_id);
  };

  const handleShowResults = ({ _id }, status) => {
    actions.employeeImports.showResults(_id, status);
  };

  return (
    <Drawer
      classes={{ paper: classes.root }}
      anchor="right"
      open={isOpen}
      onClose={actions.drawers.employeeImports.close}
    >
      <div className={classes.close}>
        {importPreview ? (
          <BackButton onClick={actions.drawers.employeeImports.closePreview} />
        ) : (
          <CloseButton onClick={actions.drawers.employeeImports.close} />
        )}
      </div>
      <div className={classes.content}>
        {loading && <Loading />}
        {!loading && !importPreview && employeeImports && (
          <ImportsTable
            employeeImports={employeeImports}
            onContinue={handleContinue}
            onDiscard={handleCancel}
            onClickShowResults={handleShowResults}
          />
        )}
        {!loading && importPreview && (
          <ImportPreview
            actions={actions}
            file={file}
            importPreview={importPreview}
            downloadResumedImport={actions.employeeImports.downloadResumedImport}
            fileData={fileData}
            onCancel={handleCancel}
            onStart={handleStart}
            summary={summary}
            resultStatus={resultStatus}
          />
        )}
      </div>
      <Alert
        isOpen={alertOpen}
        onClose={closeAlert}
        variant="success"
        label={I18n.t('PeoplesScene.Import employees')}
        caption={I18n.t('ImportUsers.CreatedUpdated', summary || { created: 0, updated: 0 })}
        autoHideDuration={autoHideDuration}
      >
        {I18n.t('ImportUsers.Success')}
      </Alert>
    </Drawer>
  );
};

ImportsDrawer.propTypes = {
  actions: object.isRequired,
  employees: arrayOf(employeeShape).isRequired,
  isLoading: bool.isRequired,
  employeeImports: arrayOf(object),
  isOpen: bool.isRequired,
  importPreview: object,
  file: object,
  fileData: object,
  loading: bool.isRequired,
  summary: object,
  status: string,
  error: object,
};

ImportsDrawer.defaultProps = {
  importPreview: null,
  file: null,
  fileData: null,
  summary: null,
  status: 'created',
  employeeImports: null,
  error: null,
};

export default deferRender(connector(ImportsDrawer));
