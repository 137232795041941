/* eslint-disable class-methods-use-this,no-nested-ternary */
import ms from 'ms';
import qs from 'querystring';
import Http from 'services/Http';
import createForm from 'utils/createForm';

class Employee {
  createForm = employee => {
    const ret = createForm(employee, {
      firstName: 'string',
      surName: 'string',
      jobTitle: 'string',
      department: 'string',
      email: 'string',
      phone: 'string',
      location: 'string',
      tags: 'string',
      code: 'string',
      id: 'string',
      reportsTo: 'string',
      channels: channels =>
        channels.map(({ _id, isAdmin, canWriteAsChannel }) => ({
          _id,
          isAdmin,
          canWriteAsChannel,
        })) || employee.channels_ids,
      channelsRemoved: 'array',
      teams: 'array',
      teamsRemoved: 'array',
      isAdmin: 'bool',
      isActive: 'bool',
      division: 'string',
      notes: 'string',
    });
    return ret;
  };

  async load(id) {
    const { data } = await Http.get(`/v1/employee/${id}`);
    return data.employee;
  }

  async mandatories(id, params) {
    const { data } = await Http.get(
      `/v1/employee/${id}/acknowledge/history?${qs.stringify(params)}`,
    );
    return data;
  }

  async destroy(id) {
    const response = await Http.delete(`/v1/employee/${id}`);

    return response.data;
  }

  async create(employee) {
    const form = this.createForm(employee);
    const response = await Http.post('/v1/employee', form);

    return response.data.employee;
  }

  async update(id, employee) {
    const form = this.createForm(employee);
    const response = await Http.patch(`/v1/employee/${id}`, form);

    return response.data.employee;
  }

  async previewChannels(id = 'new', data) {
    const form = this.createForm(data);
    const response = await Http.patch(`/v1/employee/${id}/channels-preview`, form);

    return response.data.channels;
  }

  async block(_id, isActive) {
    const response = await Http.patch(`/v1/employee/${_id}/block/${isActive}`);
    return response.data.isActive;
  }

  createImport(file) {
    const form = new FormData();
    form.append('file', file);
    return Http.post(`/v1/employee/import`, form, { timeout: ms('5m') });
  }

  async uploadAvatar(id, avatar) {
    const form = new FormData();
    form.append('avatar', avatar);
    const { data } = await Http.post(`/v1/employee/${id}/file`, form);
    return data.employee;
  }

  async listAdmins() {
    const { data } = await Http.get(`/v1/employee/admins`);
    return data.employees;
  }

  massiveInvite(employeeIds) {
    return Http.post(`/v1/employee/upload`, { employeeIds });
  }

  async findVisibleTeamsAndEmployees(params) {
    const { data } = await Http.get(
      `v1/company/current/visible-employees${params ? `?${qs.stringify(params)}` : ''}`,
    );
    return data;
  }
}

export default new Employee();
