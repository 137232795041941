import React from 'react';
import { bool } from 'prop-types';
import { Typography } from '@mui/material';
import { I18n } from 'react-redux-i18n';

const Headline = ({ isEdit, ...props }) => (
  <Typography {...props} variant="h5">
    {isEdit ? I18n.t('FormDrawer.Edit form') : I18n.t('FormDrawer.Create form')}
  </Typography>
);
Headline.propTypes = {
  isEdit: bool.isRequired,
};

export default Headline;
