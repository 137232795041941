import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: { name, parameterPresets, entryMessages, entryMessagesSuggestions },
    } = this;

    const ret = {
      name,
      parameterPresets,
      entryMessages: entryMessages
        .filter(m => m.text)
        .map(m => ({ ...m, attachments: m.attachments?.[0] ? [m.attachments[0]._id] : [] })),
    };

    const lastMessageIdx = ret.entryMessages.length - 1;
    if (lastMessageIdx > -1) {
      ret.entryMessages[lastMessageIdx].suggestions = entryMessagesSuggestions;
    }

    return ret;
  };

  sendRequest = async form => {
    let ret;
    const {
      flow: { _id },
      context: {
        current: { page },
      },
      actions,
    } = this.formikBag.props;
    if (_id === 'new') {
      const newForm = { ...form };
      delete newForm._id;
      ret = await actions.chatFlowPages.create(_id, form);
    } else {
      ret = await actions.chatFlowPages.update(_id, page, form);
    }
    actions.chatFlows.find(_id);
    return ret;
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const chatFlowPageFormik = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  mapPropsToValues: ({ flow, context }) => {
    const { page: pageId } = context.current || {};
    const page = flow?.pages?.find(p => p._id === pageId);
    const entryMessages = page?.entryMessages || [];
    if (entryMessages.length > 0 && entryMessages[entryMessages.length - 1].text) {
      entryMessages.push({
        _id: `${Math.random()}`,
        text: '',
      });
    }
    const lastMessageIdx = entryMessages.length - 2;

    const entryMessagesSuggestions =
      lastMessageIdx > -1 ? entryMessages[lastMessageIdx].suggestions : [];

    return {
      _id: page?._id || 'new',
      name: page?.name || '',
      parameterPresets: page?.parameterPresets || [],
      entryMessages,
      entryMessagesSuggestions,
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { context },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    try {
      await handler.sendRequest(form);
      context.clear();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});

export default chatFlowPageFormik;
