import { ListItem, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Icon from 'assets/icons/bolt.svg';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';
import moment from 'moment';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import channelShape from 'shapes/channel';
import employeeShape from 'shapes/employee';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    height: '70px',
    alignItems: 'center',
    borderBottom: 'solid 1px #dde2eb',
    cursor: 'pointer',
  },
  fileIcon: {
    fontSize: 24,
    marginRight: 10,
    color: theme.palette.primary.main,
  },
  chevronIcon: {
    fontSize: 14,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  nameText: {
    fontSize: '15px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.black,
  },
  ack: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.47',
    letterSpacing: '-0.5px',
    color: theme.palette.primary.main,
  },
}));

const FormItem = ({ form, children, authEmployee, channel, actions }) => {
  const acknowledge = form.acknowledges?.find(m => m.user === authEmployee?.user?._id);
  const confirmedAt = acknowledge && moment.unix(acknowledge.confirmedAt).format('LL');

  const preview = () => {
    actions.fillFormDrawer.open({ message: form, channel, employee: authEmployee });
  };

  const classes = useStyles();

  return (
    <ListItem className={classes.root} onClick={preview}>
      <SvgIcon className={classes.fileIcon}>
        <Icon />
      </SvgIcon>
      <div className={classes.textWrapper}>
        <Typography className={classes.nameText} component="div">
          {children}
        </Typography>
        {confirmedAt && (
          <Typography className={classes.ack} component="div">
            {`${I18n.t('AcknowledgementMessage.Form sent on')} ${confirmedAt}`}
          </Typography>
        )}
      </div>
      <SvgIcon className={classes.chevronIcon}>
        <ChevronRightIcon />
      </SvgIcon>
    </ListItem>
  );
};

FormItem.propTypes = {
  form: object.isRequired,
  children: object.isRequired,
  actions: object.isRequired,
  authEmployee: employeeShape.isRequired,
  channel: channelShape.isRequired,
};

export default connector(FormItem);
