import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import DirectMessagesButton from 'components/@home/@messages/ChannelsPanel/DirectMessagesButton';
import SearchField from 'components/controls/SearchField';
import ArrowBackIcon from 'mdi-react/ArrowBackIcon';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import channelsPanel from 'store/app/ui/ChannelsPanel/action';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  headSection: {
    padding: 20,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  head: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 5px 0',
  },
}));

const ChannelsPanelHead = ({ onSearch }) => {
  const classes = useStyles();
  const showArchived = useSelector(state => state.elements.ChannelsPanel.showArchived);
  const channelsPanelShowArchived = useActions(channelsPanel.showArchived);
  const onClick = useCallback(() => {
    channelsPanelShowArchived(false);
  }, [channelsPanelShowArchived]);
  return (
    <Box className={classes.headSection}>
      <Box className={classes.head}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {showArchived && (
            <IconButton edge="start" aria-label="back" onClick={onClick} size="large">
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography variant="h5">
            {I18n.t(showArchived ? 'ChannelsPanel.Archived chats' : 'ChannelsPanel.Chats')}
          </Typography>
        </Box>
        <DirectMessagesButton />
      </Box>
      <SearchField onChange={onSearch} />
    </Box>
  );
};

ChannelsPanelHead.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default memo(
  ChannelsPanelHead,
  (prevProps, nextProps) => prevProps.onSearch === nextProps.onSearch,
);
