import {
  CREATE_TEAM_FULFILLED,
  DESTROY_TEAM_FULFILLED,
  FIND_TEAM_FULFILLED,
  FIND_TEAM_PENDING,
  LOAD_TEAMS_FULFILLED,
  LOAD_TEAMS_PENDING,
  UPDATE_TEAM_FULFILLED,
} from 'store/app/entities/teams/action';
import arrayToObject from 'utils/arrayToObject';
import merge from 'utils/merge';

export const createTeam = t => ({
  _id: t._id,
  name: t.name,
  description: t.description,
  initials: t.name
    .match(/(?:^| )./g)
    .slice(0, 2)
    .join('')
    .replace(' ', '')
    .toUpperCase(),
  updatedAt: t.updatedAt,
  users: t.users,
  parent: t.parent,
  groups: t.groups,
  children: t.children,
  defaultRole: t.defaultRole,
});

const teamsReducer = (
  state = { teams: {}, loading: false, loaded: false },
  { type, payload, meta },
) => {
  switch (type) {
    case LOAD_TEAMS_FULFILLED: {
      const teams = payload.teams.map(c => createTeam(merge(state.teams[c._id], c)));

      return merge(state, { teams: arrayToObject(teams), loading: false, loaded: true });
    }

    case FIND_TEAM_PENDING: {
      return merge(state, { loading: true });
    }

    case CREATE_TEAM_FULFILLED:
    case UPDATE_TEAM_FULFILLED:
    case FIND_TEAM_FULFILLED: {
      const team = createTeam(merge({ ...state.teams[payload._id] }, payload));
      return merge(state, { loading: false, teams: { ...state.teams, [payload._id]: team } });
    }

    case DESTROY_TEAM_FULFILLED: {
      const teams = { ...state.teams };
      payload.teams.forEach(teamId => delete teams[teamId]);
      return merge(state, { teams });
    }

    case LOAD_TEAMS_PENDING:
      return merge(state, { loading: true, loaded: false });

    default:
      return state;
  }
};

export default teamsReducer;
