import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { bool, object } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import connector from './connector';

const styles = theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
  },
  label: {
    color: theme.palette.secondary.dark,
  },
});

class ArchivedChannelsButton extends Component {
  shouldComponentUpdate(nextProps) {
    const { showArchived } = this.props;
    return showArchived !== nextProps.showArchived;
  }

  handleClick = () => {
    const { actions } = this.props;
    actions.channelsPanel.showArchived(true);
  };

  render() {
    const { classes, showArchived } = this.props;
    if (showArchived) {
      return null;
    }
    return (
      <Button onClick={this.handleClick} classes={classes} color="primary" fullWidth>
        {I18n.t('ChannelsPanel.Archived chats')}
      </Button>
    );
  }
}

ArchivedChannelsButton.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  showArchived: bool.isRequired,
};

export default withStyles(styles)(connector(ArchivedChannelsButton));
