import moment from 'moment';
import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';
import Cookie from 'services/Cookie';
import {
  FLUSH_USER_DATA,
  GET_USER_DATA_FULFILLED,
  LOGOUT_USER_PENDING,
} from 'store/app/auth/action';
import { SET_ARCHIVED_CHANNEL_FULFILLED } from 'store/app/entities/channels/action';
import {
  LOAD_COMPANY_FULFILLED,
  TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED,
} from 'store/app/entities/companies/action';

import {
  ADD_EMPLOYEE,
  BLOCK_EMPLOYEE_FULFILLED,
  CREATE_EMPLOYEE_FULFILLED,
  DESTROY_EMPLOYEE_FULFILLED,
  DESTROY_EMPLOYEE_PENDING,
  FIND_VISIBLE_TEAMS_AND_EMPLOYEES_FULFILLED,
  FIND_VISIBLE_TEAMS_AND_EMPLOYEES_PENDING,
  FIND_VISIBLE_TEAMS_AND_EMPLOYEES_REJECTED,
  LOAD_EMPLOYEE_FULFILLED,
  LOAD_EMPLOYEE_PENDING,
  LOAD_EMPLOYEE_REJECTED,
  MANDATORIES_FULFILLED,
  MODIFY_EMPLOYEE,
  REMOVE_EMPLOYEE,
  UPDATE_EMPLOYEE_FULFILLED,
  UPDATE_EMPLOYEE_PENDING,
  UPDATE_EMPLOYEE_SETTINGS_FULFILLED,
  UPDATE_EMPLOYEE_SETTINGS_PENDING,
} from 'store/app/entities/employees/action';
import { employeesApiEndpoints } from 'store/app/entities/EmployeesApiSlice';
import { ADD_MESSAGE, SEND_MESSAGE_FULFILLED } from 'store/app/entities/messages/action';
import { UPDATE_SETTINGS_FULFILLED } from 'store/app/entities/settings/action';
import AddRehydratedTransform from 'utils/AddRehydratedTransform';
import arrayToObject from 'utils/arrayToObject';
import generatePath from 'utils/generatePath';
import merge from 'utils/merge';

const createInvite = i => ({
  token: i.token,
});

export const STATUS_PENDING = 'Pending';
export const STATUS_INACTIVE = 'Inactive';
export const STATUS_ACTIVE = 'Active';
export const STATUS_BLOCKED = 'Blocked';

const getStatus = e => {
  const days30ago = moment().subtract(30, 'days');
  if (!e.isActive) {
    return STATUS_BLOCKED;
  }
  if (!e.user || !e.isAcceptTerms) {
    return STATUS_PENDING;
  }
  if (days30ago.isAfter(moment(e.lastSeen))) {
    return STATUS_INACTIVE;
  }
  return STATUS_ACTIVE;
};

const createEmployee = e => ({
  _id: e._id,
  avatar: generatePath(e.avatar),
  name: e.name || (e.firstName && `${e.firstName} ${e.surName}`) || undefined,
  jobTitle: e.jobTitle,
  department: e.department,
  email: e.email,
  phone: e.phone,
  location: e.location,
  tags: e.tags,
  isAcceptTerms: e.isAcceptTerms,
  isAdmin: e.isAdmin,
  isTeamAdmin: e.isTeamAdmin,
  isActive: e.isActive,
  user_id: e.user_id || e.user?._id || e.user,
  firstName: e.firstName,
  surName: e.surName,
  code: e.code,
  id: e.id,
  channels: e.channels || [],
  channels_ids: e.channels?.map(c => c._id) || e.channels_ids || [],
  company_id: e.company_id || e.company?._id || e.company,
  createdAt: e.createdAt,
  reportsTo: e.reportsTo || '',
  settings: e.settings,
  invite: e.invite && createInvite(e.invite),
  mandatories: e.mandatories,
  mandatoryRead: e.mandatoryRead,
  mandatorySent: e.mandatorySent,
  messagesSent: e.messagesSent,
  teams: e.teams,
  archivedChannels: e.archivedChannels,
  lastSeen: e.lastSeen,
  status: getStatus(e),
  loading: e.loading,
  loaded: e.loaded,
  inviteToken: e.inviteToken,
  division: e.division,
  notes: e.notes,
  canAdmin: e.canAdmin,
});

const company = Cookie.get('company');

const getCompany = companies => companies.find(c => c._id === company?._id);

const initialState = {
  error: null,
  fulfilledTimeStamp: null,
  isError: false,
  isFetching: false,
  isLoading: false,
  isSuccess: false,
  isUninitialized: true,
  startedTimeStamp: null,
  employees: {},
  rehydrated: false,
};

const employeesReducer = (state = { ...initialState }, action) => {
  const { type, payload, meta } = action;
  const isCreateEmployeeFulfilled = employeesApiEndpoints.create.matchFulfilled(action);
  const isUpdateEmployeeFulfilled = employeesApiEndpoints.update.matchFulfilled(action);
  if (isCreateEmployeeFulfilled || isUpdateEmployeeFulfilled) {
    return {
      ...state,
      employees: {
        ...state.employees,
        [payload.data.employee._id]: createEmployee(
          merge({
            ...state.employees[payload.data.employee._id],
            ...payload.data.employee,
            loading: false,
            loaded: true,
          }),
        ),
      },
    };
  }
  switch (type) {
    case FIND_VISIBLE_TEAMS_AND_EMPLOYEES_PENDING:
      return {
        ...state,
        isLoading: !!state.isUninitialized,
        isFetching: true,
        isSuccess: false,
        isError: false,
        error: null,
        startedTimeStamp: Date.now(),
      };
    case FIND_VISIBLE_TEAMS_AND_EMPLOYEES_FULFILLED:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isSuccess: true,
        isError: false,
        isUninitialized: false,
        error: null,
        fulfilledTimeStamp: Date.now(),
        rehydrated: false,
      };
    case FIND_VISIBLE_TEAMS_AND_EMPLOYEES_REJECTED:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isSuccess: false,
        isError: true,
        error: payload,
      };
    case GET_USER_DATA_FULFILLED: {
      if (!company || company._id === 'x') return state;

      const employee = getCompany(payload.companies)?.employee;

      if (!employee || !employee._id) return state;

      return {
        ...state,
        employees: {
          ...state.employees,
          [employee._id]: createEmployee(merge(state.employees[employee._id], employee)),
        },
      };
    }
    case LOAD_COMPANY_FULFILLED:
      return payload.employees
        ? {
            ...state,
            employees: arrayToObject(
              payload.employees.map(e =>
                createEmployee({
                  ...state.employees[e._id],
                  ...e,
                }),
              ),
            ),
          }
        : state;

    case ADD_EMPLOYEE:
    case MODIFY_EMPLOYEE: {
      const newEmployee = createEmployee({ ...state.employees[meta._id], ...payload });
      return { ...state, employees: { ...state.employees, [meta._id]: newEmployee } };
    }

    case UPDATE_EMPLOYEE_PENDING:
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employee._id]: {
            ...state.employees[meta.employee._id],
            ...meta.employee,
          },
        },
      };
    case UPDATE_EMPLOYEE_FULFILLED:
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employee._id]: createEmployee({
            ...state.employees[meta.employee._id],
            ...payload,
            loading: false,
            loaded: true,
          }),
        },
      };

    case TOGGLE_ACCEPT_COMPANY_TERMS_FULFILLED:
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employeeId]: createEmployee({
            ...state.employees[meta.employeeId],
            isAcceptTerms: meta.accepted,
          }),
        },
      };

    case REMOVE_EMPLOYEE:
    case DESTROY_EMPLOYEE_PENDING:
    case DESTROY_EMPLOYEE_FULFILLED: {
      const employees = { ...state.employees };
      delete employees[meta.employee_id];

      return { ...state, employees };
    }

    case BLOCK_EMPLOYEE_FULFILLED: {
      const employee = { ...state.employees[meta.employeeId], isActive: payload };
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employeeId]: employee,
        },
      };
    }

    case LOAD_EMPLOYEE_PENDING: {
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employee_id]: merge(state.employees[meta.employee_id], { loading: true }),
        },
      };
    }

    case CREATE_EMPLOYEE_FULFILLED:
    case LOAD_EMPLOYEE_FULFILLED:
      return {
        ...state,
        employees: {
          ...state.employees,
          [payload._id]: createEmployee(
            merge(state.employees[payload._id], { ...payload, loading: false, loaded: true }),
          ),
        },
      };

    case LOAD_EMPLOYEE_REJECTED:
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employee_id]: merge(state.employees[meta.employee_id], { loading: false }),
        },
      };

    case UPDATE_EMPLOYEE_SETTINGS_PENDING:
    case UPDATE_EMPLOYEE_SETTINGS_FULFILLED: {
      const employees = { ...state.employees };
      const _id = meta.employee_id;
      const employee = employees[_id];

      employees[_id] = merge(employee, {
        settings: merge(employee.settings, meta.settings),
      });

      return { ...state, employees };
    }

    case MANDATORIES_FULFILLED: {
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employee_id]: { ...state.employees[meta.employee_id], mandatories: payload },
        },
      };
    }

    case ADD_MESSAGE:
    case SEND_MESSAGE_FULFILLED: {
      const employee = Object.values(state.employees).find(o => o.user_id === meta.authUserId);
      if (!employee) {
        return state;
      }
      let archivedChannels = state.employees[employee._id].archivedChannels || [];
      const wasArchived = archivedChannels.includes(payload.channel);
      if (wasArchived) {
        archivedChannels = archivedChannels.filter(c => c !== payload.channel);
      }
      return {
        ...state,
        employees: {
          ...state.employees,
          [employee._id]: { ...state.employees[employee._id], archivedChannels },
        },
      };
    }

    case SET_ARCHIVED_CHANNEL_FULFILLED: {
      let archivedChannels = state.employees[meta.employeeId].archivedChannels || [];
      const wasArchived = archivedChannels.includes(meta.channelId);
      if (meta.archived && !wasArchived) {
        archivedChannels = [...archivedChannels, meta.channelId];
      } else if (!meta.archived && wasArchived) {
        archivedChannels = archivedChannels.filter(c => c !== meta.channelId);
      }
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employeeId]: { ...state.employees[meta.employeeId], archivedChannels },
        },
      };
    }

    case UPDATE_SETTINGS_FULFILLED:
      return {
        ...state,
        employees: {
          ...state.employees,
          [meta.employeeId]: {
            ...state.employees[meta.employeeId],
            settings: payload.settings,
          },
        },
      };

    case FLUSH_USER_DATA:
    case LOGOUT_USER_PENDING: {
      storage('ommnio').removeItem('persist:employees');
      return { ...initialState };
    }

    default:
      return state;
  }
};

const RemoveLoadedTransform = createTransform(
  state => state,
  outboundEmployees => {
    const employees = { ...outboundEmployees };
    if (!employees) return employees;
    Object.keys(employees).forEach(employeeId => {
      employees[employeeId].loaded = false;
    });
    return employees;
  },
  { whitelist: ['employees'] },
);

const persistConfig = {
  key: 'employees',
  whitelist: ['fulfilledTimeStamp', 'isUninitialized', 'isSuccess', 'employees', 'rehydrated'],
  storage: storage('ommnio'),
  throttle: 200,
  version: 0,
  transforms: [RemoveLoadedTransform, AddRehydratedTransform],
};

export default persistReducer(persistConfig, employeesReducer);
