import requiredError from 'components/@home/@settings/Tabs/requiredError';
import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import Yup from 'utils/CustomYup';

class FormSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      values: { name, timeout, url, authentication, headers },
    } = this;

    return { name, timeout, url, authentication, headers };
  };

  sendRequest = async form => {
    let ret;
    const { webhook: { _id } = { _id: 'new' }, actions } = this.formikBag.props;
    if (_id === 'new') {
      const newForm = { ...form };
      delete newForm._id;
      ret = await actions.chatFlowWebhooks.create(newForm);
      actions.chatFlows.load();
    } else {
      ret = await actions.chatFlowWebhooks.update(_id, form);
      actions.chatFlows.load();
    }
    ret = await actions.chatFlowWebhooks.find(ret.value._id);
    return ret.value;
  };

  displayErrors = () => {
    const { setErrors } = this.formikBag;
    setErrors({ server: I18n.t('Something went wrong with sending data') });
  };
}

const validUrlError = () => I18n.t('ChatFlowSettings.Must be a valid URL');

const chatFlowWebhookFormik = withFormik({
  enableReinitialize: true,
  validateOnChange: true,
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required(requiredError('name'))
      .matches(/^[_a-z][a-z0-9_]*$/i, () => I18n.t('ChatFlowSettings.errors.Variable name')),
    timeout: Yup.number().min(1).max(30).required(requiredError('timeout')),
    url: Yup.string().isUrl(validUrlError).required(requiredError('url')),
    authentication: Yup.object().shape({
      username: Yup.string(),
      password: Yup.string(),
    }),
    headers: Yup.array().of(
      Yup.object({
        key: Yup.string()
          .matches(/^[_a-z][a-z0-9_]*$/i, () => I18n.t('ChatFlowSettings.errors.Variable name'))
          .required(requiredError('key')),
        value: Yup.string().required(requiredError('value')),
      }),
    ),
  }),
  mapPropsToValues: ({ webhook }) => {
    return {
      _id: webhook?._id || 'new',
      name: webhook?.name || '',
      timeout: webhook?.timeout || 5,
      url: webhook?.url || '',
      authentication: {
        username: webhook?.authentication?.username || '',
        password: webhook?.authentication?.password || '',
      },
      headers: webhook?.headers || [],
    };
  },
  handleSubmit: async (values, formikBag) => {
    const {
      props: { context },
      setSubmitting,
      resetForm,
    } = formikBag;

    const handler = new FormSubmit(values, formikBag);
    const form = handler.assembleForm();

    try {
      const ret = await handler.sendRequest(form);
      if (context.current.callback) {
        context.current.callback(ret);
      }
      context.pop();
      resetForm();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
    return true;
  },
});

export default chatFlowWebhookFormik;
