import Loading from 'components/common/Loading';
import TermsAcceptedGate from 'components/gates/TermsAcceptedGate';
import { elementType } from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IS_DEV } from 'services/constants';
import Cookie from 'services/Cookie';
import { useMe } from 'store/app/auth/action';
import { usePopulateVisibleTeamsAndEmployees } from 'store/app/entities/employees/action';
import getSubdomain from 'utils/getSubdomain';
import redirect from 'utils/redirect';

const isCurrentCompany = shortCompany => {
  const subdomain = getSubdomain();
  return subdomain === shortCompany?.subDomain;
};

const HomeGate = ({ component }) => {
  const companies = useSelector(state => state.companies);
  const differentCompany = useMemo(() => !isCurrentCompany(Cookie.get('company')), []);
  const { isLoading: isLoadingMe, isUninitialized: isUninitializedMe } = useMe();
  usePopulateVisibleTeamsAndEmployees();

  const redirectToOtherCompany = useCallback(() => {
    const subdomain = getSubdomain();
    const companiesArray = Object.values(companies);
    const availableCompany = companiesArray.find(c => c.subDomain === subdomain);

    if (!availableCompany) {
      return redirect('/404');
    }

    Cookie.set('company', availableCompany, { secure: !IS_DEV });

    return redirect('/home/messages', availableCompany.subDomain);
  }, [companies]);

  useEffect(() => {
    if (differentCompany) {
      redirectToOtherCompany();
    }
  }, [companies, differentCompany, redirectToOtherCompany]);

  if (isLoadingMe || isUninitializedMe) {
    return <Loading />;
  }

  if (differentCompany || isUninitializedMe) {
    return null;
  }

  return <TermsAcceptedGate component={component} />;
};

HomeGate.propTypes = {
  component: elementType.isRequired,
};

export default memo(HomeGate);
