import { Divider } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PdfPreview from 'components/@home/modals/PdfPreview';
import TextField from 'components/formik/TextField';
import { Field, Form } from 'formik';
import { func, object } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import attachmentShape from 'shapes/attachment';
import messageShape from 'shapes/message';
import Actions from './Actions';
import connector from './connector';
import DigitalSignatureSwitch from './DigitalSignatureSwitch';
import DiscardButton from './DiscardButton';
import DraftButton from './DraftButton';
import formik from './formik';
import PublishButton from './PublishButton';
import Title from './Title';

const styles = () => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },

  previewSection: {
    flex: 1,
    padding: '25px 15px',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },

  textField: {
    margin: '0 0 25px',
    width: '100%',
  },
});

class AcknowledgementPreviewForm extends Component {
  saveAsDraft = async e => {
    const { setFieldValue, submitForm, onClose } = this.props;
    setFieldValue('isDraft', true);
    await Promise.resolve();
    submitForm(e);
    onClose();
  };

  discard = () => {
    const { message, onClose, actions } = this.props;

    if (message) actions.messages.destroy(message._id);

    onClose();
  };

  getPdf = () => {
    const { message, tempAttachment } = this.props;
    if (!message) return tempAttachment.httpLink;
    return message.attachments[0].httpLink;
  };

  handleSignaturePlaced = ({ x, y }) => {
    const { setFieldValue } = this.props;
    setFieldValue('signX', x);
    setFieldValue('signY', y);
  };

  render() {
    const { classes, values } = this.props;
    return (
      <Form className={classes.root}>
        <div className={classes.previewSection}>
          <Title>{I18n.t('AcknowledgementCreate.Acknowledge message title')}</Title>
          <Field
            component={TextField}
            className={classes.textField}
            name="title"
            placeholder={I18n.t('AcknowledgementCreate.Acknowledge document title')}
          />
          <PdfPreview
            src={this.getPdf()}
            showSignature={!!values.digitalSignature?.length}
            onSignaturePlaced={this.handleSignaturePlaced}
          />
        </div>
        <Divider light />
        <Actions>
          <DiscardButton type="reset" onClick={this.discard} />
          <DigitalSignatureSwitch />
          <div>
            <DraftButton onClick={this.saveAsDraft} />
            <PublishButton type="submit" />
          </div>
        </Actions>
      </Form>
    );
  }
}

AcknowledgementPreviewForm.propTypes = {
  classes: object.isRequired,
  tempAttachment: attachmentShape,
  message: messageShape,
  onClose: func.isRequired,
  setFieldValue: func.isRequired,
  submitForm: func.isRequired,
  actions: object.isRequired,
  values: object.isRequired,
};
AcknowledgementPreviewForm.defaultProps = {
  tempAttachment: null,
  message: null,
};

export default withStyles(styles)(connector(formik(AcknowledgementPreviewForm)));
