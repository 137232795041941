import { APP_DOMAIN, IS_DEV } from 'services/constants';

export const getMagicLink = (company, e) => {
  if (!e.inviteToken) {
    return null;
  }
  const subdomain = IS_DEV ? '' : `${company.subDomain}.`;
  let apn = window.location.hostname.match(/staging/) ? 'com.ommnio.staging' : 'com.ommnio';
  const ibi = window.location.hostname.match(/staging/)
    ? 'com.ommnio.communications.ommnioapp-staging'
    : 'com.ommnio.communications.ommnioapp';
  const isi = 1481362031;
  const ius = 'ommnio';
  if (IS_DEV) {
    apn = 'com.ommnio.dev';
  }
  const link = `https://${subdomain}${APP_DOMAIN}/invite/${e.inviteToken}`;
  const magicLink = `https://l.ommnio.com/link/?link=${link}&apn=${apn}&ibi=${ibi}&isi=${isi}&ius=${ius}`;
  return magicLink;
};
