/* eslint-disable function-paren-newline,react/destructuring-assignment */
import { Table, TableBody } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Pagination from 'components/controls/Pagination';
import TableHead from 'components/controls/TableHead';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import companyShape from 'shapes/company';
import employeeShape from 'shapes/employee';
import arrayToObject from 'utils/arrayToObject';
import searchEmployees from 'utils/searchEmployees';
import EmployeeRow from './EmployeeRow';
import Toolbar from './Toolbar';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  row: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
  },
  userName: {
    marginLeft: 10,
  },
  xsHidden: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  edit: {
    marginRight: 5,
    color: '#8b98ab',
    fontSize: 11,
  },
  delete: {
    color: '#8b98ab',
    fontSize: 11,
  },
  pending: {
    color: theme.palette.secondary.main,
  },
  tag: {
    margin: theme.spacing(1),
  },
});

class PeoplesTable extends React.Component {
  state = {
    order: {
      by: 'name',
      isReverse: false,
    },
    filtered_ids: [],
    page: 0,
    rowsPerPage: 10,
    searchString: '',
  };

  columns = [
    {
      key: 'name',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Name'),
      xsVisible: true,
    },
    { key: 'code', disablePadding: false, label: I18n.t('ChannelDrawer.EmployeesTable.Code') },
    {
      key: 'department',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Department'),
    },
    {
      key: 'jobTitle',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Job Position'),
    },
    {
      key: 'location',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Location'),
    },
    {
      key: 'workedHours',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Worked Hours'),
    },
    {
      key: 'distribution',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Hour distribution'),
    },
    {
      key: 'actions',
      disablePadding: false,
      label: I18n.t('ChannelDrawer.EmployeesTable.Actions'),
    },
  ];

  sort = by => {
    const { order } = this.state;

    this.setState({
      order: { by, isReverse: !order.isReverse },
    });
  };

  search = searchString => {
    const { employees } = this.props;
    const filtered_ids = searchEmployees(searchString, employees);

    this.setState({ searchString, filtered_ids, page: 0 });
  };

  changePage = ({ selected: page }) => {
    this.setState({ page });
  };

  changeRowsPerPage = e => {
    this.setState({ rowsPerPage: e.target.value });
  };

  filterEmployees = employees => {
    const { filtered_ids, searchString } = this.state;
    if (isEmpty(filtered_ids) && isEmpty(searchString)) return employees;
    if (isEmpty(filtered_ids) && !isEmpty(searchString)) return [];

    return filtered_ids.map(_id => arrayToObject(employees)[_id]);
  };

  sortEmployees = employees => {
    const { order } = this.state;
    const sortFunction = (first, second) => {
      const f = first[order.by];
      const s = second[order.by];
      let ret = 0;
      if (typeof f === typeof s && typeof f === 'string') {
        ret = f.localeCompare(s);
      } else if (typeof f === typeof s && typeof f === 'undefined') {
        ret = 0;
      } else if (typeof f === 'undefined') {
        ret = -1;
      } else if (typeof s === 'undefined') {
        ret = 1;
      } else if (typeof f === typeof s && typeof Array.isArray(f)) {
        ret = f.sort(sortFunction).join(' ').localeCompare(s.sort(sortFunction).join(' '));
      }
      return order.isReverse ? -ret : ret;
    };
    const sorted = employees.sort(sortFunction);
    return sorted;
  };

  paginateEmployees = employees => {
    const { page, rowsPerPage } = this.state;
    return employees.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  render() {
    const { additionalControls, classes, employees, company, onExport } = this.props;

    const { order, rowsPerPage, page } = this.state;
    const employeesCount = employees.length;

    const filtered = this.filterEmployees(employees);
    const sorted = this.sortEmployees(filtered);
    const paginated = this.paginateEmployees(sorted);

    return (
      <div className={classes.root}>
        <Toolbar onSearch={this.search} tab="TimeTracking" onExport={onExport}>
          {additionalControls}
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableHead order={order} onSort={this.sort} columns={this.columns} />
            <TableBody>
              {paginated.map(
                employee =>
                  employee && (
                    <EmployeeRow key={employee._id} company={company} employee={employee} />
                  ),
              )}
            </TableBody>
          </Table>
        </div>
        <Pagination
          page={page}
          perPage={rowsPerPage}
          total={employeesCount}
          onChange={this.changePage}
          onChangePerPage={this.changeRowsPerPage}
        />
      </div>
    );
  }
}

PeoplesTable.propTypes = {
  company: companyShape.isRequired,
  employees: arrayOf(employeeShape).isRequired,
  classes: object.isRequired,
  additionalControls: object,
  onExport: func.isRequired,
};

PeoplesTable.defaultProps = {
  additionalControls: null,
};

export default withStyles(styles)(PeoplesTable);
