/* eslint-disable */
import { SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import SetupIcon from 'assets/icons/setup.svg';
import { func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    padding: '15px 1px',
    alignItems: 'center',
  },

  container: {
    flexGrow: 1,
    height: 40,
    padding: '36px 0',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid ' + theme.palette.secondary.extraLight,
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  square: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.background.paper,
    justifyContent: 'center',
    borderRadius: 5,
    marginRight: 15,
    width: 40,
    height: 40,
    background: theme.palette.primary.main,
  },
  squareIcon: {
    color: theme.palette.white,
    width: 17,
    height: 17,
  },
});

const NewChannelArea = ({ classes, onClick }) => (
  <div className={classes.root} onClick={onClick}>
    <div className={classes.square}>
      <SvgIcon className={classes.squareIcon}>
        <SetupIcon />
      </SvgIcon>
    </div>
    <div className={classes.container}>
      <Typography className={classes.label}>
        {I18n.t('EmployeesDrawer.Create new channel')}
      </Typography>
    </div>
  </div>
);

NewChannelArea.propTypes = {
  classes: object.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(NewChannelArea);
