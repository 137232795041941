import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import WhatsAppTextFormatter from 'components/@home/@messages/ChatPanel/MessagesList/MessageGroup/WhatsAppTextFormatter';
import MessageIcon from 'components/common/MessageIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import messageShape from 'shapes/message';
import { getMessageType, MESSAGE_TYPES } from 'utils/getMessageType';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline',
    lineHeight: '1.2em',
    '& *:first-child': {
      marginRight: 2,
    },
    '& *:last-child': {
      marginRight: 0,
    },
    '& > * + *': {
      marginLeft: 3,
    },
    '& $text': {
      color: theme.palette.secondary.main,
      '& *': {
        verticalAlign: 'baseline',
      },
    },
  },
  text: {
    lineHeight: '1em',
  },
  deleted: {
    fontStyle: 'oblique',
  },
}));
const MessagePreview = ({ message, showImages }) => {
  const classes = useStyles();
  if (!message) {
    return null;
  }
  const messageText = {
    [MESSAGE_TYPES.FILE]: message.attachments?.[0]?.name,
    [MESSAGE_TYPES.AUDIO]: I18n.t('LastMessage.AttachmentBubble.Audio'),
    [MESSAGE_TYPES.IMAGE]: I18n.t('LastMessage.AttachmentBubble.Image'),
    [MESSAGE_TYPES.VIDEO]: I18n.t('LastMessage.AttachmentBubble.Video'),
  };
  const type = getMessageType(message);
  const textPreview = message.softDeletedAt
    ? I18n.t('Message.deleted')
    : message.text?.replace('attachments', '') ||
      messageText[type] ||
      message.title ||
      message.article?.title ||
      '';

  return (
    <div className={classes.root}>
      <MessageIcon message={message} showImages={showImages} />
      <Typography
        component="span"
        display="inline"
        className={classnames({ [classes.deleted]: message.softDeletedAt, [classes.text]: true })}
      >
        <WhatsAppTextFormatter>{textPreview}</WhatsAppTextFormatter>
      </Typography>
    </div>
  );
};

MessagePreview.propTypes = {
  message: messageShape,
  showImages: PropTypes.bool,
};
MessagePreview.defaultProps = {
  message: null,
  showImages: true,
};

export default MessagePreview;
