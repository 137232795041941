import { Grid, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FormikTableField from 'components/formik/TableField';
import { Field } from 'formik';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import connector from './connector';

const styles = {
  root: {
    paddingTop: 20,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  field: {
    padding: 5,
  },
};

const TeamsControls = ({ classes, teams }) => {
  const teamsColumns = [
    {
      key: 'name',
      disablePadding: false,
      label: I18n.t('EmployeeDrawer.TeamsTable.TeamName'),
      xsVisible: true,
    },
    {
      key: 'groupRole',
      disablePadding: false,
      label: I18n.t('EmployeeDrawer.TeamsTable.Role'),
      xsVisible: true,
      type: 'dropdown',
      options: ['member', 'writer', 'admin'].map(value => ({
        value,
        label: I18n.t(`Roles.${value}`),
      })),
      defaultValue: 'member',
    },
    {
      key: 'delete',
      xsVisible: true,
      type: 'remove',
    },
  ];

  const onTeamCheckboxChange = (formik, value) => {
    formik.setFieldValue('teams', value);
  };

  const previewMembers = form => {
    form.setFieldValue('preview', true);
    form.submitForm();
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2} className={classes.label}>
          <Typography>{I18n.t('ChannelDrawer.Teams')}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Field
            component={FormikTableField}
            suggestions={teams?.map(team => ({
              label: team.name,
              value: {
                id: team._id,
                name: team.name,
                groupRole: 'member',
              },
            }))}
            onChange={previewMembers}
            columns={teamsColumns}
            onCheckboxChange={onTeamCheckboxChange}
            buttonText={I18n.t('ChannelDrawer.Add team')}
            placeholder={I18n.t('ChannelDrawer.Start typing')}
            name="groupTeams"
          />
        </Grid>
      </Grid>
    </div>
  );
};

TeamsControls.propTypes = {
  classes: object.isRequired,
  teams: object.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(TeamsControls));
