import { Avatar, IconButton, Typography } from '@mui/material';
import MessagePreview from 'components/@home/@messages/ChatPanel/PinnedMessage/MessagePreview';
import isEmpty from 'lodash/isEmpty';
import CloseIcon from 'mdi-react/CloseIcon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import messageShape from 'shapes/message';
import useStyles from './useStyles';

const PinnedMessage = ({ message, onUnpin, canUnpin, onClick }) => {
  const handleUnpin = useCallback(() => {
    onUnpin(message);
  }, [onUnpin, message]);
  const handleClick = useCallback(() => {
    onClick(message);
  }, [onClick, message]);
  const att = message?.attachments?.[0] || {};
  const classes = useStyles();
  if (isEmpty(message) || !message.createdAt) {
    return null;
  }
  return (
    <button type="button" className={classes.container} onClick={handleClick}>
      <div className={classes.border} />
      {att.meta?.thumb && (
        <div className={classes.imgWrapper}>
          <Avatar variant="rounded" alt="" className={classes.image} src={att.meta?.thumb} />
        </div>
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Typography className={classes.title}>{I18n.t('PinnedMessage')}</Typography>
          <MessagePreview message={message} showImages={false} />
        </div>
        {canUnpin && (
          <IconButton className={classes.unpin} onClick={handleUnpin} size="large">
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </button>
  );
};

PinnedMessage.propTypes = {
  message: messageShape,
  canUnpin: PropTypes.bool,
  onUnpin: PropTypes.func,
  onClick: PropTypes.func.isRequired,
};

PinnedMessage.defaultProps = {
  message: null,
  canUnpin: true,
  onUnpin: () => {},
};

export default PinnedMessage;
