/**
 * @flow
 */

import Add from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { Button, FormHelperText, IconButton, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/formik/TextField';
import { FastField, FieldArray } from 'formik';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  listItem: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'flex-start',
  },
  add: {
    marginTop: theme.spacing(1),
  },
  remove: {
    marginLeft: theme.spacing(1),
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  iconRemove: {
    marginTop: 9,
    minWidth: 30,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
}));

type Props = {
  field: { name: string; value: Array<string> };
  form: Object;
  add?: string;
  remove?: string;
  itemComponent?: any;
  filter?: Array<any>;
  filterOut?: Array<any>;
};

const ListField = ({ field, form, add, remove, itemComponent, filter, filterOut }: Props) => {
  const { name, value } = field;
  const fieldMeta = form.getFieldMeta(name);
  const { touched } = fieldMeta;
  let { error } = fieldMeta;
  if (typeof error !== 'string') {
    // Don't show children errors
    error = null;
  }
  const classes = useStyles();
  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          {value && value.length > 0
            ? value.map((itemValue, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={classes.listItem} key={index}>
                  <FastField
                    component={itemComponent}
                    name={`${name}.${index}`}
                    filter={filter}
                    filterOut={filterOut}
                  />
                  <IconButton
                    className={classes.remove}
                    size="small"
                    variant="text"
                    onClick={() => arrayHelpers.remove(index)}
                    disableElevation
                    title={remove || I18n.t('ListField.Remove')}
                  >
                    <SvgIcon className={classes.iconRemove}>
                      <Delete />
                    </SvgIcon>
                  </IconButton>
                </div>
              ))
            : null}
          <Button
            className={classes.add}
            size="small"
            variant="outlined"
            onClick={() => arrayHelpers.push('')}
            disableElevation
          >
            <SvgIcon className={classes.icon}>
              <Add />
            </SvgIcon>
            {add || I18n.t('ListField.Add')}
          </Button>
          {touched && error && <FormHelperText error>{error}</FormHelperText>}
        </div>
      )}
    />
  );
};

ListField.defaultProps = {
  add: null,
  remove: null,
  itemComponent: TextField,
  filter: undefined,
  filterOut: undefined,
};

export default ListField;
