import React from 'react';
import { number, func } from 'prop-types';
import { Checkbox } from '@mui/material';

const SelectAllCheckbox = ({ selected, rows, onChange }) => (
  <Checkbox
    color="primary"
    indeterminate={selected > 0 && selected < rows}
    checked={selected === rows}
    onChange={onChange}
  />
);
SelectAllCheckbox.propTypes = {
  selected: number.isRequired,
  rows: number.isRequired,
  onChange: func.isRequired,
};

export default SelectAllCheckbox;
