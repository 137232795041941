import { Button, SvgIcon, Toolbar } from '@mui/material';
import { EditDrawerContext } from 'components/@home/@documents/SentDocuments/EditDrawer';
import FolderArrowRightIcon from 'mdi-react/FolderArrowRightIcon';
import React, { useContext } from 'react';
import { I18n } from 'react-redux-i18n';

const SentDocumentsToolbar = () => {
  const editDrawer = useContext(EditDrawerContext);
  const handleClick = () => {
    editDrawer.open();
  };
  return (
    <Toolbar
      sx={{
        minHeight: '0 !important',
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'divider',
        '& .MuiButton-root': {
          borderRadius: 0,
          minHeight: 49,
          whiteSpace: 'nowrap',
          '& .MuiCircularProgress-root': {
            marginLeft: 1,
          },
        },
      }}
    >
      <Button variant="text" color="secondary" onClick={handleClick}>
        <SvgIcon
          sx={{
            fontSize: 18,
            marginRight: 1,
            color: 'primary.main',
          }}
        >
          <FolderArrowRightIcon />
        </SvgIcon>
        {I18n.t('SentDocuments.New template')}
      </Button>
    </Toolbar>
  );
};

export default SentDocumentsToolbar;
