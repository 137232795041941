import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import channels from 'store/app/entities/channels/action';
import companies from 'store/app/entities/companies/action';
import channelDrawer from 'store/app/ui/drawers/channel/action';
import currentChannel from 'store/selectors/currentChannel';

const mapStateToProps = state => ({
  isOpen: state.drawers.channel.isOpen,
  channel: currentChannel(state),
  isEdit: state.drawers.channel.type === 'edit',
});

const mapDispatchToProps = dispatch => ({
  actions: {
    channels: bindActionCreators(channels, dispatch),
    companies: bindActionCreators(companies, dispatch),
    drawers: {
      channel: bindActionCreators(channelDrawer, dispatch),
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
