/* eslint-disable react/jsx-props-no-spreading */

import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CopyIcon from 'assets/icons/copy.svg';
import copy from 'copy-to-clipboard';
import PropTypes, { element, func, object, string } from 'prop-types';
import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

const CopyButton = props => {
  const { classes, value, text, variant, color, ButtonProps, inputRef } = props;
  const handleClick = useCallback(() => {
    if (value !== undefined) {
      copy(value);
    } else if (inputRef?.current !== undefined) {
      copy(inputRef.current.value);
    }
  }, [inputRef, value]);
  return (
    <div className={classes.root}>
      <Button variant={variant} color={color} onClick={handleClick} {...ButtonProps}>
        <SvgIcon className={classes.icon}>
          <CopyIcon />
        </SvgIcon>
        {typeof text === 'undefined' ? I18n.t('CopyField.Copy') : text}
      </Button>
    </div>
  );
};

CopyButton.propTypes = {
  classes: object.isRequired,
  value: string.isRequired,
  text: string,
  variant: string,
  ButtonProps: object,
  color: string,
  inputRef: PropTypes.oneOfType([
    // Either a function
    func,
    // Or the instance of a DOM native element (see the note about SSR)
    PropTypes.shape({ current: PropTypes.instanceOf(element) }),
  ]),
};

CopyButton.defaultProps = {
  text: undefined,
  variant: 'outlined',
  color: 'secondary',
  ButtonProps: {},
  inputRef: undefined,
};
export default withStyles(styles)(CopyButton);
