import React from 'react';
import { I18n } from 'react-redux-i18n';
import { func, string, bool, shape } from 'prop-types';
import Cell from './Cell';

const TitleCells = ({ onSort, order, showName }) => {
  const cells = [
    {
      key: 'respondedAt',
      disablePadding: false,
      label: I18n.t('FormAnalyticsDrawer.EmployeesTable.RespondedAt'),
    },
    {
      key: 'response',
      disablePadding: false,
      label: I18n.t('FormAnalyticsDrawer.EmployeesTable.Response'),
    },
  ];

  if (showName) {
    cells.unshift({
      key: 'name',
      disablePadding: false,
      label: I18n.t('FormAnalyticsDrawer.EmployeesTable.Name'),
    });
  }

  return cells.map(cell => (
    <Cell key={cell.key} name={cell.key} order={order} onSort={onSort} label={cell.label} />
  ));
};

TitleCells.propTypes = {
  showName: bool.isRequired,
  onSort: func.isRequired,
  order: shape({
    by: string,
    isReverse: bool,
  }).isRequired,
};

export default TitleCells;
