/* eslint-disable no-console */
import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import { func, object } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import SelectTitle from '../SelectTitle';
import BrowserItem from '../BrowserItem';

const styles = theme => ({
  root: {
    paddingRight: '20px',
    marginBottom: '10px',
  },
  check: {
    color: theme.palette.primary.main,
  },
});

const BrowserNotifications = ({ classes, settings, onChange }) => {
  const handleChange = useCallback(
    (name, value) => {
      const newVal = {};
      newVal[name] = value;
      onChange({ ...settings, ...newVal });
    },
    [settings, onChange],
  );

  return (
    <div className={classes.root}>
      <SelectTitle headline={I18n.t('SettingsDrawer.Computer_Notifications')} />

      {Object.entries(settings).map(([name, value]) => (
        <BrowserItem
          headline={I18n.t(`SettingsDrawer.${name}`)}
          key={name}
          enabled={value}
          onClick={val => handleChange(name, val)}
        />
      ))}
    </div>
  );
};

BrowserNotifications.propTypes = {
  classes: object.isRequired,
  settings: object.isRequired,
  onChange: func.isRequired,
};

export default withStyles(styles)(BrowserNotifications);
