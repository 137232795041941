const formatMentions = (inText, mentions = [], availableEmployees, formatMentionNode) => {
  /**
   * Format the Mentions
   * and display them with
   * the different styles
   */
  if (!inText || inText === '' || !mentions.length) return inText;
  const ret = [];
  let lastIndex = 0;
  mentions.forEach(({ employee: employeeId, pos: [start, end] }, idx) => {
    const employee = availableEmployees.find(e => e._id === employeeId);
    const initialStr = start === 1 ? '' : inText.substring(lastIndex, start);
    lastIndex = end + 1;
    ret.push(initialStr);
    const formattedMention = employee
      ? formatMentionNode(`@${employee.name}`, employee, start)
      : inText.substr(start, end - start + 1);
    ret.push(formattedMention);
    if (idx === mentions.length - 1) {
      const lastStr = inText.substr(lastIndex); // remaining string
      ret.push(lastStr);
    }
  });
  return ret;
};

export default formatMentions;
