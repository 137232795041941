import { Alert, Button, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PeopleSceneContext } from 'components/@home/@people/PeopleSceneContext';
import SessionsDrawer from 'components/@home/@people/SessionsDrawer';
import DeleteDialog from 'components/common/DeleteDialog';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import FileDownload from 'mdi-react/FileDownloadIcon';
import LockOpenIcon from 'mdi-react/LockOpenIcon';
import moment from 'moment';
import { bool, object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import companyShape from 'shapes/shortCompany';
import { useGetMemberChannels } from 'store/app/entities/channels/action';
import { getMagicLink } from 'utils/getMagicLink';
import connector from './connector';
import PeoplesTable from './PeoplesTable';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflowY: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    color: theme.palette.primary.ultraDark,
    marginRight: 10,
  },
  icon: {
    fontSize: 24,
    color: theme.palette.primary.dark,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  warning: {
    position: 'relative',
  },
  warningIcon: {
    position: 'absolute',
  },
}));

const PeopleScene = ({ actions, isSuperAdmin, hasDivisions, isLoading, authCompany }) => {
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const sessionsDrawer = useRef();
  useGetMemberChannels();

  const classes = useStyles();

  const deleteEmployee = e => {
    setEmployeeToDelete(e);
  };

  const handleClose = () => {
    setEmployeeToDelete(null);
  };

  const handleDeleteConfirm = () => {
    actions.employees.destroy(employeeToDelete._id);
    setEmployeeToDelete(null);
  };

  const editEmployee = e => {
    actions.drawers.employee.open(e);
  };

  const openProfile = e => {
    actions.drawers.profile.open(e._id);
  };

  const downloadReport = async (template = false) => {
    const {
      value: { employees },
    } = await actions.companies.listEmployees();
    import('xlsx').then(XLSX => {
      const data = [
        [
          ...(template ? [] : ['_id']),
          'givenName',
          'familyName',
          ...(hasDivisions ? ['division'] : []),
          'code',
          'id',
          'phone',
          'email',
          'department',
          'jobTitle',
          'location',
          'reportsTo',
          'tags',
          'notes',
          ...(template ? [] : ['createdAt', 'lastSeen', 'status']),
          'teamsMember',
          'teamsAdmin',
          'teamsGuest',
          'trackTime',
        ],
      ];
      if (isSuperAdmin) {
        data[0].push('magicLink');
      }
      if (!template) {
        employees.forEach(e => {
          const item = [
            e._id,
            e.firstName,
            e.surName,
            ...(hasDivisions ? [e.division] : []),
            e.code,
            e.id,
            e.phone,
            e.email,
            e.department,
            e.jobTitle,
            e.location,
            e.reportsTo,
            e.tags.join(', '),
            e.notes?.replaceAll(/ ?\n/g, ' \n'),
            new Date(e.createdAt * 1000),
            e.user?.lastSeen ? moment.unix(e.user.lastSeen).toDate() : null,
            e.status,
            e.teams
              ?.filter(t => t.role === 'member')
              .map(t => t.name)
              .join(', '),
            e.teams
              ?.filter(t => t.role === 'admin')
              .map(t => t.name)
              .join(', '),
            e.teams
              ?.filter(t => t.role === 'guest')
              .map(t => t.name)
              .join(', '),
            e.settings?.isTrackingTime || false,
          ];
          if (isSuperAdmin) {
            item.push(getMagicLink(authCompany, e) || '');
          }
          data.push(item);
        });
      }
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Logs');
      const now = moment();
      const filename = template
        ? 'ommnio_users_template.xlsx'
        : `${now.format('YYYY_MM_DD')}_employee_export.xlsx`;
      XLSX.writeFile(wb, filename, {
        bookType: 'xlsx',
        type: 'array',
      });
    });
  };

  const controls = (
    <Button
      variant="outlined"
      color="secondary"
      className={classes.button}
      onClick={() => downloadReport()}
    >
      <SvgIcon className={classes.plus}>
        <FileDownload />
      </SvgIcon>
      {I18n.t('PeoplesScene.Export employees')}
    </Button>
  );

  if (isLoading) {
    return (
      <div className={classes.loading}>
        <Loading size={56} />
      </div>
    );
  }
  const showSessions = e => {
    sessionsDrawer.current.open(e);
  };
  return (
    <PeopleSceneContext.Provider value={{ downloadReport }}>
      <div className={classes.root}>
        <PeoplesTable
          isSuperAdmin={isSuperAdmin}
          onOpen={openProfile}
          onEdit={editEmployee}
          onDelete={deleteEmployee}
          additionalControls={controls}
          showSessions={showSessions}
        />
        <DeleteDialog
          typeBack={employeeToDelete?.surName?.trim()}
          message={I18n.t('PeoplesScene.ConfirmDelete', {
            name: `${employeeToDelete?.firstName} ${employeeToDelete?.surName}`.trim(),
          })}
          isOpen={!!employeeToDelete}
          onClose={handleClose}
          onConfirm={handleDeleteConfirm}
        >
          <Alert severity="warning">
            <Typography display="inline">{I18n.t('PeoplesScene.DeleteWarning')}</Typography>
            <span className={classes.warning}>
              <LockOpenIcon className={classes.warningIcon} size={18} />
            </span>
          </Alert>
        </DeleteDialog>
        <SessionsDrawer ref={sessionsDrawer} />
      </div>
    </PeopleSceneContext.Provider>
  );
};

PeopleScene.propTypes = {
  actions: object.isRequired,
  isLoading: bool.isRequired,
  isSuperAdmin: bool.isRequired,
  hasDivisions: bool.isRequired,
  authCompany: companyShape.isRequired,
};

export default deferRender(connector(PeopleScene));
