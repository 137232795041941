/**
 * @flow
 */

import type { Node } from 'react';
import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import Autosuggest from './Autosuggest';
import connector from './connector';

type Props = {
  error: boolean,
  helperText?: Node,
  actions: Object,
  onChange: Function,
  name: String,
  value?: String,
  countryCodes: {
    loading: boolean,
    codes: Object,
  },
  locale: String,
};

const PhonePrefixField = ({
  actions: {
    config: { countryCodes },
  },
  countryCodes: { loading, codes },
  value,
  error,
  helperText,
  onChange,
  name,
  locale,
}: Props) => {
  const loaded = codes[locale]?.length > 0;

  useEffect(() => {
    if (!loaded && !loading) {
      countryCodes.load(locale);
    }
  }, [countryCodes, loaded, locale]);

  return (
    <Autosuggest
      autoWidth
      name={name}
      placeholder={
        loading ? I18n.t('Login.Loading country codes') : I18n.t('Login.Choose country prefix')
      }
      error={error}
      helperText={helperText}
      onChange={onChange}
      suggestions={codes[locale] || []}
      value={value}
    />
  );
};

PhonePrefixField.defaultProps = {
  helperText: '',
  value: '',
};

export default connector(PhonePrefixField);
