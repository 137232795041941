import { Box, Drawer, Typography } from '@mui/material';
import { UserForm } from 'components/@admin/@users/UserForm';
import CloseButton from 'components/@home/drawers/CloseButton';
import { Formik } from 'formik';
import { bool, func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useCreateUserMutation, useEditUserMutation } from 'store/app/entities/UsersSlice';

const getBody = async ({ isApp, isReadOnly, role, phone }) => ({
  isApp: isApp?.[0] === 'on' ? 1 : 0,
  isReadOnly: isReadOnly?.[0] === 'on' ? 1 : 0,
  role,
  phone,
});

const UserDrawer = ({ user, isOpen, onClose }) => {
  const isEdit = user?._id;
  const [createUser, createUserStatus] = useCreateUserMutation();
  const [editUser, editUserStatus] = useEditUserMutation();
  const { error, isLoading, isError } = {
    error: createUserStatus.error || editUserStatus.error,
    isLoading: createUserStatus.isLoading || editUserStatus.isLoading,
    isError: createUserStatus.isError || editUserStatus.isError,
  };
  const handleSubmit = async ({ _id, ...values }) => {
    const body = await getBody(values);
    const ret = _id ? await editUser({ _id, body }) : await createUser(body);
    if (!ret.error) {
      onClose();
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          maxWidth: 'calc(100vw - 40px)',
          width: {
            xs: '100%',
            md: 1100,
          },
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            paddingTop: 2.5,
            paddingLeft: 2.5,
          }}
        >
          <CloseButton onClick={onClose} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            minWidth: 500,
            flexDirection: 'column',
            padding: 2.5,
          }}
        >
          <Typography variant="h6" component="h1">
            {isEdit
              ? I18n.t(`UserDrawer.Edit`, { phone: user.phone })
              : I18n.t(`UserDrawer.Create user`)}
          </Typography>
          <Formik
            initialValues={{
              ...user,
              isApp: user?.isApp ? ['on'] : [],
              isReadOnly: user?.isReadOnly ? ['on'] : [],
            }}
            onSubmit={handleSubmit}
          >
            <UserForm
              company={user}
              onClose={onClose}
              error={error}
              isLoading={isLoading}
              isError={isError}
            />
          </Formik>
        </Box>
      </Box>
    </Drawer>
  );
};

UserDrawer.propTypes = {
  user: object,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

UserDrawer.defaultProps = {
  company: {},
};

export default UserDrawer;
