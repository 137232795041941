/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import BoltIcon from 'assets/icons/bolt.svg';
import StarSignIcon from 'assets/icons/star-sign.svg';
import StarIcon from 'assets/icons/star.svg';
import { object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import messageShape from 'shapes/message';
import AcknowledgeCaption from './AcknowledgeCaption';
import MonitorButton from './MonitorButton';
import OpenButton from './OpenButton';

const styles = theme => ({
  root: {
    position: 'relative',
    padding: '30px 20px',
    height: 200,
    boxShadow: '0 7px 22px 0 rgba(2, 2, 3, 0.13)',
    border: 'solid 1px rgba(221, 226, 235, 0.1)',
    minWidth: '360px',
    maxWidth: '500px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.primary.main,
    marginBottom: '10px',
  },

  subheading: {
    fontSize: '12px',
    fontWeight: '500',
    textTransform: 'uppercase',
    marginBottom: '5px',
    color: theme.palette.primary.main,
  },

  title: {
    textAlign: 'center',
    flex: '0 0 auto',
    width: '90%',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '20px',
    margin: '0 0 10px',
    fontWeight: 'bold',
    color: theme.palette.primary.ultraDark,
  },
  buttons: {
    display: 'flex',
    flex: '0 0 auto',
  },
});

const Read = ({ classes, message }) => {
  let Icon = StarIcon;
  let subheading = I18n.t('AcknowledgementMessage.MANDATORY READ');

  if (message.digitalSignature) {
    Icon = StarSignIcon;
    subheading = I18n.t('AcknowledgementMessage.DIGITAL SIGNATURE');
  } else if (message.form) {
    Icon = BoltIcon;
    subheading = I18n.t('AcknowledgementMessage.FORM');
  }

  return (
    <div className={classes.root}>
      <SvgIcon className={classes.icon}>
        <Icon />
      </SvgIcon>
      <span className={classes.subheading}>{subheading}</span>
      <h3 className={classes.title}>{message.text}</h3>
      <div className={classes.buttons}>
        <OpenButton message={message} />
        &nbsp;
        <MonitorButton message={message} />
      </div>
      <AcknowledgeCaption
        form={message.form}
        digitalSignature={message.digitalSignature}
        acknowledges={message.acknowledges}
      />
    </div>
  );
};

Read.propTypes = {
  message: messageShape.isRequired,
  classes: object.isRequired,
};

export default withStyles(styles)(Read);
