import React from 'react';
import { object, bool, string } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    minWidth: '90px',
    marginLeft: '10px',
    fontSize: '15px',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    borderColor: theme.palette.primary.main,
  },
});

const PublishButton = ({ classes, disabled, type }) => (
<Button
    disabled={disabled}
    color="primary"
    type={type}
    variant="contained"
    className={classes.root}
  >
    {I18n.t('AcknowledgementCreate.Publish')}
  </Button>
);PublishButton.propTypes = {
  classes: object.isRequired,
  type: string.isRequired,
  disabled: bool,
};
PublishButton.defaultProps = {
  disabled: false,
};

export default withStyles(styles)(PublishButton);
