import Add from '@mui/icons-material/Add';
import { Box, Button, Typography } from '@mui/material';
import { ObjectId } from 'bson';
import ActionButtons from 'components/@home/drawers/RulesDrawer/ActionButtons';
import RuleCard from 'components/@home/drawers/RulesDrawer/RuleCard';
import Loading from 'components/common/Loading';
import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { I18n } from 'react-redux-i18n';
import { useSaveRulesMutation } from 'store/app/entities/TeamMembershipRulesSlice';
import rulesActions from 'store/app/ui/drawers/rules/action';
import useActions from 'utils/useActions';

const styles = {
  root: {
    flexGrow: 1,
    padding: 2.5,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorContainer: {
    flexGrow: 1,
  },
  errorMessage: {
    padding: '0 20px',
    alignSelf: 'center',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible auto',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    my: 2,
  },
  button: {
    borderRadius: 1,
    border: '1px dashed',
    borderColor: 'divider',
    width: 'auto',
    p: 4,
    mr: 2.5,
  },
  loaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
};

const RulesForm = ({ team, isLoading }) => {
  const close = useActions(rulesActions.close);
  const [save] = useSaveRulesMutation();
  const {
    handleSubmit,
    formState: { isValid, isSubmitted },
  } = useFormContext();
  const onSubmit = async body => {
    await save({ teamId: team._id, body });
    close();
  };
  const { fields, append, remove } = useFieldArray({
    name: 'rules',
  });
  return (
    <Box component="form" sx={styles.root} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h5">
        {I18n.t('RulesDrawer.MembershipRules', { name: team?.name })}
      </Typography>
      <Box sx={styles.content}>
        {isLoading ? (
          <Box sx={styles.loaderWrapper}>
            <Loading size={50} />
          </Box>
        ) : (
          <>
            <Box sx={styles.list}>
              {fields.map((field, index) => (
                <RuleCard key={field._id} index={index} remove={() => remove(index)} />
              ))}
              <Button
                sx={styles.button}
                startIcon={<Add />}
                onClick={() =>
                  append({
                    _id: new ObjectId().toString(),
                    role: team.defaultRole,
                    conditions: [
                      {
                        _id: new ObjectId().toString(),
                        field: '',
                        operator: 'in',
                        value: '',
                      },
                    ],
                  })
                }
                fullWidth
              >
                {I18n.t('RulesDrawer.AddRule')}
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={styles.actions}>
        <Box sx={styles.errorContainer}>
          {!isValid && isSubmitted && (
            <Typography align="right" color="error" sx={styles.errorMessage}>
              {I18n.t('RulesDrawer.hasErrors')}
            </Typography>
          )}
        </Box>
        <ActionButtons />
      </Box>
    </Box>
  );
};

RulesForm.propTypes = {
  team: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default RulesForm;
