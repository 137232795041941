import { Button, SvgIcon, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FileDownload from 'mdi-react/FileDownloadIcon';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import { func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  gray: {
    fontWeight: 'normal',
    color: theme.palette.secondary.main,
  },
  actions: {
    display: 'flex',
  },
  button: {
    color: theme.palette.primary.ultraDark,
    marginRight: 10,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

const Headline = ({ classes, onOpen, name, onExport, ...props }) => (
  <div className={classes.root}>
    <Typography {...props} className={classes.title} variant="h5">
      <span className={classes.gray}>{I18n.t('AnalyticsDrawer.Docs')}</span>{' '}
      <KeyboardArrowRightIcon className={classes.gray} /> {name}
    </Typography>
    <div className={classes.actions}>
      <Button variant="outlined" color="secondary" className={classes.button} onClick={onExport}>
        <SvgIcon className={classes.plus}>
          <FileDownload />
        </SvgIcon>
        {I18n.t('AnalyticsDrawer.Export responses')}
      </Button>
      <Button color="secondary" variant="outlined" onClick={onOpen}>
        {' '}
        {I18n.t('AnalyticsDrawer.Open file')}
      </Button>
    </div>
  </div>
);

Headline.propTypes = {
  name: string.isRequired,
  onOpen: func.isRequired,
  classes: object.isRequired,
  onExport: func.isRequired,
};

export default withStyles(styles)(Headline);
