import React from 'react';
import { object, func, string } from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    fontSize: '15px',
    color: theme.palette.secondary.main,
  },
});

const DiscardButton = ({ classes, type, onClick }) => (
<Button
    type={type}
    className={classes.root}
    onClick={onClick}
  >
    {I18n.t('AcknowledgementCreate.Discard')}
  </Button>
);DiscardButton.propTypes = {
  classes: object.isRequired,
  type: string.isRequired,
  onClick: func.isRequired,
};

export default withStyles(styles)(DiscardButton);
