import { Checkbox, MenuItem, Select, SvgIcon, TableCell, TableRow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InviteIcon from 'assets/icons/invite.svg';
import TooltipIfFromTeam from 'components/@home/drawers/ChannelDrawer/Form/TooltipIfFromTeam';
import LineClampTooltip from 'components/common/LineClampTooltip';
import isEmpty from 'lodash/isEmpty';
import { arrayOf, func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';
import matchStringArray from 'utils/matchStringArray';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  cell: {
    minWidth: 110,
  },
  inactive: {
    minWidth: 110,
    color: theme.palette.secondary.main,
  },
  checkbox: {
    position: 'relative',
    padding: 0,
    width: 'auto',
  },
  icon: {
    width: 16,
    top: 12,
    position: 'absolute',
    left: 42,
    color: theme.palette.secondary.main,
  },
}));

const EmployeeRow = ({ employee, onSelect, onSetRole, member, searchString, teams }) => {
  const classes = useStyles();

  const isInactive = !employee.isAcceptTerms;

  const select = () => {
    if (!member?.fromTeams?.length) {
      onSelect(employee);
    }
  };

  const onChangeRole = e => {
    onSetRole(employee, e.target.value);
  };

  const cellClassName = isInactive ? classes.inactive : classes.cell;

  const getName = e => e.name + (isEmpty(e.code) ? '' : ` - ${e.code}`);

  const role = member?.groupRole;

  const sortedTags = matchStringArray(employee.tags, searchString);

  return (
    <TableRow hover className={classes.root} onClick={select} selected={!!member}>
      <TableCell padding="checkbox" className={classes.checkbox}>
        {isInactive && (
          <SvgIcon className={classes.icon}>
            <InviteIcon />
          </SvgIcon>
        )}
        <TooltipIfFromTeam member={member} teams={teams}>
          <Checkbox color="primary" checked={!!member} disabled={member?.fromTeams?.length} />
        </TooltipIfFromTeam>
      </TableCell>
      <TableCell className={cellClassName} padding="normal">
        <LineClampTooltip>{getName(employee)}</LineClampTooltip>
      </TableCell>
      <TableCell className={cellClassName}>
        <LineClampTooltip>{employee.department}</LineClampTooltip>
      </TableCell>
      <TableCell className={cellClassName}>
        <LineClampTooltip>{employee.jobTitle}</LineClampTooltip>
      </TableCell>
      <TableCell className={cellClassName}>
        <LineClampTooltip>{employee.location}</LineClampTooltip>
      </TableCell>
      <TableCell className={cellClassName}>
        {!!member && (
          <Select
            MenuProps={{ onClick: e => e.stopPropagation() }}
            style={{ width: 100 }}
            value={role}
            onChange={onChangeRole}
            disabled={member?.createdFromTeams}
          >
            {['member', 'writer', 'admin'].map(r => (
              <MenuItem key={r} value={r}>
                {I18n.t(`Roles.${r}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
      <TableCell className={cellClassName}>
        <LineClampTooltip>{sortedTags.join(', ')}</LineClampTooltip>
      </TableCell>
    </TableRow>
  );
};

EmployeeRow.propTypes = {
  employee: employeeShape.isRequired,
  member: object.isRequired,
  onSelect: func.isRequired,
  searchString: string,
  onSetRole: func.isRequired,
  teams: arrayOf(object).isRequired,
};

EmployeeRow.defaultProps = {
  searchString: '',
};

export default EmployeeRow;
