/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import { Checkbox, FormControlLabel, IconButton, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import SelectionDragIcon from 'mdi-react/SelectionDragIcon';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const styles = theme => ({
  root: {
    borderRadius: 6,
    display: 'flex',
    padding: 10,
    border: `1.5px solid ${theme.palette.divider}`,
    width: '100%',
    flex: '0 0 auto',
    background: 'none',
    flexDirection: 'column',
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
  },
  space: {
    width: 33,
    flex: '0 1 auto',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'hidden',
    textAlign: 'left',
    '& label': {
      marginLeft: 0,
      marginRight: 0,
      '& span': {
        fontSize: 14,
      },
    },
  },
  selected: {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
  error: {
    border: `1.5px solid ${theme.palette.danger}`,
  },
  iconContainer: {
    display: 'flex',
  },
  iconContainerContent: {
    flex: '1 0 auto',
    display: 'flex',
  },
  primaryText: {
    fontWeight: 600,
  },
  found: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
    overflow: 'hidden',
    '& p': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  label: {
    display: 'block',
    cursor: 'pointer',
    textAlign: 'left',
    marginLeft: 5,
  },
  input: {
    padding: 2,
  },
  find: {
    padding: 5,
  },
});

const ItemCheckBox = ({
  classes,
  onChange,
  name,
  value,
  found,
  onSelectionDrag,
  selected,
  onClick,
  type,
}) => {
  const error = value?.selector === 'box' && !found;
  return (
    <div
      aria-hidden="true"
      className={classNames(classes.root, {
        [classes.selected]: selected,
        [classes.error]: error,
      })}
      onClick={onClick}
    >
      <div className={classes.iconContainer}>
        <Checkbox
          color="primary"
          classes={{ root: classes.input }}
          name={name}
          value={!!value}
          onChange={e => {
            onChange({
              name,
              selector: type === 'xml' ? 'xpath' : 'box',
              selected: e.target.checked,
            });
          }}
          type="checkbox"
          checked={!!value}
          id={`ck-${name}`}
        />
        <label htmlFor={`ck-${name}`} className={classes.label}>
          <Typography className={classes.primaryText} variant="subtitle1">
            {capitalize(I18n.t(`HrBot.fields.${name}`))}
          </Typography>
        </label>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.space} />
        <div className={classes.content}>
          {value && (
            <FormControlLabel
              label={I18n.t('HrBot.User defined')}
              control={
                <Switch
                  size="small"
                  onClick={e => e.stopPropagation()}
                  checked={value.selector === 'user'}
                  onChange={e => {
                    onChange({
                      ...value,
                      selected: true,
                      selector: e.target.checked ? 'user' : 'box',
                      ...(e.target.checked ? { defaultValue: 'previousMonth' } : {}),
                    });
                  }}
                  className={classes.switch}
                />
              }
            />
          )}
          <div className={classes.found}>
            {value?.selector === 'box' && (
              <>
                {error ? (
                  <Typography color="error">{I18n.t('HrBot.not found')}</Typography>
                ) : (
                  <Typography color="secondary">{found}</Typography>
                )}
                <IconButton onClick={onSelectionDrag} className={classes.find} size="large">
                  <SelectionDragIcon />
                </IconButton>
              </>
            )}
            {value?.selector === 'xpath' && (
              <>
                {error ? (
                  <Typography color="error">{I18n.t('HrBot.not found')}</Typography>
                ) : (
                  <Typography color="secondary">{found}</Typography>
                )}
                <IconButton onClick={onSelectionDrag} className={classes.find} size="large">
                  <SelectionDragIcon />
                </IconButton>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ItemCheckBox.propTypes = {
  selected: bool.isRequired,
  classes: object.isRequired,
  name: string.isRequired,
  value: object,
  onChange: func.isRequired,
  found: string,
  onSelectionDrag: func.isRequired,
  onClick: func,
  type: string.isRequired,
};

ItemCheckBox.defaultProps = {
  onClick: () => null,
  found: null,
  value: null,
};

export default withStyles(styles)(ItemCheckBox);
