import { connect } from 'react-redux';
import isCompanyAdmin from 'store/selectors/isCompanyAdmin';
import populateChannels from 'store/selectors/populateChannels';
import populateTeams from 'store/selectors/populateTeams';

const mapStateToProps = state => ({
  channels: populateChannels(state),
  teams: populateTeams(state),
  isCompanyAdmin: isCompanyAdmin(state),
});

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps);
