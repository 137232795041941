/* eslint-disable operator-linebreak,react/destructuring-assignment,no-console */
import React, { Component } from 'react';
import { object, shape, bool } from 'prop-types';
import { Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import isEmpty from 'lodash/isEmpty';
import Attachments from 'services/api/Attachments';
import channelShape from 'shapes/channel';
import DropzoneBox from '../../../../common/DropzoneBox';
import connector from './connector';
import Modal from '../../Modal';

const styles = () => ({
  root: {
    minWidth: 400,
  },
  title: {
    padding: '0 10px',
  },
});

class AcknowledgementCreate extends Component {
  state = {
    error: false,
    loading: false,
  };

  onDrop = async files => {
    if (isEmpty(files)) return;
    this.setState({ loading: true });

    await this.upload(files[0]);

    this.setState({ loading: false });
  };

  upload = async file => {
    const {
      actions: { acknowledgmentCreateModal, acknowledgmentPreviewModal },
      channel,
    } = this.props;

    try {
      const attachment = await Attachments.upload(channel.company, channel._id, file);

      acknowledgmentCreateModal.saveTemporarytempAttachment(attachment);
      acknowledgmentPreviewModal.open();
      acknowledgmentCreateModal.close();
    } catch (error) {
      console.error(error);
      this.setState({ error: true });
    }
  };

  render() {
    const { classes, isOpen, actions } = this.props;
    const { error, loading } = this.state;

    return (
      <Modal
        className={classes.root}
        isOpen={isOpen}
        onClose={actions.acknowledgmentCreateModal.close}
      >
        <Typography className={classes.title} variant="subtitle1" gutterBottom>
          {I18n.t('AcknowledgementCreate.Add acknowledge message')}
        </Typography>
        <Divider light />
        <DropzoneBox
          onDrop={this.onDrop}
          error={error}
          loading={loading}
          onClick={this.openDropzone}
        />
      </Modal>
    );
  }
}

AcknowledgementCreate.propTypes = {
  channel: channelShape.isRequired,
  classes: object.isRequired,
  isOpen: bool.isRequired,
  actions: shape({
    acknowledgmentCreateModal: object.isRequired,
    acknowledgmentPreviewModal: object.isRequired,
  }).isRequired,
};

export default withStyles(styles)(connector(AcknowledgementCreate));
