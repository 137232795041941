/* eslint-disable react/no-array-index-key */
import { IconButton, InputAdornment, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import LastMessage from 'components/@home/@messages/ChannelsPanel/Channels/Channel/LastMessage';
import AttachmentButton from 'components/@home/@settings/Tabs/AttachmentButton';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import connector from 'components/@home/@settings/Tabs/connector';
import FormikChipInput from 'components/formik/ChipInput';
import TextField from 'components/formik/TextField';
import { FastField, Field, FieldArray, useFormikContext } from 'formik';
import DeleteIcon from 'mdi-react/DeleteIcon';
import { string } from 'prop-types';
import React, { useCallback } from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.secondary.light}`,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  attachment: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
  },
  lastMessage: {
    padding: 10,
    width: '100%',
  },
  inputWrapper: {
    '& + $inputWrapper': {
      borderTop: `1px solid ${theme.palette.secondary.light}`,
    },
    '&:hover $delete': {
      visibility: 'inherit',
    },
    '&:hover:last-child $delete': {
      visibility: 'hidden',
    },
  },
  input: {
    marginBottom: -1,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingTop: 0,
    border: 0,
  },
  delete: {
    visibility: 'hidden',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
}));
const MessageArray = ({ field }) => {
  const classes = useStyles();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const { value: messages } = getFieldProps(field);
  if (messages.length === 0 || messages[messages.length - 1].text) {
    setFieldValue(field, [...messages, { _id: `${Math.random()}`, text: '' }]);
  }
  const render = useCallback(
    arrayHelpers => {
      const renderMessage = (message, idx) =>
        message.attachments?.[0] ? (
          <Typography
            component="div"
            className={classnames(classes.inputWrapper, classes.attachment)}
          >
            <div className={classes.lastMessage}>
              <LastMessage
                lastMessage={{
                  ...message,
                  from: { _id: 1 },
                }}
              />
            </div>
            <InputAdornment position="end" className={classes.delete}>
              <IconButton aria-label="delete" onClick={() => arrayHelpers.remove(idx)} size="small">
                <DeleteIcon />
              </IconButton>
            </InputAdornment>
          </Typography>
        ) : (
          <FastField
            component={TextField}
            name={`${field}.${idx}.text`}
            placeholder={I18n.t('ChatFlowSettings.Bot says')}
            InputProps={{
              multiline: true,
              classes: { root: classes.input },
              endAdornment: (
                <>
                  {!messages[idx].text && (
                    <InputAdornment position="end">
                      <AttachmentButton field={`${field}.${idx}`} />
                    </InputAdornment>
                  )}
                  <InputAdornment position="end" className={classes.delete}>
                    <IconButton
                      aria-label="delete"
                      onClick={() => arrayHelpers.remove(idx)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            className={classes.inputWrapper}
          />
        );
      return messages.map(renderMessage);
    },
    [
      classes.attachment,
      classes.delete,
      classes.input,
      classes.inputWrapper,
      classes.lastMessage,
      field,
      messages,
    ],
  );
  return (
    <div>
      <div className={classes.root}>
        <FieldArray name={field} render={render} />
      </div>
      {messages.length > 1 && (
        <>
          <Typography className={classes.heading}>
            {I18n.t('ChatFlowSettings.Suggestions')}
          </Typography>
          <ChatFlowCaption
            section="Suggestions"
            translationProps={{ count: messages.length - 1 }}
          />
          <Field
            className={classes.field}
            component={FormikChipInput}
            placeholder={I18n.t('ChatFlowSettings.Add suggestions')}
            name={`${field}Suggestions`}
            creatable
          />
        </>
      )}
    </div>
  );
};

MessageArray.propTypes = {
  field: string.isRequired,
};

export default connector(MessageArray);
