import DriveFileRenameOutline from '@mui/icons-material/DriveFileRenameOutline';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import { EditDrawerContext } from 'components/@home/@documents/SentDocuments/EditDrawer';
import { SendDrawerContext as SendDocumentFormatDrawerContext } from 'components/@home/@documents/SentDocuments/SendDrawerContext';
import FolderDialog from 'components/@home/@documents/SharedDocuments/FolderDialog';
import { SendDrawerContext } from 'components/@home/@documents/SharedDocuments/SendDrawer';
import { ShareDrawerContext } from 'components/@home/@documents/SharedDocuments/ShareDrawer';
import DeleteDialog from 'components/common/DeleteDialog';
import AccountPlusOutlineIcon from 'mdi-react/AccountPlusOutlineIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import EditIcon from 'mdi-react/EditIcon';
import RestoreClockIcon from 'mdi-react/RestoreClockIcon';
import SendIcon from 'mdi-react/SendIcon';
import TrashCanOutlineIcon from 'mdi-react/TrashCanOutlineIcon';
import { bool, func, object, oneOf } from 'prop-types';
import React, { memo, useContext, useRef } from 'react';
import { I18n } from 'react-redux-i18n';
import useOpenClose from 'utils/useOpenClose';

const FolderCellMenu = ({ folder, isOpen, open, close, actions, section }) => {
  const anchorEl = useRef();
  const [renameIsOpen, renameOpen, renameClose] = useOpenClose();
  const shareDrawer = useContext(ShareDrawerContext);
  const sendDrawer = useContext(SendDrawerContext);
  const sendDocumentFormatDrawer = useContext(SendDocumentFormatDrawerContext);
  const editDrawer = useContext(EditDrawerContext);
  const [isDeleteOpen, openDelete, closeDelete] = useOpenClose();

  const stopPropagation = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleRename = () => {
    renameOpen();
    close();
  };
  const handleDelete = () => {
    close();
    openDelete();
  };
  const handleConfirmDelete = () => {
    actions.destroy(folder._id);
    closeDelete();
  };
  const handleShareSubmit = ({ permissions, teamsRemoved } = {}) => {
    if (!permissions) {
      return;
    }
    actions.edit({ id: folder._id, body: { permissions, teamsRemoved } });
  };
  const handleShare = () => {
    shareDrawer.open(folder, handleShareSubmit);
    close();
  };
  const handleSendSubmit = ({ file, sendTo, text }) => {
    if (section === 'home') {
      actions.sendMine({ id: file._id, sendTo, text });
    } else {
      actions.send({ id: file._id, sendTo, text });
    }
  };
  const handleDotsClick = e => {
    stopPropagation(e);
    open();
  };
  const handleRenameSubmit = name => {
    renameClose();
    actions.edit({ id: folder._id, body: { name } });
  };
  const handleRestore = () => {
    actions.restore(folder._id);
    close();
  };
  const handleSend = () => {
    if (folder.kind === 'DocumentFormat') {
      sendDocumentFormatDrawer.open(folder, handleSendSubmit);
    } else {
      sendDrawer.open(folder, handleSendSubmit);
    }
    close();
  };
  const handleEdit = () => {
    editDrawer.open(folder);
    close();
  };
  const menuOptions =
    folder.deleted && folder.isAdmin
      ? [
          <MenuItem key="restore" onClick={handleRestore}>
            <ListItemIcon>
              <RestoreClockIcon />
            </ListItemIcon>
            <Typography variant="inherit">{I18n.t('FolderCellMenu.Restore')}</Typography>
          </MenuItem>,
          <MenuItem key="delete-forever" onClick={handleDelete}>
            <ListItemIcon>
              <TrashCanOutlineIcon />
            </ListItemIcon>
            <Typography variant="inherit">{I18n.t('FolderCellMenu.Delete forever')}</Typography>
          </MenuItem>,
        ]
      : [
          ...(folder.kind === 'Document' || (folder.kind === 'DocumentFormat' && folder.isAdmin)
            ? [
                <MenuItem key="send" onClick={handleSend}>
                  <ListItemIcon>
                    <SendIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">{I18n.t('FolderCellMenu.Send')}</Typography>
                </MenuItem>,
              ]
            : []),
          ...(folder.isAdmin
            ? [
                <MenuItem
                  key="edit"
                  onClick={folder.kind === 'DocumentFormat' ? handleEdit : handleRename}
                >
                  <ListItemIcon>
                    {folder.kind === 'DocumentFormat' ? <EditIcon /> : <DriveFileRenameOutline />}
                  </ListItemIcon>
                  <Typography variant="inherit">
                    {I18n.t(
                      `FolderCellMenu.${folder.kind === 'DocumentFormat' ? 'Edit' : 'Rename'}`,
                    )}
                  </Typography>
                </MenuItem>,
                <MenuItem key="share" onClick={handleShare}>
                  <ListItemIcon>
                    <AccountPlusOutlineIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">{I18n.t('FolderCellMenu.Share')}</Typography>
                </MenuItem>,
                <MenuItem key="delete" onClick={handleDelete}>
                  <ListItemIcon>
                    <TrashCanOutlineIcon />
                  </ListItemIcon>
                  <Typography variant="inherit">{I18n.t('FolderCellMenu.Delete')}</Typography>
                </MenuItem>,
              ]
            : []),
        ];

  if (menuOptions.length === 0) {
    return null;
  }
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={stopPropagation}>
      <IconButton ref={anchorEl} edge="end" aria-label="comments" onClick={handleDotsClick}>
        <DotsVerticalIcon />
      </IconButton>
      <Menu open={!!isOpen} onClose={close} anchorEl={anchorEl.current}>
        {menuOptions}
      </Menu>
      <DeleteDialog
        message={I18n.t(
          `FolderCellMenu.${folder.deleted ? 'ConfirmDeleteForever' : 'ConfirmDelete'}`,
          {
            name: folder?.name,
          },
        )}
        confirmText={I18n.t(`FolderCellMenu.${folder.deleted ? 'Delete forever' : 'Delete'}`)}
        isOpen={isDeleteOpen}
        onConfirm={handleConfirmDelete}
        onClose={closeDelete}
      >
        <Typography>
          {I18n.t(`FolderCellMenu.${folder.deleted ? 'WillBeDeletedForever' : 'WillBeDeleted'}`, {
            name: folder?.name,
          })}
        </Typography>
      </DeleteDialog>
      <FolderDialog
        folder={folder}
        onSubmit={handleRenameSubmit}
        onClose={renameClose}
        open={renameIsOpen}
      />
    </div>
  );
};

FolderCellMenu.propTypes = {
  actions: object,
  close: func.isRequired,
  folder: object.isRequired,
  isOpen: bool.isRequired,
  open: func.isRequired,
  section: oneOf(['chats', 'home', 'sent', 'shared']).isRequired,
};

FolderCellMenu.defaultProps = {
  actions: {},
};

const areEqual = (prevProps, nextProps) =>
  prevProps.folder._id === nextProps.folder._id &&
  prevProps.isOpen === nextProps.isOpen &&
  prevProps.folder.isAdmin === nextProps.folder.isAdmin;

export default memo(FolderCellMenu, areEqual);
