import { Close, RemoveCircleOutline } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import { makeStyles } from '@mui/styles';
import { ObjectId } from 'bson';
import cx from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';
import {
  Typography,
  Box,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  IconButton,
  Button,
  FormHelperText,
  FormLabel,
} from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { I18n } from 'react-redux-i18n';
import { darken } from '@mui/material/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    border: '1px solid',
    borderColor: theme.palette.divider,
    borderRadius: 8,
    padding: 16,
    alignItems: 'baseline',
    paddingRight: 24,
    marginRight: 20,
  },
  condition: {
    display: 'grid',
    gap: '0 0.5rem',
    gridTemplateColumns: '35% 25% min-content',
    '&  .MuiSelect-select': {
      padding: 8,
    },
    width: '100%',
    '&$withValue': {
      gridTemplateColumns: '35% 20% 35% min-content',
    },
  },
  withValue: {},
  remove: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: darken(theme.palette.background.default, 0.05),
    },
  },
  button: {
    textTransform: 'none',
  },
  select: {
    width: '100%',
    maxWidth: '100%',
    '& .MuiSelect-select': {
      padding: 8.5,
      lineHeight: '1.4375em',
    },
  },
}));

const RuleCard = ({ index, remove: removeMe }) => {
  const classes = useStyles();
  const conditionsName = `rules.${index}.conditions`;
  const { fields, append, remove } = useFieldArray({
    name: conditionsName,
  });
  const {
    watch,
    formState: { errors },
  } = useFormContext();
  return (
    <Box className={classes.root}>
      {fields.map((field, index2) => {
        const showValue = watch(`rules.${index}.conditions.${index2}.operator`) !== 'has_any_value';
        return (
          <>
            {index2 > 0 && (
              <Typography
                color="muted.main"
                variant="caption"
                sx={{ alignSelf: 'center', marginBottom: 0.5 }}
              >
                {I18n.t('RulesDrawer.And')}
              </Typography>
            )}
            <Box
              key={field._id}
              className={cx(classes.condition, { [classes.withValue]: showValue })}
            >
              <Controller
                render={({
                  field: { onChange, value, name: fieldName },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl fullWidth sx={{ minWidth: 0 }} error={invalid} size="small">
                    <InputLabel id={fieldName} shrink={false}>
                      {!value && I18n.t('RulesDrawer.formFields.field')}
                    </InputLabel>
                    <Select
                      classes={{ select: classes.select }}
                      labelId={fieldName}
                      className={classes.select}
                      value={value}
                      onChange={onChange}
                    >
                      {['code', 'department', 'jobTitle', 'location', 'reportsTo', 'tags'].map(
                        fieldValue => (
                          <MenuItem key={fieldValue} value={fieldValue}>
                            {I18n.t(`RulesDrawer.fields.${fieldValue}`)}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                    {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
                name={`${conditionsName}.${index2}.field`}
                rules={{ required: I18n.t('ValidationErrors.Required', { name: '' }) }}
              />

              <Controller
                render={({
                  field: { onChange, value, name: fieldName },
                  fieldState: { invalid, error },
                }) => (
                  <FormControl fullWidth sx={{ minWidth: 0 }} error={invalid} size="small">
                    <InputLabel id={fieldName} shrink={false}>
                      {!value && I18n.t('RulesDrawer.formFields.operator')}
                    </InputLabel>
                    <Select
                      classes={{ select: classes.select }}
                      labelId={fieldName}
                      className={classes.select}
                      value={value}
                      onChange={onChange}
                    >
                      <MenuItem value="in">{I18n.t('RulesDrawer.operators.in')}</MenuItem>
                      <MenuItem value="not_in">{I18n.t('RulesDrawer.operators.notIn')}</MenuItem>
                      <MenuItem value="has_any_value">
                        {I18n.t('RulesDrawer.operators.hasAnyValue')}
                      </MenuItem>
                      <MenuItem value="contains">
                        {I18n.t('RulesDrawer.operators.contains')}
                      </MenuItem>
                      <MenuItem value="not_contains">
                        {I18n.t('RulesDrawer.operators.notContains')}
                      </MenuItem>
                    </Select>
                    {invalid && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
                name={`${conditionsName}.${index2}.operator`}
                rules={{ required: I18n.t('ValidationErrors.Required', { name: '' }) }}
              />

              {showValue && (
                <Controller
                  render={({
                    field: { onChange, value: fieldValue, name: fieldName },
                    fieldState: { invalid, error },
                  }) => {
                    const valueOptions = [];
                    return (
                      <Autocomplete
                        multiple
                        freeSolo
                        ChipProps={{
                          size: 'small',
                          sx: { height: 18, margin: '2.5px !important', borderRadius: 1 },
                          deleteIcon: <Close />,
                        }}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                        value={[...fieldValue]}
                        onBlur={event => {
                          const val = event.target.value;
                          if (val) {
                            // eslint-disable-next-line no-param-reassign
                            event.target.value = '';
                            onChange({
                              target: { name: event.target.name, value: [...fieldValue, val] },
                              type: event.type,
                            });
                          }
                        }}
                        clearOnBlur
                        onChange={(event, value) => {
                          onChange({
                            target: { name: event.target.name, value },
                            type: event.type,
                          });
                        }}
                        classes={{
                          inputRoot: classes.inputRoot,
                          popper: classes.popper,
                        }}
                        fullWidth
                        disableClearable
                        options={valueOptions}
                        className={classes.select}
                        renderInput={({ ref, ...params }) => (
                          <TextField
                            inputRef={ref}
                            {...params}
                            error={invalid}
                            helperText={error?.message}
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              id: fieldName,
                            }}
                            placeholder={I18n.t('RulesDrawer.formFields.value')}
                          />
                        )}
                      />
                    );
                  }}
                  name={`${conditionsName}.${index2}.value`}
                  rules={{ required: I18n.t('ValidationErrors.Required', { name: '' }) }}
                />
              )}
              <IconButton
                sx={{
                  top: 0,
                  height: 40,
                  visibility: index2 === 0 ? 'hidden' : 'visible',
                }}
                onClick={() => remove(index2)}
              >
                <Close />
              </IconButton>
            </Box>
          </>
        );
      })}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
          width: 'calc(90% + 17px)',
        }}
      >
        <Button
          className={classes.button}
          startIcon={<Add />}
          onClick={() =>
            append({
              _id: new ObjectId().toString(),
              operator: '',
              value: [],
              field: '',
            })
          }
        >
          {I18n.t('RulesDrawer.AddCondition')}
        </Button>
        <Controller
          render={({
            field: { onChange, value, name: fieldName },
            fieldState: { invalid, error },
          }) => (
            <FormControl
              error={invalid}
              size="small"
              sx={{ flexDirection: 'row', alignItems: 'center', gap: 2 }}
            >
              <FormLabel id={fieldName} sx={{ whiteSpace: 'nowrap' }}>
                {I18n.t('RulesDrawer.formFields.role')}
              </FormLabel>
              <Select
                sx={{ minWidth: 150 }}
                classes={{ select: classes.select }}
                className={classes.select}
                value={value}
                onChange={onChange}
              >
                {['member', 'admin', 'guest'].map(role => (
                  <MenuItem key={role} value={role}>
                    {I18n.t(`Roles.${role}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          name={`rules.${index}.role`}
          rules={{ required: I18n.t('ValidationErrors.Required', { name: '' }) }}
        />
      </Box>
      <IconButton className={classes.remove} onClick={removeMe}>
        <RemoveCircleOutline />
      </IconButton>
    </Box>
  );
};

RuleCard.propTypes = {
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

export default RuleCard;
