import makeStyles from '@mui/styles/makeStyles';
import ShowMoreText from 'react-show-more-text';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  anchor: {
    color: theme.palette.primary.main,
  },
}));
const MyShowMoreText = props => {
  const classes = useStyles();
  const newProps = {
    lines: 2,
    anchorClass: classes.anchor,
    more: I18n.t('Show more'),
    less: I18n.t('Show less'),
    ...props,
  };
  return <ShowMoreText {...newProps} />;
};

export default MyShowMoreText;
