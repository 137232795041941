import { useFeatureToggle } from '@flopflip/react-redux';
import withStyles from '@mui/styles/withStyles';
import AnalyticsToolbar from 'components/@home/@analytics/AnalyticsToolbar';
import Loading from 'components/common/Loading';
import deferRender from 'defer-render-hoc';
import { object } from 'prop-types';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import companyShape from 'shapes/company';
import employeesStatus from 'store/selectors/employeesStatus';
import populateTeams from 'store/selectors/populateTeams';
import useWindowSize from 'utils/useWindowSize';
import connector from './connector';

const ActivationPerTeam = React.lazy(() => import('./ActivationPerTeam'));
const ActivationTotal = React.lazy(() => import('./ActivationTotal'));
const ActiveUsers = React.lazy(() => import('./ActiveUsers'));
const MessageCounts = React.lazy(() => import('./MessageCounts'));
const UserSessionsDaily = React.lazy(() => import('./UserSessionsDaily'));
const UsersPerPlatform = React.lazy(() => import('./UsersPerPlatform'));

const styles = theme => ({
  root: {
    flex: '1 0 auto',
    minHeight: '100%',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const AnalyticsScene = ({ authCompany, classes }) => {
  const areUserAnalyticsEnabled =
    useFeatureToggle('user-analytics') || window?.location?.hostname?.match(/staging/);
  const windowSize = useWindowSize();
  const width = windowSize.width > 800 ? (windowSize.width - 260) * 0.6 : windowSize.width - 100;
  const height = windowSize.width > 800 ? width / 2 : width;
  const teams = useSelector(populateTeams);
  const { isLoading } = useSelector(employeesStatus);

  const ActivationComponent =
    !isLoading && (!teams || teams.length < 2) ? ActivationTotal : ActivationPerTeam;

  return (
    <div className={classes.root}>
      <AnalyticsToolbar />
      <Suspense fallback={<Loading className={classes.loading} />}>
        {!isLoading && (
          <ActivationComponent
            company={authCompany}
            keys={['active', 'pending', 'blocked']}
            width={width}
            height={height}
          />
        )}
        <ActiveUsers width={width} height={height} />
        <MessageCounts width={width} height={height} />
        {!areUserAnalyticsEnabled && (
          <>
            <UserSessionsDaily width={width} height={height} />
            <UsersPerPlatform width={width} height={height} />
          </>
        )}
      </Suspense>
    </div>
  );
};

AnalyticsScene.propTypes = {
  classes: object.isRequired,
  authCompany: companyShape.isRequired,
};

export default deferRender(withStyles(styles)(connector(AnalyticsScene)));
