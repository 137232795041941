/* eslint-disable react/jsx-curly-spacing,react/jsx-one-expression-per-line */
import { Checkbox, FormControl, FormControlLabel, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import NextButton from 'components/@auth/NextButton';
import { bool, func, number, object } from 'prop-types';
import React, { useRef } from 'react';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';
import withNavigate from 'utils/withNavigate';
import connector from '../../connector';
import formik from './formik';
import PhonePrefixField from './PhonePrefixField';
import prefixFormat from './prefixFormat';

const styles = theme => ({
  input: {
    marginBottom: 10,
  },
  link: {
    color: theme.palette.primary.main,
  },
});

function LoginForm(props) {
  const pass = useRef();
  const { classes, values, errors, submitCount, handleBlur, handleSubmit, isSubmitting } = props;
  const hasSubmitted = submitCount > 0;
  const handleSelect = event => {
    const { actions, setFieldValue } = props;
    actions.login.form.update({ prefix: event.value });
    setFieldValue('prefix', event.value);
  };
  const handleChange = e => {
    const { actions } = props;
    const value = ['terms', 'keepSessionOpen'].includes(e.target.name)
      ? e.target.checked
      : e.target.value;
    actions.login.form.update({ [e.target.name]: value });
    if (pass.current) {
      pass.current.parentNode?.removeChild(pass.current);
      pass.current = null;
    }
    props.handleChange(e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth className={classes.input}>
        <PhonePrefixField
          value={values.prefix}
          error={!!(hasSubmitted && errors.prefix)}
          onChange={handleSelect}
          helperText={hasSubmitted && errors.prefix}
          name="prefix"
        />
      </FormControl>
      <FormControl fullWidth className={classes.input}>
        <NumberFormat
          error={!!(errors.phone && hasSubmitted)}
          helperText={hasSubmitted && errors.phone}
          customInput={TextField}
          format={prefixFormat(values.prefix)}
          mask="_"
          name="phone"
          placeholder={I18n.t('Login.phone placeholder')}
          type="tel"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="username phone"
        />
      </FormControl>
      <TextField
        style={{
          height: 0,
          overflow: 'hidden',
          padding: 0,
          margin: 0,
          display: 'block',
        }}
        type="password"
        autoComplete="current-password"
        ref={pass}
      />
      <FormControlLabel
        className={classes.input}
        control={
          <Checkbox
            name="keepSessionOpen"
            checked={values.keepSessionOpen[0] === 'on'}
            onChange={handleChange}
            value="on"
            color="primary"
          />
        }
        label={<Typography>{I18n.t('LoginConfirmation.Keep session open')}</Typography>}
      />
      <NextButton
        fullWidth
        variant="contained"
        type="submit"
        color="primary"
        disabled={isSubmitting}
      >
        {I18n.t('Login.next')}
      </NextButton>
    </form>
  );
}

LoginForm.propTypes = {
  classes: object.isRequired,
  values: object.isRequired,
  errors: object.isRequired,
  handleChange: func.isRequired,
  handleBlur: func.isRequired,
  handleSubmit: func.isRequired,
  setFieldValue: func.isRequired,
  isSubmitting: bool.isRequired,
  submitCount: number.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(withNavigate(formik(LoginForm))));
