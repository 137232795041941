import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import { object, string } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  summaryRoot: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.ultraLight,
    },
    '&.Mui-expanded': {
      minHeight: 0,
      '&:hover': {
        backgroundColor: 'initial',
      },
    },
  },
  details: {
    flexDirection: 'column',
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

const ChatFlowSection = ({ section, caption, children }) => {
  const classes = useStyles();

  return (
    <Accordion square defaultExpanded elevation={0} classes={{ root: classes.root }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${section}-content`}
        id={`${section}-header`}
        classes={{ root: classes.summaryRoot, content: classes.summaryContent }}
      >
        <Typography display="block" className={classes.heading}>
          {I18n.t(`ChatFlowSettings.${section}`)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.details }}>
        {caption && <ChatFlowCaption section={section} />}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

ChatFlowSection.propTypes = {
  caption: string,
  section: string.isRequired,
  children: object,
};

ChatFlowSection.defaultProps = {
  caption: false,
  children: null,
};

export default ChatFlowSection;
