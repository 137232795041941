import React from 'react';
import { arrayOf, bool, object } from 'prop-types';
import { Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import userShape from 'shapes/user';
import acknowledgesShape from 'shapes/acknowledge';
import moment from 'moment';
import connector from './connector';

const styles = {
  root: {
    marginTop: 10,
  },
};

const AcknowledgeCation = ({ classes, acknowledges, authUser, digitalSignature, form }) => {
  const acknowledge = acknowledges && acknowledges.find(m => m.user === authUser?._id);
  if (!acknowledge) return null;

  const date = moment.unix(acknowledge.confirmedAt).format('LL');

  let message = '';
  if (acknowledge && digitalSignature) {
    message = `${I18n.t('AcknowledgementMessage.Digital signature made on')} ${date}`;
  } else if (form) {
    message = `${I18n.t('AcknowledgementMessage.Form sent on')} ${date}`;
  } else if (acknowledge) {
    message = `${I18n.t('AcknowledgementMessage.Acknowledge made on')} ${date}`;
  }
  return (
    <Typography className={classes.root} align="center" variant="caption">
      {message}
    </Typography>
  );
};

AcknowledgeCation.propTypes = {
  classes: object.isRequired,
  acknowledges: arrayOf(acknowledgesShape).isRequired,
  authUser: userShape,
  digitalSignature: bool,
};

AcknowledgeCation.defaultProps = {
  authUser: null,
  digitalSignature: false,
};

export default withStyles(styles)(connector(AcknowledgeCation));
