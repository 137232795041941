/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { number, func, object, string } from 'prop-types';
import { Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { I18n } from 'react-redux-i18n';
import ErrorCaption from './ErrorCaption';
import PeopleSelected from './PeopleSelected';
import Search from './Search';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
  },
  actions: {
    display: 'flex',
    alignItem: 'center',
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 600,
  },
});

class EnhancedTableToolbar extends Component {
  state = {
    searchValue: '',
  };

  handleSearch = e => {
    const { onSearch } = this.props;
    this.setState({ searchValue: e.target.value });
    onSearch(e.target.value);
  };

  render() {
    const { selected, error, classes } = this.props;
    return (
      <Toolbar className={classes.root}>
        <Typography component="div" className={classes.title} variant="subtitle1">
          {I18n.t('TeamDrawer.EmployeesTable.Select member for this team')}
        </Typography>
        <div className={classes.actions}>
          <ErrorCaption>{error}</ErrorCaption>
          <PeopleSelected selected={selected} />
          <Search onChange={this.handleSearch} value={this.state.searchValue} />
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: object.isRequired,
  selected: number.isRequired,
  error: string,
  onSearch: func.isRequired,
};

EnhancedTableToolbar.defaultProps = {
  error: '',
};

export default withStyles(styles)(EnhancedTableToolbar);
