import { Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
    marginBottom: 16,
    marginTop: 24,
  },
  actions: {
    display: 'flex',
  },
  title: {
    fontWeight: 600,
  },
}));

const AnalyticsToolbar = () => {
  const classes = useStyles();
  return (
    <Toolbar className={classes.root}>
      <Typography component="div" className={classes.title} variant="h5">
        {I18n.t('Header.tabs.Analytics')}
      </Typography>
      <div className={classes.actions} />
    </Toolbar>
  );
};

export default AnalyticsToolbar;
