import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@mui/material';
import Box from '@mui/material/Box';
import { bool, func, node, string } from 'prop-types';
import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

const DeleteDialog = ({
  message,
  onClose,
  onConfirm,
  isOpen,
  typeBack,
  confirmText = I18n.t('DeleteDialog.Delete'),
  children,
}) => {
  const [typedBack, setTypedBack] = useState('');
  const [submitCount, setsubmitCount] = useState(0);
  const error = !!(typeBack && typedBack !== typeBack);

  const onChange = event => {
    if (submitCount > 0) {
      setsubmitCount(0);
    }
    setTypedBack(event.target.value);
  };

  const checkTypeBack = e => {
    e.preventDefault();
    e.stopPropagation();
    setsubmitCount(submitCount + 1);
    if (!error) {
      setTypedBack(null);
      setsubmitCount(0);
      onConfirm();
    }
  };

  const close = () => {
    setsubmitCount(0);
    setTypedBack(null);
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClick={e => e.stopPropagation()}
    >
      <form onSubmit={checkTypeBack}>
        <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {typeBack && (
          <FormControl style={{ padding: 24 }} fullWidth>
            <Box sx={{ whiteSpace: 'pre-wrap' }}>
              {I18n.t('DeleteDialog.Enter the phrase', { phrase: typeBack })}
            </Box>
            <TextField
              autoFocus
              fullWidth
              onChange={onChange}
              value={typedBack}
              error={error && submitCount > 0}
              helperText={error && submitCount > 0 && I18n.t('DeleteDialog.Phrases dont match')}
            />
          </FormControl>
        )}
        <DialogActions>
          <Button
            onClick={close}
            variant="outlined"
            color="secondary"
            sx={{ color: 'primary.ultraDark' }}
          >
            {I18n.t('DeleteDialog.Cancel')}
          </Button>
          <Button type="submit" variant="contained" color="primary" autoFocus={!typeBack}>
            {confirmText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  message: node.isRequired,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  isOpen: bool.isRequired,
  typeBack: string,
  confirmText: string,
  children: node,
};

DeleteDialog.defaultProps = {
  children: null,
  typeBack: null,
  confirmText: undefined,
};

export default DeleteDialog;
