import React, { Component } from 'react';
import { bool, object, func } from 'prop-types';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { I18n } from 'react-redux-i18n';
import Attachments from 'services/api/Attachments';
import channelShape from 'shapes/channel';
import CancelButton from './CancelButton';
import ConfirmButton from './ConfirmButton';
import DialogPicture from './DialogPicture';

class ClipboardImageDialog extends Component {
  state = {
    isLoading: false,
  };

  uploadAttachment = async () => {
    const { file, onClose, onConfirm, channel } = this.props;
    this.setState({ isLoading: true });

    try {
      const attachment = await Attachments.upload(channel.company, channel._id, file);
      onConfirm(attachment);
    } catch (e) {
      onClose();
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen, file, onClose } = this.props;
    const { isLoading } = this.state;

    if (!isOpen) return null;

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{I18n.t('PasteImageDialog.Title')}</DialogTitle>
        <DialogPicture file={file} />
        <DialogActions>
          <CancelButton onClick={onClose} />
          <ConfirmButton isLoading={isLoading} onClick={this.uploadAttachment} />
        </DialogActions>
      </Dialog>
    );
  }
}

ClipboardImageDialog.propTypes = {
  isOpen: bool.isRequired,
  file: object,
  onClose: func.isRequired,
  onConfirm: func.isRequired,
  channel: channelShape.isRequired,
};

ClipboardImageDialog.defaultProps = {
  file: null,
};

export default ClipboardImageDialog;
