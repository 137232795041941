/* eslint-disable no-param-reassign */
import withStyles from '@mui/styles/withStyles';
import { string, func, object, oneOfType } from 'prop-types';
import React, { Component } from 'react';
import ChatInput from './ChatInput';

const styles = {
  root: {
    flexGrow: 1,
    maxHeight: 72,
    overflow: 'auto',
  },
};

class ChatField extends Component {
  listenForEnter = async e => {
    const { onSend } = this.props;
    const code = e.keyCode ? e.keyCode : e.which;

    if (code === 13 && !e.shiftKey) {
      onSend(e);
    }
  };

  render() {
    const {
      classes,
      name,
      value,
      placeholder,
      onChange,
      onPaste,
      inputRef,
      suggestionsPortal,
    } = this.props;

    return (
      <div className={classes.root}>
        <ChatInput
          name={name}
          value={value}
          placeholder={placeholder}
          className={classes.root}
          onKeyPress={this.listenForEnter}
          onChange={onChange}
          onPaste={onPaste}
          inputRef={inputRef}
          suggestionsPortal={suggestionsPortal}
        />
      </div>
    );
  }
}

ChatField.defaultProps = {
  inputRef: () => {},
};

ChatField.propTypes = {
  classes: object.isRequired,
  placeholder: string.isRequired,
  name: string.isRequired,
  value: string.isRequired,
  onSend: func.isRequired,
  onChange: func.isRequired,
  onPaste: func.isRequired,
  inputRef: oneOfType([object, func]),
  suggestionsPortal: object.isRequired,
};

export default withStyles(styles)(ChatField);
