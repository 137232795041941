import { Button, SvgIcon, Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import FileDownload from 'mdi-react/FileDownloadIcon';
import { func, object, string } from 'prop-types';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import Search from './Search';

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(1),
    marginBottom: 16,
  },
  actions: {
    display: 'flex',
    color: theme.palette.text.secondary,
    flex: '1 0 auto'
  },
  title: {
    fontWeight: 600,
  },
  addEmployee: {},
  button: {
    minWidth: 36,
    margin: '0 10px',
    color: theme.palette.primary.ultraDark,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
});

class EnhancedTableToolbar extends Component {
  state = {
    searchValue: '',
  };

  handleSearch = e => {
    const { onSearch } = this.props;
    this.setState({ searchValue: e.target.value });
    onSearch(e.target.value);
  };

  render() {
    const { classes, tab, children, onExport } = this.props;
    const { searchValue } = this.state;
    return (
      <Toolbar className={classes.root}>
        <Typography component="div" className={classes.title} variant="h5">
          {I18n.t(`Header.tabs.${tab}`)}
        </Typography>
        <div className={classes.actions}>
          {children}
          <Search onChange={this.handleSearch} value={searchValue} />
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            onClick={onExport}
          >
            <SvgIcon className={classes.plus}>
              <FileDownload />
            </SvgIcon>
            {I18n.t('TimeTracking.Export report')}
          </Button>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: object.isRequired,
  onSearch: func.isRequired,
  tab: string.isRequired,
  children: object.isRequired,
  onExport: func.isRequired,
};

export default withStyles(styles)(EnhancedTableToolbar);
