/**
 *  @flow
 */
import { Button, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FormDrawerQuestions from 'components/@home/drawers/FormDrawer/FormDrawerQuestions';
import Headline from 'components/@home/drawers/FormDrawer/Headline';
import QuestionErrorMessage from 'components/@home/drawers/FormDrawer/QuestionErrorMessage';
import Title from 'components/@home/modals/acknowledgment/PreviewModal/Form/Title';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Checkbox from 'components/formik/Checkbox';
import TextField from 'components/formik/TextField';
import { Field, FieldArray, Form, useFormikContext } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { I18n } from 'react-redux-i18n';

type Props = {
  isEdit: boolean;
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    maxWidth: 'calc(100% - 60px)',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: 20,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    marginLeft: theme.spacing(0.5),
  },
  errors: {
    flex: '1 0 auto',
    textAlign: 'right',
    marginRight: theme.spacing(1),
  },
  textField: {
    margin: '0 0 25px',
    width: '100%',
  },
  title: {
    marginTop: theme.spacing(3),
  },
  space: {
    marginLeft: theme.spacing(4),
  },
}));

const FormDrawerContent = ({ isEdit }: Props) => {
  const [isOpen, setOpen] = useState(false);
  const classes = useStyles();
  const formik = useFormikContext();
  const {
    isSubmitting,
    submitCount,
    errors,
    values: { questions, adminOnlyDisabled },
  } = formik;
  const showError = !isEmpty(errors) && submitCount > 0;
  const handleSubmit = async e => {
    e.preventDefault();
    const err = await formik.validateForm();
    if (isEmpty(err)) {
      setOpen(true);
    } else {
      formik.submitForm();
    }
  };
  return (
    <Form className={classes.root} onSubmit={handleSubmit}>
      <Headline isEdit={isEdit} />
      <div className={classes.body}>
        {questions.length > 0 && (
          <div className={classes.title}>
            <Title>{I18n.t('FormDrawer.Form title')}</Title>
            <Field
              component={TextField}
              className={classes.textField}
              name="title"
              placeholder={I18n.t('FormDrawer.Form title placeholder')}
            />
          </div>
        )}
        <FieldArray name="questions" component={FormDrawerQuestions} />
      </div>
      <div className={classes.footer}>
        <Tooltip title={adminOnlyDisabled ? I18n.t('FormDrawer.Only admins read only') : ''}>
          <div>
            <Field
              name="adminOnly"
              component={Checkbox}
              disabled={adminOnlyDisabled}
              label={I18n.t('FormDrawer.Show answers only to admins')}
            />
          </div>
        </Tooltip>
        <div className={classes.space}>
          <Field
            name="anonymous"
            component={Checkbox}
            label={I18n.t('FormDrawer.Anonymous answers')}
          />
        </div>
        <div className={classes.errors}>
          {showError ? (
            <QuestionErrorMessage>
              {errors.server || I18n.t('FormDrawer.Please check errors above')}
            </QuestionErrorMessage>
          ) : null}
        </div>
        <Button
          variant="contained"
          disabled={isSubmitting || showError || isEmpty(questions)}
          className={classes.create}
          color="primary"
          type="submit"
        >
          {isSubmitting
            ? `${
                isEdit ? I18n.t('FormDrawer.Updating form') : I18n.t('FormDrawer.Creating form')
              }...`
            : `${isEdit ? I18n.t('FormDrawer.Update form') : I18n.t('FormDrawer.Create form')}`}
        </Button>
      </div>
      <ConfirmDialog
        message={I18n.t('FormDrawer.ConfirmAlert.Title')}
        buttons={[
          {
            text: I18n.t('FormDrawer.ConfirmAlert.Confirm'),
            variant: 'contained',
            color: 'primary',
            onClick: () => {
              setOpen(false);
              formik.submitForm();
            },
          },
          { text: I18n.t('FormDrawer.ConfirmAlert.Cancel') },
        ]}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      >
        <Typography>{I18n.t('FormDrawer.ConfirmAlert.Message')}</Typography>
        {!adminOnlyDisabled && (
          <>
            <br />
            <Field
              name="adminOnly"
              component={Checkbox}
              label={I18n.t('FormDrawer.Show answers only to admins')}
            />
          </>
        )}
        <br />
        <Field
          name="anonymous"
          component={Checkbox}
          label={I18n.t('FormDrawer.Anonymous answers')}
        />
      </ConfirmDialog>
    </Form>
  );
};

export default FormDrawerContent;
