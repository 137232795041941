/* eslint-disable react/jsx-props-no-spreading */
/**
 * @flow
 */

import Refresh from '@mui/icons-material/Refresh';
import Visibility from '@mui/icons-material/Visibility';
import { Button, InputAdornment, SvgIcon, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CopyButton from 'components/@home/drawers/ProfileDrawer/Profile/EmployeeActions/InviteLink/CopyButton';
import DeleteDialog from 'components/common/DeleteDialog';
import React, { forwardRef, useRef, useState } from 'react';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: theme.palette.secondary.ultraLight,
  },
  buttonWrapper: {
    display: 'flex',
    height: 33.5,
    gap: 4,
    marginRight: -10,
  },
  button: {
    backgroundColor: theme.palette.white,
    minHeight: 'auto',
    '&:disabled $icon': {
      color: 'inherit',
    },
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  regenerate: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  name: String;
  value: String;
  confirmRegenerate: Function;
  input?: Object;
};

const SecretField = forwardRef(
  ({ name, value, confirmRegenerate, input: Input, ...rest }: Props, ref) => {
    const inputRef = useRef();
    const [visible, setVisible] = useState(false);
    const [confirmRegenerateVisible, setConfirmRegenerateVisible] = useState(false);

    const classes = useStyles();

    const toggleVisible = () => {
      setVisible(prev => !prev);
    };

    const handleMouseDownPassword = event => {
      event.preventDefault();
      event.stopPropagation();
    };

    const select = event => {
      // event properties must be copied to use async
      const { target } = event;
      setTimeout(() => target.select(), 10);
    };

    const openConfirmRegenerate = () => {
      setConfirmRegenerateVisible(true);
    };

    const closeConfirmRegenerate = () => {
      setConfirmRegenerateVisible(false);
    };

    const handleConfirmRegenerate = () => {
      closeConfirmRegenerate();
      confirmRegenerate({ name, value });
    };

    return (
      <>
        <Input
          name={name}
          type={visible ? 'text' : 'password'}
          InputProps={{
            className: classes.input,
            readOnly: true,
            onSelect: select,
            onMouseDown: select,
            endAdornment: (
              <InputAdornment position="end">
                <div className={classes.buttonWrapper}>
                  <Button
                    className={classes.button}
                    size="small"
                    disabled={visible}
                    variant="outlined"
                    color="secondary"
                    onClick={toggleVisible}
                    onMouseDown={handleMouseDownPassword}
                    disableElevation
                  >
                    <SvgIcon className={classes.icon}>
                      <Visibility />
                    </SvgIcon>
                    {I18n.t('SecretField.Show')}
                  </Button>
                  <CopyButton
                    value={value}
                    inputRef={inputRef}
                    size="small"
                    ButtonProps={{ size: 'small', className: classes.button }}
                  />
                </div>
              </InputAdornment>
            ),
          }}
          {...rest}
          ref={ref}
          inputRef={inputRef}
        />
        <Button
          className={classes.regenerate}
          size="small"
          variant="outlined"
          onClick={openConfirmRegenerate}
          disableElevation
        >
          <SvgIcon className={classes.icon}>
            <Refresh />
          </SvgIcon>
          {I18n.t('SecretField.Regenerate')}
        </Button>
        <DeleteDialog
          confirmText={I18n.t('SecretField.Regenerate')}
          message={I18n.t('SecretField.ConfirmRegenerateTitle', {
            name: I18n.t(`SecretField.${name}`),
          })}
          isOpen={confirmRegenerateVisible}
          onConfirm={handleConfirmRegenerate}
          onClose={closeConfirmRegenerate}
        >
          <Typography>
            {I18n.t('SecretField.ConfirmRegenerateDescription', {
              name: I18n.t(`SecretField.${name}`),
            })}
          </Typography>
        </DeleteDialog>
      </>
    );
  },
);

SecretField.defaultProps = {
  input: TextField,
};

export default SecretField;
