import Http from 'services/Http';

class HrBot {
  async send(body) {
    const response = await Http.post(`/v1/hr-bot/send`, body);
    return response;
  }

  async checkIds({ ids }) {
    const response = await Http.post(`/v1/hr-bot/check-ids`, { ids });
    return response.data;
  }
}

export default new HrBot();
