/**
 * @flow
 */
import { OpenInNew } from '@mui/icons-material';
import { Box, Link, Typography } from '@mui/material';
import Control from 'components/@whistleblower-admin/pages/Portals/Control';
import SlugField from 'components/@whistleblower-admin/pages/Portals/SlugField';
import { useCanEdit } from 'components/@whistleblower-admin/WhistleblowerContext';
import { Field, useFormikContext } from 'formik';
import React from 'react';
import { I18n } from 'react-redux-i18n';

const SlugControl = () => {
  const { values } = useFormikContext();
  const canEdit = useCanEdit('portals');
  return (
    <Control
      name="slug"
      control={
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <Field
            disabled={!canEdit}
            component={SlugField}
            name="slug"
            sx={{ width: 2 / 3, maxWidth: 700 }}
          />
          <Typography>
            <Link
              href={`/secure#/${values.slug}`}
              sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', gap: 0.5 }}
              target="_blank"
            >
              <span>{I18n.t('WhistleblowerPortalConfig.OpenPortal')}</span>
              <OpenInNew sx={{ fontSize: 'inherit' }} />
            </Link>
          </Typography>
        </Box>
      }
    />
  );
};

export default SlugControl;
