import React, { Component } from 'react';
import { object } from 'prop-types';
import { Button, SvgIcon } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import PlusCircleIcon from 'mdi-react/PlusCircleIcon';
import { I18n } from 'react-redux-i18n';
import connector from './connector';

const styles = {
  plus: {
    fontSize: 18,
    marginRight: 5,
  },
};

class AddEmployeeButton extends Component {
  openDrawer = () => {
    const { actions } = this.props;
    actions.drawers.employee.open();
  };

  render() {
    const { classes } = this.props;
    return (
      <Button
        onClick={this.openDrawer}
        variant="contained"
        color="primary"
        className={classes.root}
      >
        <SvgIcon className={classes.plus}>
          <PlusCircleIcon />
        </SvgIcon>
        {I18n.t('PeoplesScene.Add employee')}
      </Button>
    );
  }
}

AddEmployeeButton.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
};

export default withStyles(styles)(connector(AddEmployeeButton));
